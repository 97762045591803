import { LocalizationTypes } from 'src/common/types';

import { RECOVERY_STAGE_FORM_PHONE_NUMBER_RU_LOCALIZATION } from './ru.localization';
import { RECOVERY_STAGE_FORM_PHONE_NUMBER_EN_LOCALIZATION } from './en.localization';


export const RECOVERY_STAGE_FORM_PHONE_NUMBER_LOCALIZATION = {
	[LocalizationTypes.RU]: RECOVERY_STAGE_FORM_PHONE_NUMBER_RU_LOCALIZATION,
	[LocalizationTypes.EN]: RECOVERY_STAGE_FORM_PHONE_NUMBER_EN_LOCALIZATION,
};
