import { useEffect, useState } from 'react';

import { FormField, FormSelectBox } from 'src/components';
import { TEducationType } from 'src/store';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_TYPE_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
	locale: string;
	items: TEducationType[];
};

type TOptionItem = {
	text: string;
	value: string;
};

export function AccountEducationTypeField(props: TProps) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		items = [],
	} = props;

	const {
		typeLegend,
		typeTitle,
		typePlaceholder,
	} = useLocalization(ACCOUNT_EDUCATION_TYPE_FIELD_LOCALIZATION);

	const [options, setOptions] = useState<TOptionItem[]>();

	useEffect(() => {
		if (items) {
			const arr = items.map(item => {
				const { alias, name } = item;

				return {
					value: alias,
					text: name,
				};
			});

			setOptions(arr);
		}
	}, [items]);

	return (
		<FormField
			legend={ typeLegend }
			errors={ errors }
			required
		>
			<FormSelectBox
				title={ typeTitle }
				placeholder={ typePlaceholder }
				items={ options }
				onChange={ val => setValue(val) }
				value={ value }
				name={ name }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
