import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_USERS } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { clearUserCreate, useAppDispatch } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { USERS_COMMON_LOCALIZATION } from '../../localization';

import { UserFormCreate } from './components';
import { USERS_CREATE_LOCALIZATION } from './localization';


export function UserCreateWidget() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const { title, headline, breadCrumbNew } = useLocalization(USERS_CREATE_LOCALIZATION);
	const { breadCrumbList } = useLocalization(USERS_COMMON_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearUserCreate());
		};
	}, []);

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_USERS, text: breadCrumbList },
		{ to: `${pathname}/`, text: breadCrumbNew },
	];

	const item = {
		nickname: '',
		password: '',
		image: '',
		updatedAt: '',
		createdAt: '',
	};

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<UserFormCreate { ...item } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
