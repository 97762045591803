import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearAccountCvUpdate, useAppDispatch, useAccountCvReadSelector, accountCvReadGetApi, clearAccountCvRead, useAccountCvsSelector, setAccountCvRead, clearAccountCvsCreatedItem, useAccountSourceStacksSelector, sourceStacksGetApi, useCredentialsSelector, useAccountSourceLanguageLevelSelector, useAccountSourceUserSexSelector, useAccountSourceUserCvStatusSelector, accountSourceLanguageLevelGetApi, accountSourceUserSexGetApi, accountSourceUserCvStatusGetApi, accountEducationsGetApi, useAccountEducationsSelector, useAccountExperiencesSelector, accountExperiencesGetApi } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidNickname, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_CVS, PATH_ACCOUNT_CVS_CREATE_END } from 'src/routes';

import { ACCOUNT_CVS_COMMON_LOCALIZATION } from '../../localization';
import { AccountCvsLocalesCreateWidget } from '../../widgets';

import { AccountCvsFormUpdate, AccountCvsDetails } from './components';
import { ACCOUNT_CVS_UPDATE_LOCALIZATION } from './localization';


export function AccountCvsUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname, hash } = useLocation();
	const { lang, languages } = useCredentialsSelector();
	const { list, isLoading: isLoadingItems } = useAccountSourceStacksSelector();
	const { items: languagesLevels, isLoading: isLoadingLanguagesLevel } = useAccountSourceLanguageLevelSelector();
	const { items: usersSex, isLoading: isLoadingUsersSex } = useAccountSourceUserSexSelector();
	const { items: usersCvStatuses, isLoading: isLoadingUsersCvStatuses } = useAccountSourceUserCvStatusSelector();
	const { list: education, isLoading: isLoadingEducation } = useAccountEducationsSelector();
	const { list: experience, isLoading: isLoadingExperience } = useAccountExperiencesSelector();
	const { alias = '' } = useParams();
	const { item: created } = useAccountCvsSelector();
	const { data: item, isLoading, error } = useAccountCvReadSelector();
	const { breadCrumbList } = useLocalization(ACCOUNT_CVS_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(ACCOUNT_CVS_UPDATE_LOCALIZATION);

	const isInvalidAlias = isInvalidNickname(alias);

	useEffect(() => {
		dispatch(sourceStacksGetApi(lang));
		dispatch(accountSourceLanguageLevelGetApi(lang));
		dispatch(accountSourceUserSexGetApi(lang));
		dispatch(accountSourceUserCvStatusGetApi(lang));
		dispatch(accountEducationsGetApi({ locale: lang }));
		dispatch(accountExperiencesGetApi({ locale: lang }));
	}, [lang]);

	useEffect(() => {
		const isExisted = created && created.alias === alias;
		const isNotRequest = isInvalidAlias || isExisted;

		if (isExisted) {
			dispatch(setAccountCvRead(created));
		}

		if (!isNotRequest) {
			dispatch(accountCvReadGetApi({ alias, locale: lang }));
		}

		return () => {
			dispatch(clearAccountCvUpdate());
			dispatch(clearAccountCvRead());
			dispatch(clearAccountCvsCreatedItem());
		};
	}, []);

	if (isInvalidAlias) {
		return (
			<ErrorWidget />
		);
	}

	if (!list || !item || isLoading || isLoadingItems || isLoadingLanguagesLevel || isLoadingUsersSex || isLoadingUsersCvStatuses || isLoadingEducation || isLoadingExperience) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	if (hash === PATH_ACCOUNT_CVS_CREATE_END) {
		return (
			<AccountCvsLocalesCreateWidget { ...item } />
		);
	}

	setTitle(`${breadCrumbItem} ${alias}`);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_CVS, text: breadCrumbList },
		{ to: pathname, text: alias },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { alias }</PageHeadline>

				<PageRow>
					<PageCol>
						<AccountCvsFormUpdate { ...item }
							languagesSource={ languages }
							languageLevelsSource={ languagesLevels }
							usersSexSource={ usersSex }
							cvStatusesSource={ usersCvStatuses }
							educationSource={ education.items }
							experienceSource={ experience.items }
						/>
					</PageCol>

					<PageCol>
						<AccountCvsDetails { ...item } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
