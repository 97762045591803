import { ChangeEvent, useState } from 'react';


type TBind = {
	name: string;
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export type TInput = {
	bind: TBind;
	setValue: (value: string) => void;
	value: string;
};

export function useInput(name: string, defaultValue?: string): TInput {
	const defaultVal = defaultValue || '';

	const [value, setValue] = useState(defaultVal);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	return {
		bind: {
			value,
			name,
			onChange,
		},
		setValue,
		value,
	};
}
