import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from '../../../styles/FormFileListValueRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	file: File;
	onDelete: (value: File) => void;
};

const enum Size {
	KB = 1000,
	MB = 1000000,
	GB = 1000000000,
}

function formatSize(value: number = 0): [string, string] {
	if (value < Size.KB) {
		return [value.toString(), 'Byte'];
	}

	if (value < Size.MB) {
		const shortValue = value / Size.KB;

		return [shortValue.toFixed(2), 'Kb'];
	}

	if (value < Size.GB) {
		const shortValue = value / Size.MB;

		return [shortValue.toFixed(2), 'Mb'];
	}

	const shortValue = value / Size.GB;

	return [shortValue.toFixed(2), 'Gb'];
}

export function FormFileListRow(props: TProps) {
	const { onDelete, file, children } = props;

	const classNames = [styles.row];

	const [size, sizeName] = formatSize(file.size);

	return (
		<div className={ classNames.join(' ') }>
			{ children }

			<span className={ styles.nowrap }>
				<span className={ styles.size }>({ size } { sizeName })</span>
				<span className={ styles.delete } onClick={ e => onDelete(file) }>X</span>
			</span>
		</div>
	);
}
