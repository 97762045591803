import { LocalizationTypes } from 'src/common/types';

import { ASIDE_RU_LOCALIZATION } from './ru.localization';
import { ASIDE_EN_LOCALIZATION } from './en.localization';


export const ASIDE_LOCALIZATION = {
	[LocalizationTypes.RU]: ASIDE_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ASIDE_EN_LOCALIZATION,
};
