import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountEducationResponse, adaptAccountEducationFromResponse, adaptAccountEducationLocaleToRequest, TAccountEducation, TAccountEducationLocaleCreate } from '../../types';


export const accountEducationLocaleCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_EDUCATIONS}/:pid`,
	(payload: TAccountEducationLocaleCreate, thunkApi) => {
		const { data, pid } = payload;
		const path = `${BACKEND_ACCOUNT_EDUCATIONS}/${pid}`;
		const dataRequest = adaptAccountEducationLocaleToRequest(data);

		return HttpService.post<TAccountEducationResponse>(path, dataRequest).then(result => {
			return adaptAccountEducationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountEducation | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEducationLocaleCreateSlice = createSlice({
	name: 'accountEducationLocaleCreateSlice',
	initialState,
	reducers: {
		clearAccountEducationLocaleCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEducationLocaleCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountEducationLocaleCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEducationLocaleCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountEducationLocaleCreateSelector = () => useAppSelector(state => state[accountEducationLocaleCreateSlice.name]);

export const { clearAccountEducationLocaleCreate } = accountEducationLocaleCreateSlice.actions;
