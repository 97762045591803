import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccount, TAccountRequest, TAccountResponse, adaptAccountFromResponse } from '../../types';


export const accountUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT}`,
	(payload: TAccountRequest, thunkApi) => {
		return HttpService.put<TAccountResponse>(BACKEND_ACCOUNT, payload).then(result => {
			return adaptAccountFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccount | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountUpdateSlice = createSlice({
	name: 'accountUpdateSlice',
	initialState,
	reducers: {
		clearAccountUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccount>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountUpdateSelector = () => useAppSelector(state => state[accountUpdateSlice.name]);

export const { clearAccountUpdate } = accountUpdateSlice.actions;
