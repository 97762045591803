import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './TableHeadSortWrap.module.scss';
import { TTableSort, TableSort } from '../col';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
	sort?: TTableSort;
};

export function TableHeadSortWrap(props: TProps) {
	const {
		children,
		sort,
		...restProps
	} = props;

	const classNames = [styles.tableHeadSortWrap];

	if (sort) {
		const className = sort === TableSort.DESC ? styles.desc : styles.asc;

		classNames.push(className);
	}

	return (
		<span className={ classNames.join(' ') } { ...restProps }>
			{ children }
		</span>
	);
}
