// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvExperienceProjectsItems_box__Duc\\+j {\n  margin-top: 0.5em;\n}\n.PublicCvExperienceProjectsItems_box__Duc\\+j > div:first-child {\n  margin-top: 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/experience/components/experience-item/components/projects/components/project-items/PublicCvExperienceProjectsItems.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;AACC;EACC,aAAA;AACF","sourcesContent":[".box {\n\tmargin-top: 0.5em;\n\n\t& > div:first-child {\n\t\tmargin-top: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvExperienceProjectsItems_box__Duc+j"
};
export default ___CSS_LOADER_EXPORT___;
