import { useEffect } from 'react';

import { Link } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_SIGN_IN } from 'src/routes';
import { RecoveryPageStageState, clearRecoveryAction, useAppDispatch, useRecoverySelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { RecoveryStageSuccess, RecoveryLayer } from '../../components';

import { RecoveryStageEmailSuccess, RecoveryRouter } from './components';
import { RECOVERY_MAIN_LOCALIZATION } from './localization';

import styles from './RecoveryPage.module.scss';


export function RecoveryPage() {
	const dispatch = useAppDispatch();

	const { stage, email } = useRecoverySelector();

	const {
		title,
		underText,
		underLink,
	} = useLocalization(RECOVERY_MAIN_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearRecoveryAction());
		};
	}, []);

	setTitle(title);

	if (stage === RecoveryPageStageState.SUCCESS) {
		return (
			<RecoveryLayer>
				<RecoveryStageSuccess />
			</RecoveryLayer>
		);
	}

	if (stage === RecoveryPageStageState.EMAIL_SUCCESS) {
		return (
			<RecoveryLayer>
				<RecoveryStageEmailSuccess email={ email } />
			</RecoveryLayer>
		);
	}

	return (
		<RecoveryLayer>
			<RecoveryRouter />

			<p className={ styles.underText }>
				<span>{ underText } </span>
				<Link to={ PATH_SIGN_IN }>{ underLink }</Link>
			</p>
		</RecoveryLayer>
	);
}

