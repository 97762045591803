import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline, Preloader } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_CVS, PATH_DEFAULT_CV } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { sourceStacksGetApi, clearAccountCvCreate, useAccountSourceStacksSelector, useAppDispatch, useCredentialsSelector, useAccountSourceLanguageLevelSelector, accountSourceLanguageLevelGetApi, accountSourceUserSexGetApi, useAccountSourceUserSexSelector, accountSourceUserCvStatusGetApi, useAccountSourceUserCvStatusSelector, useAccountExperiencesSelector, useAccountEducationsSelector, accountEducationsGetApi, accountExperiencesGetApi } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { ACCOUNT_CVS_COMMON_LOCALIZATION } from '../../localization';

import { AccountCvsFormCreate } from './components';
import { ACCOUNT_CVS_CREATE_LOCALIZATION } from './localization';


export function AccountCvsCreateWidget() {
	const dispatch = useAppDispatch();
	const { lang, languages } = useCredentialsSelector();
	const { list, isLoading } = useAccountSourceStacksSelector();
	const { items: languagesLevels, isLoading: isLoadingLanguagesLevel } = useAccountSourceLanguageLevelSelector();
	const { list: education, isLoading: isLoadingEducation } = useAccountEducationsSelector();
	const { list: experience, isLoading: isLoadingExperience } = useAccountExperiencesSelector();
	const { items: usersSex, isLoading: isLoadingUsersSex } = useAccountSourceUserSexSelector();
	const { items: usersCvStatuses, isLoading: isLoadingUsersCvStatuses } = useAccountSourceUserCvStatusSelector();
	const { pathname, hash } = useLocation();
	const { breadCrumbList } = useLocalization(ACCOUNT_CVS_COMMON_LOCALIZATION);
	const { title, headline, breadCrumbNew } = useLocalization(ACCOUNT_CVS_CREATE_LOCALIZATION);

	useEffect(() => {
		dispatch(sourceStacksGetApi(lang));
		dispatch(accountSourceLanguageLevelGetApi(lang));
		dispatch(accountSourceUserSexGetApi(lang));
		dispatch(accountSourceUserCvStatusGetApi(lang));
		dispatch(accountEducationsGetApi({ locale: lang }));
		dispatch(accountExperiencesGetApi({ locale: lang }));

		return () => {
			dispatch(clearAccountCvCreate());
		};
	}, [lang]);

	if (!list || isLoading || isLoadingLanguagesLevel || isLoadingUsersSex || isLoadingUsersCvStatuses || isLoadingEducation || isLoadingExperience) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	setTitle(title);

	const initialItem = {
		locale: lang,
		pid: '',
		alias: '',
		title: '',
		description: '',
		firstName: '',
		lastName: '',
		city: '',
		birthday: '',
		sex: { alias: '', name: '' },
		status: { alias: 'inactive', name: '' },
		preferredSalary: '',
		currency: '',
		countExperience: [],
		image: PATH_DEFAULT_CV,
		phoneNumber: '',
		email: '',
		telegram: '',
		linkedin: '',
		headhunter: '',
		experience: [],
		education: [],
		languages: [],
		locales: [],
		createdAt: '',
		updatedAt: '',
	};

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_CVS, text: breadCrumbList },
		{ to: `${pathname}${hash}`, text: breadCrumbNew },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountCvsFormCreate { ...initialItem }
						languagesSource={ languages }
						languageLevelsSource={ languagesLevels }
						usersSexSource={ usersSex }
						cvStatusesSource={ usersCvStatuses }
						educationSource={ education.items }
						experienceSource={ experience.items }
					/>
				</PageCol>
			</div>
		</AccountLayout>
	);
}
