// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvTelegramIconName_box__VYLSw {\n  display: inline-block;\n  font-size: 1.2em;\n  width: 1.2em;\n  margin-right: 0.25em;\n}\n\n.PublicCvTelegramIconName_icon__aQ-DX {\n  font-size: 0.9em;\n  width: 1.15em;\n  vertical-align: text-bottom;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/contacts/components/telegram/components/icon-name/PublicCvTelegramIconName.module.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,oBAAA;AACD;;AAEA;EACC,gBAAA;EACA,aAAA;EACA,2BAAA;AACD","sourcesContent":[".box {\n\tdisplay: inline-block;\n\tfont-size: 1.2em;\n\twidth: 1.2em;\n\tmargin-right: 0.25em;\n}\n\n.icon {\n\tfont-size: 0.9em;\n\twidth: 1.15em;\n\tvertical-align: text-bottom;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvTelegramIconName_box__VYLSw",
	"icon": "PublicCvTelegramIconName_icon__aQ-DX"
};
export default ___CSS_LOADER_EXPORT___;
