import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountProject, TAccountProjectLocaleUpdate, TAccountProjectResponse, adaptAccountProjectFromResponse, adaptAccountProjectLocaleToRequest } from '../../types';
import { BACKEND_ACCOUNT_PROJECTS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';


export const accountProjectLocaleUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_PROJECTS}/:alias/:locale`,
	(payload: TAccountProjectLocaleUpdate, thunkApi) => {
		const { data, alias, locale } = payload;
		const path = `${BACKEND_ACCOUNT_PROJECTS}/${alias}/${locale}`;
		const dataRequest = adaptAccountProjectLocaleToRequest(data);

		return HttpService.put<TAccountProjectResponse>(path, dataRequest).then(result => {
			return adaptAccountProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountProject | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountProjectLocaleUpdateSlice = createSlice({
	name: 'accountProjectLocaleUpdateSlice',
	initialState,
	reducers: {
		clearAccountProjectLocaleUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountProjectLocaleUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountProjectLocaleUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountProjectLocaleUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountProjectLocaleUpdateSelector = () => useAppSelector(state => state[accountProjectLocaleUpdateSlice.name]);

export const { clearAccountProjectLocaleUpdate } = accountProjectLocaleUpdateSlice.actions;
