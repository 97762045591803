// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableRowEmpty_tableRowEmpty__rPtgd {\n  color: rgb(180, 180, 180);\n  font-family: inherit;\n  font-size: 1em;\n  font-weight: 100;\n  height: 5em;\n  margin: 1em auto 1em;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/table/empty/TableRowEmpty.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;AACD","sourcesContent":[".tableRowEmpty {\n\tcolor: rgba(180, 180, 180, 1);\n\tfont-family: inherit;\n\tfont-size: 1em;\n\tfont-weight: 100;\n\theight: 5em;\n\tmargin: 1em auto 1em;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRowEmpty": "TableRowEmpty_tableRowEmpty__rPtgd"
};
export default ___CSS_LOADER_EXPORT___;
