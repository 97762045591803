import { FormButtonLink } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';
import { useLocalization } from 'src/toolkit';

import { RECOVERY_STAGE_EMAIL_SUCCESS_LOCALIZATION } from './localization';

import styles from '../../../../components/styles/RecoveryStage.module.scss';


type TProps = {
	email: string;
};

export function RecoveryStageEmailSuccess(props: TProps) {
	const {
		successTextLeftHalf,
		successTextRightHalf,
		buttonText,
	} = useLocalization(RECOVERY_STAGE_EMAIL_SUCCESS_LOCALIZATION);

	const { email } = props;

	return (
		<div className={ styles.recoveryPageStage }>
			<p className={ styles.paragraph }>
				{ successTextLeftHalf } «{ email }» { successTextRightHalf }
			</p>

			<FormButtonLink
				className={ styles.button }
				to={ PATH_SIGN_IN }
			>{ buttonText }</FormButtonLink>
		</div>
	);
}

