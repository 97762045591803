import { FooterCol } from '../footer-col';

import styles from './FooterRow.module.scss';


type TProps = {
	items?: string[][];
	style?: Record<string, string>;
};

export function FooterRow(props: TProps) {
	const {
		items = [],
		style = {},
	} = props;

	return (
		<div className={ styles.row } style={ style }>
			{
				items.map((item, index) => <FooterCol items={ item } key={ index } />)
			}
		</div>
	);
}
