import { FormButtonLink, FormField, Icon } from 'src/components';
import { useLocalization } from 'src/toolkit';

import { LocaleBox } from '../locale-box';

import { LOCALE_FIELD_LOCALIZATION } from './localization';

import styles from './LocaleBoxField.module.scss';


type TProps = {
	path: string;
	to: string;
	alias: string;
	locales: string[];
	isLoading: boolean;
	isButton?: boolean;
};

export function LocaleBoxField(props: TProps) {
	const { localesLegend, localesTitle } = useLocalization(LOCALE_FIELD_LOCALIZATION);

	const {
		path,
		to,
		alias,
		locales,
		isLoading,
		isButton: isButtonRaw,
	} = props;

	const isButton = isButtonRaw === undefined ? true : isButtonRaw;

	return (
		<FormField className={ styles.row } legend={ localesLegend }>
			<LocaleBox
				pathStart={ `${path}/${alias}` }
				items={ locales }
				disabled={ isLoading }
			/>

			{ isButton &&
				<FormButtonLink
					to={ to }
					title={ localesTitle }
					disabled={ isLoading }
					mini
				>
					<Icon icon="icon-plus" />
				</FormButtonLink>
			}
		</FormField>
	);
}
