// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvLanguagesItem_row__vYiVV {\n  margin-top: 0.25em;\n}\n\n.PublicCvLanguagesItem_box__k71EC {\n  border: 1px solid rgb(200, 200, 200);\n  display: inline-block;\n  line-height: 1.75em;\n  padding: 0 1em;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/languages/components/item/PublicCvLanguagesItem.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;;AAEA;EACC,oCAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;AACD","sourcesContent":[".row {\n\tmargin-top: 0.25em;\n}\n\n.box {\n\tborder: 1px solid rgba(200, 200, 200, 1);\n\tdisplay: inline-block;\n\tline-height: 1.75em;\n\tpadding: 0 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "PublicCvLanguagesItem_row__vYiVV",
	"box": "PublicCvLanguagesItem_box__k71EC"
};
export default ___CSS_LOADER_EXPORT___;
