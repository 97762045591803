import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice } from 'src/components';
import { formHandleServerError, formValidateCode, formValidatePhoneNumber, libClearIntstr, libNumberMask, useInputFormField, useLocalization } from 'src/toolkit';
import { TAccountPhoneNumber, accountPhoneNumberUpdateConfirmApi, clearAccountPhoneNumberUpdateConfirm, setAccountPhoneNumberNoticeUpdate, setAccountPhoneNumberRead, useAccountPhoneNumberUpdateConfirmSelector, useAppDispatch } from 'src/store';

import { AccountPhoneNumberCodeField, AccountPhoneNumberField } from '../../../../../../components';
import { ACCOUNT_PHONE_NUMBER_ROUTER_LOCALIZATION } from '../../localization';

import { AccountPhoneNumberCodeTimer } from './components';
import { ACCOUNT_PHONE_NUMBER_CONFIRM_STAGE_LOCALIZATION } from './localization';

import styles from './AccountPhoneNumberConfirmStage.module.scss';


type TProps = {
	item: TAccountPhoneNumber;
};

export function AccountPhoneNumberConfirmStage(props: TProps) {
	const dispatch = useAppDispatch();
	const { item: itemUpdated, isLoading, error } = useAccountPhoneNumberUpdateConfirmSelector();
	const [notice, setNotice] = useState('');
	const { item } = props;

	const { unconfirmedLegend, unconfirmedTitle, errorAlreadyConfirmed } = useLocalization(ACCOUNT_PHONE_NUMBER_ROUTER_LOCALIZATION);
	const {
		textLeftHalf,
		textRightHalf,
		successNotice,
		button,
	} = useLocalization(ACCOUNT_PHONE_NUMBER_CONFIRM_STAGE_LOCALIZATION);

	const {
		phoneNumber,
		phoneNumberExpected,
	} = item;

	const phoneNumberExpectedField = useInputFormField({
		name: 'phone_number_expected',
		defaultValue: phoneNumberExpected,
		validation: formValidatePhoneNumber,
	});

	const codeField = useInputFormField({
		name: 'code',
		validation: formValidateCode,
	});

	useEffect(() => {
		return () => {
			dispatch(clearAccountPhoneNumberUpdateConfirm());
		};
	}, []);

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice,
				codeField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (itemUpdated) {
			setNotice('');
			dispatch(setAccountPhoneNumberNoticeUpdate(successNotice));
			dispatch(setAccountPhoneNumberRead(itemUpdated));
		}
	}, [itemUpdated]);

	const submit = () => {
		const errors = [
			codeField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const currentValue = libClearIntstr(phoneNumber);
		const value = libClearIntstr(phoneNumberExpectedField.value);

		if (value === currentValue) {
			phoneNumberExpectedField.setErrors([errorAlreadyConfirmed]);

			return;
		}

		const dataRequest = {
			phoneNumberExpected: libClearIntstr(phoneNumberExpectedField.value),
			code: libClearIntstr(codeField.value),
		};

		dispatch(accountPhoneNumberUpdateConfirmApi(dataRequest));
	};

	const phoneNumberformat = libNumberMask(phoneNumberExpected, { template: '+_ ___ ___ __ __' });

	return (
		<Form className={ styles.form } onSubmit={ submit }>
			<AccountPhoneNumberField
				{ ...phoneNumberExpectedField }
				legend={ unconfirmedLegend }
				title={ unconfirmedTitle }
				isLoading
				unconfirmed
			/>

			<p className={ styles.text }>{ textLeftHalf } <span className={ styles.percepted }>{ phoneNumberformat }</span> { textRightHalf }:</p>

			<AccountPhoneNumberCodeField { ...codeField } />

			<AccountPhoneNumberCodeTimer item={ item } />

			<FormNotice error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
