import { DetailedHTMLProps, HTMLAttributes } from 'react';

import './Skeleton.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function Skeleton(props: TProps) {
	const { children, className = '' } = props;
	const classNames = ['placeholderLoader'];

	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') }>
			{ children }
		</div>
	);
}
