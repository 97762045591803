import { FormPassword } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { USERS_PASSWORD_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function UsersPasswordField(props: TProps) {
	const { legend, placeholder, title } = useLocalization(USERS_PASSWORD_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormPassword
			legend={ legend }
			errors={ errors }
			placeholder={ placeholder }
			name={ name }
			title={ title }
			value={ value }
			onChange={ e => setValue(e.target.value) }
			disabled={ isLoading }
			required
		/>
	);
}
