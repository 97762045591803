import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline, Preloader } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_PROJECTS, PATH_DEFAULT_PROJECT } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { sourceStacksGetApi, clearAccountProjectCreate, useAccountSourceStacksSelector, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { ACCOUNT_PROJECTS_COMMON_LOCALIZATION } from '../../localization';

import { AccountProjectsFormCreate } from './components';
import { ACCOUNT_PROJECTS_CREATE_LOCALIZATION } from './localization';


export function AccountProjectsCreateWidget() {
	const dispatch = useAppDispatch();
	const { lang } = useCredentialsSelector();
	const { list, isLoading } = useAccountSourceStacksSelector();
	const { pathname, hash } = useLocation();
	const { breadCrumbList } = useLocalization(ACCOUNT_PROJECTS_COMMON_LOCALIZATION);
	const { title, headline, breadCrumbNew } = useLocalization(ACCOUNT_PROJECTS_CREATE_LOCALIZATION);

	useEffect(() => {
		if (!list) {
			dispatch(sourceStacksGetApi(lang));
		}

		return () => {
			dispatch(clearAccountProjectCreate());
		};
	}, []);

	if (!list || isLoading) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	setTitle(title);

	const { items } = list;

	const initialItem = {
		locale: lang,
		alias: '',
		title: '',
		description: '',
		uri: '',
		repositoryUri: '',
		image: PATH_DEFAULT_PROJECT,
		files: [],
		stack: [],
		locales: [],
		createdAt: '',
		updatedAt: '',
	};

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_PROJECTS, text: breadCrumbList },
		{ to: `${pathname}${hash}`, text: breadCrumbNew },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountProjectsFormCreate { ...initialItem } items={ items } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
