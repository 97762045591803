import { DetailedHTMLProps, TdHTMLAttributes } from 'react';

import styles from './TableBodyCol.module.scss';


type TProps = DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> & {
	left?: boolean;
	center?: boolean;
	right?: boolean;
	justify?: boolean;
};

export function TableBodyCol(props: TProps) {
	const {
		children,
		left = false,
		right = false,
		center = false,
		justify = false,
		className = '',
		...restProps
	} = props;

	const classNames = [styles.tableBodyCol];

	if (className) {
		classNames.push(className);
	}

	if (left) {
		classNames.push(styles.left);
	}

	if (center) {
		classNames.push(styles.center);
	}

	if (right) {
		classNames.push(styles.right);
	}

	if (justify) {
		classNames.push(styles.justify);
	}

	return (
		<td className={ classNames.join(' ') } { ...restProps }>
			{ children }
		</td>
	);
}
