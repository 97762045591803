import { LocalizationTypes } from 'src/common/types';

import { LOCALE_FIELD_RU_LOCALIZATION } from './ru.localization';
import { LOCALE_FIELD_EN_LOCALIZATION } from './en.localization';


export const LOCALE_FIELD_LOCALIZATION = {
	[LocalizationTypes.RU]: LOCALE_FIELD_RU_LOCALIZATION,
	[LocalizationTypes.EN]: LOCALE_FIELD_EN_LOCALIZATION,
};
