import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountExperience, setAccountExperiencesLocalesCreatedItem, accountExperienceLocaleCreateApi, useAppDispatch, useAccountExperienceLocaleCreateSelector } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { LocaleField } from 'src/pages/components';
import { PATH_ACCOUNT_EXPERIENCE } from 'src/routes';

import { AccountExperienceDescriptionField, AccountExperienceCompanyCityField, AccountExperiencePositionNameField } from '../../../../components';
import { ACCOUNT_EXPERIENCE_LOCALE_CREATE_LOCALIZATION } from '../../localization';


export function AccountExperienceLocaleFormCreate(props: TAccountExperience) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountExperienceLocaleCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(ACCOUNT_EXPERIENCE_LOCALE_CREATE_LOCALIZATION);

	const {
		locale = '',
		pid = '',
		companyCity = '',
		positionName = '',
		description = '',
	} = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const companyCityField = useInputFormField({
		name: 'company_city',
		defaultValue: companyCity,
		validation: value => formValidateText(value),
	});

	const positionNameField = useInputFormField({
		name: 'position_name',
		defaultValue: positionName,
		validation: value => formValidateText(value),
	});

	const descriptionField = useInputFormField({
		name: 'description',
		defaultValue: description,
		validation: value => formValidateText(value, { max: 512 }),
	});

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				localeField,
				companyCityField,
				positionNameField,
				descriptionField,
			);
		}

		if (item) {
			dispatch(setAccountExperiencesLocalesCreatedItem(item));
			navigate(`${PATH_ACCOUNT_EXPERIENCE}/${item.pid}/${item.locale}`);
		}
	}, [error, item]);

	const onSubmit = () => {
		const errors = [
			localeField.validate(),
			companyCityField.validate(),
			positionNameField.validate(),
			descriptionField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			locale: localeField.value,
			companyCity: companyCityField.value,
			positionName: positionNameField.value,
			description: descriptionField.value,
		};

		dispatch(accountExperienceLocaleCreateApi({ data: dataRequest, pid }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading } />

			<AccountExperienceCompanyCityField { ...companyCityField } isLoading={ isLoading } />

			<AccountExperiencePositionNameField { ...positionNameField } isLoading={ isLoading } />

			<AccountExperienceDescriptionField { ...descriptionField } isLoading={ isLoading } />

			<FormNotice errorText={ notice } />

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
