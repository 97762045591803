import styles from './FooterLine.module.scss';


type TProps = {
	item: string;
	type: string;
	style?: Record<string, string>;
};

export function FooterLine(props: TProps) {
	const { type = 'line', item = '', style = {} } = props;

	const className = styles[type] ? styles[type] : styles.line;

	return (
		<div className={ className } style={ style }>{ item }</div>
	);
}
