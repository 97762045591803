import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TStack, TStackLocaleCreate, TStackResponse, adaptStackFromResponse, adaptStackLocaleToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';


export const stackLocaleCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_STACKS}/:alias`,
	(payload: TStackLocaleCreate, thunkApi) => {
		const { data, alias } = payload;
		const path = `${BACKEND_ADMIN_STACKS}/${alias}`;
		const dataRequest = adaptStackLocaleToRequest(data);

		return HttpService.post<TStackResponse>(path, dataRequest).then(result => {
			return adaptStackFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TStack | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const stackLocaleCreateSlice = createSlice({
	name: 'stackLocaleCreateSlice',
	initialState,
	reducers: {
		clearStackLocaleCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[stackLocaleCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[stackLocaleCreateApi.fulfilled.type]: (state, action: PayloadAction<TStack>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[stackLocaleCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useStackLocaleCreateSelector = () => useAppSelector(state => state[stackLocaleCreateSlice.name]);

export const { clearStackLocaleCreate } = stackLocaleCreateSlice.actions;
