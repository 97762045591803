import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserRolesPayloadUpdate, TUserRole, TUserRolesList, adaptUserRolesListFromResponse } from '../../types';


export const userRolesUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/roles/:locale`,
	(payload: TUserRolesPayloadUpdate, thunkApi) => {
		const { items, nickname, locale } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/roles/${locale}`;
		const dataRequest = { items };

		return HttpService.put<TUserRolesList>(path, dataRequest).then(result => {
			return adaptUserRolesListFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	items: TUserRole[] | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	items: undefined,
	isLoading: false,
	error: undefined,
};

export const userRolesUpdateSlice = createSlice({
	name: 'userRolesUpdateSlice',
	initialState,
	reducers: {
		clearUserRolesUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userRolesUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userRolesUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserRolesList>) => {
			const { items } = action.payload;

			state.items = items;
			state.isLoading = false;
		},

		[userRolesUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserRolesUpdateSelector = () => useAppSelector(state => state[userRolesUpdateSlice.name]);

export const { clearUserRolesUpdate } = userRolesUpdateSlice.actions;
