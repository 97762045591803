import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TAccountExperience, TAccountExperienceResponse, adaptAccountExperienceFromResponse } from '../../types';
import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';


export const accountExperienceReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_EXPERIENCE}/:pid`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_EXPERIENCE}/${payload}`;

		return HttpService.get<TAccountExperienceResponse>(path).then(result => {
			return adaptAccountExperienceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TAccountExperience | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExperienceReadSlice = createSlice({
	name: 'accountExperienceReadSlice',
	initialState,
	reducers: {
		setAccountExperienceRead(state, action: PayloadAction<TAccountExperience>) {
			state.data = action.payload;
		},

		clearAccountExperienceRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExperienceReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExperienceReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperience>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountExperienceReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountExperienceReadSelector = () => useAppSelector(state => state[accountExperienceReadSlice.name]);

export const { clearAccountExperienceRead, setAccountExperienceRead } = accountExperienceReadSlice.actions;
