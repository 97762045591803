import { useEffect, useState } from 'react';

import { BreadCrumbs, PageHeadline } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_PUBLIC_CV } from 'src/routes';
import { clearPublicCvs, useAppDispatch } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { PUBLIC_CVS_COMMON_LOCALIZATION } from '../../localization';

import { PublicCvsList } from './components';
import { PUBLIC_CVS_LIST_LOCALIZATION } from './localization';
import { TErrorPage } from 'src/pages/components';
import { ErrorPage } from 'src/pages';


export function PublicCvsPage() {
	const dispatch = useAppDispatch();
	const { title, headline } = useLocalization(PUBLIC_CVS_LIST_LOCALIZATION);
	const { breadCrumbList } = useLocalization(PUBLIC_CVS_COMMON_LOCALIZATION);
	const [error, setError] = useState<TErrorPage>();

	useEffect(() => {
		return () => {
			dispatch(clearPublicCvs());
		};
	}, []);

	if (error) {
		return (
			<ErrorPage { ...error } />
		);
	}

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_PUBLIC_CV, text: breadCrumbList },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PublicCvsList setError={ setError } />
			</div>
		</AccountLayout>
	);
}
