import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_SOURCE_USERS_CVS_STATUSES } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserCvStatus, TUserCvStatusListResponse, adaptUserCvStatussFromListResponse } from '../../types';


export const accountSourceUserCvStatusGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SOURCE_USERS_CVS_STATUSES}/:locale`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SOURCE_USERS_CVS_STATUSES}/${payload}`;

		return HttpService.get<TUserCvStatusListResponse>(path).then(result => {
			return adaptUserCvStatussFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TUserCvStatus[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	items: undefined,
	error: undefined,
};

export const accountSourceUserCvStatusSlice = createSlice({
	name: 'accountSourceUserCvStatusSlice',
	initialState,
	reducers: {
		clearAccountSourceUserCvStatus(state) {
			return { ...initialState, list: state.items };
		},
	},

	extraReducers: {
		[accountSourceUserCvStatusGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountSourceUserCvStatusGetApi.fulfilled.type]: (state, action: PayloadAction<TUserCvStatus[]>) => {
			state.isLoading = false;
			state.items = action.payload;
			state.error = undefined;
		},

		[accountSourceUserCvStatusGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountSourceUserCvStatusSelector = () => useAppSelector(state => state[accountSourceUserCvStatusSlice.name]);

export const { clearAccountSourceUserCvStatus } = accountSourceUserCvStatusSlice.actions;
