import { useState } from 'react';


type TErrors = string[];

export type TInputField = {
	required: boolean;
	name: string;
	value: string;
	setValue: (value: string) => void;
	errors: TErrors;
	setErrors: (value: TErrors) => void;
	clear: (value?: string) => void;
	validate: () => boolean;
};

type TRuleValidate = (value: string) => string[];

export function useInputField(name: string, defaultValue?: string, defaultRequired?: boolean, defaultErrors?: TErrors, validation?: TRuleValidate): TInputField {
	const defaultVal = defaultValue || '';
	const err = defaultErrors || [];
	const defaultReq = defaultRequired || false;

	const [required, setRequired] = useState(defaultReq || !!defaultVal);
	const [value, setValue] = useState(defaultVal);
	const [errors, setErrors] = useState(err as TErrors);

	const validate = (value: string): boolean => {
		if (!validation) {
			return false;
		}

		const errors = validation(value);

		setErrors(errors);

		return errors.length > 0;
	};

	const onChange = (value: string) => {
		setValue(value);

		validate(value);

		if (!defaultRequired && value) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	};

	const onErrors = (err: TErrors) => {
		setErrors(err);
	};

	const clear = (value?: string) => {
		const clearValue = value ?? defaultVal;
		setValue(clearValue);

		if (!defaultRequired && clearValue) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	};

	return {
		required,
		name,
		value,
		setValue: onChange,
		errors,
		setErrors: onErrors,
		clear,
		validate: () => validate(value),
	};
}
