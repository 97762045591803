import { RefObject, useRef, useState } from 'react';

import { FormButton, FormField, FormInputFile } from 'src/components';
import { TInputFilesField } from 'src/toolkit';

import { FormFileList, FormFileListValue } from './components';


export type TFormFileExistedValue = {
	path: string;
	name: string;
};

function clearInput(ref: RefObject<HTMLInputElement>): void {
	const elem = ref.current;

	if (elem) {
		elem.value = '';
	}
};

type TProps = TInputFilesField<TFormFileExistedValue> & {
	setFiles: (value: File[]) => void;
	legend?: string;
	selectImageText?: string;
	changeImageText?: string;
};

export function FormFile(props: TProps) {
	const {
		legend = '',
		required = false,
		value,
		setValue,
		name,
		errors,
		setFiles,
		selectImageText = 'Select image',
		changeImageText = 'Change image',
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [filesValue, setFilesValue] = useState<File[]>([]);

	const onDeleteExistedValue = (existedValue: TFormFileExistedValue) => {
		const { path: pathDeleted } = existedValue;

		const updatedFileArr = value.filter(item => {
			const { path } = item;
			const isMatched = path === pathDeleted;

			return !isMatched;
		});

		setValue(updatedFileArr);
	};

	const onDelete = (value: File) => {
		const {
			name: nameDeleted,
			size: sizeDeleted,
			lastModified: lastModifiedDeleted,
		} = value;

		const updatedFileArr = filesValue.filter(item => {
			const { name, size, lastModified } = item;
			const isMatched = name === nameDeleted && size === sizeDeleted && lastModified === lastModifiedDeleted;

			return !isMatched;
		});

		setFiles(updatedFileArr);
		setFilesValue(updatedFileArr);
	};

	const onChange = (files: FileList | null) => {
		if (!files) {
			return;
		}

		const arr = [];

		for (let i = 0; i < files.length; i++) {
			const item = files[i];
			arr.push(item);
		}

		setFiles(arr);
		setFilesValue(arr);

		if (!files) {
			return;
		}

		clearInput(inputRef);
	};

	const buttonText = value || filesValue.length > 0 ? changeImageText : selectImageText;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
		>
			<FormButton file>
				{ buttonText }
				<FormInputFile
					type="file"
					name={ name }
					onChange={ e => onChange(e.target.files) }
					ref={ inputRef }
					multiple
				/>
			</FormButton>

			{ value.length > 0 && <FormFileListValue onDelete={ onDeleteExistedValue } files={ value } /> }

			{ filesValue.length > 0 && <FormFileList onDelete={ onDelete } files={ filesValue } /> }
		</FormField>
	);
}
