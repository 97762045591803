import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Footer, HeaderHome, NavMenu } from 'src/components';

import styles from './DefaultLayout.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function DefaultLayout(props: TProps) {
	const { children } = props;

	return (
		<div className={ styles.layout }>
			<NavMenu>
				<HeaderHome />

				<main>
					{ children }
				</main>

				<Footer />
			</NavMenu>
		</div>
	);
}
