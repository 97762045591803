// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserPasswordForm_accountPasswordForm__rsCzu {\n  box-sizing: border-box;\n  margin: auto;\n  padding: 1em;\n}\n\n.UserPasswordForm_recoveryText__3ZROY {\n  text-align: right;\n  font-size: 0.875em;\n  margin: 1em 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/users/views/password/components/form/UserPasswordForm.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,YAAA;EACA,YAAA;AACD;;AAEA;EACC,iBAAA;EACA,kBAAA;EACA,aAAA;AACD","sourcesContent":[".accountPasswordForm {\n\tbox-sizing: border-box;\n\tmargin: auto;\n\tpadding: 1em;\n}\n\n.recoveryText {\n\ttext-align: right;\n\tfont-size: 0.875em;\n\tmargin: 1em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountPasswordForm": "UserPasswordForm_accountPasswordForm__rsCzu",
	"recoveryText": "UserPasswordForm_recoveryText__3ZROY"
};
export default ___CSS_LOADER_EXPORT___;
