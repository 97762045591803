import { libFormatDate, useLocalization } from 'src/toolkit';

import styles from './PublicCvLastWorkExperienceCount.module.scss';
import { LOCALIZATION } from 'src/localization';


type TProps = {
	dateStart: string;
	dateEnd?: string;
};

export function PublicCvLastWorkExperienceCount(props: TProps) {
	const {
		until,
		januaryParental,
		februaryParental,
		marchParental,
		aprilParental,
		mayParental,
		juneParental,
		julyParental,
		augustParental,
		septemberParental,
		octoberParental,
		novemberParental,
		decemberParental,
	} = useLocalization(LOCALIZATION);

	const months = [
		januaryParental,
		februaryParental,
		marchParental,
		aprilParental,
		mayParental,
		juneParental,
		julyParental,
		augustParental,
		septemberParental,
		octoberParental,
		novemberParental,
		decemberParental,
	];

	const { dateStart, dateEnd = '' } = props;

	const options = { template: 'MM yyyy', months };
	const dateEndFormat = dateEnd ? libFormatDate(dateEnd, options) : until;
	const dateStartFormat = libFormatDate(dateStart, options);

	return (
		<span className={ styles.text }>{ dateStartFormat } — { dateEndFormat.toLowerCase() }</span>
	);
}
