export const STACK_TABLE_RU_LOCALIZATION = {
	image: 'Иконка',
	name: 'Название',
	locales: 'Версии',
	createdAt: 'Дата создания',
	updatedAt: 'Дата обновления',

	searchPlaceholder: 'Поиск',
	searchTitle: 'Введите текст для поиска',

	emptyText: 'Стека технологий не найдено',
};
