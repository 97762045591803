import { useEffect, useState } from 'react';

import { TAccountCvLanguage, TLanguage, TLanguageLevel } from 'src/store';
import { formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { FormButton, Icon, PageRow } from 'src/components';

import { AccountCvLanguage, AccountCvLanguageBox, AccountCvLanguageLevelBox } from './components';
import { ACCOUNT_CV_LANGUAGES_BOX_FIELD_LOCALIZATION } from './localization';
import { TAccountCvLanguageItem } from './components/language-box/components';

import styles from './AccountCvLanguagesField.module.scss';



type TProps = {
	setItems: (value: TAccountCvLanguage[]) => void;
	languagesSource: TLanguage[];
	languageLevelsSource: TLanguageLevel[];
	languages: TAccountCvLanguage[];
	isLoading: boolean;
};

export function AccountCvLanguagesField(props: TProps) {
	const { headline, button } = useLocalization(ACCOUNT_CV_LANGUAGES_BOX_FIELD_LOCALIZATION);
	const {
		setItems,
		languagesSource,
		languageLevelsSource,
		languages,
		isLoading = false,
	} = props;

	const validateConfig = { min: 2, max: 2 };

	const languageField = useInputFormField({
		name: 'language',
		validation: value => formValidateText(value, validateConfig),
	});

	const languageLevelField = useInputFormField({
		name: 'level',
		validation: value => formValidateText(value, validateConfig),
	});

	const [languagesValue, setLanguagesValue] = useState<TAccountCvLanguage[]>(languages);
	const [languageItems, setLanguageItems] = useState<TAccountCvLanguageItem[]>([]);

	useEffect(() => {
		const languagesItems = languagesValue.map(item => {
			const { language, level } = item;

			const existedLanguage = languagesSource.find(item => item.alias === language);
			const existedLevel = languageLevelsSource.find(item => item.alias === level);

			const languageName = existedLanguage ? existedLanguage.name : '—';
			const levelName = existedLevel ? existedLevel.name : '—';

			return {
				language,
				level,
				languageName,
				levelName,
			};
		});

		setItems(languagesValue);
		setLanguageItems(languagesItems);
	}, [languagesValue]);

	const onClick = () => {
		const errors = [
			languageField.validate(),
			languageLevelField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const data = {
			language: languageField.value,
			level: languageLevelField.value,
		};

		const existed = languagesValue.find(item => item.language === data.language && item.level === data.level);

		languageField.clear();
		languageLevelField.clear();

		if (existed) {
			return;
		}

		const filteredLanguages = languagesValue.filter(item => item.language !== data.language);

		setLanguagesValue([...filteredLanguages, data]);
	};

	return (
		<>
			<div className={ styles.headline }>{ headline }</div>

			<PageRow className={ styles.row }>
				<AccountCvLanguage { ...languageField } isLoading={ isLoading } items={ languagesSource } />
				<AccountCvLanguageLevelBox { ...languageLevelField } isLoading={ isLoading } items={ languageLevelsSource } />

				<FormButton
					onClick={ onClick }
					type="button"
					title={ button }
					disabled={ isLoading || !(languageField.value && languageLevelField.value)}
					mini
				>
					<Icon icon="icon-plus" />
				</FormButton>
			</PageRow>

			<AccountCvLanguageBox items={ languageItems } setItems={ setItems } setLanguageItems={ setLanguageItems } />
		</>
	);
}
