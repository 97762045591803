import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountProject, setAccountProjectsCreatedItem, accountProjectCreateApi, useAppDispatch, useAccountProjectCreateSelector, TAccountStack, TStack } from 'src/store';
import { formHandleServerError, formValidateLink, formValidateNickname, formValidateText, useInputFileField, useInputFilesField, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_ACCOUNT_PROJECTS } from 'src/routes';

import { AccountProjectAliasField, AccountProjectTitleField, AccountProjectImageField, AccountProjectUriField, AccountProjectRepositoryUriField, AccountProjectStackField, AccountProjectFilesField } from '../../../../components';
import { ACCOUNT_PROJECTS_CREATE_LOCALIZATION } from '../../localization';


type TProps = TAccountProject & {
	items: TStack[];
};

export function AccountProjectsFormCreate(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountProjectCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(ACCOUNT_PROJECTS_CREATE_LOCALIZATION);

	const {
		alias = '',
		title = '',
		uri = '',
		repositoryUri = '',
		image = '',
		files = [],
		stack = [],
		items: stackSource,
	} = props;

	const [stackValue, setStackValue] = useState<TAccountStack[]>(stack);

	const stackField = useInputFormField({
		name: 'stack',
		validation: value => formValidateText(value),
	});

	const aliasField = useInputFormField({
		name: 'alias',
		defaultValue: alias,
		validation: value => formValidateNickname(value),
	});

	const titleField = useInputFormField({
		name: 'title',
		defaultValue: title,
		validation: value => formValidateText(value),
	});

	const uriField = useInputFormField({
		name: 'uri',
		defaultValue: uri,
		validation: value => formValidateLink(value),
	});

	const repositoryUriField = useInputFormField({
		name: 'repository_uri',
		defaultValue: repositoryUri,
		validation: value => formValidateLink(value),
	});

	const imageField = useInputFileField('image', image);
	const [imageValue, setImageValue] = useState<File>();

	const filesField = useInputFilesField('files', files);
	const [filesValue, setFilesValue] = useState<File[]>([]);

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				aliasField,
				titleField,
				uriField,
				repositoryUriField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			dispatch(setAccountProjectsCreatedItem(item));
			navigate(`${PATH_ACCOUNT_PROJECTS}/${item.alias}`);
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			aliasField.validate(),
			titleField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			alias: aliasField.value,
			title: titleField.value,
			uri: uriField.value || undefined,
			repositoryUri: repositoryUriField.value || undefined,
			image: imageValue || imageField.value,
		};

		const stack = stackValue.map(item => item.alias);
		const defaultFilesValues = filesField.value.map(item => item.path);
		const files = [...filesValue, ...defaultFilesValues];

		dispatch(accountProjectCreateApi({ data: dataRequest, files, stack }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<AccountProjectImageField { ...imageField } setFile={ setImageValue } />

			<AccountProjectAliasField { ...aliasField } isLoading={ isLoading } />

			<AccountProjectTitleField { ...titleField } isLoading={ isLoading } />

			<AccountProjectUriField { ...uriField } isLoading={ isLoading } />

			<AccountProjectRepositoryUriField { ...repositoryUriField } isLoading={ isLoading } />

			<AccountProjectFilesField { ...filesField } setFiles={ setFilesValue } />

			<AccountProjectStackField
				{ ...stackField }
				isLoading={ isLoading }
				items={ stackSource }
				stack={ stackValue }
				setStack={ setStackValue }
			/>

			<FormNotice errorText={ notice } />

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
