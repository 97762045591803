import { LocalizationTypes } from 'src/common/types';

import { ACCOUNT_CVS_DETAILS_RU_LOCALIZATION } from './ru.localization';
import { ACCOUNT_CVS_DETAILS_EN_LOCALIZATION } from './en.localization';


export const ACCOUNT_CVS_DETAILS_LOCALIZATION = {
	[LocalizationTypes.RU]: ACCOUNT_CVS_DETAILS_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ACCOUNT_CVS_DETAILS_EN_LOCALIZATION,
};
