import { useMemo } from 'react';

import { TAccountPhoneNumber, useAccountPhoneNumberReadSelector } from 'src/store';

import { AccountPhoneNumberConfirmStage, AccountPhoneNumberSendStage } from './components';


type TProps = {
	item: TAccountPhoneNumber;
};

export function AccountPhoneNumberRouter(props: TProps) {
	const { item: itemParam } = props;
	const { item = itemParam, timeExpired } = useAccountPhoneNumberReadSelector();

	const isConfirmation = useMemo(() => {
		const { phoneNumberExpected } = item;

		return phoneNumberExpected && timeExpired > 0 ? true : false;
	}, [item, timeExpired]);

	if (isConfirmation) {
		return (
			<AccountPhoneNumberConfirmStage item={ item } />
		);
	}

	return (
		<AccountPhoneNumberSendStage item={ item } />
	);
}
