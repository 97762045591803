import { Link } from 'src/components';

import { TableBodyCol, TableBodyRow } from 'src/components';

import styles from './AccountExperienceListRow.module.scss';
import { TAccountExperience } from 'src/store';
import { libFormatDate } from 'src/toolkit';
import { PATH_ACCOUNT_EXPERIENCE } from 'src/routes';

import { LocaleBox } from 'src/pages/components';


export function AccountExperienceListRow(props: TAccountExperience) {
	const {
		pid,
		locale,
		companyName,
		companyLogo,
		locales,
		createdAt,
		updatedAt,
	} = props;

	const path = `${PATH_ACCOUNT_EXPERIENCE}/${pid}/${locale}`;

	return (
		<TableBodyRow>
			<TableBodyCol className={ styles.imageCol } center>
				{ companyLogo && <img className={ styles.image } src={ companyLogo } alt={ companyName } /> }
			</TableBodyCol>

			<TableBodyCol><Link to={ path }>{ companyName }</Link></TableBodyCol>

			<TableBodyCol left>
				<LocaleBox pathStart={ `${PATH_ACCOUNT_EXPERIENCE}/${pid}` } items={ locales }/>
			</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(createdAt) }</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(updatedAt) }</TableBodyCol>
		</TableBodyRow>
	);
}
