import { FormButtonLink, Link } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';
import { useLocalization } from 'src/toolkit';

import { SIGN_UP_PHONE_NUMBER_SUCCESS_LOCALIZATION } from './localization';

import styles from '../../../../../../components/stages/SignUpStages.module.scss';


export function SignUpStagePhoneNumberSuccess() {
	const classNames = [styles.paragraph, styles.percepted];
	const {
		successText,
		successTextLeftHalf,
		successTextRightHalf,
		successLink,
		buttonText,
	} = useLocalization(SIGN_UP_PHONE_NUMBER_SUCCESS_LOCALIZATION);

	return (
		<div className={ styles.succesContainer }>
			<p className={ classNames.join(' ') }>
				{ successText }
			</p>

			<p className={ styles.paragraph }>
				{ successTextLeftHalf } <Link to={ PATH_SIGN_IN }>{ successLink }</Link> { successTextRightHalf }
			</p>

			<FormButtonLink
				className={ styles.button }
				to={ PATH_SIGN_IN }
			>{ buttonText }</FormButtonLink>
		</div>
	);
}
