export const USERS_PHONE_NUMBER_DETAILS_EN_LOCALIZATION = {
	createdAtLegend: 'Date of creation',
	createdAtTitle: 'Date of creation phone number of the user',

	updatedAtLegend: 'Date of update',
	updatedAtTitle: 'Date of updating phone number of the user',

	unconfirmedLegend: 'Unconfirmed phone number',
	unconfirmedTitle: 'The phone number of the user is unconfirmed',

	removeLinkText: 'Remove phone number',
};
