import { TAccountStack } from 'src/store';

import styles from './PublicStackItem.module.scss';


type TProps = {
	item: TAccountStack;
	onFocus: (value: TAccountStack) => void;
	onBlur: () => void;
};

export function PublicStackItem(props: TProps) {
	const { item, onFocus, onBlur } = props;
	const { image, name } = item;

	const classNames = [styles.item];

	return (
		<div className={ classNames.join(' ') } onFocus={ e => onFocus(item) } onBlur={ onBlur } tabIndex={ -1 }>
			<img className={ styles.image } src={ image } />
			<span className={ styles.name }>{ name }</span>
		</div>
	);
}
