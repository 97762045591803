export const SIGN_IN_FORM_RU_LOCALIZATION = {
	loginLegend: 'Логин',
	loginPlaceholder: 'Логин',
	loginTitle: 'Введите ваш никнейм, E-Mail or номер телефона',

	passwordLegend: 'Пароль',
	passwordTitle: 'Введите ваш пароль',

	recoveryText: 'Забыли пароль?',

	buttonText: 'Войти',
};
