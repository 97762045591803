import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline, PageRow, Preloader } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_STACK } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { clearStackLocaleCreate, clearStackLocaleCreatedItem, clearStackLocaleRead, clearStackLocaleUpdate, setStackLocaleRead, stackLocaleReadGetApi, useAppDispatch, useStackLocaleReadSelector, useStacksSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidLocale, isInvalidNickname, useLocalization } from 'src/toolkit';

import { STACK_COMMON_LOCALIZATION } from '../../localization';

import { StackLocaleFormUpdate, StackLocaleDetails } from './components';
import { STACK_LOCALE_UPDATE_LOCALIZATION } from './localization';


export function StackLocaleUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const { alias = '', locale = '' } = useParams();
	const { itemLocale: created } = useStacksSelector();
	const { data: item, isLoading, error } = useStackLocaleReadSelector();
	const { breadCrumbList } = useLocalization(STACK_COMMON_LOCALIZATION);
	const { title, headline } = useLocalization(STACK_LOCALE_UPDATE_LOCALIZATION);

	const isInvalidAlias = isInvalidNickname(alias);
	const isInvalidLang = isInvalidLocale(locale);
	const isInvalid = isInvalidAlias || isInvalidLang;

	useEffect(() => {
		const isExisted = created && created.locale === locale && created.alias === alias;
		const isNotRequest = isInvalid || isExisted;

		if (isExisted) {
			dispatch(setStackLocaleRead(created));
		}

		if (!isNotRequest) {
			dispatch(stackLocaleReadGetApi({ alias, locale }));
		}

		return () => {
			dispatch(clearStackLocaleUpdate());
			dispatch(clearStackLocaleRead());
			dispatch(clearStackLocaleCreate());
			dispatch(clearStackLocaleCreatedItem());
		};
	}, []);

	if (isInvalid) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const { name } = item;

	setTitle(`${title} ${name}`);

	const breadCrumbs = [
		{ to: PATH_STACK, text: breadCrumbList },
		{ to: `${PATH_STACK}/${alias}`, text: name },
		{ to: pathname, text: locale.toUpperCase() },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageRow>
					<PageCol>
						<StackLocaleFormUpdate { ...item } alias={ alias } />
					</PageCol>

					<PageCol>
						<StackLocaleDetails { ...item } alias={ alias } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
