import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { setCredentialsLangInit, useAppDispatch, useCredentialsSelector } from 'src/store';
import { LC_KEY_IS_AUTH, LC_KEY_IS_VISIBLE_ASIDE, LocalStorage, useLocalization } from 'src/toolkit';
import { PATH_HOME, PATH_PUBLIC_CV, PATH_SIGN_IN } from 'src/routes';

import { NAV_MENU_LOCALIZATION } from './localization';
import { Aside } from './aside';
import { Nav } from './nav/Nav';

import styles from './NavMenu.module.scss';


export type TProps = {
	children?: ReactNode;
};

export function NavMenu(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { credentials, roles } = useCredentialsSelector();
	const { login, logout, home, list } = useLocalization(NAV_MENU_LOCALIZATION);

	const isAuth = LocalStorage.get(LC_KEY_IS_AUTH);

	useEffect(() => {
		dispatch(setCredentialsLangInit());
	}, []);

	useEffect(() => {
		if (isAuth && !credentials) {
			LocalStorage.removeFrom(LC_KEY_IS_AUTH);

			navigate(PATH_SIGN_IN);
		}
	}, [credentials, roles, isAuth]);

	const isVisible = isAuth ? !!LocalStorage.get(LC_KEY_IS_VISIBLE_ASIDE) : false;

	const [state, setState] = useState(isVisible);

	const settings = {
		logo: { style: { width: '3.5em' } },
		login: { text: login, icon: 'icon-enter', href: PATH_SIGN_IN },
		logout: { text: logout, icon: 'icon-exit', href: '/' },
		items: [
			{ text: home, icon: 'icon-home', href: PATH_HOME },
			{ text: list, icon: 'icon-list', href: PATH_PUBLIC_CV },
		],
	};

	const { children } = props;

	const pageWrapClassNames = [styles.pageWrap];
	const veilClassNames = [styles.veil];

	if (state) {
		pageWrapClassNames.push(styles.active);
		veilClassNames.push(styles.veilActive);
	}

	const toggle = () => {
		const bool = !state;

		setState(bool);
		LocalStorage.set(LC_KEY_IS_VISIBLE_ASIDE, bool);
	};

	const hideAside = () => {
		const bool = false;

		setState(bool);
		LocalStorage.set(LC_KEY_IS_VISIBLE_ASIDE, bool);
	};

	return (
		<>
			<Nav
				{ ...settings }
				onClick={ toggle }
				active={ state }
				isVisibleButton={ !!credentials }
			/>

			<div className={ pageWrapClassNames.join(' ') }>
				{ credentials &&
					<Aside
						onClick={ hideAside }
						credentials={ credentials }
						isVisible={ state }
					/>
				}

				<div className={ styles.contentWrap }>
					{ children && children }
				</div>

				{ credentials &&
					<div
						className={ veilClassNames.join(' ') }
						onClick={ hideAside }
					></div>
				}
			</div>
		</>
	);
}
