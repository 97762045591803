export const ACCOUNT_MAIN_COMMON_EN_LOCALIZATION = {
	title: 'Account',
	headline: 'Account settings',

	breadCrumbAccount: 'Account',

	phoneNumberLegend: 'Phone number',
	phoneNumberConfirmedTitle: 'Phone number confirmed',
	phoneNumberUnconfirmedTitle: 'Phone number unconfirmed',
	phoneNumberButtonTitle: 'Go to phone number settings',

	emailLegend: 'E-Mail',
	emailConfirmedTitle: 'E-Mail confirmed',
	emailUnconfirmedTitle: 'E-Mail unconfirmed',
	emailButtonTitle: 'Go to E-Mail settings',

	passwordButton: 'Change password',
};
