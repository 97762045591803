// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSearchListItem_item__fynWP {\n  background-color: rgb(250, 250, 250);\n  border-right: 1px solid rgb(180, 180, 180);\n  border-bottom: 1px solid rgb(180, 180, 180);\n  box-sizing: border-box;\n  color: rgb(150, 150, 150);\n  cursor: pointer;\n  font-family: inherit;\n  font-size: 1em;\n  font-weight: 100;\n  font-style: inherit;\n  line-height: 2em;\n  animation-timing-function: ease-in-out;\n  transition-duration: 0.1s;\n  transition-property: background-color, color;\n  height: 2em;\n  width: 100%;\n  padding: 0 0.5em;\n}\n.FormSearchListItem_item__fynWP:hover {\n  background-color: rgb(255, 255, 255);\n  color: rgb(90, 90, 90);\n}\n\n* > .FormSearchListItem_item__fynWP:last-child {\n  border-bottom: none;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-search-box/components/list/components/item/FormSearchListItem.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,0CAAA;EACA,2CAAA;EACA,sBAAA;EACA,yBAAA;EACA,eAAA;EAEA,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EAEA,gBAAA;EAEA,sCAAA;EACA,yBAAA;EACA,4CAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;AAFD;AAIC;EACC,oCAAA;EACA,sBAAA;AAFF;;AAMA;EACC,mBAAA;AAHD","sourcesContent":[".item {\n\tbackground-color: rgba(250, 250, 250, 1);\n\tborder-right: 1px solid rgba(180, 180, 180, 1);\n\tborder-bottom: 1px solid rgba(180, 180, 180, 1);\n\tbox-sizing: border-box;\n\tcolor: rgba(150, 150, 150, 1);\n\tcursor: pointer;\n\n\tfont-family: inherit;\n\tfont-size: 1em;\n\tfont-weight: 100;\n\tfont-style: inherit;\n\n\tline-height: 2em;\n\n\tanimation-timing-function: ease-in-out;\n\ttransition-duration: 0.1s;\n\ttransition-property: background-color, color;\n\theight: 2em;\n\twidth: 100%;\n\tpadding: 0 0.5em;\n\n\t&:hover {\n\t\tbackground-color: rgba(255, 255, 255, 1);\n\t\tcolor: rgba(90, 90, 90, 1);\n\t}\n}\n\n* > .item:last-child {\n\tborder-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "FormSearchListItem_item__fynWP"
};
export default ___CSS_LOADER_EXPORT___;
