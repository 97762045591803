import { ReactNode } from 'react';

import styles from './PageRow.module.scss';


type TProps = {
	className?: string;
	classNames?: string[];
	style?: Record<string, string>;
	children?: ReactNode;
};

export function PageRow(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		children,
		style = {},
	} = props;

	const classNames = [styles.pageRow, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } style={ style }>
			{ children }
		</div>
	);
}
