import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Preloader } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { ErrorWidget } from 'src/pages/components';
import { PATH_ACCOUNT_EMAIL, PATH_SIGN_IN } from 'src/routes';
import { accountEmailUpdateConfirmApi, clearAccountEmailUpdateConfirm, setAccountEmailNoticeUpdate, setSignUpLoginAction, useAccountEmailUpdateConfirmSelector, useAppDispatch, useCredentialsSelector } from 'src/store';
import { isInvalidHashcode, useLocalization } from 'src/toolkit';

import { ACCOUNT_EMAIL_CONFIRM_LOCALIZATION } from './localization';


export function AccountEmailConfirmPage() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { hashcode } = useParams();

	const { successNotice } = useLocalization(ACCOUNT_EMAIL_CONFIRM_LOCALIZATION);

	const isInvalid = isInvalidHashcode(hashcode, 128);

	const { item, error } = useAccountEmailUpdateConfirmSelector();
	const { credentials } = useCredentialsSelector();

	useEffect(() => {
		if (hashcode && !isInvalid) {
			dispatch(accountEmailUpdateConfirmApi({ hashcode }));
		}

		return () => {
			dispatch(clearAccountEmailUpdateConfirm());
		};
	}, []);

	useEffect(() => {
		if (item) {
			const { email } = item;
			const isAuth = !!credentials
			const path = isAuth ? PATH_ACCOUNT_EMAIL : PATH_SIGN_IN;

			if (isAuth) {
				dispatch(setAccountEmailNoticeUpdate(successNotice));
			} else {
				dispatch(setSignUpLoginAction(email));
			}

			navigate(path);
		}
	}, [item]);

	if (isInvalid || error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	return (
		<AccountLayout>
			<div>
				<Preloader />
			</div>
		</AccountLayout>
	);
}
