import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountCv, TAccountCvLocaleCreate, TAccountCvResponse, adaptAccountCvFromResponse, adaptAccountCvLocaleToRequest } from '../../types';
import { BACKEND_ACCOUNT_CVS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';


export const accountCvLocaleCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_CVS}/:alias`,
	(payload: TAccountCvLocaleCreate, thunkApi) => {
		const { data, alias } = payload;
		const path = `${BACKEND_ACCOUNT_CVS}/${alias}`;
		const dataRequest = adaptAccountCvLocaleToRequest(data);

		return HttpService.post<TAccountCvResponse>(path, dataRequest).then(result => {
			return adaptAccountCvFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountCv | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCvLocaleCreateSlice = createSlice({
	name: 'accountCvLocaleCreateSlice',
	initialState,
	reducers: {
		clearAccountCvLocaleCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCvLocaleCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountCvLocaleCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountCv>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountCvLocaleCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountCvLocaleCreateSelector = () => useAppSelector(state => state[accountCvLocaleCreateSlice.name]);

export const { clearAccountCvLocaleCreate } = accountCvLocaleCreateSlice.actions;
