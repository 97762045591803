// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableBody_tableBody__MIOh2 {\n  color: rgb(0, 0, 0);\n}", "",{"version":3,"sources":["webpack://./src/components/table/tbody/TableBody.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;AACD","sourcesContent":[".tableBody {\n\tcolor: rgba(0, 0, 0, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBody": "TableBody_tableBody__MIOh2"
};
export default ___CSS_LOADER_EXPORT___;
