import { DefaultLayout } from 'src/layouts';


export function HomePage() {
	return (
		<DefaultLayout>

		</DefaultLayout>
	);
}

export default HomePage;
