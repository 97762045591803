// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocaleBoxField_row__oIfsi {\n  display: flex;\n  justify-content: space-between;\n  padding-top: 0.5em;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/locale-box-field/LocaleBoxField.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EAEA,kBAAA;AAAD","sourcesContent":[".row {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\n\tpadding-top: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "LocaleBoxField_row__oIfsi"
};
export default ___CSS_LOADER_EXPORT___;
