import { TPublicExperience } from 'src/store';

import { PageRow } from 'src/components';
import { CvExperienceCount, TextBlock } from 'src/pages/components';

import { PublicCvTextLink } from '../../../text-link';

import { PublicCvExperienceDateTimeRange, PublicCvExperienceItemLogo, PublicCvExperiencePositionName, PublicCvExperienceProjects } from './components';

import styles from './PublicCvExperienceItem.module.scss';


type TProps = {
	item: TPublicExperience;
};

export function PublicCvExperienceItem(props: TProps) {
	const { item } = props;

	const {
		companyLogo,
		companyName,
		companyUri,
		dateStart,
		dateEnd,
		description,
		positionName,
		countExperience,
		projects,
	} = item;

	return (
		<div className={ styles.box }>
			<PageRow className={ styles.row }>
				<PublicCvExperienceItemLogo src={ companyLogo } alt={ companyName } />

				<div>
					<PublicCvTextLink path={ companyUri }>{ companyName }</PublicCvTextLink>

					<PublicCvExperiencePositionName>{ positionName }</PublicCvExperiencePositionName>

					<PublicCvExperienceDateTimeRange start={ dateStart } end={ dateEnd } />

					<CvExperienceCount value={ countExperience }/>
				</div>
			</PageRow>

			<PageRow>
				<TextBlock>{ description }</TextBlock>
			</PageRow>

			<PublicCvExperienceProjects items={ projects } />

			<div className={ styles.map }>
				<div className={ styles.start }></div>
				<div className={ styles.arrow }></div>
			</div>
		</div>
	);
}
