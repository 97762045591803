import { Link } from 'react-router-dom';

import { Icon } from 'src/components';
import { getExt } from 'src/common/helpers';
import { PATH_PUBLIC_IMAGES, PATH_STATIC_PROJECTS_FILES } from 'src/routes';
import { TPublicProjectFile } from 'src/store';

import styles from './PublicFileItem.module.scss';


type TProps = {
	item: TPublicProjectFile;
};

export function PublicFileItem(props: TProps) {
	const { item } = props;
	const { path, name } = item;

	const classNames = [styles.item];
	const linkPath = `${PATH_STATIC_PROJECTS_FILES}/${path}`;
	const ext = getExt(path);

	const isImage = ext === 'jpg' || ext === 'png' || ext === 'jpeg' || ext === 'svg';

	if (isImage) {
		return (
			<div className={ classNames.join(' ') }>
				<img className={ styles.image } src={ linkPath } alt={ name } />

				<Link className={ styles.link } to={ linkPath } title={ name } target="_blank">
					<div className={ styles.name }>{ name }</div>
				</Link>
			</div>
		);
	}

	const isPreviewIcon = ext === 'html' || ext === 'pdf';

	if (isPreviewIcon) {
		const to = `${PATH_PUBLIC_IMAGES}/icons/${ext}-file.svg`;

		return (
			<div className={ classNames.join(' ') }>
				<img className={ styles.logo } src={ to } alt={ name } />

				<Link className={ styles.link } to={ linkPath } title={ name } target="_blank">
					<div className={ styles.name }>{ name }</div>
				</Link>
			</div>
		);
	}

	const isXlsx = ext === 'xlsx' || ext === 'xls';

	if (isXlsx) {
		const to = `${PATH_PUBLIC_IMAGES}/icons/xlsx-file.svg`;

		return (
			<div className={ classNames.join(' ') }>
				<img className={ styles.logo } src={ to } alt={ name } />

				<Link className={ styles.link } to={ linkPath } title={ name } target="_blank">
					<div className={ styles.name }>{ name }</div>
				</Link>
			</div>
		);
	}

	const isDocx = ext === 'docx' || ext === 'doc';

	if (isDocx) {
		const to = `${PATH_PUBLIC_IMAGES}/icons/docx-file.svg`;

		return (
			<div className={ classNames.join(' ') }>
				<img className={ styles.logo } src={ to } alt={ name } />

				<Link className={ styles.link } to={ linkPath } title={ name } target="_blank">
					<div className={ styles.name }>{ name }</div>
				</Link>
			</div>
		);
	}

	const isArchive = ext === 'rar' || ext === 'zip';

	if (isArchive) {
		const to = `${PATH_PUBLIC_IMAGES}/icons/rar-file.svg`;

		return (
			<div className={ classNames.join(' ') }>
				<img className={ styles.logo } src={ to } alt={ name } />

				<Link className={ styles.link } to={ linkPath } title={ name } target="_blank">
					<div className={ styles.name }>{ name }</div>
				</Link>
			</div>
		);
	}

	const icon = `icon-libreoffice`;

	return (
		<div className={ classNames.join(' ') }>
			<Icon className={ styles.icon } icon={ icon }></Icon>

			<Link className={ styles.link } to={ linkPath } title={ name } target="_blank">
				<div className={ styles.name }>{ name }</div>
			</Link>
		</div>
	);
}
