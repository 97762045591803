// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AsideItem_asideLinkStyle__qTIKt {\n  border-left: 0px solid rgba(0, 0, 0, 0);\n  box-sizing: border-box;\n  color: inherit;\n  cursor: inherit;\n  display: inline-block;\n  height: 100%;\n  width: 100%;\n  padding: 0 0.5em;\n  text-decoration: none;\n  transition: border 0.1s ease-in-out;\n}\n\n.AsideItem_active__axN-6 {\n  border-left: 5px solid rgb(0, 0, 0);\n}\n\n.AsideItem_iconLink__PQdne {\n  display: inline-block;\n  font-size: 1.2em;\n  line-height: 1em;\n  height: 1.15em;\n  vertical-align: middle;\n  min-width: 1.4em;\n  max-width: 1.4em;\n}", "",{"version":3,"sources":["webpack://./src/components/nav/aside-item/AsideItem.module.scss"],"names":[],"mappings":"AAAA;EACC,uCAAA;EACA,sBAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;EAEA,YAAA;EACA,WAAA;EAEA,gBAAA;EACA,qBAAA;EACA,mCAAA;AADD;;AAIA;EACC,mCAAA;AADD;;AAIA;EACC,qBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;AADD","sourcesContent":[".asideLinkStyle {\n\tborder-left: 0px solid rgba(0, 0, 0, 0);\n\tbox-sizing: border-box;\n\tcolor: inherit;\n\tcursor: inherit;\n\tdisplay: inline-block;\n\n\theight: 100%;\n\twidth: 100%;\n\n\tpadding: 0 0.5em;\n\ttext-decoration: none;\n\ttransition: border 0.1s ease-in-out;\n}\n\n.active {\n\tborder-left: 5px solid rgba(0, 0, 0, 1);\n}\n\n.iconLink {\n\tdisplay: inline-block;\n\tfont-size: 1.2em;\n\tline-height: 1em;\n\theight: 1.15em;\n\tvertical-align: middle;\n\tmin-width: 1.4em;\n\tmax-width: 1.4em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asideLinkStyle": "AsideItem_asideLinkStyle__qTIKt",
	"active": "AsideItem_active__axN-6",
	"iconLink": "AsideItem_iconLink__PQdne"
};
export default ___CSS_LOADER_EXPORT___;
