// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableHeadCol_tableHeadCol__PLDSG {\n  border: 1px solid rgb(180, 180, 180);\n  border-top: 1px solid rgb(180, 180, 180);\n  box-sizing: border-box;\n  color: rgb(0, 0, 0);\n  line-height: 1em;\n  padding: 1em 0.5em;\n}\n.TableHeadCol_tableHeadCol__PLDSG:first-child {\n  border-left: 1px solid rgb(180, 180, 180);\n}\n.TableHeadCol_tableHeadCol__PLDSG:last-child {\n  border-right: 1px solid rgb(180, 180, 180);\n}", "",{"version":3,"sources":["webpack://./src/components/table/thead/col/TableHeadCol.module.scss"],"names":[],"mappings":"AAGA;EACC,oCAAA;EACA,wCAAA;EACA,sBAAA;EACA,mBAPO;EAQP,gBAAA;EACA,kBAAA;AAFD;AAKC;EACC,yCAAA;AAHF;AAMC;EACC,0CAAA;AAJF","sourcesContent":["$color: rgba(0, 0, 0, 1);\n$borderColor: rgba(180, 180, 180, 1);\n\n.tableHeadCol {\n\tborder: 1px solid $borderColor;\n\tborder-top: 1px solid $borderColor;\n\tbox-sizing: border-box;\n\tcolor: $color;\n\tline-height: 1em;\n\tpadding: 1em 0.5em;\n\n\n\t&:first-child {\n\t\tborder-left: 1px solid $borderColor;\n\t}\n\n\t&:last-child {\n\t\tborder-right: 1px solid $borderColor;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeadCol": "TableHeadCol_tableHeadCol__PLDSG"
};
export default ___CSS_LOADER_EXPORT___;
