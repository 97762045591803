// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountProjectsPage_actionBox__FLI68 {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  font-size: 0.8em;\n  margin: 0 auto;\n  padding: 0 0 1em;\n}\n.AccountProjectsPage_actionBox__FLI68 > * {\n  margin: 0.15em;\n  padding: 0 0.5em;\n  width: auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-projects/views/list/AccountProjectsPage.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,yBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AACD;AACC;EACC,cAAA;EACA,gBAAA;EACA,WAAA;AACF","sourcesContent":[".actionBox {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\talign-items: flex-start;\n\tflex-wrap: wrap;\n\tfont-size: 0.8em;\n\tmargin: 0 auto;\n\tpadding: 0 0 1em;\n\n\t& > * {\n\t\tmargin: 0.15em;\n\t\tpadding: 0 0.5em;\n\t\twidth: auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBox": "AccountProjectsPage_actionBox__FLI68"
};
export default ___CSS_LOADER_EXPORT___;
