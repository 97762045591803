import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TListResponse } from 'src/common/types';

import { HttpError, HttpService } from 'src/toolkit';
import { useAppSelector } from '../../hooks';
import { TPublicCv, TPublicCvListResponse, adaptPublicCvFromListResponse } from '../../types';
import { BACKEND_PUBLIC_CVS } from '../../backend-paths';


export const publicCvsGetApi = createAsyncThunk(
	BACKEND_PUBLIC_CVS,
	(payload: string, thunkApi) => {
		const params = payload ? { locale: payload } : {};

		return HttpService.get<TPublicCvListResponse>(BACKEND_PUBLIC_CVS, { params }).then(result => {
			return adaptPublicCvFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	list: TListResponse<TPublicCv>;
	isLoading: boolean;
	error: HttpError | undefined;
};


const initialState: TInitialState = {
	list: { items: [], count: 0 },
	isLoading: false,
	error: undefined,
};

export const publicCvsSlice = createSlice({
	name: 'publicCvsSlice',
	initialState,
	reducers: {
		clearPublicCvs(state) {
			return { ...initialState, list: state.list };
		},
	},

	extraReducers: {
		[publicCvsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[publicCvsGetApi.fulfilled.type]: (state, action: PayloadAction<TListResponse<TPublicCv>>) => {
			state.isLoading = false;
			state.error = undefined;
			state.list = { ...action.payload };
		},

		[publicCvsGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const usePublicCvsSelector = () => useAppSelector(state => state[publicCvsSlice.name]);

export const { clearPublicCvs } = publicCvsSlice.actions;
