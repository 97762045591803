import { useEffect } from 'react';

import { Link } from 'src/components';
import { PATH_SIGN_UP } from 'src/routes';
import { clearSignInAction, clearSignUpLoginAction, useAppDispatch } from 'src/store';
import { setTitle } from 'src/common/helpers';
import { useLocalization } from 'src/toolkit';

import { AuthLayer, TAuthContent } from '../../components';

import { SignInForm } from './components';
import { SIGN_IN_COMMON_LOCALIZATION } from './localization';

import styles from './SignInPage.module.scss';


export function SignInPage() {
	const dispatch = useAppDispatch();
	const {
		title,
		underText,
		underLink,
		...restProps
	} = useLocalization(SIGN_IN_COMMON_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearSignUpLoginAction());
			dispatch(clearSignInAction());
		};
	}, []);

	setTitle(title);

	const localeText = restProps as TAuthContent;

	return (
		<AuthLayer { ...localeText } contentLinkPath={ PATH_SIGN_UP }>
			<SignInForm />

			<p className={ styles.underText }>
				<span>{ underText }</span>
				<Link to={ PATH_SIGN_UP }>{ underLink }</Link>
			</p>
		</AuthLayer>
	);
}

