import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_SPECIALIZATION_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountEducationSpecializationField(props: TProps) {
	const {
		specializationLegend,
		specializationPlaceholder,
		specializationTitle,
	} = useLocalization(ACCOUNT_EDUCATION_SPECIALIZATION_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ specializationLegend }
			errors={ errors }
			required
		>
			<FormInput
				placeholder={ specializationPlaceholder }
				name={ name }
				title={ specializationTitle }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
