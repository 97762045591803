export const ACCOUNT_EDUCATION_TABLE_EN_LOCALIZATION = {
	educationName: 'Education',
	educationalInstitution: 'Educational institution',
	locales: 'Versions',
	createdAt: 'Created at',
	updatedAt: 'Updated at',

	searchPlaceholder: 'Search in database',
	searchTitle: 'Type text for searching in database',

	emptyText: 'My education`s items not found',
};
