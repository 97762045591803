export type TAccountPhoneNumber = {
  phoneNumber: string;
  phoneNumberExpected: string;
  timeExpired: number;
};

export type TAccountPhoneNumberResponse = {
  phone_number: string;
  phone_number_expected: string;
  time_expected: number;
};

export type TAccountPhoneNumberUpdate = {
  phoneNumberExpected: string;
};

export type TAccountPhoneNumberUpdateConfirm = TAccountPhoneNumberUpdate & {
  code: string;
};

export type TAccountPhoneNumberRequest = {
  phone_number_expected: string;
};

export type TAccountPhoneNumberConfirmRequest = TAccountPhoneNumberRequest & {
  code: string;
};

export function adaptAccountPhoneNumberFromResponse(data: TAccountPhoneNumberResponse): TAccountPhoneNumber {
	const {
		phone_number: phoneNumber,
		phone_number_expected: phoneNumberExpected,
		time_expected: timeExpired,
	} = data;

	return {
		phoneNumber: phoneNumber || '',
		phoneNumberExpected: phoneNumberExpected || '',
		timeExpired: timeExpired || 0,
	};
}

export function adaptAccountPhoneNumberToRequest(data: TAccountPhoneNumberUpdate): TAccountPhoneNumberRequest {
	const { phoneNumberExpected } = data;

	return {
		phone_number_expected: phoneNumberExpected,
	};
}

export function adaptAccountPhoneNumberConfirmToRequest(data: TAccountPhoneNumberUpdateConfirm): TAccountPhoneNumberConfirmRequest {
	const { phoneNumberExpected, code } = data;

	return {
		code,
		phone_number_expected: phoneNumberExpected,
	};
}
