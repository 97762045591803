import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice } from 'src/components';
import { formHandleServerError, formValidatePhoneNumber, isInvalidPhoneNumber, libClearIntstr, useInputFormField, useLocalization } from 'src/toolkit';
import { TAccountPhoneNumber, accountPhoneNumberUpdateApi, clearAccountPhoneNumberUpdate, setAccountPhoneNumberRead, useAccountPhoneNumberUpdateSelector, useAppDispatch } from 'src/store';

import { AccountPhoneNumberField } from '../../../../../../components';
import { ACCOUNT_PHONE_NUMBER_ROUTER_LOCALIZATION } from '../../localization';

import { ACCOUNT_PHONE_NUMBER_SEND_STAGE_LOCALIZATION } from './localization';


type TProps = {
	item: TAccountPhoneNumber;
};

export function AccountPhoneNumberSendStage(props: TProps) {
	const dispatch = useAppDispatch();
	const { item: itemUpdated, noticeConfirmed, isLoading, error } = useAccountPhoneNumberUpdateSelector();
	const [notice, setNotice] = useState('');
	const [isInvalid, setInIsValid] = useState(false);

	const { unconfirmedLegend, unconfirmedTitle, errorAlreadyConfirmed } = useLocalization(ACCOUNT_PHONE_NUMBER_ROUTER_LOCALIZATION);
	const { button } = useLocalization(ACCOUNT_PHONE_NUMBER_SEND_STAGE_LOCALIZATION);

	const { item } = props;
	const { phoneNumber, phoneNumberExpected } = item;

	const phoneNumberExpectedField = useInputFormField({
		name: 'phone_number_expected',
		defaultValue: phoneNumberExpected,
		validation: formValidatePhoneNumber,
	});

	useEffect(() => {
		return () => {
			dispatch(clearAccountPhoneNumberUpdate());
		};
	}, []);

	useEffect(() => {
		if (noticeConfirmed) {
			setNotice(noticeConfirmed);
		}
	}, [noticeConfirmed]);

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice,
				phoneNumberExpectedField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (itemUpdated) {
			setNotice('');
			dispatch(setAccountPhoneNumberRead(itemUpdated));
		}
	}, [itemUpdated]);

	useEffect(() => {
		const isInvalidValue = isInvalidPhoneNumber(phoneNumberExpectedField.value);

		setInIsValid(isInvalidValue);
	}, [phoneNumberExpectedField.value]);

	const submit = () => {
		const errors = [
			phoneNumberExpectedField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const currentValue = libClearIntstr(phoneNumber);
		const value = libClearIntstr(phoneNumberExpectedField.value);

		if (value === currentValue) {
			phoneNumberExpectedField.setErrors([errorAlreadyConfirmed]);

			return;
		}

		const dataRequest = {
			phoneNumberExpected: libClearIntstr(phoneNumberExpectedField.value),
		};

		dispatch(accountPhoneNumberUpdateApi(dataRequest));
	};

	return (
		<Form onSubmit={ submit }>
			<AccountPhoneNumberField
				{ ...phoneNumberExpectedField }
				legend={ unconfirmedLegend }
				title={ unconfirmedTitle }
				unconfirmed={ !!phoneNumberExpectedField.value }
			/>

			<FormNotice success={ !!notice && !error } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading } disabled={ isInvalid }>{ button }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
