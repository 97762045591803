import { LocalizationTypes } from 'src/common/types';

import { ACCOUNT_PROJECTS_MODAL_DELETE_RU_LOCALIZATION } from './ru.localization';
import { ACCOUNT_PROJECTS_MODAL_DELETE_EN_LOCALIZATION } from './en.localization';


export const ACCOUNT_PROJECTS_MODAL_DELETE_LOCALIZATION = {
	[LocalizationTypes.RU]: ACCOUNT_PROJECTS_MODAL_DELETE_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ACCOUNT_PROJECTS_MODAL_DELETE_EN_LOCALIZATION,
};
