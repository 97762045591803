export const ACCOUNT_EDUCATION_TABLE_RU_LOCALIZATION = {
	educationName: 'Образование',
	locales: 'Языковые версии',
	createdAt: 'Дата создания',
	updatedAt: 'Дата обновления',

	searchPlaceholder: 'Поиск',
	searchTitle: 'Введите текст для поиска',

	emptyText: 'Моих образований не найдено',
};
