import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountEducation, TAccountEducationLocaleUpdate, TAccountEducationResponse, adaptAccountEducationFromResponse, adaptAccountEducationLocaleToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';


export const accountEducationLocaleUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_EDUCATIONS}/:pid/:locale`,
	(payload: TAccountEducationLocaleUpdate, thunkApi) => {
		const { data, pid, locale } = payload;
		const path = `${BACKEND_ACCOUNT_EDUCATIONS}/${pid}/${locale}`;
		const dataRequest = adaptAccountEducationLocaleToRequest(data);

		return HttpService.put<TAccountEducationResponse>(path, dataRequest).then(result => {
			return adaptAccountEducationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountEducation | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEducationLocaleUpdateSlice = createSlice({
	name: 'accountEducationLocaleUpdateSlice',
	initialState,
	reducers: {
		clearAccountEducationLocaleUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEducationLocaleUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEducationLocaleUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEducationLocaleUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountEducationLocaleUpdateSelector = () => useAppSelector(state => state[accountEducationLocaleUpdateSlice.name]);

export const { clearAccountEducationLocaleUpdate } = accountEducationLocaleUpdateSlice.actions;
