// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountProjectDescriptionField_box__9Wvk- {\n  min-height: 6em !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-projects/components/description-field/AccountProjectDescriptionField.module.scss"],"names":[],"mappings":"AAAA;EACC,0BAAA;AACD","sourcesContent":[".box {\n\tmin-height: 6em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountProjectDescriptionField_box__9Wvk-"
};
export default ___CSS_LOADER_EXPORT___;
