export const USERS_DETAILS_RU_LOCALIZATION = {
	phoneNumberLegend: 'Номер телефона',
	phoneNumberPlaceholder: '—',
	phoneNumberTitle: 'Номер телефона пользователя подтверждён',
	phoneNumberNoneTitle: 'Номер телефона пользователя не установлен',
	phoneNumberButtonTitle: 'Перейти в настройки номера телефона',

	emailLegend: 'E-Mail',
	emailPlaceholder: '—',
	emailTitle: 'E-Mail пользователя подтверждён',
	emailNoneTitle: 'E-Mail пользователя не установлен',
	emailButtonTitle: 'Перейти в настройки E-Mail',

	createdAtLegend: 'Дата создания',
	createdAtTitle: 'Дата создания этого пользователя',

	updatedAtLegend: 'Дата обновления',
	updatedAtTitle: 'Дата обновления этого пользователя',

	removeLinkText: 'Удалить пользователя',

	passwordButton: 'Пароль',
	rolesButton: 'Права и доступы',
};
