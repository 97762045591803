// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvExperienceItemLogo_box__m8B4O {\n  box-sizing: border-box;\n  border: 1px solid rgb(220, 220, 220);\n  display: flex;\n  height: 6em;\n  width: 6em;\n  min-height: 6em;\n  min-width: 6em;\n  max-height: 6em;\n  max-width: 6em;\n  margin-right: 1em;\n  overflow: hidden;\n}\n\n.PublicCvExperienceItemLogo_image__ut72s {\n  box-sizing: border-box;\n  display: block;\n  object-fit: contain;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/experience/components/experience-item/components/logo/PublicCvExperienceItemLogo.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,oCAAA;EACA,aAAA;EAEA,WAAA;EACA,UAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;EAEA,iBAAA;EAEA,gBAAA;AAFD;;AAKA;EACC,sBAAA;EACA,cAAA;EACA,mBAAA;EACA,WAAA;AAFD","sourcesContent":[".box {\n\tbox-sizing: border-box;\n\tborder: 1px solid rgba(220, 220, 220, 1);\n\tdisplay: flex;\n\n\theight: 6em;\n\twidth: 6em;\n\tmin-height: 6em;\n\tmin-width: 6em;\n\tmax-height: 6em;\n\tmax-width: 6em;\n\n\tmargin-right: 1em;\n\n\toverflow: hidden\n}\n\n.image {\n\tbox-sizing: border-box;\n\tdisplay: block;\n\tobject-fit: contain;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvExperienceItemLogo_box__m8B4O",
	"image": "PublicCvExperienceItemLogo_image__ut72s"
};
export default ___CSS_LOADER_EXPORT___;
