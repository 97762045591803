import { useLocalization } from 'src/toolkit';
import { TAccountPhoneNumber } from 'src/store';

import { AccountPhoneNumberField } from '../../../../components';

import { ACCOUNT_PHONE_NUMBER_CONFIRMED_LOCALIZATION } from './localization';


type TProps = {
	item: TAccountPhoneNumber;
};

export function AccountPhoneNumberConfirmed(props: TProps) {
	const { item } = props;

	const { legend, title } = useLocalization(ACCOUNT_PHONE_NUMBER_CONFIRMED_LOCALIZATION);

	const { phoneNumber } = item;

	if (!phoneNumber) {
		return (
			<></>
		);
	}

	return (
		<AccountPhoneNumberField
			value={ phoneNumber }
			setValue={ () => {} }
			errors={ [] }
			setErrors={ () => {} }
			name="phone_number"
			validate={ () => false }
			clear={ () => {} }

			readOnly
			confirmed={ !!phoneNumber }
			required={ false }
			legend={ legend }
			title={ title }
		/>
	);
}
