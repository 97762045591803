import { Link } from 'react-router-dom';

import styles from './PublicCvLastWorkCompanyName.module.scss';


type TProps = {
	name: string;
	path?: string;
};

export function PublicCvLastWorkCompanyName(props: TProps) {
	const { name, path } = props;

	if (path) {
		const classNames = [styles.name, styles.link];

		return (
			<Link className={ classNames.join(' ') } to={ path }>{ name }</Link>
		);
	}

	return (
		<span className={ styles.name }>{ name }</span>
	);
}
