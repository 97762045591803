// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeadlineLink_text__RTj46 {\n  color: rgb(120, 120, 120);\n  display: inline;\n  font-size: 1.125em;\n  font-weight: bold;\n  line-height: 1.2em;\n  height: 1.2em;\n  white-space: normal;\n  text-decoration: none;\n}\n\n.HeadlineLink_link__ZJs-8 {\n  border-bottom: 1px solid rgb(120, 120, 120);\n  cursor: pointer;\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n}\n.HeadlineLink_link__ZJs-8:active, .HeadlineLink_link__ZJs-8:hover {\n  border-bottom: 1px solid transparent;\n  color: rgb(90, 90, 90);\n}\n.HeadlineLink_link__ZJs-8:active {\n  transition: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/headline-link/HeadlineLink.module.scss"],"names":[],"mappings":"AAGA;EACC,yBAJO;EAKP,eAAA;EAEA,kBAAA;EACA,iBAAA;EAEA,kBAAA;EACA,aAAA;EAEA,mBAAA;EAEA,qBAAA;AAND;;AASA;EACC,2CAAA;EACA,eAAA;EAEC,2DAAA;AAPF;AASC;EAEC,oCAAA;EACA,sBA1BW;AAkBb;AAWC;EACC,gBAAA;AATF","sourcesContent":["$color: rgba(120, 120, 120, 1);\n$colorHover: rgba(90, 90, 90, 1);\n\n.text {\n\tcolor: $color;\n\tdisplay: inline;\n\n\tfont-size: 1.125em;\n\tfont-weight: bold;\n\n\tline-height: 1.2em;\n\theight: 1.2em;\n\n\twhite-space: normal;\n\n\ttext-decoration: none;\n}\n\n.link {\n\tborder-bottom: 1px solid $color;\n\tcursor: pointer;\n\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n\n\t&:active,\n\t&:hover {\n\t\tborder-bottom: 1px solid transparent;\n\t\tcolor: $colorHover;\n\t}\n\n\t&:active {\n\t\ttransition: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "HeadlineLink_text__RTj46",
	"link": "HeadlineLink_link__ZJs-8"
};
export default ___CSS_LOADER_EXPORT___;
