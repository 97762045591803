import { ReactNode, useRef, useState } from 'react';

import { FormSelectBoxLabel, FormSelectBoxOption, FormSelectBoxWrap } from './components';

import styles from './FormSelectBox.module.scss';


type TOptionData = {
	value: string | number;
	text?: ReactNode;
};

type TOptionValue = string | number;

type TProps = {
	items?: TOptionData[] | TOptionValue[];
	value?: string;
	onChange: (value: string) => void;
	disabled?: boolean;
	classNames?: string[];
	className?: string;
	title?: string;
	name?: string;
	placeholder?: string;
};

function isOptionData(value: TOptionValue[] | TOptionData[]): value is TOptionData[] {
	const item = value[0];

  return typeof (item as TOptionData) === 'object';
}

const TRANSITION_DURATION = 100;

export function FormSelectBox(props: TProps) {
	const {
		items = [],
		value = '',
		disabled = false,
		placeholder = '',
		onChange,
		...restProps
	} = props;

	const [active, setActive] = useState(false);
	const labelRef = useRef<HTMLLabelElement>(null);
	const [isReady, setIsReady] = useState(true);

	const onFocus = () => {
		setActive(true);
		setIsReady(false);
		setTimeout(() => setIsReady(true), TRANSITION_DURATION);
	};

	const onBlur = () => {
		setTimeout(() => setActive(false), TRANSITION_DURATION);
	};

	const onClick = (optionValue: string) => {
		if (value === optionValue) {
			return labelRef.current && labelRef.current.focus();
		}

		onChange(optionValue);
		setActive(false);

		if (onChange) {
			onChange(optionValue);
		}
	};

	const onToggle = () => {
		if (labelRef.current !== document.activeElement || isReady) {
			setActive(!active);
		}
	};

	const classNames = [styles.formSelectBoxContainer, 'scroll-box'];

	if (active) {
		classNames.push(styles.show);
	}

	if (isOptionData(items)) {
		const item = items.find(item => item.value === value);
		const text = item ? item.text : placeholder;

		return (
			<FormSelectBoxWrap { ...restProps }>
				<FormSelectBoxLabel ref={ labelRef } active={ active } onFocus={ onFocus } onBlur={ onBlur } onClick={ onToggle } disabled={ disabled }>{ text }</FormSelectBoxLabel>

				<div className={ classNames.join(' ') }>
					{ items.length > 0 &&
						items.map(item => {
							const text = item.text || item.value;
							const val = item.value;
							const strVal = typeof val === 'string' ? val : val.toString();
							const className = value === strVal ? styles.activeOption : undefined;
							const title = typeof text === 'string' ? text : '';

							return (
								<FormSelectBoxOption title={ title } className={ className } key={ strVal } onClick={ e => onClick(strVal) }>{ text }</FormSelectBoxOption>
							);
						})
					}
				</div>
			</FormSelectBoxWrap>
		);
	}

	const labelValue = items.includes(value) ? value : placeholder;

	return (
		<FormSelectBoxWrap { ...restProps }>
			<FormSelectBoxLabel ref={ labelRef } active={ active } onFocus={ onFocus } onBlur={ onBlur } onClick={ onToggle } disabled={ disabled }>{ labelValue }</FormSelectBoxLabel>

			<div className={ classNames.join(' ') }>
				{ items.length > 0 &&
					items.map(item => {
						const val = item;
						const strVal = typeof val === 'string' ? val : val.toString();
						const className = value === strVal ? styles.activeOption : undefined;
						const title = typeof val === 'string' ? val : '';

						return (
							<FormSelectBoxOption title={ title } className={ className } key={ strVal } onClick={ e => onClick(strVal) }>{ strVal }</FormSelectBoxOption>
						);
					})
				}
			</div>
		</FormSelectBoxWrap>
	);
}
