export type TAccountEmail = {
  email: string;
  emailExpected: string;
  timeExpired: number;
};

export type TAccountEmailResponse = {
  email: string;
  email_expected: string;
  time_expected: number;
};

export type TAccountEmailUpdate = {
  emailExpected: string;
};

export type TAccountEmailRequest = {
  email_expected: string;
};

export type TAccountEmailConfirmRequest = {
  hashcode: string;
};

export function adaptAccountEmailFromResponse(data: TAccountEmailResponse): TAccountEmail {
	const {
		email: email,
		email_expected: emailExpected,
		time_expected: timeExpired,
	} = data;

	return {
		email: email || '',
		emailExpected: emailExpected || '',
		timeExpired: timeExpired || 0,
	};
}

export function adaptAccountEmailToRequest(data: TAccountEmailUpdate): TAccountEmailRequest {
	const { emailExpected } = data;

	return {
		email_expected: emailExpected,
	};
}
