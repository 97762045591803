import { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react';

import { FormImagePreloader } from '../preloader';

import styles from './FormImageSvgDisplay.module.scss';


type TProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export function FormImageSvgDisplay(props: TProps) {
	const [isLoading, setIsLoading] = useState(true);

	const onLoad = () => {
		setIsLoading(false);
	};

	const classNames = [styles.image];

	if (!isLoading) {
		classNames.push(styles.loaded);
	}

	return (
		<>
			{ isLoading && <FormImagePreloader  /> }

			<img className={ classNames.join(' ') } { ...props } onLoad={ onLoad } />
		</>
	);
}
