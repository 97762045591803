import { FormImageSvg } from 'src/components';
import { PATH_DEFAULT_CV } from 'src/routes';
import { TInputFileField, useLocalization } from 'src/toolkit';

import { ACCOUNT_CVS_IMAGE_FIELD_LOCALIZATION } from './localization';


type TProps = TInputFileField & {
	setFile: (value: File) => void;
	isLoading?: boolean;
};

export function AccountCvImageField(props: TProps) {
	const {
		imageLegend,
		selectImageText,
		changeImageText,
	} = useLocalization(ACCOUNT_CVS_IMAGE_FIELD_LOCALIZATION);

	const { isLoading, ...restProps } = props;

	return (
		<FormImageSvg
			legend={ imageLegend }
			{ ...restProps }
			selectImageText={selectImageText}
			changeImageText={changeImageText}
			defaultSrc={ PATH_DEFAULT_CV }
			accept="image/jpeg, image/png"
		/>
	);
}
