// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StacksListRow_image__WIkEH {\n  max-height: 2em;\n  max-width: 4em;\n}\n\n.StacksListRow_imageCol__\\+-83I {\n  max-width: 5em;\n  min-width: 5em;\n  width: 5em;\n}\n\n.StacksListRow_dateCol__ULXbY {\n  max-width: 11em;\n  min-width: 11em;\n  width: 11em;\n}", "",{"version":3,"sources":["webpack://./src/pages/stacks/views/list/components/table/components/row/StacksListRow.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,cAAA;AACD;;AAEA;EACC,cAAA;EACA,cAAA;EACA,UAAA;AACD;;AAEA;EACC,eAAA;EACA,eAAA;EACA,WAAA;AACD","sourcesContent":[".image {\n\tmax-height: 2em;\n\tmax-width: 4em;\n}\n\n.imageCol {\n\tmax-width: 5em;\n\tmin-width: 5em;\n\twidth: 5em;\n}\n\n.dateCol {\n\tmax-width: 11em;\n\tmin-width: 11em;\n\twidth: 11em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "StacksListRow_image__WIkEH",
	"imageCol": "StacksListRow_imageCol__+-83I",
	"dateCol": "StacksListRow_dateCol__ULXbY"
};
export default ___CSS_LOADER_EXPORT___;
