import { useState } from 'react';

import { FormField, FormInput } from 'src/components';
import { LinkDelete } from 'src/pages/components';
import { TAccountEducation } from 'src/store';
import { libFormatDate, useLocalization } from 'src/toolkit';

import { AccountEducationModalDelete } from './components';
import { ACCOUNT_EDUCATION_DETAILS_LOCALIZATION } from './localization';

import styles from 'src/components/form/form-box/FormBox.module.scss';


export function AccountEducationDetails(props: TAccountEducation) {
	const {
		createdAt = '',
		updatedAt = '',
	} = props;

	const [modal, setModal] = useState(false);
	const {
		createdAtLegend,
		createdAtTitle,
		updatedAtLegend,
		updatedAtTitle,
		removeLinkText,
	} = useLocalization(ACCOUNT_EDUCATION_DETAILS_LOCALIZATION);

	const openModal = () => {
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
	};

	return (
		<div className={ styles.formBox }>
			<FormField
				legend={ createdAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ createdAtTitle }
					value={ libFormatDate(createdAt) }
					readOnly
				/>
			</FormField>

			<FormField
				legend={ updatedAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ updatedAtTitle }
					value={ libFormatDate(updatedAt) }
					readOnly
				/>
			</FormField>

			<LinkDelete onClick={ openModal }>{ removeLinkText }</LinkDelete>

			{ modal && <AccountEducationModalDelete data={ props } onClose={ closeModal } /> }
		</div>
	);
}
