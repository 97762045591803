import { LocalizationTypes } from 'src/common/types';

import { RECOVERY_FORM_MAIN_RU_LOCALIZATION } from './ru.localization';
import { RECOVERY_FORM_MAIN_EN_LOCALIZATION } from './en.localization';


export const RECOVERY_FORM_MAIN_LOCALIZATION = {
	[LocalizationTypes.RU]: RECOVERY_FORM_MAIN_RU_LOCALIZATION,
	[LocalizationTypes.EN]: RECOVERY_FORM_MAIN_EN_LOCALIZATION,
};
