import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './FormSearchListItem.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function FormSearchListItem(props: TProps) {
	const {
		children,
		...restFields
	} = props;

	return (
		<div className={ styles.item } { ...restFields }>
			{ children }
		</div>
	);
}
