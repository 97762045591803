export const USERS_EMAIL_DETAILS_RU_LOCALIZATION = {
	createdAtLegend: 'Дата создания',
	createdAtTitle: 'Дата создания E-Mail пользователя',

	updatedAtLegend: 'Дата обновления',
	updatedAtTitle: 'Дата обновления E-Mail пользователя',

	unconfirmedLegend: 'Не подтверждённый E-Mail',
	unconfirmedTitle: 'E-Mail пользователя не подтверждён',

	removeLinkText: 'Удалить E-Mail',
};
