// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvSexField_box__qupzm {\n  margin-left: 0.1em;\n  max-width: 100%;\n  width: 100%;\n  position: relative;\n  z-index: 10;\n}\n\n.AccountCvSexField_selectBox__2MezW {\n  min-width: 9em;\n  width: 100%;\n  max-width: 100%;\n}\n\n.AccountCvSexField_icon__Ju9X4 {\n  border: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/sex-field/AccountCvSexField.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AACD;;AAEA;EACC,cAAA;EACA,WAAA;EACA,eAAA;AACD;;AAEA;EACC,YAAA;AACD","sourcesContent":[".box {\n\tmargin-left: 0.1em;\n\tmax-width: 100%;\n\twidth: 100%;\n\tposition: relative;\n\tz-index: 10;\n}\n\n.selectBox {\n\tmin-width: 9em;\n\twidth: 100%;\n\tmax-width: 100%;\n}\n\n.icon {\n\tborder: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvSexField_box__qupzm",
	"selectBox": "AccountCvSexField_selectBox__2MezW",
	"icon": "AccountCvSexField_icon__Ju9X4"
};
export default ___CSS_LOADER_EXPORT___;
