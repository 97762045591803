import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline, Preloader } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { sourceEducationTypesGetApi, clearAccountEducationCreate, useAccountSourceEducationTypesSelector, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_COMMON_LOCALIZATION } from '../../localization';

import { AccountEducationFormCreate } from './components';
import { ACCOUT_EDUCATION_CREATE_LOCALIZATION } from './localization';


export function AccountEducationCreateWidget() {
	const dispatch = useAppDispatch();
	const { lang } = useCredentialsSelector();
	const { list, isLoading } = useAccountSourceEducationTypesSelector();
	const { pathname, hash } = useLocation();
	const { title, headline, breadCrumbNew } = useLocalization(ACCOUT_EDUCATION_CREATE_LOCALIZATION);
	const { breadCrumbList } = useLocalization(ACCOUNT_EDUCATION_COMMON_LOCALIZATION);

	useEffect(() => {
		if (!list) {
			dispatch(sourceEducationTypesGetApi(lang));
		}

		return () => {
			dispatch(clearAccountEducationCreate());
		};
	}, []);

	if (isLoading || !list) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	setTitle(title);

	const initialItem = {
		pid: '',
		locale: lang,
		city: '',
		type: '',
		shortName: '',
		educationalInstitution: '',
		faculty: '',
		specialization: '',
		dateEnd: '',
		locales: [],
		createdAt: '',
		updatedAt: '',
	};

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EDUCATION, text: breadCrumbList },
		{ to: `${pathname}/${hash}`, text: breadCrumbNew },
	];

	const { items } = list;

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountEducationFormCreate { ...initialItem } items={ items } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
