// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecoveryPage_underText__zZWFc {\n  font-size: 0.875em;\n  margin: 1em 0;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/recovery/views/main/RecoveryPage.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,kBAAA;AACD","sourcesContent":[".underText {\n\tfont-size: 0.875em;\n\tmargin: 1em 0;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underText": "RecoveryPage_underText__zZWFc"
};
export default ___CSS_LOADER_EXPORT___;
