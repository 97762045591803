import { NavLink } from 'react-router-dom';
import { NavIcon } from '../nav-icon';

import styles from './NavItem.module.scss';


export type TNavItem = {
	href?: string;
	text?: string;
	icon?: string;
};

export function NavItem(props: TNavItem) {
	const {
		icon = 'icon-home',
		href = '#',
		text = 'Default',
	} = props;

	const classNames = [styles.link];

	return (
		<NavLink
			to={ href }
			className={
				(options) => {
					if (options.isActive) {
						classNames.push(styles.active);
					}

					return classNames.join(' ');
				}
			}
		>
			{ icon && <NavIcon icon={ icon } className={ styles.icon } /> }

			<span>{ text }</span>
		</NavLink>
	);
}
