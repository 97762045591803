export const USERS_TABLE_EN_LOCALIZATION = {
	image: 'Image',
	nickname: 'Nickname',
	phoneNumber: 'Phone number',
	email: 'E-Mail',
	createdAt: 'Created at',
	updatedAt: 'Updated at',

	searchPlaceholder: 'Search in database',
	searchTitle: 'Type text for searching in database',

	emptyText: 'Users not found',
};
