import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { BreadCrumbs, PageCol, PageHeadline, Preloader } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { AccountLayout } from 'src/layouts';
import { PATH_USERS } from 'src/routes';
import { clearUserPasswordRead, useAppDispatch, useUserPasswordReadSelector, userPasswordReadGetApi } from 'src/store';
import { isInvalidNickname, useLocalization } from 'src/toolkit';
import { ErrorWidget } from 'src/pages/components';

import { USERS_COMMON_LOCALIZATION } from '../../localization';

import { UserPasswordForm } from './components';
import { USERS_PASSWORD_LOCALIZATION } from './localization';


export function UserPasswordPage() {
	const dispatch = useAppDispatch();
	const { nickname: nicknameParam = '' } = useParams();
	const { data: item, isLoading, error } = useUserPasswordReadSelector();
	const { breadCrumbList } = useLocalization(USERS_COMMON_LOCALIZATION);
	const {
		title,
		headline,
		breadCrumbPassword,
	} = useLocalization(USERS_PASSWORD_LOCALIZATION);

	const isInvalidNick = isInvalidNickname(nicknameParam);

	useEffect(() => {
		if (!isInvalidNick) {
			dispatch(userPasswordReadGetApi(nicknameParam));
		}

		return () => {
			dispatch(clearUserPasswordRead());
		};
	}, []);

	if (isInvalidNick) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const { nickname } = item;

	const items = [
		{ to: PATH_USERS, text: breadCrumbList },
		{ to: `${PATH_USERS}/${nickname}`, text: nickname },
		{ to: `${PATH_USERS}/${nickname}/password`, text: breadCrumbPassword },
	];

	setTitle(title);

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ items } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<UserPasswordForm item={ item } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
