// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvUpdatedAt_box__BhDvF {\n  font-size: 0.875em;\n  margin: 0 0 0.5em 0;\n  text-align: center;\n}\n\n.PublicCvUpdatedAt_shortTime__euKFk {\n  color: rgb(75, 178, 78);\n}\n\n.PublicCvUpdatedAt_middleTime__xGmto {\n  color: rgb(255, 170, 51);\n}\n\n.PublicCvUpdatedAt_longTime__hlWnr {\n  color: rgb(120, 120, 120);\n}\n\n.PublicCvUpdatedAt_defaultTime__CRR9P {\n  color: rgb(0, 0, 0);\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/updated-at/PublicCvUpdatedAt.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,mBAAA;EACA,kBAAA;AACD;;AAEA;EACC,uBAAA;AACD;;AAEA;EACC,wBAAA;AACD;;AAEA;EACC,yBAAA;AACD;;AAEA;EACC,mBAAA;AACD","sourcesContent":[".box {\n\tfont-size: 0.875em;\n\tmargin: 0 0 0.5em 0;\n\ttext-align: center;\n}\n\n.shortTime {\n\tcolor: rgba(75, 178, 78, 1);\n}\n\n.middleTime {\n\tcolor: rgba(255, 170, 51, 1);\n}\n\n.longTime {\n\tcolor: rgba(120, 120, 120, 1);\n}\n\n.defaultTime {\n\tcolor: rgba(0, 0, 0, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvUpdatedAt_box__BhDvF",
	"shortTime": "PublicCvUpdatedAt_shortTime__euKFk",
	"middleTime": "PublicCvUpdatedAt_middleTime__xGmto",
	"longTime": "PublicCvUpdatedAt_longTime__hlWnr",
	"defaultTime": "PublicCvUpdatedAt_defaultTime__CRR9P"
};
export default ___CSS_LOADER_EXPORT___;
