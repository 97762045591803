import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_DEFAULT_CV, PATH_STATIC_CV_IMAGES } from 'src/routes';
import { TAccountEducation, TAccountEducationResponse, adaptAccountEducationFromResponse } from './account-education.type';
import { TAccountExperience, TAccountExperienceResponse, adaptAccountExperienceFromResponse } from './account-experience.type';
import { TUserSex } from './account-source-user-sex.type';
import { TUserCvStatus } from './account-source-user-cv-status.type';
import { libFormatDate } from 'src/toolkit';

export const AccountCvSortCol = {
	ALIAS: 'alias',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountCvSortCol = TTableSortCols<typeof AccountCvSortCol>;

export type TAccountCvQuery = {
	locale: string;
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountCvSortCol;
};

export type TAccountCvQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountCvFile = {
	name: string;
	path: string;
};

export type TAccountCvLanguage = {
	language: string;
	level: string;
};

export type TAccountCvLanguageResponse = {
	language: string;
	level: string;
	language_alias: string;
	level_alias: string;
};

export type TAccountCv = TAccountCvLocale & {
	pid: string;
	alias: string;
	birthday: string;
	sex: TUserSex;
	status: TUserCvStatus;
	image: string;
	phoneNumber: string;
	email: string;
	telegram: string;
	linkedin: string;
	headhunter: string;

	countExperience: number[];
	experience: TAccountExperience[];
	education: TAccountEducation[];
	languages: TAccountCvLanguage[];

	locales: string[];
};

export type TAccountCvLocale = {
  locale: string;

  title: string;
  firstName: string;
  lastName: string;
  city: string;
  description: string;
  preferredSalary: string;
  currency: string;

  createdAt: string;
  updatedAt: string;
};

export type TAccountCvLocaleBodyRequest = Omit<TAccountCvLocale, 'createdAt' | 'updatedAt'>;

export type TAccountCvLocaleRequest = Omit<TAccountCvLocale, 'firstName' | 'lastName' | 'preferredSalary' | 'createdAt' | 'updatedAt'> & {
	first_name: string;
	last_name: string;
	preferred_salary: number;
};

export type TAccountCvLocaleCreate = Omit<TAccountCvLocaleUpdate, 'locale'>;

export type TAccountCvFileRequest = Array<File | string>;

export type TAccountCvBodyRequest = Pick<TAccountCv, 'alias' | 'birthday' | 'phoneNumber' | 'email' | 'telegram' | 'linkedin' | 'headhunter'> & {
	image?: File | string;
	sex: string;
	status: string;
};

export type TAccountCvResponse = {
	pid: string;
	alias: string;
	birthday: string;
	sex: TUserSex;
	status: TUserCvStatus;
	image: string;
	phone_number: string;
	email: string;
	telegram: string;
	linkedin: string;
	headhunter: string;

	count_experience: number[];
	experience: TAccountExperienceResponse[];
	educations: TAccountEducationResponse[];
	languages: TAccountCvLanguageResponse[];
	locales: string[];

  title: string;
  first_name: string;
  last_name: string;
  city: string;
  description: string;
  preferred_salary: number;
  currency: string;

	locale: string;
	created_at: string;
	updated_at: string;
};

export type TAccountCvLocaleParam = {
  alias: string;
  locale: string;
};

export type TAccountCvRequest = Pick<TAccountCvResponse, 'alias' | 'birthday' | 'phone_number' | 'email' | 'telegram' | 'linkedin' | 'headhunter'> & {
	image?: File | string;
	sex: string;
	status: string;
};

export type TAccountCvListResponse = TListResponse<TAccountCvResponse>;

export type TAccountCvList = TListResponse<TAccountCv>;

export type TAccountCvPayloadCreate = {
	data: TAccountCvBodyRequest;
	experience?: string[];
	education?: string[];
	languages?: TAccountCvLanguage[];
};

export type TAccountCvPayloadUpdate = TAccountCvPayloadCreate & {
	alias: string;
};

export type TAccountCvLocaleUpdate = TAccountCvLocaleParam & {
	data: TAccountCvLocaleBodyRequest;
};

export function adaptAccountCvFromListResponse(data: TAccountCvListResponse): TAccountCvList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountCvFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountCvFromResponse(data: TAccountCvResponse): TAccountCv {
	const {
		locale,
		pid,
		alias,
		title,
		description,
		image: imageRaw = '',
		phone_number: phoneNumber = '',
		email = '',
		telegram = '',
		linkedin = '',
		headhunter = '',
		locales = [],
		created_at: createdAt,
		updated_at: updatedAt,

		sex,
		birthday,
		status,

		count_experience: countExperience,
		experience: experienceRaw = [],
		educations: educationRaw = [],
		languages: languagesRaw = [],

		first_name: firstName,
		last_name: lastName,
		city,
		preferred_salary: preferredSalaryRaw,
		currency,
	} = data;

	const preferredSalary = preferredSalaryRaw ? preferredSalaryRaw.toString() : '';

	const image = imageRaw ? `${PATH_STATIC_CV_IMAGES}/${imageRaw}` : PATH_DEFAULT_CV;
	const experience = experienceRaw.map(item => adaptAccountExperienceFromResponse(item));
	const education = educationRaw.map(item => adaptAccountEducationFromResponse(item));
	const languages = languagesRaw.map(item => adaptAccountLanguageFromResponse(item));

	return {
		locale,
		pid,
		alias,
		title,
		description,
		image,
		phoneNumber,
		email,
		telegram,
		linkedin,
		headhunter,
		locales,
		createdAt,
		updatedAt,
		experience,
		education,
		languages,
		countExperience,
		firstName,
		lastName,
		city,
		sex,
		birthday: libFormatDate(birthday, 'yyyy-MM-dd'),
		preferredSalary,
		currency,
		status,
	};
}

export function adaptAccountLanguageFromResponse(data: TAccountCvLanguageResponse): TAccountCvLanguage {
	const {
		language_alias: languageAlias,
		level_alias: levelAlias,
	} = data;

	return {
		language: languageAlias,
		level: levelAlias,
	};
}

export function adaptAccountCvToRequest(data: TAccountCvBodyRequest): TAccountCvRequest {
	const {
		alias,
		image,
		phoneNumber,
		email,
		telegram,
		linkedin,
		headhunter,
		sex,
		birthday,
		status,
	} = data;

	return {
		alias,
		image,
		phone_number: phoneNumber,
		email,
		telegram,
		linkedin,
		headhunter,
		sex,
		birthday,
		status,
	};
}

export function adaptAccountCvLocaleToRequest(data: TAccountCvLocaleBodyRequest): TAccountCvLocaleRequest {
	const {
		locale,
		firstName,
		lastName,
		city,
		preferredSalary,
		currency,
		title,
		description,
	} = data;

	return {
		locale,
		first_name: firstName,
		last_name: lastName,
		city,
		preferred_salary: parseInt(preferredSalary),
		currency,
		title,
		description,
	};
}
