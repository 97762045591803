// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageHeadline_pageHeadline__kPVC4 {\n  color: rgb(90, 90, 90);\n  font-size: 1.5em;\n  font-weight: bold;\n  margin: 0.5em auto;\n}\n\n.PageHeadline_margin__KsHJA {\n  margin: 0.5em auto 1.5em;\n}", "",{"version":3,"sources":["webpack://./src/components/page/page-headline/PageHeadline.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACD;;AAEA;EACC,wBAAA;AACD","sourcesContent":[".pageHeadline {\n\tcolor: rgba(90, 90, 90, 1);\n\tfont-size: 1.5em;\n\tfont-weight: bold;\n\tmargin: 0.5em auto;\n}\n\n.margin {\n\tmargin: 0.5em auto 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeadline": "PageHeadline_pageHeadline__kPVC4",
	"margin": "PageHeadline_margin__KsHJA"
};
export default ___CSS_LOADER_EXPORT___;
