import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { LocalizationTypes } from 'src/common/types';

import { BACKEND_ACCOUNT_SOURCE_EDUCATION_TYPES } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TEducationTypesList, TEducationTypesListResponse, TEducationTypesQuery, TEducationTypesSortCol, adaptEducationTypesFromListResponse } from '../../types';


export const sourceEducationTypesGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SOURCE_EDUCATION_TYPES}`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SOURCE_EDUCATION_TYPES}/${payload}`;

		return HttpService.get<TEducationTypesListResponse>(path).then(result => {
			return adaptEducationTypesFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list?: TEducationTypesList,
	query: TEducationTypesQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: undefined,
	query: {
		locale: LocalizationTypes.EN,
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: undefined,
	},
	error: undefined,
};

export const accountSourceEducationTypesSlice = createSlice({
	name: 'accountSourceEducationTypesSlice',
	initialState,
	reducers: {
		setAccountSourceEducationTypeQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountSourceEducationTypeQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountSourceEducationTypeQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountSourceEducationTypeQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountSourceEducationTypeQuerySortCol(state, action: PayloadAction<TEducationTypesSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountSourceEducationTypes(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceEducationTypesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceEducationTypesGetApi.fulfilled.type]: (state, action: PayloadAction<TEducationTypesList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceEducationTypesGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountSourceEducationTypesSelector = () => useAppSelector(state => state[accountSourceEducationTypesSlice.name]);

export const { clearAccountSourceEducationTypes, setAccountSourceEducationTypeQueryLimit, setAccountSourceEducationTypeQueryOffset, setAccountSourceEducationTypeQuerySort, setAccountSourceEducationTypeQuerySortCol, setAccountSourceEducationTypeQuerySearch } = accountSourceEducationTypesSlice.actions;
