import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TStack, adaptStackFromResponse } from 'src/store/types';

import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';


export const stackDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_STACKS}/:alias`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_STACKS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptStackFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item?: TStack,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const stackDeleteSlice = createSlice({
	name: 'stackDeleteSlice',
	initialState,
	reducers: {
		clearStackDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[stackDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[stackDeleteApi.fulfilled.type]: (state, action: PayloadAction<TStack>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[stackDeleteApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useStackDeleteSelector = () => useAppSelector(state => state[stackDeleteSlice.name]);

export const { clearStackDelete } = stackDeleteSlice.actions;
