// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountExperienceProjectsBox_box__LbynZ {\n  width: 100%;\n  padding: 0 0.5em;\n}\n\n.AccountExperienceProjectsBox_emptyBox__Z4j-7 {\n  color: rgb(180, 180, 180);\n  display: block;\n  font-size: 0.875em;\n  line-height: 2em;\n  text-align: center;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-experience/components/projects-field/components/projects-box/AccountExperienceProjectsBox.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,gBAAA;AACD;;AAEA;EACC,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACD","sourcesContent":[".box {\n\twidth: 100%;\n\tpadding: 0 0.5em;\n}\n\n.emptyBox {\n\tcolor: rgba(180, 180, 180, 1);\n\tdisplay: block;\n\tfont-size: 0.875em;\n\tline-height: 2em;\n\ttext-align: center;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountExperienceProjectsBox_box__LbynZ",
	"emptyBox": "AccountExperienceProjectsBox_emptyBox__Z4j-7"
};
export default ___CSS_LOADER_EXPORT___;
