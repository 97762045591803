// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_form__Owudy {\n  box-sizing: border-box;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form/Form.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EAEA,WAAA;AAAD","sourcesContent":[".form {\n\tbox-sizing: border-box;\n\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Form_form__Owudy"
};
export default ___CSS_LOADER_EXPORT___;
