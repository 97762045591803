import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TPublicEducation } from 'src/store';
import { CvHeadline } from 'src/components';
import { useLocalization } from 'src/toolkit';

import { PublicCvEducationItem } from './components';
import { PUBLIC_CV_EDUCATION_LOCALIZATION } from './localization';

import styles from './PublicCvEducation.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	className?: string;
	classNames?: string[];
	items: TPublicEducation[];
};

export function PublicCvEducation(props: TProps) {
	const { headline } = useLocalization(PUBLIC_CV_EDUCATION_LOCALIZATION);
	const {
		className = '',
		classNames: classNamesOuter = [],
		items,
		...restProps
	} = props;

	if (items.length === 0) {
		return (
			<></>
		);
	}

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			<CvHeadline className={ styles.headline }>{ headline }</CvHeadline>

			<div className={ styles.items }>
				{ items.map(item => <PublicCvEducationItem key={ `${item.type}-${item.dateEnd}` } item={ item } />) }
			</div>
		</div>
	);
}
