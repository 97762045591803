// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvLinkedIn_box__aXZBA {\n  margin-top: 0.5em;\n}\n\n.PublicCvLinkedIn_text__lDzsW {\n  color: rgb(130, 130, 130);\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/contacts/components/linkedin/PublicCvLinkedIn.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AAEA;EACC,yBAAA;AACD","sourcesContent":[".box {\n\tmargin-top: 0.5em;\n}\n\n.text {\n\tcolor: rgba(130, 130, 130, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvLinkedIn_box__aXZBA",
	"text": "PublicCvLinkedIn_text__lDzsW"
};
export default ___CSS_LOADER_EXPORT___;
