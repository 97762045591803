// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvLastWorkCompanyName_name__VLZDS {\n  color: rgb(90, 90, 90);\n  font-weight: bold;\n}\n\n.PublicCvLastWorkCompanyName_link__BAGlY {\n  border-bottom: 1px solid rgb(120, 120, 120);\n  color: rgb(120, 120, 120);\n  cursor: pointer;\n  display: inline;\n  line-height: 1.2em;\n  height: 1.2em;\n  text-decoration: none;\n  white-space: normal;\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n}\n.PublicCvLastWorkCompanyName_link__BAGlY:active, .PublicCvLastWorkCompanyName_link__BAGlY:hover {\n  border-bottom: 1px solid transparent;\n  color: rgb(90, 90, 90);\n}\n.PublicCvLastWorkCompanyName_link__BAGlY:active {\n  transition: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/last-work/components/company-name/PublicCvLastWorkCompanyName.module.scss"],"names":[],"mappings":"AAGA;EACC,sBAHY;EAIZ,iBAAA;AAFD;;AAKA;EACC,2CAAA;EACA,yBAVO;EAWP,eAAA;EACA,eAAA;EAEA,kBAAA;EACA,aAAA;EACA,qBAAA;EAEA,mBAAA;EAEC,2DAAA;AALF;AAOC;EAEC,oCAAA;EACA,sBAxBW;AAkBb;AASC;EACC,gBAAA;AAPF","sourcesContent":["$color: rgba(120, 120, 120, 1);\n$colorHover: rgba(90, 90, 90, 1);\n\n.name {\n\tcolor: $colorHover;\n\tfont-weight: bold;\n}\n\n.link {\n\tborder-bottom: 1px solid $color;\n\tcolor: $color;\n\tcursor: pointer;\n\tdisplay: inline;\n\n\tline-height: 1.2em;\n\theight: 1.2em;\n\ttext-decoration: none;\n\n\twhite-space: normal;\n\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n\n\t&:active,\n\t&:hover {\n\t\tborder-bottom: 1px solid transparent;\n\t\tcolor: $colorHover;\n\t}\n\n\t&:active {\n\t\ttransition: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "PublicCvLastWorkCompanyName_name__VLZDS",
	"link": "PublicCvLastWorkCompanyName_link__BAGlY"
};
export default ___CSS_LOADER_EXPORT___;
