	export const PATH_HOME = '/';

	export const PATH_PUBLIC_IMAGES = '/images';
	export const PATH_DEFAULT_STACK = `${PATH_PUBLIC_IMAGES}/stack.svg`;
	export const PATH_DEFAULT_PROJECT = `${PATH_PUBLIC_IMAGES}/projects.svg`;
	export const PATH_DEFAULT_EXPERIENCE = `${PATH_PUBLIC_IMAGES}/experience.svg`;
	export const PATH_DEFAULT_CV = `${PATH_PUBLIC_IMAGES}/experience.svg`;

	export const PATH_PUBLIC_CV = '/cv';

	export const PATH_SIGN_IN = '/login';
	export const PATH_SIGN_UP = '/sign-up';
	export const PATH_RECOVERY = '/account/recovery';

	export const PATH_ACCOUNT = '/account';
	export const PATH_ACCOUNT_PHONE_NUMBER = '/account/phone-number';
	export const PATH_ACCOUNT_EMAIL = '/account/email';

	export const PATH_ACCOUNT_CVS = `${PATH_ACCOUNT}/cv`;
	export const PATH_ACCOUNT_CVS_CREATE_END = `#create`;
	export const PATH_ACCOUNT_CVS_CREATE = `${PATH_ACCOUNT_CVS}${PATH_ACCOUNT_CVS_CREATE_END}`;

	export const PATH_ACCOUNT_EDUCATION = `${PATH_ACCOUNT}/education`;
	export const PATH_ACCOUNT_EDUCATION_CREATE_END = `#create`;
	export const PATH_ACCOUNT_EDUCATION_CREATE = `${PATH_ACCOUNT_EDUCATION}${PATH_ACCOUNT_EDUCATION_CREATE_END}`;

	export const PATH_ACCOUNT_PROJECTS = `${PATH_ACCOUNT}/projects`;
	export const PATH_ACCOUNT_PROJECTS_CREATE_END = `#create`;
	export const PATH_ACCOUNT_PROJECTS_CREATE = `${PATH_ACCOUNT_PROJECTS}${PATH_ACCOUNT_PROJECTS_CREATE_END}`;

	export const PATH_ACCOUNT_EXPERIENCE = `${PATH_ACCOUNT}/experience`;
	export const PATH_ACCOUNT_EXPERIENCE_CREATE_END = `#create`;
	export const PATH_ACCOUNT_EXPERIENCE_CREATE = `${PATH_ACCOUNT_EXPERIENCE}${PATH_ACCOUNT_EXPERIENCE_CREATE_END}`;

	export const PATH_ACCOUNT_CV = `${PATH_ACCOUNT}/cv`;
	export const PATH_ACCOUNT_PASSPORT = `${PATH_ACCOUNT}/passport`;
	export const PATH_ACCOUNT_PASSWORD = `${PATH_ACCOUNT}/password`;

	export const PATH_USERS = '/users';
	export const PATH_USERS_CREATE_END = '#create';
	export const PATH_USERS_CREATE = `${PATH_USERS}${PATH_USERS_CREATE_END}`;

	export const PATH_STACK = '/stacks';
	export const PATH_STACK_CREATE_END = '#create';
	export const PATH_STACK_CREATE = `${PATH_STACK}${PATH_STACK_CREATE_END}`;

	export const PATH_ERROR = '*';

	export const PATH_STATIC = `${process.env.REACT_APP_BACKEND_URL}/static`;
	export const PATH_STATIC_USER_IMAGES = `${PATH_STATIC}/users-images`;
	export const PATH_STATIC_STACK_IMAGES = `${PATH_STATIC}/stacks-images`;
	export const PATH_STATIC_PROJECTS_FILES = `${PATH_STATIC}/projects-files`;
	export const PATH_STATIC_PROJECTS_IMAGES = `${PATH_STATIC}/projects-images`;
	export const PATH_STATIC_EXPERIENCE_IMAGES = `${PATH_STATIC}/experiences-images`;
	export const PATH_STATIC_CV_IMAGES = `${PATH_STATIC}/cv-images`;
