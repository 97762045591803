import { useEffect, useState } from 'react';

import { FormBox, FormButton, FormButtonWrap, FormNotice } from 'src/components';
import { TAccount, accountUpdateApi, setCredentialsName, useAccountUpdateSelector, useAppDispatch, useCredentialsSelector } from 'src/store';
import { formHandleServerError, formValidateNickname, useInputFormField, useLocalization } from 'src/toolkit';

import { AccountNicknameField } from '../../../../components';

import { ACCOUNT_FORM_LOCALIZATION } from './localization';

import styles from './AccountForm.module.scss';


export function AccountForm(props: TAccount) {
	const dispatch = useAppDispatch();
	const { button, noticeSuccess } = useLocalization(ACCOUNT_FORM_LOCALIZATION);

	const { credentials } = useCredentialsSelector();
	const { item, isLoading, error } = useAccountUpdateSelector();
	const [notice, setNotice] = useState('');

	const { nickname } = props;

	const nicknameField = useInputFormField({
		name: 'nickname',
		defaultValue: nickname,
		validation: value => formValidateNickname(value),
	});

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				nicknameField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);

			const { nickname } = item;

			if (credentials && credentials.name !== nickname) {
				dispatch(setCredentialsName(nickname));
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			nicknameField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			nickname: nicknameField.value,
		};

		dispatch(accountUpdateApi(dataRequest));
	};

	return (
		<FormBox className={ styles.form } onSubmit={ onSubmit }>
			<AccountNicknameField { ...nicknameField } isLoading={ isLoading } />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
