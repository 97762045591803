import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TStack, TStackLocaleParam, adaptStackLocaleFromResponse } from 'src/store/types';

import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';


export const stackLocaleDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ADMIN_STACKS}/:alias/:locale`,
	(payload: TStackLocaleParam, thunkApi) => {
		const { alias, locale } = payload;
		const path = `${BACKEND_ADMIN_STACKS}/${alias}/${locale}`;

		return HttpService.delete(path).then(result => {
			return adaptStackLocaleFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item?: TStack,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const stackLocaleDeleteSlice = createSlice({
	name: 'stackLocaleDeleteSlice',
	initialState,
	reducers: {
		clearStackLocaleDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[stackLocaleDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[stackLocaleDeleteApi.fulfilled.type]: (state, action: PayloadAction<TStack>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[stackLocaleDeleteApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useStackLocaleDeleteSelector = () => useAppSelector(state => state[stackLocaleDeleteSlice.name]);

export const { clearStackLocaleDelete } = stackLocaleDeleteSlice.actions;
