import { MouseEventHandler } from 'react';
import { AsideItem, TAsideItem } from '../aside-item/AsideItem';

import styles from './AsideItemList.module.scss';


export type TAsideItemList = {
	items: TAsideItem[];
	onClick: MouseEventHandler<HTMLElement>;
};

export function AsideItemList(props: TAsideItemList) {
	const { items, onClick } = props;

	return (
		<ul className={ styles.list }>
			{
				items.map(item => <AsideItem { ...item } key={ item.href } onClick={ onClick } />)
			}
		</ul>
	);
}
