// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvContacts_box__Ext0u {\n  margin: 0 0.5em;\n}\n\n.PublicCvContacts_contactBox__KuRtm {\n  margin-top: 0.25em;\n}\n.PublicCvContacts_contactBox__KuRtm > div:first-child {\n  margin-top: 0;\n}\n\n.PublicCvContacts_contact__1lPLF {\n  margin-left: 0.5em;\n}\n\n.PublicCvContacts_linkedin__wLgJM {\n  margin: 0.5em 0 0 0;\n}\n\n.PublicCvContacts_headhunter__b\\+GCD {\n  margin: 0 0 0.5em 0;\n}\n\n.PublicCvContacts_linkedin__wLgJM,\n.PublicCvContacts_headhunter__b\\+GCD {\n  font-size: 0.875em;\n}\n\n@media screen and (max-width: 480px) {\n  .PublicCvContacts_box__Ext0u {\n    margin: 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/contacts/PublicCvContacts.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;AACD;;AAEA;EACC,kBAAA;AACD;AACC;EACC,aAAA;AACF;;AAGA;EACC,kBAAA;AAAD;;AAGA;EACC,mBAAA;AAAD;;AAGA;EACC,mBAAA;AAAD;;AAGA;;EAEC,kBAAA;AAAD;;AAIA;EACC;IACC,SAAA;EADA;AACF","sourcesContent":[".box {\n\tmargin: 0 0.5em;\n}\n\n.contactBox {\n\tmargin-top: 0.25em;\n\n\t& > div:first-child {\n\t\tmargin-top: 0;\n\t}\n}\n\n.contact {\n\tmargin-left: 0.5em;\n}\n\n.linkedin {\n\tmargin: 0.5em 0 0 0;\n}\n\n.headhunter {\n\tmargin: 0 0 0.5em 0;\n}\n\n.linkedin,\n.headhunter {\n\tfont-size: 0.875em;\n}\n\n\n@media screen and (max-width: 480px) {\n\t.box {\n\t\tmargin: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvContacts_box__Ext0u",
	"contactBox": "PublicCvContacts_contactBox__KuRtm",
	"contact": "PublicCvContacts_contact__1lPLF",
	"linkedin": "PublicCvContacts_linkedin__wLgJM",
	"headhunter": "PublicCvContacts_headhunter__b+GCD"
};
export default ___CSS_LOADER_EXPORT___;
