import { useEffect } from 'react';

import { TableBox, TableRowEmpty, Icon } from 'src/components';
import { AccountCvSortCol, TAccountCvSortCol, setAccountCvsQueryLimit, setAccountCvsQueryOffset, setAccountCvsQuerySearch, setAccountCvsQuerySort, setAccountCvsQuerySortCol, accountCvsGetApi, useAppDispatch, useAccountCvsSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { useDebounce, useIsMobile, useLocalization } from 'src/toolkit/hooks';
import { TTableSort } from 'src/components/table/thead/col';

import { AccountCvsListRow } from './components';
import { ACCOUNT_CVS_TABLE_LOCALIZATION } from './localization';

import styles from './AccountCvsList.module.scss';
import { PATH_ACCOUNT_CVS } from 'src/routes';


export function AccountCvsList() {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();

	const { query, list, isLoading, error } = useAccountCvsSelector();
	const { limit, offset, search, sort, sortCol } = query;
	const { items, count = 0 } = list;

	const searchDebounced = useDebounce(search || '');
	const {
		image,
		alias,
		locales,
		createdAt,
		updatedAt,
		emptyText,
		searchPlaceholder,
		searchTitle,
	} = useLocalization(ACCOUNT_CVS_TABLE_LOCALIZATION);

	useEffect(() => {
		dispatch(accountCvsGetApi(query));
	}, [limit, offset, sort, sortCol, searchDebounced]);

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const theadSettings = {
		sort,
		setSort: (value: TTableSort) => dispatch(setAccountCvsQuerySort(value)),
		sortCol,
		setSortCol: (value: TAccountCvSortCol) => dispatch(setAccountCvsQuerySortCol(value)),
		items: [
			{ name: image },
			{ name: alias, colName: AccountCvSortCol.ALIAS },
			{ name: locales },
			{ name: createdAt, colName: AccountCvSortCol.CREATED_AT },
			{ name: updatedAt, colName: AccountCvSortCol.UPDATED_AT },
		],
	};

	const controlSettings = {
		limit,
		setLimit: (value: number) => dispatch(setAccountCvsQueryLimit(value)),
		search,
		searchPlaceholder,
		searchTitle,
		setSearch: (value: string) => dispatch(setAccountCvsQuerySearch(value)),
		onSubmit: () => {},
		options: [1, 2, 3, 5, 10, 25, 50, 100],
	};

	const paginationSettings = {
		itemsLength: items.length,
		count,
		limit,
		offset,
		setOffset: (value: number) => dispatch(setAccountCvsQueryOffset(value)),
		prevLabel: <Icon icon="icon-chevron-left" className={ styles.iconChevron } />,
		nextLabel: <Icon icon="icon-chevron-right" className={ styles.iconChevron } />,
		isArrowsControls: !isMobile,
	};

	if (items.length === 0) {
		return (
			<TableBox
				isLoading={ isLoading }
				thead={ theadSettings }
				control={ controlSettings }
				pagination={ paginationSettings }
			>
				<TableRowEmpty colSpan={ 5 }>{ emptyText }</TableRowEmpty>
			</TableBox>
		);
	}

	return (
		<TableBox
			isLoading={ isLoading }
			thead={ theadSettings }
			control={ controlSettings }
			pagination={ paginationSettings }
		>
			{ items.map(item => <AccountCvsListRow key={ item.alias } { ...item } to={ `${PATH_ACCOUNT_CVS}/${item.alias}`} />) }
		</TableBox>
	);
}
