import { ReactNode } from 'react';

import styles from './ModalHeadline.module.scss';


type TProps = {
	className?: string;
	style?: Record<string, string>;
	children?: ReactNode;
};

export function ModalHeadline(props: TProps) {
	const { className = '', children, style = {} } = props;
	const classNames = [styles.modalHeadline];

	if (styles.className) {
		classNames.push(styles[className]);
	}

	return (
		<div className={ classNames.join(' ') } style={ style }>
			{ children }
		</div>
	);
}
