import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserRequest, TUserResponse, adaptUserFromResponse } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_USERS}`,
	(payload: TUserRequest, thunkApi) => {
		return HttpService.post<TUserResponse>(BACKEND_ADMIN_USERS, payload).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TUser | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userCreateSlice = createSlice({
	name: 'userCreateSlice',
	initialState,
	reducers: {
		clearUserCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[userCreateApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useUserCreateSelector = () => useAppSelector(state => state[userCreateSlice.name]);

export const { clearUserCreate } = userCreateSlice.actions;
