import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { PATH_PUBLIC_IMAGES } from 'src/routes';

import styles from './PublicCvTelegramIconName.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
};

export function PublicCvTelegramIconName(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	const path = `${PATH_PUBLIC_IMAGES}/icons/telegram.svg`;

	return (
		<div { ...restProps } className={ classNames.join(' ') }>
			<img className={ styles.icon } src={ path } alt="telegram-contact" />
		</div>
	);
}
