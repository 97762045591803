import { useEffect } from 'react';

import { Preloader } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { TErrorPage } from 'src/pages/components';
import { publicCvsGetApi, useAppDispatch, useCredentialsSelector, usePublicCvsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { PublicCvsListRow } from './components';
import { PUBLIC_CVS_TABLE_LOCALIZATION } from './localization';

import styles from './PublicCvsList.module.scss';


type TPageProps = {
	setError: (value?: TErrorPage) => void;
};

export function PublicCvsList(props: TPageProps) {
	const dispatch = useAppDispatch();
	const { emptyText } = useLocalization(PUBLIC_CVS_TABLE_LOCALIZATION);

	const { lang } = useCredentialsSelector();
	const { list, isLoading, error } = usePublicCvsSelector();
	const { items } = list;
	const { setError } = props;

	useEffect(() => {
		dispatch(publicCvsGetApi(lang));
	}, [lang]);

	useEffect(() => {
		if (error) {
			setError(error);
		}
	}, [error]);

	if (isLoading || error) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (items.length === 0) {
		const classNames = [styles.box, styles.emptyBox];

		return (
			<div className={ classNames.join(' ') }>
				{ emptyText }
			</div>
		);
	}

	return (
		<div className={ styles.box }>
			{
				items.map(item => <PublicCvsListRow key={ item.alias } { ...item } />)
			}
		</div>
	);
}
