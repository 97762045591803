import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButton, FormNotice, Modal, ModalButtonWrap, ModalHeadline } from 'src/components';
import { PATH_STACK } from 'src/routes';
import { TStack, clearStackLocaleDelete, stackLocaleDeleteApi, useAppDispatch, useStackLocaleDeleteSelector } from 'src/store';
import { formHandleServerError, useLocalization } from 'src/toolkit';
import { STACK_LOCALE_MODAL_DELETE_LOCALIZATION } from './localization';


type TProps = {
	data: TStack;
	onClose: () => void;
};

export function StackLocaleModalDelete(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useStackLocaleDeleteSelector();
	const { data, onClose } = props;
	const [notice, setNotice] = useState('');
	const { questionText, buttonRemove, buttonCancel } = useLocalization(STACK_LOCALE_MODAL_DELETE_LOCALIZATION);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice);
		}

		if (item) {
			const path = `${PATH_STACK}/${alias}`;

			onClose();
			navigate(path);
		}

		return () => {
			dispatch(clearStackLocaleDelete());
		};
	}, [error, item]);

	const { name, alias, locale } = data;

	const onClick = () => {
		dispatch(stackLocaleDeleteApi({ alias, locale }));
	};

	return (
		<Modal onClose={ onClose }>
			<ModalHeadline>{ questionText } {name} «{ locale.toUpperCase() }»?</ModalHeadline>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<ModalButtonWrap>
				<FormButton cancel className="first" type="button" busy={ isLoading } onClick={ onClick }>{ buttonRemove }</FormButton>
				<FormButton className="last" type="button" onClick={ onClose }>{ buttonCancel }</FormButton>
			</ModalButtonWrap>
		</Modal>
	);
}
