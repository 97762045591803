import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_PROJECT_REPOSITORY_URI_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountProjectRepositoryUriField(props: TProps) {
	const {
		legend,
		placeholder,
		title,
	} = useLocalization(ACCOUNT_PROJECT_REPOSITORY_URI_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
		>
			<FormInput
				placeholder={ placeholder }
				name={ name }
				title={ title }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
