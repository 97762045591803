export const USERS_EMAIL_DETAILS_EN_LOCALIZATION = {
	createdAtLegend: 'Date of creation',
	createdAtTitle: 'Date of creation E-Mail of the user',

	updatedAtLegend: 'Date of update',
	updatedAtTitle: 'Date of updating E-Mail of the user',

	unconfirmedLegend: 'Unconfirmed E-Mail',
	unconfirmedTitle: 'The E-Mail of the user is unconfirmed',

	removeLinkText: 'Remove E-Mail',
};
