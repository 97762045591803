import { LinkProps, Link as LinkRouter, useNavigate } from 'react-router-dom';

import styles from './Link.module.scss';


type TProps = Omit<LinkProps, 'to'> & {
	children?: React.ReactNode;
	target?: string;
	history?: number;
	className?: string;
	to?: string;
};

export function Link(props: TProps) {
	const {
		to = '#',
		children = '',
		className = '',
		history = 0,
		...restProps
	} = props;

	const classNames = [styles.link];
	if (className) {
		classNames.push(className);
	}

	if (history) {
		const navigate = useNavigate();

		return (
			<span
				onClick={ () => navigate(history) }
				className={ classNames.join(' ') }
				{ ...restProps }
			>
				{ children }
			</span>
		);
	}

	return (
		<LinkRouter
			to={ to }
			className={ classNames.join(' ') }
			{ ...restProps }
		>
			{ children }
		</LinkRouter>
	);
}
