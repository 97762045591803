export const ACCOUNT_PROJECTS_TABLE_EN_LOCALIZATION = {
	image: 'Image',
	alias: 'Alias',
	locales: 'Language locales',
	createdAt: 'Created at',
	updatedAt: 'Updated at',

	searchPlaceholder: 'Search in database',
	searchTitle: 'Type text for searching in database',

	emptyText: 'Projects not found',
};
