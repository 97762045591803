// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvName_box__S8nlJ {\n  color: rgb(90, 90, 90);\n  font-size: 1.25em;\n  font-weight: bold;\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/data/components/name/PublicCvName.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AACD","sourcesContent":[".box {\n\tcolor: rgba(90, 90, 90, 1);\n\tfont-size: 1.25em;\n\tfont-weight: bold;\n\twhite-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvName_box__S8nlJ"
};
export default ___CSS_LOADER_EXPORT___;
