// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StackBox_box__knmz0 {\n  -webkit-user-select: none;\n          user-select: none;\n  margin: 1em auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/stack-box/StackBox.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;UAAA,iBAAA;EAEA,gBAAA;AAAD","sourcesContent":[".box {\n\tuser-select: none;\n\n\tmargin: 1em auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "StackBox_box__knmz0"
};
export default ___CSS_LOADER_EXPORT___;
