import { useEffect, useState } from 'react';


export function useIsMobile(): boolean {
	const [width, setWidth] = useState<number>(window.innerWidth);

	const onChange = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', onChange);

		return () => {
			window.removeEventListener('resize', onChange);
		}
	}, []);

	return width <= 480;
}
