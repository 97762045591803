// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocaleField_selectBox__7tkLP {\n  width: 9em;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/locale-field/LocaleField.module.scss"],"names":[],"mappings":"AAAA;EACC,UAAA;AACD","sourcesContent":[".selectBox {\n\twidth: 9em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectBox": "LocaleField_selectBox__7tkLP"
};
export default ___CSS_LOADER_EXPORT___;
