import { LOCALIZATION } from 'src/localization';
import { libFormatDate, useLocalization } from 'src/toolkit';

import { PUBLIC_CV_UPDATED_AT_LOCALIZATION } from './localization';

import styles from './PublicCvUpdatedAt.module.scss';


type TProps = {
	date: string;
};

const SHORT_TIME = 86400000;
const MIDDLE_TIME = SHORT_TIME * 7;
const LONG_TIME = MIDDLE_TIME * 4;

export function PublicCvUpdatedAt(props: TProps) {
	const { updated, into } = useLocalization(PUBLIC_CV_UPDATED_AT_LOCALIZATION);
	const {
		januaryNominative,
		februaryNominative,
		marchNominative,
		aprilNominative,
		mayNominative,
		juneNominative,
		julyNominative,
		augustNominative,
		septemberNominative,
		octoberNominative,
		novemberNominative,
		decemberNominative,
	} = useLocalization(LOCALIZATION);

	const months = [
		januaryNominative,
		februaryNominative,
		marchNominative,
		aprilNominative,
		mayNominative,
		juneNominative,
		julyNominative,
		augustNominative,
		septemberNominative,
		octoberNominative,
		novemberNominative,
		decemberNominative,
	];

	const { date } = props;

	const classNames = [styles.box, generateDiffStyle(date)];

	return (
		<div className={ classNames.join(' ') }>
			{ updated }
			<br />
			{ libFormatDate(date, { template: `dd MM ${into} hh:mm`, months }) }
		</div>
	);
}

function generateDiffStyle(updatedAt: string): string {
	const date = new Date(updatedAt);
	const difference = Date.now() - date.getTime();

	if (difference <= SHORT_TIME) {
		return styles.shortTime;
	}

	if (difference <= MIDDLE_TIME) {
		return styles.middleTime;
	}

	if (difference <= LONG_TIME) {
		return styles.longTime;
	}

	return styles.defaultTime;
}
