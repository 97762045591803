import styles from './PublicCvExperiencePositionName.module.scss';


type TProps = {
	name: string;
};

export function PublicCvExperiencePositionName(props: TProps) {
	const { name } = props;

	return (
		<div className={ styles.name }>
			{ name }
		</div>
	);
}
