import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountEducation, TAccountEducationUpdate, TAccountEducationResponse, adaptAccountEducationFromResponse, adaptAccountEducationToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';


export const accountEducationUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_EDUCATIONS}/:pid`,
	(payload: TAccountEducationUpdate, thunkApi) => {
		const { data, pid } = payload;
		const path = `${BACKEND_ACCOUNT_EDUCATIONS}/${pid}`;
		const dataRequest = adaptAccountEducationToRequest(data);

		return HttpService.put<TAccountEducationResponse>(path, dataRequest).then(result => {
			return adaptAccountEducationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountEducation | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEducationUpdateSlice = createSlice({
	name: 'accountEducationUpdateSlice',
	initialState,
	reducers: {
		clearAccountEducationUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEducationUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEducationUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEducationUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountEducationUpdateSelector = () => useAppSelector(state => state[accountEducationUpdateSlice.name]);

export const { clearAccountEducationUpdate } = accountEducationUpdateSlice.actions;
