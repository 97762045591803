import { TextareaHTMLAttributes } from 'react';

import styles from './FormTextarea.module.scss';


type TProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
	autocomplete?: string;
	classNames?: string[];
};

export function FormTextarea(props: TProps) {
	const {
		children = '',
		className = '',
		classNames: classNamesOuter = [],
		autocomplete = 'off',
		disabled = false,
		...restProps
	} = props;

	const classNames = [styles.formTextarea, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	if (disabled) {
		classNames.push(styles.formTextarea);
	}

	return (
		<textarea
			className={ classNames.join(' ') }
			disabled={ disabled }
			autoComplete={ autocomplete }
			{ ...restProps }
		>{ children }</textarea>
	);
}
