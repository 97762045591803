// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvImage_image__G2oh8 {\n  box-sizing: border-box;\n  border: 1px solid rgb(220, 220, 220);\n  display: block;\n  width: 100%;\n  margin: 0 0 0 auto;\n  max-width: 10em;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/image/PublicCvImage.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,oCAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACD","sourcesContent":[".image {\n\tbox-sizing: border-box;\n\tborder: 1px solid rgba(220, 220, 220, 1);\n\tdisplay: block;\n\twidth: 100%;\n\tmargin: 0 0 0 auto;\n\tmax-width: 10em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "PublicCvImage_image__G2oh8"
};
export default ___CSS_LOADER_EXPORT___;
