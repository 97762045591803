import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice } from 'src/components';
import { formHandleServerError, formValidateEmail, isInvalidEmail, useInputFormField, useLocalization } from 'src/toolkit';
import { TAccountEmail, accountEmailUpdateApi, clearAccountEmailNoticeUpdate, clearAccountEmailUpdate, setAccountEmailRead, useAccountEmailUpdateSelector, useAppDispatch } from 'src/store';

import { AccountEmailField } from '../../../../../../components';
import { ACCOUNT_EMAIL_ROUTER_LOCALIZATION } from '../../localization';

import { ACCOUNT_EMAIL_SEND_STAGE_LOCALIZATION } from './localization';


type TProps = {
	item: TAccountEmail;
};

export function AccountEmailSendStage(props: TProps) {
	const dispatch = useAppDispatch();
	const { item: itemUpdated, noticeConfirmed, isLoading, error } = useAccountEmailUpdateSelector();
	const [notice, setNotice] = useState('');
	const [isInvalid, setInIsValid] = useState(false);

	const { unconfirmedLegend, unconfirmedTitle, errorAlreadyConfirmed } = useLocalization(ACCOUNT_EMAIL_ROUTER_LOCALIZATION);
	const { button } = useLocalization(ACCOUNT_EMAIL_SEND_STAGE_LOCALIZATION);

	const { item } = props;
	const { email, emailExpected } = item;

	const emailExpectedField = useInputFormField({
		name: 'email_expected',
		defaultValue: emailExpected,
		validation: formValidateEmail,
	});

	useEffect(() => {
		return () => {
			dispatch(clearAccountEmailUpdate());
			dispatch(clearAccountEmailNoticeUpdate());
		};
	}, []);

	useEffect(() => {
		if (noticeConfirmed) {
			setNotice(noticeConfirmed);
		}
	}, [noticeConfirmed]);

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice,
				emailExpectedField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (itemUpdated) {
			setNotice('');
			dispatch(setAccountEmailRead(itemUpdated));
		}
	}, [itemUpdated]);

	useEffect(() => {
		const isInvalidValue = isInvalidEmail(emailExpectedField.value);

		setInIsValid(isInvalidValue);
	}, [emailExpectedField.value]);

	const submit = () => {
		const errors = [
			emailExpectedField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		if (email === emailExpectedField.value) {
			emailExpectedField.setErrors([errorAlreadyConfirmed]);

			return;
		}

		const dataRequest = {
			emailExpected: emailExpectedField.value,
		};

		dispatch(accountEmailUpdateApi(dataRequest));
	};

	return (
		<Form onSubmit={ submit }>
			<AccountEmailField
				{ ...emailExpectedField }
				legend={ unconfirmedLegend }
				title={ unconfirmedTitle }
				unconfirmed={ !!emailExpectedField.value }
			/>

			<FormNotice success={ !!notice && !error } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading } disabled={ isInvalid }>{ button }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
