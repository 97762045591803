import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_CV_BIRTHDAY_FIELD_LOCALIZATION } from './localization';

import styles from './AccountCvBirthdayField.module.scss';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountCvBirthdayField(props: TProps) {
	const {
		birthdayLegend,
		birthdayTitle,
	} = useLocalization(ACCOUNT_CV_BIRTHDAY_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ birthdayLegend }
			errors={ errors }
			required
			className={ styles.box }
		>
			<FormInput
				type="date"
				name={ name }
				title={ birthdayTitle }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
