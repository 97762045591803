import styles from './NavIcon.module.scss';


type TProps = {
	children?: React.ReactNode;
	className?: string;
	icon: string;
};

export function NavIcon(props: TProps) {
	const { icon = '', className = '', children = '' } = props;

	const classNameArr = [styles.navIcon];
	const iconClass = styles[icon];

	if (iconClass) {
		classNameArr.push(iconClass);
	}

	if (className) {
		classNameArr.push(className);
	}

	return (
		<i
			className={ classNameArr.join(' ') }
		>
			{ children }
		</i>
	);
}
