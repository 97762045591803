import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TAccountCv, TAccountCvLocaleParam, TAccountCvResponse, adaptAccountCvFromResponse } from '../../types';
import { BACKEND_ACCOUNT_CVS } from '../../backend-paths';


export const accountCvLocaleReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_CVS}/:alias/:locale`,
	(payload: TAccountCvLocaleParam, thunkApi) => {
		const { alias, locale } = payload;
		const path = `${BACKEND_ACCOUNT_CVS}/${alias}/${locale}`;

		return HttpService.get<TAccountCvResponse>(path).then(result => {
			return adaptAccountCvFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TAccountCv | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCvLocaleReadSlice = createSlice({
	name: 'accountCvLocaleReadSlice',
	initialState,
	reducers: {
		setAccountCvLocaleRead(state, action: PayloadAction<TAccountCv>) {
			state.data = action.payload;
		},

		clearAccountCvLocaleRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCvLocaleReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCvLocaleReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountCv>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountCvLocaleReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountCvLocaleReadSelector = () => useAppSelector(state => state[accountCvLocaleReadSlice.name]);

export const { clearAccountCvLocaleRead, setAccountCvLocaleRead } = accountCvLocaleReadSlice.actions;
