import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_PHONE_NUMBER_CONFIRM } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountPhoneNumber, TAccountPhoneNumberResponse, TAccountPhoneNumberUpdateConfirm, adaptAccountPhoneNumberConfirmToRequest, adaptAccountPhoneNumberFromResponse } from '../../types';


export const accountPhoneNumberUpdateConfirmApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_PHONE_NUMBER_CONFIRM}`,
	(payload: TAccountPhoneNumberUpdateConfirm, thunkApi) => {
		const body = adaptAccountPhoneNumberConfirmToRequest(payload);

		return HttpService.post<TAccountPhoneNumberResponse>(BACKEND_ACCOUNT_PHONE_NUMBER_CONFIRM, body).then(result => {
			return adaptAccountPhoneNumberFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountPhoneNumber | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountPhoneNumberUpdateConfirmSlice = createSlice({
	name: 'accountPhoneNumberUpdateConfirmSlice',
	initialState,
	reducers: {
		clearAccountPhoneNumberUpdateConfirm(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountPhoneNumberUpdateConfirmApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountPhoneNumberUpdateConfirmApi.fulfilled.type]: (state, action: PayloadAction<TAccountPhoneNumber>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountPhoneNumberUpdateConfirmApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountPhoneNumberUpdateConfirmSelector = () => useAppSelector(state => state[accountPhoneNumberUpdateConfirmSlice.name]);

export const { clearAccountPhoneNumberUpdateConfirm } = accountPhoneNumberUpdateConfirmSlice.actions;
