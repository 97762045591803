import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { BreadCrumbs, Footer, NavMenu } from 'src/components';

import styles from './AccountLayout.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function AccountLayout(props: TProps) {
	const {
		children,
		className,
		...restProps
	} = props;

	const classNames = [styles.layout];
	if (className) {
		classNames.push(className);
	}

	return (
		<div { ...restProps } className={ classNames.join(' ') }>
			<NavMenu>
				<main className={ styles.main }>
					<BreadCrumbs />

					{ children }
				</main>

				<Footer />
			</NavMenu>
		</div>
	);
}
