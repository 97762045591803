import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButton, FormNotice, Modal, ModalButtonWrap, ModalHeadline } from 'src/components';
import { PATH_ACCOUNT_EXPERIENCE } from 'src/routes';
import { TAccountExperience, clearAccountExperienceDelete, accountExperienceDeleteApi, useAppDispatch, useAccountExperienceDeleteSelector } from 'src/store';
import { formHandleServerError, useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_MODAL_DELETE_LOCALIZATION } from './localization';


type TProps = {
	data: TAccountExperience;
	onClose: () => void;
};

export function AccountExperienceModalDelete(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountExperienceDeleteSelector();
	const { data, onClose } = props;
	const [notice, setNotice] = useState('');
	const { questionText, buttonRemove, buttonCancel } = useLocalization(ACCOUNT_EXPERIENCE_MODAL_DELETE_LOCALIZATION);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice);
		}

		if (item) {
			onClose();
			navigate(PATH_ACCOUNT_EXPERIENCE);
		}

		return () => {
			dispatch(clearAccountExperienceDelete());
		};
	}, [error, item]);

	const { positionName, pid } = data;

	const onClick = () => {
		dispatch(accountExperienceDeleteApi(pid));
	};

	return (
		<Modal onClose={ onClose }>
			<ModalHeadline>{ questionText } «{ positionName }»?</ModalHeadline>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<ModalButtonWrap>
				<FormButton cancel className="first" type="button" busy={ isLoading } onClick={ onClick }>{ buttonRemove }</FormButton>
				<FormButton className="last" type="button" onClick={ onClose }>{ buttonCancel }</FormButton>
			</ModalButtonWrap>
		</Modal>
	);
}
