// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BreadCrumbs_breadCrumbs__RPG4g {\n  box-sizing: border-box;\n  margin: 0.5em 0;\n  min-height: 1.2em;\n  width: 100%;\n}\n@media screen and (max-width: 480px) {\n  .BreadCrumbs_breadCrumbs__RPG4g .BreadCrumbs_breadCrumbs__RPG4g {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/bread-crumbs/BreadCrumbs.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,eAAA;EAEA,iBAAA;EACA,WAAA;AAAD;AAEC;EACC;IACC,WAAA;EAAD;AACF","sourcesContent":[".breadCrumbs {\n\tbox-sizing: border-box;\n\tmargin: 0.5em 0;\n\n\tmin-height: 1.2em;\n\twidth: 100%;\n\n\t@media screen and (max-width: 480px) {\n\t\t.breadCrumbs {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadCrumbs": "BreadCrumbs_breadCrumbs__RPG4g"
};
export default ___CSS_LOADER_EXPORT___;
