import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButton, FormNotice, Modal, ModalButtonWrap, ModalHeadline } from 'src/components';
import { PATH_USERS } from 'src/routes';
import { TUserPhoneNumber, clearUserPhoneNumberDelete, userPhoneNumberDeleteApi, useAppDispatch, useUserPhoneNumberDeleteSelector } from 'src/store';
import { formHandleServerError, useLocalization } from 'src/toolkit';

import { USERS_PHONE_NUMBER_MODAL_DELETE_LOCALIZATION } from './localization';


export type TUserPhoneNumberModalDeleteProps = TUserPhoneNumber & {
	nickname: string;
};

type TProps = {
	data: TUserPhoneNumberModalDeleteProps;
	onClose: () => void;
};

export function UserPhoneNumberModalDelete(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useUserPhoneNumberDeleteSelector();
	const { data, onClose } = props;
	const [notice, setNotice] = useState('');
	const { questionText, buttonRemove, buttonCancel } = useLocalization(USERS_PHONE_NUMBER_MODAL_DELETE_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearUserPhoneNumberDelete());
		};
	}, []);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			const path = `${PATH_USERS}/${nickname}`;

			onClose();
			navigate(path);
		}
	}, [item]);

	const { phoneNumber, nickname } = data;

	const onClick = () => {
		dispatch(userPhoneNumberDeleteApi(nickname));
	};

	return (
		<Modal onClose={ onClose }>
			<ModalHeadline>{ questionText } «{ phoneNumber }»?</ModalHeadline>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<ModalButtonWrap>
				<FormButton cancel className="first" type="button" busy={ isLoading } onClick={ onClick }>{ buttonRemove }</FormButton>
				<FormButton className="last" type="button" onClick={ onClose }>{ buttonCancel }</FormButton>
			</ModalButtonWrap>
		</Modal>
	);
}
