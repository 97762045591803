import { DetailedHTMLProps, SelectHTMLAttributes } from 'react';

import styles from './FormSelect.module.scss';

type TOptionData = {
	value: string | number;
	text?: string | number;
};

type TOptionValue = string | number;

type TProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
	items?: TOptionData[] | TOptionValue[];
};

function isOptionData(value: TOptionValue[] | TOptionData[]): value is TOptionData[] {
	const item = value[0];

  return typeof (item as TOptionData) === 'object';
}

export function FormSelect(props: TProps) {
	const {
		items = [],
		...restProps
	} = props;

	if (isOptionData(items)) {
		return (
			<select
				className={ styles.formSelect }
				{ ...restProps }
			>
				{ items.length > 0 &&
					items.map(item => {
						const text = item.text || item.value;
						return (
							<option key={ item.value }>{ text }</option>
						);
					})
				}
			</select>
		);
	}

	return (
		<select
			className={ styles.formSelect }
			{ ...restProps }
		>
			{ items.length > 0 &&
				items.map(item => {
					return (
						<option key={ item }>{ item }</option>
					);
				})
			}
		</select>
	);
}
