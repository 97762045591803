import { LocalizationTypes } from 'src/common/types';

import { CONTACT_TITLE_RU_LOCALIZATION } from './ru.localization';
import { CONTACT_TITLE_EN_LOCALIZATION } from './en.localization';


export const CONTACT_TITLE_LOCALIZATION = {
	[LocalizationTypes.RU]: CONTACT_TITLE_RU_LOCALIZATION,
	[LocalizationTypes.EN]: CONTACT_TITLE_EN_LOCALIZATION,
};
