import { TListResponse } from 'src/common/types';


export const DefaultUserSex = { alias: 'female', name: 'Female' };

export type TUserSexResponse = {
	alias: string;
	name?: string;
};

export type TUserSex = {
	alias: string;
	name: string;
};

export type TUserSexListResponse = TListResponse<TUserSex>;

export function adaptUserSexFromResponse(data: TUserSexResponse): TUserSex {
	const { name = '', alias } = data;

	return { name, alias };
}

export function adaptUserSexsFromListResponse(data: TUserSexListResponse): TUserSex[] {
	const { items } = data;

	return items.map(item => adaptUserSexFromResponse(item));
}
