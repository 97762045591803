import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TStack, setStackCreatedItem, stackCreateApi, useAppDispatch, useStackCreateSelector } from 'src/store';
import { formHandleServerError, formValidateNickname, formValidateText, useInputFileField, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_STACK } from 'src/routes';

import { StackAliasField, StackImageField, StackNameField } from '../../../../components';
import { STACK_CREATE_LOCALIZATION } from '../../localization';


export function StackFormCreate(props: TStack) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useStackCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(STACK_CREATE_LOCALIZATION);

	const {
		name = '',
		alias = '',
		image = '',
	} = props;

	const nameField = useInputFormField({
		name: 'name',
		defaultValue: name,
		validation: value => formValidateText(value),
	});

	const aliasField = useInputFormField({
		name: 'alias',
		defaultValue: alias,
		validation: value => formValidateNickname(value),
	});

	const imageField = useInputFileField('image', image);
	const [file, setFile] = useState<File>();

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice, nameField, aliasField);
		}

		if (item) {
			dispatch(setStackCreatedItem(item));
			navigate(`${PATH_STACK}/${item.alias}`);
		}
	}, [error, item]);

	const onSubmit = () => {
		const errors = [
			nameField.validate(),
			aliasField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			name: nameField.value,
			alias: aliasField.value,
			image: file || imageField.value,
		};

		dispatch(stackCreateApi(dataRequest));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<StackImageField { ...imageField } setFile={ setFile } />

			<StackNameField { ...nameField } isLoading={ isLoading } />

			<StackAliasField { ...aliasField } isLoading={ isLoading } />

			<FormNotice errorText={ notice } />

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
