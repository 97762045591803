import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_DEFAULT_EXPERIENCE, PATH_STATIC_EXPERIENCE_IMAGES } from 'src/routes';
import { TAccountProject, TAccountProjectResponse, adaptAccountProjectFromResponse } from './account-project.type';
import { libFormatDate } from 'src/toolkit';


export const AccountExperienceSortCol = {
	COMPANY_NAME: 'company_name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountExperienceSortCol = TTableSortCols<typeof AccountExperienceSortCol>;

export type TAccountExperienceQuery = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountExperienceSortCol;
};

export type TAccountExperienceQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};


export type TAccountExperienceLocale = {
	locale: string;
  companyCity: string;
  positionName: string;
  description: string;
	createdAt: string;
  updatedAt: string;
};

export type TAccountExperience = TAccountExperienceLocale & {
	pid: string;
	companyName: string;
  companyUri: string;
  companyLogo: string;
  dateStart: string;
  dateEnd: string;
	locales: string[];
	projects: TAccountProject[];
	countExperience: number[];
};

export type TAccountExperienceResponse = {
	pid: string;
  company_name: string;
  company_uri: string;
  company_city: string;
  position_name: string;
  description: string;
  company_logo: string;
  locale: string;
  locales: string[];
  date_start: string;
  date_end: string;
  created_at: string;
  updated_at: string;

  projects: TAccountProjectResponse[];
	count_experience: number[];
};

export type TAccountExperienceLocaleBodyRequest = Omit<TAccountExperienceLocale, 'createdAt' | 'updatedAt'>;

export type TAccountExperienceBodyRequest = Pick<TAccountExperience, 'companyName' | 'dateStart'> & {
	companyLogo?: File | string;
	companyUri?: string;
	dateEnd?: string;
};

export type TAccountExperienceLocaleRequest = Pick<TAccountExperienceResponse, 'locale' | 'company_city' | 'position_name' | 'description'>;

export type TAccountExperienceRequest = Pick<TAccountExperienceResponse, 'company_name' | 'company_uri' | 'date_start'> & {
	company_logo?: File | string;
	date_end?: string;
};

export type TAccountExperienceLocaleParam = {
  pid: string;
  locale: string;
};

export type TAccountExperienceListResponse = TListResponse<TAccountExperienceResponse>;

export type TAccountExperienceList = TListResponse<TAccountExperience>;

export type TAccountExperienceLocalePayloadCreate = {
	pid: string;
	data: TAccountExperienceLocaleBodyRequest;
};

export type TAccountExperienceLocalePayloadUpdate = TAccountExperienceLocaleParam & {
	data: TAccountExperienceLocaleBodyRequest;
};

export type TAccountExperiencePayloadUpdate = {
	pid: string;
	data: TAccountExperienceBodyRequest;
	projects: string[];
};


export function adaptAccountExperienceFromListResponse(data: TAccountExperienceListResponse): TAccountExperienceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountExperienceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountExperienceFromResponse(data: TAccountExperienceResponse): TAccountExperience {
	const {
		pid,
		company_name: companyName,
		company_uri: companyUri,
		company_city: companyCity,
		date_start: dateStartRaw,
		date_end: dateEndRaw,
		position_name: positionName,
		description,
		company_logo: companyLogoRaw,
		created_at: createdAt,
		updated_at: updatedAt,
		locales = [],
		locale = '',
		projects: projectsRaw = [],
		count_experience: countExperience,
	} = data;

	const companyLogo = companyLogoRaw ? `${PATH_STATIC_EXPERIENCE_IMAGES}/${companyLogoRaw}` : PATH_DEFAULT_EXPERIENCE;

	const projects = projectsRaw.map(item => adaptAccountProjectFromResponse(item));
	const dateStart = dateStartRaw ? libFormatDate(dateStartRaw, 'yyyy-MM-dd') : dateStartRaw;
	const dateEnd = dateEndRaw ? libFormatDate(dateEndRaw, 'yyyy-MM-dd') : dateEndRaw;

	return {
		pid,
		companyLogo,
		companyName,
		companyUri,
		companyCity,
		positionName,
		description,
		dateStart,
		dateEnd,
		locale,
		locales,
		createdAt,
		updatedAt,
		projects,
		countExperience,
	};
}

export function adaptAccountExperienceToRequest(data: TAccountExperienceBodyRequest): TAccountExperienceRequest {
	const {
		dateStart,
		dateEnd,
		companyName,
		companyUri = '',
		companyLogo,
	} = data;

	return {
		date_start: dateStart,
		date_end: dateEnd,
		company_name: companyName,
		company_uri: companyUri,
		company_logo: companyLogo,
	};
}

export function adaptAccountExperienceLocaleToRequest(data: TAccountExperienceLocaleBodyRequest): TAccountExperienceLocaleRequest {
	const {
		locale,
		companyCity,
		positionName,
		description,
	} = data;

	return {
		locale,
		company_city: companyCity,
		position_name: positionName,
		description,
	};
}
