import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice, FormPassword } from 'src/components';
import { formHandleServerError, formValidatePassword, formValidatePasswordConfirm, formValidatePasswordUnConfirm, useInputFormField, useLocalization } from 'src/toolkit';
import { accountPasswordApi, setAccountPasswordHideSuccessAction, useAccountPasswordSelector, useAppDispatch } from 'src/store';

import styles from './AccountPasswordForm.module.scss';
import { ACCOUNT_PASSWORD_FORM_LOCALIZATION } from './localization';

export function AccountPasswordForm() {
	const dispatch = useAppDispatch();
	const { isSuccess, isLoading, error } = useAccountPasswordSelector();
	const [notice, setNotice] = useState('');

	const {
		currentPasswordLegend,
		currentPasswordTitle,
		newPasswordLegend,
		newPasswordTitle,
		newPasswordConfirmLegend,
		newPasswordConfirmTitle,
		successNotice,
		buttonText,
	} = useLocalization(ACCOUNT_PASSWORD_FORM_LOCALIZATION);

	const passwordField = useInputFormField({
		name: 'password',
		validation: formValidatePassword,
	});

	const passwordNewField = useInputFormField({
		name: 'password_new',
		validation: value => ([
			...formValidatePassword(value),
			...formValidatePasswordUnConfirm(passwordField.value, value),
		]),
	});

	const passwordNewConfirmField = useInputFormField({
		name: 'password_new_confirm',
		validation: value => formValidatePasswordConfirm(passwordNewField.value, value),
	});

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice, passwordField, passwordNewField);
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			setNotice(successNotice);

			passwordField.clear();
			passwordNewField.clear();
			passwordNewConfirmField.clear();

			return () => {
				dispatch(setAccountPasswordHideSuccessAction())
			};
		}
	}, [isSuccess]);

	const submit = () => {
		const errors = [
			passwordField.validate(),
			passwordNewField.validate(),
			passwordNewConfirmField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			password: passwordField.value,
			passwordNew: passwordNewField.value,
		};

		dispatch(accountPasswordApi(dataRequest));
	};

	return (
		<Form className={ styles.accountPasswordForm } onSubmit={ submit }>
			<FormPassword
				legend={ currentPasswordLegend }
				errors={ passwordField.errors }
				placeholder="••••••••"
				name={ passwordField.name }
				title={ currentPasswordTitle }
				value={ passwordField.value }
				onChange={ e => passwordField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			<FormPassword
				legend={ newPasswordLegend }
				errors={ passwordNewField.errors }
				placeholder="••••••••"
				name={ passwordNewField.name }
				title={ newPasswordTitle }
				value={ passwordNewField.value }
				onChange={ e => passwordNewField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			<FormPassword
				legend={ newPasswordConfirmLegend }
				errors={ passwordNewConfirmField.errors }
				placeholder="••••••••"
				name={ passwordNewConfirmField.name }
				title={ newPasswordConfirmTitle }
				value={ passwordNewConfirmField.value }
				onChange={ e => passwordNewConfirmField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			{ isSuccess && <FormNotice success={ isSuccess } error={ !!error }>{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ buttonText }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
