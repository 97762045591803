import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButton, FormNotice, Modal, ModalButtonWrap, ModalHeadline } from 'src/components';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';
import { TAccountEducation, accountEducationDeleteApi, useAppDispatch, useAccountEducationDeleteSelector, clearAccountEducationDelete } from 'src/store';
import { formHandleServerError, useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_MODAL_DELETE_LOCALIZATION } from './localization';


type TProps = {
	data: TAccountEducation;
	onClose: () => void;
};

export function AccountEducationModalDelete(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountEducationDeleteSelector();
	const { data, onClose } = props;
	const [notice, setNotice] = useState('');
	const { questionText, buttonRemove, buttonCancel } = useLocalization(ACCOUNT_EDUCATION_MODAL_DELETE_LOCALIZATION);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice);
		}

		if (item) {
			onClose();
			navigate(PATH_ACCOUNT_EDUCATION);
		}

		return () => {
			dispatch(clearAccountEducationDelete());
		};
	}, [error, item]);

	const { shortName, pid } = data;

	const onClick = () => {
		dispatch(accountEducationDeleteApi(pid));
	};

	return (
		<Modal onClose={ onClose }>
			<ModalHeadline>{ questionText } «{ shortName }»?</ModalHeadline>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<ModalButtonWrap>
				<FormButton cancel className="first" type="button" busy={ isLoading } onClick={ onClick }>{ buttonRemove }</FormButton>
				<FormButton className="last" type="button" onClick={ onClose }>{ buttonCancel }</FormButton>
			</ModalButtonWrap>
		</Modal>
	);
}
