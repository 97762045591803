import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TUserSex } from 'src/store';
import { PublicCvAge } from 'src/pages/components';

import { PublicCvBirthday, PublicCvName } from './components';

import styles from './PublicCvData.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	className?: string;
	classNames?: string[];
	birthday: string,
	firstName: string,
	lastName: string,
	sex: TUserSex,
};

export function PublicCvData(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],

		birthday,
		firstName = '',
		lastName = '',
		sex,

		...restProps
	} = props;

	const classNames = [...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			<PublicCvName>{ firstName } { lastName }</PublicCvName>

			<PublicCvAge className={ styles.age } sex={ sex.name } sign={ sex.alias } date={ birthday } />

			<PublicCvBirthday className={ styles.birthday } sex={ sex.alias } date={ birthday } />
		</div>
	);
}
