import { Link } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';
import { useLocalization } from 'src/toolkit';

import { SIGN_UP_MAIN_LOCALIZATION } from './localization';
import { SignUpFormMain } from './components/form/SignUpFormMain';

import styles from '../../../../../../components/stages/SignUpStages.module.scss';


export function SignUpStageMain() {
	const { underText, linkText } = useLocalization(SIGN_UP_MAIN_LOCALIZATION);

	return (
		<>
			<SignUpFormMain />

			<p className={ styles.underText }>
				<span>{ underText } </span>
				<Link to={ PATH_SIGN_IN }>{ linkText }</Link>
			</p>
		</>
	);
}

