// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocaleBoxItem_buttonLink__f04FD {\n  border: 1px solid rgb(150, 150, 150);\n  margin: 0 0.2em 0.2em 0;\n  width: 2em;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/locale-box/components/item/LocaleBoxItem.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,uBAAA;EACA,UAAA;AACD","sourcesContent":[".buttonLink {\n\tborder: 1px solid rgba(150, 150, 150, 1);\n\tmargin: 0 0.2em 0.2em 0;\n\twidth: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonLink": "LocaleBoxItem_buttonLink__f04FD"
};
export default ___CSS_LOADER_EXPORT___;
