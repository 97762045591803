import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './Preloader.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	noLayout?: boolean;
	another?: boolean;
};

export function Preloader(props: TProps) {
	const { noLayout = false, another = false } = props;

	const animationClassNames = [styles.animation];
	const textClassNames = [styles.text];
	const classNames = [styles.preloader];

	if (noLayout) {
		classNames.push(styles.noLayout);
	}

	if (another) {
		textClassNames.push(styles.another);
		animationClassNames.push(styles.another);
	}

	return (
		<div className={ classNames.join(' ') }>
			<div className={ textClassNames.join(' ') }>Loading...</div>
			<div className={ animationClassNames.join(' ') }></div>
		</div>
	);
}
