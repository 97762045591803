// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvExperienceDateTimeRange_text__IFqPg {\n  color: rgb(120, 120, 120);\n  font-size: 1em;\n  line-height: 1.2em;\n  height: 1.2em;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/experience/components/experience-item/components/date-time-range/PublicCvExperienceDateTimeRange.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EAEA,cAAA;EACA,kBAAA;EACA,aAAA;AAAD","sourcesContent":[".text {\n\tcolor: rgba(120, 120, 120, 1);\n\n\tfont-size: 1em;\n\tline-height: 1.2em;\n\theight: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "PublicCvExperienceDateTimeRange_text__IFqPg"
};
export default ___CSS_LOADER_EXPORT___;
