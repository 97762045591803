import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButton, FormNotice, Modal, ModalButtonWrap, ModalHeadline } from 'src/components';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';
import { TAccountEducation, accountEducationLocaleDeleteApi, useAppDispatch, useAccountEducationLocaleDeleteSelector, clearAccountEducationLocaleDelete } from 'src/store';
import { formHandleServerError, useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_LOCALE_MODAL_DELETE_LOCALIZATION } from './localization';


type TProps = {
	data: TAccountEducation;
	onClose: () => void;
};

export function AccountEducationLocaleModalDelete(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountEducationLocaleDeleteSelector();
	const { data, onClose } = props;
	const [notice, setNotice] = useState('');
	const { questionText, buttonRemove, buttonCancel } = useLocalization(ACCOUNT_EDUCATION_LOCALE_MODAL_DELETE_LOCALIZATION);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice);
		}

		if (item) {
			const path = `${PATH_ACCOUNT_EDUCATION}/${pid}`;

			onClose();
			navigate(path);
		}

		return () => {
			dispatch(clearAccountEducationLocaleDelete());
		};
	}, [error, item]);

	const { shortName, pid, locale } = data;

	const onClick = () => {
		dispatch(accountEducationLocaleDeleteApi({ pid, locale }));
	};

	return (
		<Modal onClose={ onClose }>
			<ModalHeadline>{ questionText } «{ shortName }»?</ModalHeadline>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<ModalButtonWrap>
				<FormButton cancel className="first" type="button" busy={ isLoading } onClick={ onClick }>{ buttonRemove }</FormButton>
				<FormButton className="last" type="button" onClick={ onClose }>{ buttonCancel }</FormButton>
			</ModalButtonWrap>
		</Modal>
	);
}
