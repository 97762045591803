import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountCv, adaptAccountCvFromResponse } from 'src/store/types';

import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_CVS } from '../../backend-paths';


export const accountCvDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_CVS}/:alias`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_CVS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptAccountCvFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item?: TAccountCv,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCvDeleteSlice = createSlice({
	name: 'accountCvDeleteSlice',
	initialState,
	reducers: {
		clearAccountCvDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCvDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCvDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountCv>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountCvDeleteApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountCvDeleteSelector = () => useAppSelector(state => state[accountCvDeleteSlice.name]);

export const { clearAccountCvDelete } = accountCvDeleteSlice.actions;
