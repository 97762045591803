import { useState } from 'react';

import { CvHeadline, Icon } from 'src/components';
import { TPublicProject } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { PublicCvExperienceProjectsItems } from './components';
import { PUBLIC_CV_EXPERIENCE_PROJECTS_LOCALIZATION } from './localization';

import styles from './PublicCvExperienceProjects.module.scss';


type TProps = {
	items: TPublicProject[];
};

export function PublicCvExperienceProjects(props: TProps) {
	const [isVisible, setIsVisible] = useState(false);

	const { headline } = useLocalization(PUBLIC_CV_EXPERIENCE_PROJECTS_LOCALIZATION);
	const { items } = props;

	if (items.length === 0) {
		return (
			<></>
		);
	}

	const onClick = () => {
		setIsVisible(!isVisible);
	};

	const iconClassNames = [styles.icon];

	if (isVisible) {
		iconClassNames.push(styles.active);
	}

	return (
		<div className={ styles.box }>
			<div className={ styles.projects }>
				<CvHeadline className={ styles.headline } onClick={ onClick }>{ headline }</CvHeadline>
				<Icon className={ iconClassNames.join(' ') } onClick={ onClick } icon="icon-chevron-bottom" />
			</div>

			{ isVisible && <PublicCvExperienceProjectsItems items={ items } /> }
		</div>
	);
}
