import { useState } from 'react';

import { FormField, FormInput } from 'src/components';
import { TStack, useCredentialsSelector } from 'src/store';
import { libFormatDate, useLocalization } from 'src/toolkit';
import { LinkDelete } from 'src/pages/components';
import { ROLES } from 'src/routes';

import { StackModalDelete } from './components';
import { STACK_DETAILS_LOCALIZATION } from './localization';

import styles from 'src/components/form/form-box/FormBox.module.scss';


export function StackDetails(props: TStack) {
	const { roles } = useCredentialsSelector();

	const {
		createdAt = '',
		updatedAt = '',
	} = props;

	const [modal, setModal] = useState(false);
	const {
		createdAtLegend,
		createdAtTitle,
		updatedAtLegend,
		updatedAtTitle,
		removeLinkText,
	} = useLocalization(STACK_DETAILS_LOCALIZATION);

	const openModal = () => {
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
	};

	const isDelete = roles.includes(ROLES.DELETE_STACKS);

	return (
		<div className={ styles.formBox }>
			<FormField
				legend={ createdAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ createdAtTitle }
					value={ libFormatDate(createdAt) }
					readOnly
				/>
			</FormField>

			<FormField
				legend={ updatedAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ updatedAtTitle }
					value={ libFormatDate(updatedAt) }
					readOnly
				/>
			</FormField>

			{ isDelete && <LinkDelete onClick={ openModal }>{ removeLinkText }</LinkDelete> }

			{ isDelete && modal && <StackModalDelete data={ props } onClose={ closeModal } /> }
		</div>
	);
}
