import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './TableBodyRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function TableBodyRow(props: TProps) {
	const {
		children,
	} = props;

	return (
		<tr className={ styles.tableBodyRow }>
			{ children }
		</tr>
	);
}
