import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_STACK } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { TStack, clearStackLocaleCreate, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { STACK_COMMON_LOCALIZATION } from '../../localization';

import { StackLocaleCreateForm } from './components';
import { STACK_LOCALE_CREATE_LOCALIZATION } from './localization';


export function StackLocaleCreateWidget(props: TStack) {
	const dispatch = useAppDispatch();
	const { lang } = useCredentialsSelector();
	const { pathname, hash } = useLocation();
	const { breadCrumbList } = useLocalization(STACK_COMMON_LOCALIZATION);
	const { title, headline, breadCrumbNew } = useLocalization(STACK_LOCALE_CREATE_LOCALIZATION);
	const { name, alias } = props;

	useEffect(() => {
		return () => {
			dispatch(clearStackLocaleCreate());
		};
	}, []);

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_STACK, text: breadCrumbList },
		{ to: pathname, text: name },
		{ to: `${pathname}${hash}`, text: breadCrumbNew },
	];

	const initialItem = {
		locale: lang,
		description: '',
		updatedAt: '',
		createdAt: '',
	};

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<StackLocaleCreateForm { ...initialItem } alias={ alias } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
