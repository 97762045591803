import { LocalizationTypes } from 'src/common/types';

import { ACCOUNT_EMAIL_SEND_STAGE_RU_LOCALIZATION } from './ru.localization';
import { ACCOUNT_EMAIL_SEND_STAGE_EN_LOCALIZATION } from './en.localization';


export const ACCOUNT_EMAIL_SEND_STAGE_LOCALIZATION = {
	[LocalizationTypes.RU]: ACCOUNT_EMAIL_SEND_STAGE_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ACCOUNT_EMAIL_SEND_STAGE_EN_LOCALIZATION,
};
