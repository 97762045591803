import { FormButton, Icon, PageRow } from 'src/components';
import { TAccountEducation } from 'src/store';
import { formValidatePid, useInputFormField, useLocalization } from 'src/toolkit';

import { AccountCvEducationBox, AccountCvEducationSelectBox } from './components';
import { ACCOUNT_CV_EDUCATION_FIELD_LOCALIZATION } from './localization';

import styles from './AccountCvEducationField.module.scss';


type TProps = {
	isLoading?: boolean;
	items: TAccountEducation[];
	setItems: (value: TAccountEducation[]) => void;
	educationSource: TAccountEducation[];
};

export function AccountCvEducationField(props: TProps) {
	const { headline, button } = useLocalization(ACCOUNT_CV_EDUCATION_FIELD_LOCALIZATION);

	const educationField = useInputFormField({
		name: 'education',
		validation: value => formValidatePid(value),
	});

	const {
		isLoading,
		items,
		setItems,
		educationSource,
	} = props;

	const onClick = () => {
		const errors = [
			educationField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const value = educationField.value;

		const existedItem = items.find(item => item.pid === value);

		educationField.clear();

		if (existedItem) {
			return;
		}

		const item = educationSource.find(item => item.pid === value);
		if (!item) {
			return;
		}

		setItems([...items, item]);
	};


	return (
		<>
			<div className={ styles.headline }>{ headline }</div>

			<PageRow className={ styles.row }>
				<AccountCvEducationSelectBox { ...educationField } items={ educationSource } isLoading={ isLoading } />

				<FormButton
					onClick={ onClick }
					type="button"
					title={ button }
					disabled={ isLoading || !educationField.value }
					mini
				>
					<Icon icon="icon-plus" />
				</FormButton>
			</PageRow>

			<AccountCvEducationBox items={ items } setItems={ setItems } />
		</>
	);
}
