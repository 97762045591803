import { ReactNode } from 'react';

import { FormImage } from 'src/components/form/form-image';
import { useLocalization } from 'src/toolkit';
import { USER_IMAGE_FORM_LOCALIZATION } from './localization';


type TProps = {
	nickname?: string;
	src?: string;
	children?: ReactNode;
};

export function UserImageForm(props: TProps) {
	const localeText = useLocalization(USER_IMAGE_FORM_LOCALIZATION);

	return (
		<FormImage localeText={ localeText } />
	);
}
