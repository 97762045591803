import { Link } from 'src/components';
import { EmptyLayout } from 'src/layouts';

import styles from './ErrorWidget.module.scss';
import { useCredentialsSelector } from 'src/store';
import { PATH_SIGN_IN } from 'src/routes';
import { setTitle } from 'src/common/helpers';

type TErrorPageData = {
	message: string;
};

export type TErrorPage = {
	status?: number;
	text?: string;
	data?: TErrorPageData;
};

export function ErrorWidget(props: TErrorPage) {
	const { credentials } = useCredentialsSelector();

	const {
		status = 404,
		text = 'Not found',
		data = { message: 'Страница не найдена' },
	} = props;

	const bottom = [styles.fade, styles.bottom];
	let history = -1;
	let to = '';
	let textLink = 'Go Back';
	let message = data.message;

	if (status === 403) {
		message = 'Доступ запрещён';

		if (!credentials) {
			history = 0;
			to = PATH_SIGN_IN;
			textLink = 'Go to Login';
		}
	}

	setTitle(text);

	return (
		<EmptyLayout>
			<div className={ styles.errorWidget }>
				<div className={ styles.background }></div>

				<div className={ styles.content }>
					<h2 className={ styles.title }>
						<div className={ styles.topText }>{ status }</div>
						<div className={ styles.middleText }>{ text }</div>

						<Link
							className={ styles.bottomText }
							to={ to }
							history={ history }
						>{ textLink }</Link>
					</h2>

					<div className={ styles.errorText }>{ message }</div>
				</div>

				<div className={ bottom.join(' ') }></div>
			</div>
		</EmptyLayout>
	);
}
