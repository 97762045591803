import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Preloader } from 'src/components';
import { clearSignUpAction, signUpEmailConfirmApi, useAppDispatch, useSignUpSelector } from 'src/store';
import { isInvalidHashcode, useLocalization } from 'src/toolkit';
import { ErrorWidget } from 'src/pages/components';
import { setTitle } from 'src/common/helpers';
import { PATH_SIGN_IN } from 'src/routes';

import { AuthLayer, TAuthContent } from '../../components';
import { SIGN_UP_COMMON_LOCALIZATION } from '../sign-up/localization';

import { SignUpStageEmailConfirmedSuccess } from './components';


export function SignUpEmailConfirmPage() {
	const dispatch = useAppDispatch();
	const { isLoading, error } = useSignUpSelector();
	const { hashcode = '' } = useParams();
	const {
		title,
		...restProps
	} = useLocalization(SIGN_UP_COMMON_LOCALIZATION);

	const isInvalidHash = isInvalidHashcode(hashcode, 128);

	useEffect(() => {
		if (!isInvalidHash) {
			dispatch(signUpEmailConfirmApi(hashcode));
		}

		return () => {
			dispatch(clearSignUpAction());
		};
	}, []);

	if (isInvalidHash) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading) {
		return (
			<Preloader noLayout />
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	setTitle(title);

	const localeText = restProps as TAuthContent;

	return (
		<AuthLayer { ...localeText } contentLinkPath={ PATH_SIGN_IN }>
			<SignUpStageEmailConfirmedSuccess />
		</AuthLayer>
	);
}

