// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormRow_formRow__n64nh {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  position: relative;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-row/FormRow.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,kBAAA;AACD","sourcesContent":[".formRow {\n\tbox-sizing: border-box;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-start;\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRow": "FormRow_formRow__n64nh"
};
export default ___CSS_LOADER_EXPORT___;
