import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TFormFileExistedValue } from '../../FormFile';

import { FormFileListValueRow } from './components';

import styles from '../styles/FormFileListValue.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	files: TFormFileExistedValue[];
	onDelete: (value: TFormFileExistedValue) => void;
};

export function FormFileListValue(props: TProps) {
	const { onDelete, files = [] } = props;

	if (files.length <= 0) {
		return (
			<></>
		);
	}

	const classNames = [styles.list];

	return (
		<div className={ classNames.join(' ') }>
			{
				files.map(item => {
					return (
						<FormFileListValueRow key={ item.path } file={ item } onDelete={ onDelete }>{ item.name }</FormFileListValueRow>
					);
				})
			}
		</div>
	);
}
