// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvPreferredSalaryField_box__nxFj- {\n  margin-right: 0.1em;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/preferred-salary-field/AccountCvPreferredSalaryField.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,WAAA;AACD","sourcesContent":[".box {\n\tmargin-right: 0.1em;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvPreferredSalaryField_box__nxFj-"
};
export default ___CSS_LOADER_EXPORT___;
