import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, FormButtonLink, Icon, PageHeadline } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EXPERIENCE, PATH_ACCOUNT_EXPERIENCE_CREATE } from 'src/routes';
import { clearAccountExperiences, useAppDispatch } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION } from '../../localization';
import { AccountExperienceCreateWidget } from '../../widgets';

import { AccountExperienceList } from './components';
import { ACCOUNT_EXPERIENCE_LIST_LOCALIZATION } from './localization';

import styles from './AccountExperiencePage.module.scss';


export function AccountExperiencePage() {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { pathname, hash } = location;
	const { title, headline, addItemButton } = useLocalization(ACCOUNT_EXPERIENCE_LIST_LOCALIZATION);
	const { breadCrumbList } = useLocalization(ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearAccountExperiences());
		};
	}, []);

	const uri = `${pathname}${hash}`;
	if (uri === PATH_ACCOUNT_EXPERIENCE_CREATE) {
		return (
			<AccountExperienceCreateWidget />
		);
	}

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EXPERIENCE, text: breadCrumbList },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<div className={ styles.actionBox }>
					<FormButtonLink to={ PATH_ACCOUNT_EXPERIENCE_CREATE }>
						<Icon icon="icon-plus" />
						{ addItemButton }
					</FormButtonLink>
				</div>

				<AccountExperienceList />
			</div>
		</AccountLayout>
	);
}
