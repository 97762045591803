import { LocalizationTypes } from 'src/common/types';

import { PUBLIC_CVS_COMMON_RU_LOCALIZATION } from './ru.localization';
import { PUBLIC_CVS_COMMON_EN_LOCALIZATION } from './en.localization';


export const PUBLIC_CVS_COMMON_LOCALIZATION = {
	[LocalizationTypes.RU]: PUBLIC_CVS_COMMON_RU_LOCALIZATION,
	[LocalizationTypes.EN]: PUBLIC_CVS_COMMON_EN_LOCALIZATION,
};
