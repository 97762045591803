import { useState } from 'react';


type TErrors = string[];

export type TInputFileField = {
	required: boolean;
	name: string;
	value: string;
	setValue: (value: string) => void;
	errors: TErrors;
	setErrors: (value: TErrors) => void;
	clear: (value?: string) => void;
};

export function useInputFileField(name: string, defaultValue?: string, defaultRequired?: boolean, defaultErrors?: TErrors): TInputFileField {
	const defaultVal = defaultValue || '';
	const err = defaultErrors || [];
	const defaultReq = defaultRequired || false;

	const [required, setRequired] = useState(defaultReq);
	const [value, setValue] = useState(defaultVal);
	const [errors, setErrors] = useState(err as TErrors);

	const onChange = (value: string) => {
		setValue(value);

		if (!defaultRequired && value) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	};

	const onErrors = (err: TErrors) => {
		setErrors(err);
	};

	const clear = (value?: string) => {
		const clearValue = value ?? defaultVal;
		setValue(clearValue);

		if (!defaultRequired && clearValue) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	};

	return {
		required,
		name,
		value,
		setValue: onChange,
		errors,
		setErrors: onErrors,
		clear,
	};
}
