import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearAccountProjectLocaleUpdate, useAppDispatch, useAccountProjectLocaleReadSelector, accountProjectLocaleReadGetApi, clearAccountProjectLocaleRead, useAccountProjectsSelector, setAccountProjectLocaleRead, clearAccountProjectsLocalesCreatedItem } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidLocale, isInvalidNickname, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_PROJECTS } from 'src/routes';

import { ACCOUNT_PROJECTS_COMMON_LOCALIZATION } from '../../localization';

import { AccountProjectLocaleFormUpdate, AccountProjectsLocalesDetails } from './components';
import { ACCOUNT_PROJECTS_LOCALE_UPDATE_LOCALIZATION } from './localization';


export function AccountProjectsLocalesUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const { alias = '', locale = '' } = useParams();
	const { item: created } = useAccountProjectsSelector();
	const { data: item, isLoading, error } = useAccountProjectLocaleReadSelector();
	const { breadCrumbList } = useLocalization(ACCOUNT_PROJECTS_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(ACCOUNT_PROJECTS_LOCALE_UPDATE_LOCALIZATION);

	const isInvalidAlias = isInvalidNickname(alias);
	const isInvalidLang = isInvalidLocale(locale);
	const isInvalid = isInvalidAlias || isInvalidLang;

	useEffect(() => {
		const isExisted = created && created.locale === locale && created.alias === alias;
		const isNotRequest = isInvalid || isExisted;

		if (isExisted) {
			dispatch(setAccountProjectLocaleRead(created));
		}

		if (!isNotRequest) {
			dispatch(accountProjectLocaleReadGetApi({ alias, locale }));
		}

		return () => {
			dispatch(clearAccountProjectLocaleUpdate());
			dispatch(clearAccountProjectLocaleRead());
			dispatch(clearAccountProjectsLocalesCreatedItem());
		};
	}, []);

	if (isInvalid) {
		return (
			<ErrorWidget />
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	const { title } = item;

	setTitle(`${breadCrumbItem} ${title}`);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_PROJECTS, text: breadCrumbList },
		{ to: `${PATH_ACCOUNT_PROJECTS}/${alias}`, text: title },
		{ to: pathname, text: locale.toUpperCase() },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { title }</PageHeadline>

				<PageRow>
					<PageCol>
						<AccountProjectLocaleFormUpdate { ...item } alias={ alias } />
					</PageCol>

					<PageCol>
						<AccountProjectsLocalesDetails { ...item } alias={ alias } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
