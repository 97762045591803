import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PATH_ACCOUNT_EXPERIENCE } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { LocaleField } from 'src/pages/components';
import { TAccountExperience, accountExperienceLocaleUpdateApi, setAccountExperienceRead, useAppDispatch, useAccountExperienceLocaleUpdateSelector } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';

import { AccountExperienceDescriptionField, AccountExperienceCompanyCityField, AccountExperiencePositionNameField } from '../../../../components';
import { ACCOUNT_EXPERIENCE_LOCALE_UPDATE_LOCALIZATION } from '../../localization';


export function AccountExperienceLocaleFormUpdate(props: TAccountExperience) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { locale: localePath } = useParams();
	const { item, isLoading, error } = useAccountExperienceLocaleUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(ACCOUNT_EXPERIENCE_LOCALE_UPDATE_LOCALIZATION);

	const {
		locale = '',
		pid = '',
		companyCity = '',
		positionName = '',
		description = '',
	} = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const companyCityField = useInputFormField({
		name: 'company_city',
		defaultValue: companyCity,
		validation: value => formValidateText(value),
	});

	const positionNameField = useInputFormField({
		name: 'position_name',
		defaultValue: positionName,
		validation: value => formValidateText(value),
	});

	const descriptionField = useInputFormField({
		name: 'description',
		defaultValue: description,
		validation: value => formValidateText(value, { max: 512 }),
	});

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				localeField,
				companyCityField,
				positionNameField,
				descriptionField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setAccountExperienceRead(item));

			const { locale: changedLocale } = item;

			if (localePath !== changedLocale) {
				navigate(`${PATH_ACCOUNT_EXPERIENCE}/${pid}/${changedLocale}`);
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			localeField.validate(),
			companyCityField.validate(),
			positionNameField.validate(),
			descriptionField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			locale: localeField.value,
			companyCity: companyCityField.value,
			positionName: positionNameField.value,
			description: descriptionField.value,
		};

		const localeActual = item ? item.locale : locale;

		dispatch(accountExperienceLocaleUpdateApi({ data: dataRequest, pid, locale: localeActual }))
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading } />

			<AccountExperienceCompanyCityField { ...companyCityField } isLoading={ isLoading } />

			<AccountExperiencePositionNameField { ...positionNameField } isLoading={ isLoading } />

			<AccountExperienceDescriptionField { ...descriptionField } isLoading={ isLoading } />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
