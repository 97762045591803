// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountExperienceListRow_image__R9yAE {\n  max-height: 2em;\n}\n\n.AccountExperienceListRow_imageCol__t\\+j-w {\n  max-width: 5em;\n  min-width: 5em;\n  width: 5em;\n}\n\n.AccountExperienceListRow_dateCol__SY9RR {\n  max-width: 11em;\n  min-width: 11em;\n  width: 11em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-experience/views/list/components/table/components/row/AccountExperienceListRow.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;AACD;;AAEA;EACC,cAAA;EACA,cAAA;EACA,UAAA;AACD;;AAEA;EACC,eAAA;EACA,eAAA;EACA,WAAA;AACD","sourcesContent":[".image {\n\tmax-height: 2em;\n}\n\n.imageCol {\n\tmax-width: 5em;\n\tmin-width: 5em;\n\twidth: 5em;\n}\n\n.dateCol {\n\tmax-width: 11em;\n\tmin-width: 11em;\n\twidth: 11em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "AccountExperienceListRow_image__R9yAE",
	"imageCol": "AccountExperienceListRow_imageCol__t+j-w",
	"dateCol": "AccountExperienceListRow_dateCol__SY9RR"
};
export default ___CSS_LOADER_EXPORT___;
