import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { ModalClose } from './modal-close';

import styles from './Modal.module.scss';

type TProps = {
	children?: ReactNode;
	style?: Record<string, string>;
	onClose: () => void;
	parent?: Element;
};

export function Modal(props: TProps) {
	const {
		children,
		style = {},
		parent: parentElement,
		onClose,
	} = props;

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'visible';
		};
	}, []);

	const classNames = [styles.modal, styles.active];

	const elem = (
		<div className={ classNames.join(' ') } { ...style }>

			<div className={ styles.wrap }>
				<div className={ styles.content }>
				{ children }
				</div>

				<ModalClose
					onClick={ onClose }
				/>
			</div>
		</div>
	);

	const parent = parentElement || document.body;

	return createPortal(elem, parent);
}
