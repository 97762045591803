import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_EMAIL_CONFIRM } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountEmail, TAccountEmailResponse, TAccountEmailConfirmRequest, adaptAccountEmailFromResponse } from '../../types';


export const accountEmailUpdateConfirmApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_EMAIL_CONFIRM}`,
	(payload: TAccountEmailConfirmRequest, thunkApi) => {
		return HttpService.post<TAccountEmailResponse>(BACKEND_ACCOUNT_EMAIL_CONFIRM, payload).then(result => {
			return adaptAccountEmailFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountEmail | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEmailUpdateConfirmSlice = createSlice({
	name: 'accountEmailUpdateConfirmSlice',
	initialState,
	reducers: {
		clearAccountEmailUpdateConfirm(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEmailUpdateConfirmApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEmailUpdateConfirmApi.fulfilled.type]: (state, action: PayloadAction<TAccountEmail>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEmailUpdateConfirmApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountEmailUpdateConfirmSelector = () => useAppSelector(state => state[accountEmailUpdateConfirmSlice.name]);

export const { clearAccountEmailUpdateConfirm } = accountEmailUpdateConfirmSlice.actions;
