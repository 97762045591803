import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButton, FormNotice, Modal, ModalButtonWrap, ModalHeadline } from 'src/components';
import { PATH_ACCOUNT_EXPERIENCE } from 'src/routes';
import { TAccountExperience, clearAccountExperienceDelete, accountExperienceLocaleDeleteApi, useAppDispatch, useAccountExperienceDeleteSelector } from 'src/store';
import { formHandleServerError, useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_LOCALE_MODAL_DELETE_LOCALIZATION } from './localization';


type TProps = {
	data: TAccountExperience;
	onClose: () => void;
};

export function AccountExperienceLocaleModalDelete(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountExperienceDeleteSelector();
	const { data, onClose } = props;
	const [notice, setNotice] = useState('');
	const { questionText, buttonRemove, buttonCancel } = useLocalization(ACCOUNT_EXPERIENCE_LOCALE_MODAL_DELETE_LOCALIZATION);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice);
		}

		if (item) {
			const path = `${PATH_ACCOUNT_EXPERIENCE}/${pid}`;

			onClose();
			navigate(path);
		}

		return () => {
			dispatch(clearAccountExperienceDelete());
		};
	}, [error, item]);

	const { positionName, pid, locale } = data;

	const onClick = () => {
		dispatch(accountExperienceLocaleDeleteApi({ pid, locale }));
	};

	return (
		<Modal onClose={ onClose }>
			<ModalHeadline>{ questionText } «{ positionName }»?</ModalHeadline>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<ModalButtonWrap>
				<FormButton cancel className="first" type="button" busy={ isLoading } onClick={ onClick }>{ buttonRemove }</FormButton>
				<FormButton className="last" type="button" onClick={ onClose }>{ buttonCancel }</FormButton>
			</ModalButtonWrap>
		</Modal>
	);
}
