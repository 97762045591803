import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TUser, setUserCreatedItem, userCreateApi, useAppDispatch, useUserCreateSelector } from 'src/store';
import { formHandleServerError, formValidateNickname, formValidatePassword, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_USERS } from 'src/routes';

import { UsersNicknameField, UsersPasswordField } from '../../../../components';
import { USERS_CREATE_LOCALIZATION } from '../../localization';


export function UserFormCreate(props: TUser) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useUserCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(USERS_CREATE_LOCALIZATION);

	const { nickname = '', password = '' } = props;

	const nicknameField = useInputFormField({
		name: 'nickname',
		defaultValue: nickname,
		validation: value => formValidateNickname(value),
	});

	const passwordField = useInputFormField({
		name: 'password',
		defaultValue: password,
		validation: value => formValidatePassword(value),
	});

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice,
				nicknameField,
				passwordField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			dispatch(setUserCreatedItem(item));
			navigate(`${PATH_USERS}/${item.nickname}`);
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			nicknameField.validate(),
			passwordField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			nickname: nicknameField.value,
			password: passwordField.value,
		};

		dispatch(userCreateApi(dataRequest));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<UsersNicknameField { ...nicknameField } isLoading={ isLoading } />

			<UsersPasswordField { ...passwordField } isLoading={ isLoading } />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
