import { useEffect } from 'react';
import { PATH_SIGN_IN } from 'src/routes';
import { clearSignUpAction, useAppDispatch } from 'src/store';
import { setTitle } from 'src/common/helpers';
import { useLocalization } from 'src/toolkit';

import { AuthLayer, TAuthContent } from '../../components';

import { SIGN_UP_COMMON_LOCALIZATION } from './localization';
import { SignUpRouter } from './components';


export function SignUpPage() {
	const dispatch = useAppDispatch();
	const {
		title,
		...restProps
	} = useLocalization(SIGN_UP_COMMON_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearSignUpAction());
		};
	}, []);

	setTitle(title);

	const localeText = restProps as TAuthContent;

	return (
		<AuthLayer { ...localeText } contentLinkPath={ PATH_SIGN_IN }>
			<SignUpRouter />
		</AuthLayer>
	);
}

