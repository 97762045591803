export type TUserPhoneNumber = {
  phoneNumber: string;
  phoneNumberExpected: string;
  timeExpired: number;
	createdAt: string;
	updatedAt: string;
};

export type TUserPhoneNumberResponse = {
  phone_number: string;
  phone_number_expected: string;
  time_expected: number;
	created_at: string;
	updated_at: string;
};

export type TUserPhoneNumberPayloadUpdate = {
  nickname: string;
  data: TUserPhoneNumberBodyRequest;
};

export type TUserPhoneNumberBodyRequest = {
  phoneNumberExpected: string;
};

export type TUserPhoneNumberRequest = {
  phone_number_expected: string;
};

export function adaptUserPhoneNumberToRequest(data: TUserPhoneNumberBodyRequest): TUserPhoneNumberRequest {
	const { phoneNumberExpected } = data;

	return {
		phone_number_expected: phoneNumberExpected,
	};
}

export function adaptUserPhoneNumberFromResponse(data: TUserPhoneNumberResponse): TUserPhoneNumber {
	const {
		phone_number: phoneNumber,
		phone_number_expected: phoneNumberExpected,
		time_expected: timeExpired,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	return {
		phoneNumber: phoneNumber || '',
		phoneNumberExpected: phoneNumberExpected || '',
		timeExpired: timeExpired || 0,
		createdAt,
		updatedAt,
	};
}
