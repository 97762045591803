import { Icon } from 'src/components';
import { TAccountExperience } from 'src/store';
import { libFormatDate } from 'src/toolkit';

import styles from './AccountCvExperienceItem.module.scss';


type TProps = {
	item: TAccountExperience;
	onClick: (value: TAccountExperience) => void;
};

export function AccountCvExperienceItem(props: TProps) {
	const { item, onClick, ...restProps } = props;
	const { dateEnd, companyName } = item;

	const dateFormat = libFormatDate(dateEnd, 'yyyy');

	return (
		<div className={ styles.item } { ...restProps }>
			<span className={ styles.text }>{dateFormat} {companyName}</span>
			<Icon className={ styles.remove } onClick={ e => onClick(item) } icon="icon-cancel" />
		</div>
	);
}
