import { LocalizationTypes } from 'src/common/types';

import { RECOVERY_STAGE_SUCCESS_RU_LOCALIZATION } from './ru.localization';
import { RECOVERY_STAGE_SUCCESS_EN_LOCALIZATION } from './en.localization';


export const RECOVERY_STAGE_SUCCESS_LOCALIZATION = {
	[LocalizationTypes.RU]: RECOVERY_STAGE_SUCCESS_RU_LOCALIZATION,
	[LocalizationTypes.EN]: RECOVERY_STAGE_SUCCESS_EN_LOCALIZATION,
};
