export const LocalizationTypes = {
	EN: 'en',
	RU: 'ru',
	DE: 'de',
};

export type TListResponse<T> = {
	items: T[];
	count?: number;
};

export type TListSort = 'ASC' | 'DESC';

export type TListQuery = {
	[key: string]: string | number;
}
