export const CV_EXPERIENCE_COUNT_EN_LOCALIZATION = {
	headline: 'Number of work experience',
	none: 'None',
	year1: 'year',
	year2: 'years',
	year5: 'years',
	month1: 'month',
	month2: 'months',
	month5: 'months',
	day1: 'day',
	day2: 'days',
	day5: 'days',
};
