import { useEffect, useState } from 'react';

import { FormField, FormSelectBox } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_CV_STATUS_FIELD_LOCALIZATION } from './localization';

import styles from './AccountCvStatusField.module.scss';
import { TUserCvStatus } from 'src/store';


type TProps = TInputField & {
	isLoading?: boolean;
	items?: TUserCvStatus[];
};

type TOptionItem = {
	text: string;
	value: string;
};

function createOptionItem(item: TUserCvStatus): TOptionItem {
	const { alias, name } = item;

	return { text: name, value: alias };
}

export function AccountCvStatusField(props: TProps) {
	const {
		statusLegend,
		statusTitle,
	} = useLocalization(ACCOUNT_CV_STATUS_FIELD_LOCALIZATION);

	const [options, setOptions] = useState<TOptionItem[]>();

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		items = [],
	} = props;

	useEffect(() => {
		if (items.length > 0) {
			const arr = items.map(item => createOptionItem(item));

			setOptions(arr);
		}
	}, [items]);

	return (
		<FormField
			className={ styles.lowerSelectField }
			legend={ statusLegend }
			errors={ errors }
			required
		>
			<FormSelectBox
				title={ statusTitle }
				items={ options }
				onChange={ val => setValue(val) }
				value={ value }
				name={ name }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
