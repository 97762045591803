import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearStackUpdate, useAppDispatch, useStackReadSelector, stackReadGetApi, clearStackRead, useStacksSelector, setStackRead, clearStackCreatedItem, useCredentialsSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidNickname, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_STACK, PATH_STACK_CREATE_END, ROLES } from 'src/routes';

import { STACK_COMMON_LOCALIZATION } from '../../localization';

import { StackFormUpdate, StackDetails } from './components';
import { STACK_UPDATE_LOCALIZATION } from './localization';
import { StackLocaleCreateWidget } from '../../widgets';


export function StackUpdatePage() {
	const dispatch = useAppDispatch();
	const { roles } = useCredentialsSelector();
	const { pathname, hash } = useLocation();

	const { alias = '' } = useParams();
	const { item: created } = useStacksSelector();
	const { data: item, isLoading, error } = useStackReadSelector();
	const { breadCrumbList } = useLocalization(STACK_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(STACK_UPDATE_LOCALIZATION);

	const isInvalidAlias = isInvalidNickname(alias);

	useEffect(() => {
		const isExisted = created && created.alias === alias;
		const isNotRequest = isInvalidAlias || isExisted;

		if (isExisted) {
			dispatch(setStackRead(created));
		}

		if (!isNotRequest) {
			dispatch(stackReadGetApi(alias));
		}

		return () => {
			dispatch(clearStackUpdate());
			dispatch(clearStackRead());
			dispatch(clearStackCreatedItem());
		};
	}, []);

	if (isInvalidAlias) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const isCreate = roles.includes(ROLES.CREATE_STACKS);

	if (isCreate && hash === PATH_STACK_CREATE_END) {
		return (
			<StackLocaleCreateWidget { ...item } />
		);
	}

	const { name } = item;

	setTitle(`${breadCrumbItem} ${name}`);

	const breadCrumbs = [
		{ to: PATH_STACK, text: breadCrumbList },
		{ to: pathname, text: name },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { name }</PageHeadline>

				<PageRow>
					<PageCol>
						<StackFormUpdate { ...item } />
					</PageCol>

					<PageCol>
						<StackDetails { ...item } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
