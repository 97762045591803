import { useState } from 'react';

import { TAccountStack } from 'src/store';

import { StackDescription, StackItem } from './components';

import styles from './StackBox.module.scss';


type TProps = {
	items: TAccountStack[];
	setItems: (value: TAccountStack[]) => void;
};

export function StackBox(props: TProps) {
	const { items, setItems } = props;

	const [itemDetail, setItemDetail] = useState<TAccountStack>();

	const onFocus = (value: TAccountStack) => {
		setItemDetail(value);
	};

	const onBlur = () => {
		setItemDetail(undefined);
	};

	const onClick = (value: TAccountStack) => {
		const { alias } = value;
		const itemsFiltered = items.filter(item => item.alias !== alias);

		setItems(itemsFiltered);
	};

	return (
		<>
			<div className={ styles.box }>
				{
					items.map(item => {
						return (
							<StackItem
								key={ item.alias }
								item={ item }
								onFocus={ onFocus }
								onBlur={ onBlur }
								onClick={ onClick }
							/>
						);
					})
				}
			</div>

			{ itemDetail && <StackDescription item={ itemDetail }/> }
		</>
	);
}
