import styles from './IconText.module.scss';


type TProps = {
	path?: string;
	text?: string;
	classNames?: string[];
	className?: string;
	textClassNames?: string[];
	textClassName?: string;
};

export function IconText(props: TProps) {
	const {
		path = '',
		text = '',
		classNames: classNamesOuter = [],
		className,
		textClassNames: textClassNamesOuter = [],
		textClassName,
	} = props;

	const classNames = [styles.container, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	const textClassNames = [...textClassNamesOuter];
	if (textClassName) {
		textClassNames.push(textClassName);
	}

	return (
		<div className={ classNames.join(' ') }>
			<img className={ styles.image } src={ path } />
			<span className={ textClassNames.join(' ') }>{ text }</span>
		</div>
	);
}
