// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecoveryStage_recoveryPageStage__Hzw7x {\n  margin: 2em auto 0;\n  text-align: center;\n}\n\n.RecoveryStage_paragraph__YX9ds {\n  color: rgb(90, 90, 90);\n  margin: 0.5em auto;\n}\n\n.RecoveryStage_percepted__PS9Og {\n  color: rgb(0, 150, 50);\n}\n\n.RecoveryStage_button__zgDrB {\n  margin-top: 2em;\n}", "",{"version":3,"sources":["webpack://./src/pages/recovery/components/styles/RecoveryStage.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,kBAAA;AACD;;AAEA;EACC,sBAAA;EACA,kBAAA;AACD;;AAEA;EACC,sBAAA;AACD;;AAEA;EACC,eAAA;AACD","sourcesContent":[".recoveryPageStage {\n\tmargin: 2em auto 0;\n\ttext-align: center;\n}\n\n.paragraph {\n\tcolor: rgba(90, 90, 90, 1);\n\tmargin: 0.5em auto;\n}\n\n.percepted {\n\tcolor: rgba(0, 150, 50, 1);\n}\n\n.button {\n\tmargin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recoveryPageStage": "RecoveryStage_recoveryPageStage__Hzw7x",
	"paragraph": "RecoveryStage_paragraph__YX9ds",
	"percepted": "RecoveryStage_percepted__PS9Og",
	"button": "RecoveryStage_button__zgDrB"
};
export default ___CSS_LOADER_EXPORT___;
