export const EN_LOCALIZATION = {
	until: 'Until now',

	januaryParental: 'January',
	februaryParental: 'February',
	marchParental: 'March',
	aprilParental: 'April',
	mayParental: 'May',
	juneParental: 'June',
	julyParental: 'July',
	augustParental: 'August',
	septemberParental: 'September',
	octoberParental: 'October',
	novemberParental: 'November',
	decemberParental: 'December',

	januaryNominative: 'January',
	februaryNominative: 'February',
	marchNominative: 'March',
	aprilNominative: 'April',
	mayNominative: 'May',
	juneNominative: 'June',
	julyNominative: 'July',
	augustNominative: 'August',
	septemberNominative: 'September',
	octoberNominative: 'October',
	novemberNominative: 'November',
	decemberNominative: 'December',
};
