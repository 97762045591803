import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';

import styles from './FormInputFile.module.scss';


type TProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const FormInputFile = forwardRef<HTMLInputElement, TProps>((props, ref) => {
	return (
		<input
			type="file"
			className={ styles.formInputFile }
			ref={ ref }
			{ ...props }
		/>
	);
});
