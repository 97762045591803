import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './PageHeadline.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	margin?: boolean;
};

export function PageHeadline(props: TProps) {
	const {
		className = '',
		children,
		margin = false,
		...restFields
	} = props;
	const classNames = [styles.pageHeadline];

	if (styles.className) {
		classNames.push(styles[className]);
	}

	if (margin) {
		classNames.push(styles.margin);
	}

	return (
		<div className={ classNames.join(' ') } { ...restFields }>
			{ children }
		</div>
	);
}
