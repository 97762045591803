export type TUserRole = {
	alias: string;
  title: string;
  description: string;
	value: boolean;
};

export type TUserRolesList = {
  items: TUserRole[];
	count: number;
};

export type TUserRoleRequest = {
  alias: string;
	value: boolean;
};

export type TUserRolesParams = {
  nickname: string;
  locale: string;
};

export type TUserRolesPayloadUpdate = TUserRolesParams & {
  items: TUserRoleRequest[];
};

export function adaptUserRolesListFromResponse(data: TUserRolesList): TUserRolesList {
	const json = JSON.stringify(data);

	return JSON.parse(json);
}

export function adaptUserRoleToRequest(data: TUserRole): TUserRoleRequest {
	const { alias, value } = data;

	return {
		alias,
		value,
	};
}
