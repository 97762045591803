// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableHeadSortWrap_tableHeadSortWrap__boB7C {\n  color: rgb(150, 150, 150);\n  cursor: pointer;\n  -webkit-user-select: none;\n          user-select: none;\n  transition: color 0.1s ease-in-out;\n}\n.TableHeadSortWrap_tableHeadSortWrap__boB7C:active, .TableHeadSortWrap_tableHeadSortWrap__boB7C:hover {\n  color: rgb(90, 90, 90);\n}\n.TableHeadSortWrap_tableHeadSortWrap__boB7C:active {\n  transition-duration: 0s;\n}\n.TableHeadSortWrap_tableHeadSortWrap__boB7C::after {\n  font-family: FontIcons;\n  font-size: 1em;\n  content: \"\\eee0\";\n  padding-left: 0.25em;\n}\n\n.TableHeadSortWrap_asc__Pa4wL::after {\n  content: \"\\eee1\";\n}\n\n.TableHeadSortWrap_desc__2eTRs::after {\n  content: \"\\eee2\";\n}", "",{"version":3,"sources":["webpack://./src/components/table/thead/sort-wrap/TableHeadSortWrap.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,eAAA;EAEA,yBAAA;UAAA,iBAAA;EAEA,kCAAA;AADD;AAGC;EAEC,sBAAA;AAFF;AAKC;EACC,uBAAA;AAHF;AAMC;EACC,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,oBAAA;AAJF;;AAQA;EACC,gBAAA;AALD;;AAQA;EACC,gBAAA;AALD","sourcesContent":[".tableHeadSortWrap {\n\tcolor: rgba(150, 150, 150, 1);\n\tcursor: pointer;\n\n\tuser-select: none;\n\n\ttransition: color 0.1s ease-in-out;\n\n\t&:active,\n\t&:hover {\n\t\tcolor: rgba(90, 90, 90, 1);\n\t}\n\n\t&:active {\n\t\ttransition-duration: 0s;\n\t}\n\n\t&::after {\n\t\tfont-family: FontIcons;\n\t\tfont-size: 1em;\n\t\tcontent: \"\\eee0\";\n\t\tpadding-left: 0.25em;\n\t}\n}\n\n.asc::after {\n\tcontent: \"\\eee1\";\n}\n\n.desc::after {\n\tcontent: \"\\eee2\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeadSortWrap": "TableHeadSortWrap_tableHeadSortWrap__boB7C",
	"asc": "TableHeadSortWrap_asc__Pa4wL",
	"desc": "TableHeadSortWrap_desc__2eTRs"
};
export default ___CSS_LOADER_EXPORT___;
