// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterRow_row__LZ-OY {\n  box-sizing: border-box;\n  display: flex;\n  justify-content: space-between;\n  margin: 1em auto;\n}\n\n@media screen and (max-width: 480px) {\n  .FooterRow_row__LZ-OY {\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/footer/footer-row/FooterRow.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;AACD;;AAEA;EACC;IACC,sBAAA;IACA,2BAAA;IACA,mBAAA;EACA;AACF","sourcesContent":[".row {\n\tbox-sizing: border-box;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin: 1em auto;\n}\n\n@media screen and (max-width: 480px) {\n\t.row {\n\t\tflex-direction: column;\n\t\tjustify-content: flex-start;\n\t\talign-items: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "FooterRow_row__LZ-OY"
};
export default ___CSS_LOADER_EXPORT___;
