import { TAccountStack } from 'src/store';

import styles from './AccountProjectStackOption.module.scss';


export function AccountProjectStackOption(props: TAccountStack) {
	const { alias, name, image } = props;

	return (
		<>
			<img className={ styles.image } src={ image } alt={ alias } />

			<span>{ name }</span>
		</>
	);
}
