// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UsersListRow_image__BNRnb {\n  max-height: 2em;\n}\n\n.UsersListRow_imageCol__kEmmS {\n  max-width: 5em;\n  min-width: 5em;\n  width: 5em;\n}\n\n.UsersListRow_dateCol__6pJzl {\n  max-width: 11em;\n  min-width: 11em;\n  width: 11em;\n}", "",{"version":3,"sources":["webpack://./src/pages/users/views/list/components/table/components/row/UsersListRow.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;AACD;;AAEA;EACC,cAAA;EACA,cAAA;EACA,UAAA;AACD;;AAEA;EACC,eAAA;EACA,eAAA;EACA,WAAA;AACD","sourcesContent":[".image {\n\tmax-height: 2em;\n}\n\n.imageCol {\n\tmax-width: 5em;\n\tmin-width: 5em;\n\twidth: 5em;\n}\n\n.dateCol {\n\tmax-width: 11em;\n\tmin-width: 11em;\n\twidth: 11em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "UsersListRow_image__BNRnb",
	"imageCol": "UsersListRow_imageCol__kEmmS",
	"dateCol": "UsersListRow_dateCol__6pJzl"
};
export default ___CSS_LOADER_EXPORT___;
