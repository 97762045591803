import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_DEFAULT_PROJECT, PATH_STATIC_PROJECTS_IMAGES } from 'src/routes';
import { TAccountStack, TAccountStackResponse, adaptAccountStackFromResponse } from './stack.type';

export const PublicProjectSortCol = {
	ALIAS: 'alias',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TPublicProjectSortCol = TTableSortCols<typeof PublicProjectSortCol>;

export type TPublicProjectQuery = {
	locale: string;
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TPublicProjectSortCol;
};

export type TPublicProjectQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TPublicProjectFile = {
	name: string;
	path: string;
};

export type TPublicProject = TPublicProjectLocale & {
	alias: string;
  image: string;
  uri: string;
  repositoryUri: string;
  files: TPublicProjectFile[];
  stack: TAccountStack[];
  locales: string[];
};

export type TPublicProjectLocale = {
  locale: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type TPublicProjectLocaleRequest = Omit<TPublicProjectLocale, 'createdAt' | 'updatedAt'>;

export type TPublicProjectLocaleCreate = Omit<TPublicProjectLocaleUpdate, 'locale'>;

export type TPublicProjectLocaleBodyRequest = TPublicProjectLocaleRequest;

export type TPublicProjectFileRequest = Array<File | string>;

export type TPublicProjectBodyRequest = Omit<TPublicProject, 'title' | 'description' | 'stack' | 'files' | 'image' | 'locale' | 'locales' | 'createdAt' | 'updatedAt'> & {
	stack?: string[];
	files?: TPublicProjectFileRequest;
	image?: File | string;
};

export type TPublicProjectResponse = {
  locale: string;
  alias: string;
  title: string;
  description: string;
  image: string;
  uri: string;
  repository_uri: string;
  files: TPublicProjectFile[];
  stack: TAccountStackResponse[];
  locales: string[];
  created_at: string;
  updated_at: string;
};

export type TPublicProjectLocaleParam = {
  alias: string;
  locale: string;
};

export type TPublicProjectRequest = Omit<TPublicProjectResponse, 'title' | 'description' | 'stack' | 'files' | 'image' | 'locale' | 'locales' | 'created_at' | 'updated_at'> & {
	stack?: string[];
	files?: TPublicProjectFileRequest;
	image?: File | string;
};

export type TPublicProjectListResponse = TListResponse<TPublicProjectResponse>;

export type TPublicProjectList = TListResponse<TPublicProject>;

export type TPublicProjectPayloadCreate = {
	data: TPublicProjectBodyRequest;
	stack: string[];
	files: TPublicProjectFileRequest;
};

export type TPublicProjectPayloadUpdate = TPublicProjectPayloadCreate & {
	alias: string;
};

export type TPublicProjectLocaleUpdate = TPublicProjectLocaleParam & {
	data: TPublicProjectLocaleBodyRequest;
};

export function adaptPublicProjectFromListResponse(data: TPublicProjectListResponse): TPublicProjectList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptPublicProjectFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptPublicProjectFromResponse(data: TPublicProjectResponse): TPublicProject {
	const {
		locale,
		alias,
		title,
		description,
		image: imageRaw = '',
		uri = '',
		repository_uri: repositoryUri = '',
		files = [],
		locales = [],
		created_at: createdAt,
		updated_at: updatedAt,
		stack: stackRaw = [],
	} = data;

	const image = imageRaw ? `${PATH_STATIC_PROJECTS_IMAGES}/${imageRaw}` : PATH_DEFAULT_PROJECT;
	const stack = stackRaw.map(item => adaptAccountStackFromResponse(item));

	return {
		locale,
		alias,
		title,
		description,
		image,
		uri,
		repositoryUri,
		files,
		locales,
		createdAt,
		updatedAt,
		stack,
	};
}

export function adaptPublicProjectToRequest(data: TPublicProjectBodyRequest): TPublicProjectRequest {
	const {
		alias,
		image,
		uri,
		repositoryUri,
	} = data;

	return {
		alias,
		image,
		uri,
		repository_uri: repositoryUri,
	};
}

export function adaptPublicProjectLocaleToRequest(data: TPublicProjectLocaleBodyRequest): TPublicProjectLocaleRequest {
	const {
		locale,
		title,
		description,
	} = data;

	return {
		locale,
		title,
		description,
	};
}
