import { InputHTMLAttributes, ReactNode } from 'react';

import styles from './FormCheckboxInfo.module.scss';


type TProps = InputHTMLAttributes<HTMLInputElement> & {
	classNameBox?: string;
	nodeBefore?: ReactNode;
};

export function FormCheckboxInfo(props: TProps) {
	const {
		id,
		classNameBox = '',
		className = '',
		children,
		nodeBefore,
		...restProps
	} = props;

	const classNamesBox = [styles.row];
	if (classNameBox) {
		classNamesBox.push(classNameBox);
	}

	const classNames = [styles.formCheckbox];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ styles.box }>
			{ nodeBefore }

			<div className={ classNamesBox.join(' ') }>
				<input
					id={ id }
					className={ classNames.join(' ') }
					{ ...restProps }
					type="checkbox"
					required={ false }
				/>
				<label htmlFor={ id }>{ children }</label>
			</div>
		</div>
	);
}
