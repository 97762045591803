// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountExperienceProjectsField_headline__g0k31 {\n  font-weight: bold;\n  margin: 2em auto 0;\n}\n\n.AccountExperienceProjectsField_box__HyhKE {\n  margin-left: 0.1em;\n  margin-bottom: 0;\n  width: 100%;\n  max-width: 100%;\n}\n\n.AccountExperienceProjectsField_row__sGuww {\n  align-items: flex-end;\n  position: relative;\n  z-index: 8;\n}\n\n.AccountExperienceProjectsField_selectBox__oADec {\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-experience/components/projects-field/AccountExperienceProjectsField.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,kBAAA;AACD;;AAEA;EACC,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AACD;;AAEA;EACC,qBAAA;EACA,kBAAA;EACA,UAAA;AACD;;AAEA;EACC,eAAA;AACD","sourcesContent":[".headline {\n\tfont-weight: bold;\n\tmargin: 2em auto 0;\n}\n\n.box {\n\tmargin-left: 0.1em;\n\tmargin-bottom: 0;\n\twidth: 100%;\n\tmax-width: 100%;\n}\n\n.row {\n\talign-items: flex-end;\n\tposition: relative;\n\tz-index: 8;\n}\n\n.selectBox {\n\tmax-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": "AccountExperienceProjectsField_headline__g0k31",
	"box": "AccountExperienceProjectsField_box__HyhKE",
	"row": "AccountExperienceProjectsField_row__sGuww",
	"selectBox": "AccountExperienceProjectsField_selectBox__oADec"
};
export default ___CSS_LOADER_EXPORT___;
