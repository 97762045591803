export const SIGN_IN_FORM_EN_LOCALIZATION = {
	loginLegend: 'Login',
	loginPlaceholder: 'Login',
	loginTitle: 'Enter your Nickname, E-Mail or Phone number',

	passwordLegend: 'Password',
	passwordTitle: 'Enter your password',

	recoveryText: 'Forgot your password?',

	buttonText: 'Login',
};
