import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_COMPANY_CITY_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountExperienceCompanyCityField(props: TProps) {
	const {
		companyCityLegend,
		companyCityPlaceholder,
		companyCityTitle,
	} = useLocalization(ACCOUNT_EXPERIENCE_COMPANY_CITY_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ companyCityLegend }
			errors={ errors }
			required
		>
			<FormInput
				placeholder={ companyCityPlaceholder }
				name={ name }
				title={ companyCityTitle }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
