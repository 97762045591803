import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountCvSortCol, TAccountCv, TAccountCvList, TAccountCvListResponse, TAccountCvQuery, TAccountCvQueryRequest, TAccountCvSortCol, adaptAccountCvFromListResponse } from '../../types';
import { BACKEND_ACCOUNT_CVS } from '../../backend-paths';
import { LocalizationTypes } from 'src/common/types';


export const accountCvsGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_CVS,
	(payload: TAccountCvQuery | undefined, thunkApi) => {
		const params = payload ? toAccountCvsQueryRequest(payload) : {};

		return HttpService.get<TAccountCvListResponse>(BACKEND_ACCOUNT_CVS, { params }).then(result => {
			return adaptAccountCvFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toAccountCvsQueryRequest(data: TAccountCvQuery): TAccountCvQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	item?: TAccountCv,
	itemLocale?: TAccountCv,
	isLoading: boolean,
	list: TAccountCvList,
	query: TAccountCvQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	itemLocale: undefined,
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		locale: LocalizationTypes.EN,
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: AccountCvSortCol.UPDATED_AT,
	},
	error: undefined,
};

export const accountCvsSlice = createSlice({
	name: 'accountCvsSlice',
	initialState,
	reducers: {
		setAccountCvsCreatedItem(state, action: PayloadAction<TAccountCv>) {
			state.item = action.payload;
		},

		setAccountCvsLocalesCreatedItem(state, action: PayloadAction<TAccountCv>) {
			state.itemLocale = action.payload;
		},

		setAccountCvsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountCvsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountCvsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountCvsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountCvsQuerySortCol(state, action: PayloadAction<TAccountCvSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountCvs(state) {
			return { ...initialState, list: state.list, query: state.query };
		},

		clearAccountCvsCreatedItem(state) {
			state.item = undefined;
		},

		clearAccountCvsLocalesCreatedItem(state) {
			state.itemLocale = undefined;
		},
	},

	extraReducers: {
		[accountCvsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCvsGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountCvList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountCvsGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountCvsSelector = () => useAppSelector(state => state[accountCvsSlice.name]);

export const { clearAccountCvs, setAccountCvsQueryLimit, setAccountCvsQueryOffset, setAccountCvsQuerySort, setAccountCvsQuerySortCol, setAccountCvsQuerySearch, setAccountCvsCreatedItem, setAccountCvsLocalesCreatedItem, clearAccountCvsCreatedItem, clearAccountCvsLocalesCreatedItem } = accountCvsSlice.actions;
