import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice } from 'src/components';
import { formHandleServerError, formValidateCode, formValidateEmail, libClearIntstr, useInputFormField, useLocalization } from 'src/toolkit';
import { TAccountEmail, accountEmailUpdateConfirmApi, clearAccountEmailUpdateConfirm, setAccountEmailNoticeUpdate, setAccountEmailRead, useAccountEmailUpdateConfirmSelector, useAppDispatch } from 'src/store';

import { AccountEmailField } from '../../../../../../components';
import { ACCOUNT_EMAIL_ROUTER_LOCALIZATION } from '../../localization';

import { AccountEmailCodeTimer } from './components';
import { ACCOUNT_EMAIL_CONFIRM_STAGE_LOCALIZATION } from './localization';

import styles from './AccountEmailConfirmStage.module.scss';


type TProps = {
	item: TAccountEmail;
};

export function AccountEmailConfirmStage(props: TProps) {
	const dispatch = useAppDispatch();
	const { item: itemUpdated, isLoading, error } = useAccountEmailUpdateConfirmSelector();
	const [notice, setNotice] = useState('');
	const { item } = props;

	const { unconfirmedLegend, unconfirmedTitle, errorAlreadyConfirmed } = useLocalization(ACCOUNT_EMAIL_ROUTER_LOCALIZATION);
	const {
		textLeftHalf,
		textRightHalf,
		successNotice,
		button,
	} = useLocalization(ACCOUNT_EMAIL_CONFIRM_STAGE_LOCALIZATION);

	const {
		email,
		emailExpected,
	} = item;

	const emailExpectedField = useInputFormField({
		name: 'EMAIL_expected',
		defaultValue: emailExpected,
		validation: formValidateEmail,
	});

	const codeField = useInputFormField({
		name: 'code',
		validation: formValidateCode,
	});

	useEffect(() => {
		return () => {
			dispatch(clearAccountEmailUpdateConfirm());
		};
	}, []);

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice,
				codeField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (itemUpdated) {
			setNotice('');
			dispatch(setAccountEmailNoticeUpdate(successNotice))
			dispatch(setAccountEmailRead(itemUpdated))
		}
	}, [itemUpdated]);

	const submit = () => {
		const errors = [
			codeField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const currentValue = libClearIntstr(email);
		const value = libClearIntstr(emailExpectedField.value);

		if (value === currentValue) {
			emailExpectedField.setErrors([errorAlreadyConfirmed]);

			return;
		}

		const dataRequest = {
			hashcode: codeField.value,
		};

		dispatch(accountEmailUpdateConfirmApi(dataRequest));
	};

	return (
		<Form className={ styles.form } onSubmit={ submit }>
			<AccountEmailField
				{ ...emailExpectedField }
				legend={ unconfirmedLegend }
				title={ unconfirmedTitle }
				isLoading
				unconfirmed
			/>

			<p className={ styles.text }>{ textLeftHalf } <span className={ styles.percepted }>{ emailExpected }</span> { textRightHalf }:</p>

			<AccountEmailCodeTimer item={ item } />

			<FormNotice error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
