import { TListResponse } from 'src/common/types';


export const DefaultUserCvStatus = { alias: 'inactive', name: 'Inactive' };

export type TUserCvStatusResponse = {
	alias: string;
	name?: string;
};

export type TUserCvStatus = {
	alias: string;
	name: string;
};

export type TUserCvStatusListResponse = TListResponse<TUserCvStatus>;

export function adaptUserCvStatusFromResponse(data: TUserCvStatusResponse): TUserCvStatus {
	const { name = '', alias } = data;

	return { name, alias };
}

export function adaptUserCvStatussFromListResponse(data: TUserCvStatusListResponse): TUserCvStatus[] {
	const { items } = data;

	return items.map(item => adaptUserCvStatusFromResponse(item));
}
