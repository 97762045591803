import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageRow, PageCol, PageHeadline } from 'src/components';
import { useAppDispatch, useCredentialsSelector, publicCvReadGetApi, usePublicCvReadSelector } from 'src/store';
import { ErrorWidget, PerceptedBlock, TextBlock } from 'src/pages/components';
import { isInvalidPid, isInvalidNickname, useLocalization, isInvalidLocale } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_PUBLIC_CV } from 'src/routes';

import { PublicCvContacts, PublicCvData, PublicCvImage, PublicCvPreferredSalary, PublicCvUpdatedAt, PublicCvExperience, PublicCvEducation, PublicCvVersions, PublicCvLanguages } from '../../components';
import { PUBLIC_CVS_COMMON_LOCALIZATION } from '../../localization';

import styles from './PublicCvReadPage.module.scss';
import { PUBLIC_CV_LOCALIZATION } from './localization';


export function PublicCvReadPage() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const { credentials } = useCredentialsSelector();
	const { nickname: nicknamePath = '', alias: aliasPath = '', locale = '', pid } = useParams();
	const { data: item, isLoading, error } = usePublicCvReadSelector();
	const { noticeForNoAuth } = useLocalization(PUBLIC_CV_LOCALIZATION);
	const { breadCrumbList } = useLocalization(PUBLIC_CVS_COMMON_LOCALIZATION);
	const [cvLang, setCvLang] = useState(locale);

	const isInvalidAlias = isInvalidNickname(nicknamePath) || isInvalidNickname(aliasPath);
	const isInvalidId = isInvalidPid(pid);

	useEffect(() => {
		if (!isInvalidAlias) {
			dispatch(publicCvReadGetApi({
				nickname: nicknamePath,
				alias: aliasPath,
				locale: cvLang,
				pid,
			}));
		}
	}, [cvLang]);

	useEffect(() => {
		const isInvalidLang = isInvalidLocale(locale);

		if (!isInvalidLang) {
			setCvLang(locale);
		}
	}, [locale]);

	if (isInvalidAlias) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error || !item) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const {
		alias,
		title: positionName,
		description = '',
		image = '',
		phoneNumber = '',
		email = '',
		telegram = '',
		linkedin = '',
		headhunter = '',
		birthday,
		preferredSalary,
		currency,
		countExperience,
		firstName = '',
		lastName = '',
		nickname,
		experience,
		education,
		languages,
		locales,
		sex,
		updatedAt,
	} = item;

	const title = positionName || alias;

	setTitle(`${title}`);

	const breadCrumbs = [
		{ to: PATH_PUBLIC_CV, text: breadCrumbList },
		{ to: pathname, text: title },
	];

	const isHaveContacts = phoneNumber || email || telegram || linkedin || headhunter;

	const pathStart = `${PATH_PUBLIC_CV}/${nicknamePath}/${aliasPath}`;
	const pathEnd = pid ? `/${pid}` : '';

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				{ isInvalidId && !credentials && <PerceptedBlock className={ styles.noticeBlock } info>{ noticeForNoAuth }</PerceptedBlock> }

				<PageRow className={ styles.row }>
					<PageRow className={ styles.dataRow }>
						<PublicCvData
							birthday={ birthday }
							firstName={ firstName }
							lastName={ lastName }
							sex={ sex }
						/>

						{ isHaveContacts &&
							<PublicCvContacts
								className={ styles.contacts }
								phoneNumber={ phoneNumber }
								email={ email }
								telegram={ telegram }
								linkedin={ linkedin }
								headhunter={ headhunter }
							/>
						}

						{ locales.length > 1 && <PublicCvVersions pathStart={ pathStart } pathEnd={ pathEnd } locales={ locales }/>}

					</PageRow>

					<PageCol className={ styles.imageBox }>
						<PublicCvUpdatedAt date={ updatedAt } />

						{ isHaveContacts && <PublicCvImage src={ image } alt={ nickname } /> }
					</PageCol>
				</PageRow>

				<PageCol className={ styles.section }>
					<PageHeadline>{ title }</PageHeadline>

					<PublicCvPreferredSalary value={ preferredSalary } currency={ currency }/>
					<TextBlock>{ description }</TextBlock>
				</PageCol>

				<PublicCvExperience className={ styles.section } items={ experience } countExperience={ countExperience } />

				<PublicCvEducation className={ styles.section } items={ education } />

				<PublicCvLanguages className={ styles.section } items={ languages } />
			</div>
		</AccountLayout>
	);
}
