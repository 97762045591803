import { useEffect, useState } from 'react';

import { FormButtonWrap, FormButton, FormNotice, FormBox, FormInput, FormField } from 'src/components';
import { TUserEmail, setUserEmailRead, useAppDispatch, useUserEmailUpdateSelector, userEmailUpdateApi } from 'src/store';
import { formHandleServerError, formValidateEmail, isInvalidNickname, isInvalidEmail, useInputFormField, useLocalization } from 'src/toolkit';

import { USERS_EMAIL_UPDATE_LOCALIZATION } from './localization';


type TProps = TUserEmail & {
	nickname: string;
};

export function UserEmailForm(props: TProps) {
	const dispatch = useAppDispatch();
	const { item, isLoading, error } = useUserEmailUpdateSelector();
	const [notice, setNotice] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const { legend, title, button, noticeSuccess } = useLocalization(USERS_EMAIL_UPDATE_LOCALIZATION);

	const { email, nickname } = props;

	const emailField = useInputFormField({
		name: 'email_expected',
		defaultValue: email,
		validation: value => formValidateEmail(value),
	});

	useEffect(() => {
		const isInvalid = isInvalidEmail(emailField.value);

		setIsDisabled(isInvalid);
	}, [emailField.value]);

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				emailField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setUserEmailRead(item));
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			emailField.validate(),
			isInvalidNickname(nickname),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			emailExpected: emailField.value,
		};

		dispatch(userEmailUpdateApi({ data: dataRequest, nickname }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<FormField
				legend={ legend }
				errors={ emailField.errors }
				required={ emailField.required }
				confirmed
			>
				<FormInput
					placeholder="—"
					title={ title }
					name={ emailField.name }
					value={ emailField.value }
					onChange={ e => emailField.setValue(e.target.value) }
					disabled={ isLoading }
					autoComplete="off"
				/>
			</FormField>

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading } disabled={ isDisabled }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
