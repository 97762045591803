import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { RecoveryPageStageState, clearRecoveryAction, useAppDispatch, useRecoverySelector } from 'src/store';
import { isInvalidHashcode } from 'src/toolkit';
import { ErrorWidget } from 'src/pages/components';

import { RecoveryLayer, RecoveryStageFormEmail, RecoveryStageSuccess } from '../../components';


export function RecoveryEmailConfirmPage() {
	const dispatch = useAppDispatch();
	const { hashcode = '' } = useParams();
	const { stage } = useRecoverySelector();

	const isInvalidHash = isInvalidHashcode(hashcode, 128);

	useEffect(() => {
		return () => {
			dispatch(clearRecoveryAction());
		};
	}, []);

	if (isInvalidHash) {
		return (
			<ErrorWidget />
		);
	}

	if (stage === RecoveryPageStageState.SUCCESS) {
		return (
			<RecoveryLayer>
				<RecoveryStageSuccess />
			</RecoveryLayer>
		);
	}

	return (
		<RecoveryLayer>
			<RecoveryStageFormEmail hashcode={ hashcode } />
		</RecoveryLayer>
	);
}
