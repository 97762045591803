import { RecoveryPageStageState, useRecoverySelector } from 'src/store';

import { RecoveryStageFormEmail } from '../../../../components';

import { RecoveryFormMain, RecoveryStageFormPhoneNumber } from './components';


export function RecoveryRouter() {
	const { stage, hashcode } = useRecoverySelector();

	if (hashcode) {
		return (
			<RecoveryStageFormEmail hashcode={ hashcode } />
		);
	}

	if (stage === RecoveryPageStageState.PHONE_NUMBER_CONFIRM) {
		return (
			<RecoveryStageFormPhoneNumber />
		);
	}

	return (
		<RecoveryFormMain />
	);
}

