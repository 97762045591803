import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { BreadCrumbs, PageHeadline, Preloader } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { AccountLayout } from 'src/layouts';
import { PATH_USERS } from 'src/routes';
import { clearUserRolesRead, useAppDispatch, useCredentialsSelector, useUserRolesReadSelector, userRolesReadGetApi } from 'src/store';
import { isInvalidNickname, useLocalization } from 'src/toolkit';
import { ErrorWidget } from 'src/pages/components';

import { USERS_COMMON_LOCALIZATION } from '../../localization';

import { UserRolesForm } from './components';
import { USERS_ROLES_LOCALIZATION } from './localization';


export function UserRolesPage() {
	const dispatch = useAppDispatch();
	const { nickname: nicknameParam = '' } = useParams();
	const { lang } = useCredentialsSelector();
	const { items: roles, isLoading, error } = useUserRolesReadSelector();
	const { breadCrumbList } = useLocalization(USERS_COMMON_LOCALIZATION);
	const {
		title,
		headline,
		breadCrumbItem,
	} = useLocalization(USERS_ROLES_LOCALIZATION);

	const isInvalidNick = isInvalidNickname(nicknameParam);

	useEffect(() => {
		if (!isInvalidNick) {
			dispatch(userRolesReadGetApi({ nickname: nicknameParam, locale: lang }));
		}

		return () => {
			dispatch(clearUserRolesRead());
		};
	}, [lang]);

	if (isInvalidNick) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || !roles) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const items = [
		{ to: PATH_USERS, text: breadCrumbList },
		{ to: `${PATH_USERS}/${nicknameParam}`, text: nicknameParam },
		{ to: `${PATH_USERS}/${nicknameParam}/roles`, text: breadCrumbItem },
	];

	setTitle(title);

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ items } />

				<PageHeadline>{ headline }</PageHeadline>

				<UserRolesForm items={ roles } nickname={ nicknameParam } />
			</div>
		</AccountLayout>
	);
}
