import { useParams } from 'react-router-dom';

import { LocaleBoxItem } from './components';

import styles from './LocaleBox.module.scss';
import { useLocalization } from 'src/toolkit';
import { LOCALE_BOX_LOCALIZATION } from './localization';


type TProps = {
	className?: string;

	items: string[];
	pathStart: string;
	pathEnd?: string;
	disabled?: boolean;
};

export function LocaleBox(props: TProps) {
	const { language } = useParams();
	const { emptyText } = useLocalization(LOCALE_BOX_LOCALIZATION);
	const {
		items = [],
		pathStart,
		pathEnd = '',
		className = '',
		disabled = false,
	} = props;

	const classNames = [styles.box];
	if (className) {
		classNames.push(className);
	}

	if (items.length === 0) {
		classNames.push(styles.emptyBox);

		return (
			<div className={ classNames.join(' ') }>
				{ emptyText }
			</div>
		);
	}

	return (
		<div className={ classNames.join(' ') }>
			{
				items.map(item => {
					const isDisabled = language === item;

					return (
						<LocaleBoxItem
							key={ item }
							to={ `${pathStart}/${item}${pathEnd}` }
							disabled={ isDisabled || disabled }
						>
							{ item.toLocaleUpperCase() }
						</LocaleBoxItem>
					);
				})
			}
		</div>
	);
}
