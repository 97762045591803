import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountProject, TAccountProjectLocaleCreate, TAccountProjectResponse, adaptAccountProjectFromResponse, adaptAccountProjectLocaleToRequest } from '../../types';
import { BACKEND_ACCOUNT_PROJECTS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';


export const accountProjectLocaleCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_PROJECTS}/:alias`,
	(payload: TAccountProjectLocaleCreate, thunkApi) => {
		const { data, alias } = payload;
		const path = `${BACKEND_ACCOUNT_PROJECTS}/${alias}`;
		const dataRequest = adaptAccountProjectLocaleToRequest(data);

		return HttpService.post<TAccountProjectResponse>(path, dataRequest).then(result => {
			return adaptAccountProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountProject | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountProjectLocaleCreateSlice = createSlice({
	name: 'accountProjectLocaleCreateSlice',
	initialState,
	reducers: {
		clearAccountProjectLocaleCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountProjectLocaleCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountProjectLocaleCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountProjectLocaleCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountProjectLocaleCreateSelector = () => useAppSelector(state => state[accountProjectLocaleCreateSlice.name]);

export const { clearAccountProjectLocaleCreate } = accountProjectLocaleCreateSlice.actions;
