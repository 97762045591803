// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormFileListValue_list__DXq-p {\n  box-sizing: border-box;\n  margin: 0.5em;\n}\n\n.FormFileListValue_headline__jJdQx {\n  color: rgb(150, 150, 150);\n  font-weight: bold;\n  text-indent: 1em;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-file/components/styles/FormFileListValue.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,aAAA;AACD;;AAEA;EACC,yBAAA;EACA,iBAAA;EACA,gBAAA;AACD","sourcesContent":[".list {\n\tbox-sizing: border-box;\n\tmargin: 0.5em;\n}\n\n.headline {\n\tcolor: rgba(150, 150, 150, 1);\n\tfont-weight: bold;\n\ttext-indent: 1em;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "FormFileListValue_list__DXq-p",
	"headline": "FormFileListValue_headline__jJdQx"
};
export default ___CSS_LOADER_EXPORT___;
