import { useEffect, useState } from 'react';

import { FormField, FormSearchBox } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';
import { StackBox } from 'src/pages/components';
import { TAccountStack } from 'src/store';

import { AccountProjectStackOption } from '../stack-option';

import { ACCOUNT_PROJECT_STACK_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
	items: TAccountStack[];
	stack: TAccountStack[];
	setStack: (value: TAccountStack[]) => void;
};

type TStackNum = {
	[key: string]: TAccountStack;
};

export function AccountProjectStackField(props: TProps) {
	const {
		stackLegend,
		stackPlaceholder,
		stackTitle,
	} = useLocalization(ACCOUNT_PROJECT_STACK_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		items,
		stack = [],
		setStack,
	} = props;

	const stackString = stack.map(item => item.alias);
	const [stackValue, setStackValue] = useState<string[]>(stackString);
	const [stackNum, setStackNum] = useState<TStackNum>({});

	useEffect(() => {
		const itemsNum: TStackNum = {};

		for (let i = 0; i < items.length; i++) {
			const item = items[i];

			itemsNum[item.alias] = item;
		}

		setStackNum(itemsNum);
	}, [items]);


	const options = items.map(item => {
		const { alias, name } = item;

		return {
			value: alias,
			search: name,
			text: <AccountProjectStackOption { ...item } />,
		};
	});

	const onClickItem = (value: string) => {
		if (stackValue.includes(value)) {
			return;
		}

		const newStackValue = [...stackValue, value];

		setStackValue(newStackValue);

		const newStack = newStackValue.map(item => stackNum[item]);

		setStack(newStack);
	};

	const onDelete = (value: TAccountStack[]) => {
		const newStackValue = value.map(item => item.alias);

		setStackValue(newStackValue);

		const newStack = newStackValue.map(item => stackNum[item]);

		setStack(newStack);
	};

	return (
		<FormField
			legend={ stackLegend }
			errors={ errors }
			required
		>
			<FormSearchBox
				items={ options }
				placeholder={ stackPlaceholder }
				title={ stackTitle }
				name={ name }
				onClickItem={ onClickItem }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>

			{ stack.length > 0 && <StackBox items={ stack } setItems={ onDelete } /> }
		</FormField>
	);
}
