import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_STATIC_USER_IMAGES } from 'src/routes';


export const UsersSortCol = {
	NICKNAME: 'nickname',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
	PHONE_NUMBER: 'phone_number',
	EMAIL: 'email',
};

export type TUsersSortCol = TTableSortCols<typeof UsersSortCol>;

export type TUserQuery = {
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TUsersSortCol;
};

export type TUsersQueryRequest = {
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TUser = {
  nickname: string;
  password?: string;
  image: string;
	createdAt: string;
  updatedAt: string;

	phoneNumber?: string,
	email?: string,
};

export type TUserResponse = {
  nickname: string;
  image?: string;
  created_at: string;
  updated_at: string;

	phone_number?: string,
	email?: string,
};

export type TUserListResponse = TListResponse<TUserResponse>;

export type TUserList = TListResponse<TUser>;

export type TUserRequest = Pick<TUser, 'nickname'>;
export type TUserPasswordRequest = Pick<TUser, 'password'>;

export type TUserPasswordUpdate = {
	nickname: string;
	data: TUserPasswordRequest;
};

export type TUserUpdate = {
	nickname: string;
	data: TUserRequest;
};

export type TUserLocaleParam = {
	nickname: string;
	locale: string;
};

export function adaptUserFromListResponse(data: TUserListResponse): TUserList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptUserFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptUserFromResponse(data: TUserResponse): TUser {
	const {
		created_at: createdAt,
		updated_at: updatedAt,
		image: imageRaw,
		phone_number: phoneNumber,
		email,
		...restFields
	} = data;

	const image = imageRaw ? `${PATH_STATIC_USER_IMAGES}/${imageRaw}` : PATH_STATIC_USER_IMAGES;

	return {
		...restFields,
		image,
		phoneNumber,
		email,
		createdAt,
		updatedAt,
	};
}
