// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvsListRow_row__vPP9V {\n  border-top: 1px solid rgb(230, 230, 230);\n  box-sizing: border-box;\n  justify-content: flex-start;\n  max-width: 480px !important;\n  padding: 1em;\n}\n.PublicCvsListRow_row__vPP9V:nth-child(even) {\n  background-color: rgb(250, 250, 250);\n  border: 1px solid rgb(230, 230, 230);\n}\n.PublicCvsListRow_row__vPP9V:first-child {\n  border-top: none;\n}\n\n.PublicCvsListRow_info__hRyWf {\n  margin-left: 1em;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/views/list/components/table/components/row/PublicCvsListRow.module.scss"],"names":[],"mappings":"AAAA;EACC,wCAAA;EACA,sBAAA;EACA,2BAAA;EAEA,2BAAA;EACA,YAAA;AAAD;AAEC;EACC,oCAAA;EACA,oCAAA;AAAF;AAQC;EACC,gBAAA;AANF;;AAcA;EACC,gBAAA;AAXD","sourcesContent":[".row {\n\tborder-top: 1px solid rgba(230, 230, 230, 1);\n\tbox-sizing: border-box;\n\tjustify-content: flex-start;\n\n\tmax-width: 480px !important;\n\tpadding: 1em;\n\n\t&:nth-child(even) {\n\t\tbackground-color: rgba(250, 250, 250, 1);\n\t\tborder: 1px solid rgba(230, 230, 230, 1);\n\t\t// border-right: 1px solid rgba(230, 230, 230, 1);\n\t}\n\n\t// &:nth-child(odd) {\n\t// \tborder-left: 1px solid rgba(230, 230, 230, 1);\n\t// }\n\n\t&:first-child {\n\t\tborder-top: none;\n\t}\n\n\t// &:last-child:nth-child(even) {\n\t// \tborder-bottom: 1px solid rgba(230, 230, 230, 1);\n\t// }\n}\n\n.info {\n\tmargin-left: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "PublicCvsListRow_row__vPP9V",
	"info": "PublicCvsListRow_info__hRyWf"
};
export default ___CSS_LOADER_EXPORT___;
