import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountExperience, TAccountExperienceLocalePayloadCreate, TAccountExperienceResponse, adaptAccountExperienceFromResponse, adaptAccountExperienceLocaleToRequest } from '../../types';


export const accountExperienceLocaleCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_EXPERIENCE}/:pid`,
	(payload: TAccountExperienceLocalePayloadCreate, thunkApi) => {
		const { data, pid } = payload;
		const path = `${BACKEND_ACCOUNT_EXPERIENCE}/${pid}`;
		const dataRequest = adaptAccountExperienceLocaleToRequest(data);

		return HttpService.post<TAccountExperienceResponse>(path, dataRequest).then(result => {
			return adaptAccountExperienceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountExperience | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExperienceLocaleCreateSlice = createSlice({
	name: 'accountExperienceLocaleCreateSlice',
	initialState,
	reducers: {
		clearAccountExperienceLocaleCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExperienceLocaleCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountExperienceLocaleCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperience>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExperienceLocaleCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountExperienceLocaleCreateSelector = () => useAppSelector(state => state[accountExperienceLocaleCreateSlice.name]);

export const { clearAccountExperienceLocaleCreate } = accountExperienceLocaleCreateSlice.actions;
