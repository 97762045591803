import { TListResponse } from 'src/common/types';


export type TPublicEducationType = {
  alias: string;
  name: string;
};

export type TPublicEducationTypeListResponse = TListResponse<TPublicEducationType>;

export type TPublicEducation = TPublicEducationLocale & {
  type: string;
  dateEnd: string;
};

export type TPublicEducationLocale = {
  locale: string;
  city: string;
  shortName: string;
  educationalInstitution: string;
  faculty: string;
  specialization: string;
};

export type TPublicEducationLocaleResponse = {
  locale: string;
  city: string;
  short_name: string;
  educational_institution: string;
  faculty: string;
  specialization: string;
};

export type TPublicEducationResponse = {
  locale: string;
  city: string;
  type: string;
  short_name: string;
  educational_institution: string;
  faculty: string;
  specialization: string;
  date_end: string;
};

export type TPublicEducationParam = {
  pid: string;
  locale: string;
};

export type TPublicEducationListResponse = TListResponse<TPublicEducationResponse>;

export type TPublicEducationList = TListResponse<TPublicEducation>;

export type TPublicEducationRequest = Pick<TPublicEducationResponse, 'type' | 'date_end'>;

export function adaptPublicEducationFromListResponse(data: TPublicEducationListResponse): TPublicEducationList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptPublicEducationFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptPublicEducationFromResponse(data: TPublicEducationResponse): TPublicEducation {
	const {
		locale,
		city,
		type,
		short_name: shortName,
		educational_institution: educationalInstitution,
		faculty = '',
		specialization,
		date_end: dateEnd,
	} = data;

	return {
		locale,
		city,
		type,
		shortName,
		educationalInstitution,
		faculty,
		specialization,
		dateEnd,
	};
}
