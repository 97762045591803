import { Link } from 'src/components';

import { TableBodyCol, TableBodyRow } from 'src/components';
import { LocaleBox } from 'src/pages/components';
import { PATH_STACK } from 'src/routes';

import styles from './StacksListRow.module.scss';
import { libFormatDate } from 'src/toolkit';


type TProps = {
	image?: string;
	to: string;
	name: string;
	alias: string;
	locales: string[];
	createdAt: string;
	updatedAt: string;
};

export function StacksListRow(props: TProps) {
	const {
		image = '',
		to,
		name,
		alias,
		locales,
		createdAt,
		updatedAt,
	} = props;

	return (
		<TableBodyRow>
			<TableBodyCol className={ styles.imageCol } center>
				{ image && <img className={ styles.image } src={ image } alt={ alias } /> }
			</TableBodyCol>

			<TableBodyCol><Link to={ to }>{ name } </Link></TableBodyCol>

			<TableBodyCol>
				<LocaleBox pathStart={ `${PATH_STACK}/${alias}` } items={ locales }/>
			</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(createdAt) }</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(updatedAt) }</TableBodyCol>
		</TableBodyRow>
	);
}
