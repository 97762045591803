// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvLanguage_box__356SB {\n  margin-right: 0.1em;\n  max-width: 7em;\n  width: 100%;\n  min-width: 7em;\n  margin-bottom: 0;\n}\n\n.AccountCvLanguage_selectBox__j1p6B {\n  max-width: 7em;\n  width: 100%;\n  min-width: 7em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/languages-field/components/language/AccountCvLanguage.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,cAAA;EACA,WAAA;EACA,cAAA;EAEA,gBAAA;AAAD;;AAGA;EACC,cAAA;EACA,WAAA;EACA,cAAA;AAAD","sourcesContent":[".box {\n\tmargin-right: 0.1em;\n\tmax-width: 7em;\n\twidth: 100%;\n\tmin-width: 7em;\n\n\tmargin-bottom: 0;\n}\n\n.selectBox {\n\tmax-width: 7em;\n\twidth: 100%;\n\tmin-width: 7em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvLanguage_box__356SB",
	"selectBox": "AccountCvLanguage_selectBox__j1p6B"
};
export default ___CSS_LOADER_EXPORT___;
