import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearAccountEducationLocaleUpdate, useAppDispatch, useAccountEducationLocaleReadSelector, accountEducationLocaleReadGetApi, clearAccountEducationLocaleRead, useAccountEducationsSelector, setAccountEducationLocaleRead, clearAccountEducationLocaleCreatedItem } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidLocale, isInvalidPid, libFormatDate, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';

import { ACCOUNT_EDUCATION_COMMON_LOCALIZATION } from '../../localization';

import { AcountEducationLocaleFormUpdate, AccountEducationLocaleDetails } from './components';
import { ACCOUNT_EDUCATION_LOCALE_UPDATE_LOCALIZATION } from './localization';


export function AccountEducationLocaleUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const { pid = '', locale = '' } = useParams();
	const { item: created } = useAccountEducationsSelector();
	const { data: item, isLoading, error } = useAccountEducationLocaleReadSelector();
	const { breadCrumbList } = useLocalization(ACCOUNT_EDUCATION_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(ACCOUNT_EDUCATION_LOCALE_UPDATE_LOCALIZATION);

	const isInvalidId = isInvalidPid(pid);
	const isInvalidLang = isInvalidLocale(locale);
	const isInvalid = isInvalidId || isInvalidLang;

	useEffect(() => {
		const isExisted = created && created.pid === pid;
		const isNotRequest = isInvalid || isExisted;

		if (isExisted) {
			dispatch(setAccountEducationLocaleRead(created));
		}

		if (!isNotRequest) {
			dispatch(accountEducationLocaleReadGetApi({ pid, locale }));
		}

		return () => {
			dispatch(clearAccountEducationLocaleUpdate());
			dispatch(clearAccountEducationLocaleRead());
			dispatch(clearAccountEducationLocaleCreatedItem());
		};
	}, []);

	if (isInvalid) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const { dateEnd, shortName, type } = item;

	const year = libFormatDate(dateEnd, 'yyyy');

	setTitle(`${breadCrumbItem} ${shortName}`);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EDUCATION, text: breadCrumbList },
		{ to: `${PATH_ACCOUNT_EDUCATION}/${pid}`, text: `${type} ${year}` },
		{ to: pathname, text: locale.toUpperCase() },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { shortName }</PageHeadline>

				<PageRow>
					<PageCol>
						<AcountEducationLocaleFormUpdate { ...item } pid={ pid } />
					</PageCol>

					<PageCol>
						<AccountEducationLocaleDetails { ...item } pid={ pid } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
