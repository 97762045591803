export const ROLES = {
	USER: 'USER',

	CREATE_USERS: 'CREATE_USERS',
	UPDATE_USERS: 'UPDATE_USERS',
	RETRIEVE_USERS: 'RETRIEVE_USERS',
	DELETE_USERS: 'DELETE_USERS',

	CREATE_USERS_EDUCATIONS: 'CREATE_USERS_EDUCATIONS',
	UPDATE_USERS_EDUCATIONS: 'UPDATE_USERS_EDUCATIONS',
	RETRIEVE_USERS_EDUCATIONS: 'RETRIEVE_USERS_EDUCATIONS',
	DELETE_USERS_EDUCATIONS: 'DELETE_USERS_EDUCATIONS',

	CREATE_USERS_PROJECTS: 'CREATE_USERS_PROJECTS',
	UPDATE_USERS_PROJECTS: 'UPDATE_USERS_PROJECTS',
	RETRIEVE_USERS_PROJECTS: 'RETRIEVE_USERS_PROJECTS',
	DELETE_USERS_PROJECTS: 'DELETE_USERS_PROJECTS',

	CREATE_USERS_EXPERIENCES: 'CREATE_USERS_EXPERIENCES',
	UPDATE_USERS_EXPERIENCES: 'UPDATE_USERS_EXPERIENCES',
	RETRIEVE_USERS_EXPERIENCES: 'RETRIEVE_USERS_EXPERIENCES',
	DELETE_USERS_EXPERIENCES: 'DELETE_USERS_EXPERIENCES',

	CREATE_USERS_CVS: 'CREATE_USERS_CVS',
	UPDATE_USERS_CVS: 'UPDATE_USERS_CVS',
	RETRIEVE_USERS_CVS: 'RETRIEVE_USERS_CVS',
	DELETE_USERS_CVS: 'DELETE_USERS_CVS',

	CREATE_STACKS: 'CREATE_STACKS',
	UPDATE_STACKS: 'UPDATE_STACKS',
	RETRIEVE_STACKS: 'RETRIEVE_STACKS',
	DELETE_STACKS: 'DELETE_STACKS',

	ACCOUNT_EDUCATIONS: 'ACCOUNT_EDUCATIONS',
	ACCOUNT_PROJECTS: 'ACCOUNT_PROJECTS',
	ACCOUNT_EXPERIENCES: 'ACCOUNT_EXPERIENCES',
	ACCOUNT_CVS: 'ACCOUNT_CVS',
};
