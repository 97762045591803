import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { StackSortCol, TStack, TStackList, TStackListResponse, TStackQuery, TStackQueryRequest, TStackSortCol, adaptStackFromListResponse } from '../../types';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';
import { LocalizationTypes } from 'src/common/types';


export const stacksGetApi = createAsyncThunk(
	BACKEND_ADMIN_STACKS,
	(payload: TStackQuery | undefined, thunkApi) => {
		const params = payload ? toStaksQueryRequest(payload) : {};

		return HttpService.get<TStackListResponse>(BACKEND_ADMIN_STACKS, { params }).then(result => {
			return adaptStackFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toStaksQueryRequest(data: TStackQuery): TStackQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	item?: TStack,
	itemLocale?: TStack,
	isLoading: boolean,
	list: TStackList,
	query: TStackQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	itemLocale: undefined,
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		locale: LocalizationTypes.EN,
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: StackSortCol.CREATED_AT,
	},
	error: undefined,
};

export const stacksSlice = createSlice({
	name: 'stacksSlice',
	initialState,
	reducers: {
		setStackLocaleCreatedItem(state, action: PayloadAction<TStack>) {
			state.itemLocale = action.payload;
		},

		setStackCreatedItem(state, action: PayloadAction<TStack>) {
			state.item = action.payload;
		},

		setStackQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setStackQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setStackQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setStackQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setStackQuerySortCol(state, action: PayloadAction<TStackSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearStacks(state) {
			return { ...initialState, list: state.list, query: state.query };
		},

		clearStackCreatedItem(state) {
			state.item = undefined;
		},

		clearStackLocaleCreatedItem(state) {
			state.itemLocale = undefined;
		},
	},

	extraReducers: {
		[stacksGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[stacksGetApi.fulfilled.type]: (state, action: PayloadAction<TStackList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[stacksGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useStacksSelector = () => useAppSelector(state => state[stacksSlice.name]);

export const { clearStacks, setStackQueryLimit, setStackQueryOffset, setStackQuerySort, setStackQuerySortCol, setStackQuerySearch, setStackCreatedItem, setStackLocaleCreatedItem, clearStackCreatedItem, clearStackLocaleCreatedItem } = stacksSlice.actions;
