// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablePagination_tablePagination__i-Sfj {\n  box-sizing: border-box;\n  color: rgb(180, 180, 180);\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  font-family: inherit;\n  font-size: 1.25em;\n  font-weight: 100;\n  margin: 1em auto 1em;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/table/pagination/TablePagination.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,yBAAA;EAEA,aAAA;EACA,uBAAA;EACA,uBAAA;EAEA,oBAAA;EACA,iBAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;AADD","sourcesContent":[".tablePagination {\n\tbox-sizing: border-box;\n\tcolor: rgba(180, 180, 180, 1);\n\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: flex-start;\n\n\tfont-family: inherit;\n\tfont-size: 1.25em;\n\tfont-weight: 100;\n\tmargin: 1em auto 1em;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablePagination": "TablePagination_tablePagination__i-Sfj"
};
export default ___CSS_LOADER_EXPORT___;
