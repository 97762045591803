import { LocalizationTypes } from 'src/common/types';

import { PUBLIC_CV_CONTACTS_RU_LOCALIZATION } from './ru.localization';
import { PUBLIC_CV_CONTACTS_EN_LOCALIZATION } from './en.localization';


export const PUBLIC_CV_CONTACTS_LOCALIZATION = {
	[LocalizationTypes.RU]: PUBLIC_CV_CONTACTS_RU_LOCALIZATION,
	[LocalizationTypes.EN]: PUBLIC_CV_CONTACTS_EN_LOCALIZATION,
};
