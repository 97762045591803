import { Link, TableBodyCol, TableBodyRow } from 'src/components';
import { LocaleBox } from 'src/pages/components';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';
import { TAccountEducation } from 'src/store';
import { libFormatDate } from 'src/toolkit';

import styles from './AccountEducationListRow.module.scss';


export function AccountEducationListRow(props: TAccountEducation) {
	const {
		pid,
		type,
		dateEnd,
		locales,
		createdAt,
		updatedAt,
	} = props;


	const path = `${PATH_ACCOUNT_EDUCATION}/${pid}`;

	return (
		<TableBodyRow>
			<TableBodyCol><Link to={ path }>{ type } ({ libFormatDate(dateEnd, 'yyyy') })</Link></TableBodyCol>

			<TableBodyCol left>
				<LocaleBox pathStart={ `${PATH_ACCOUNT_EDUCATION}/${pid}` } items={ locales }/>
			</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(createdAt) }</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(updatedAt) }</TableBodyCol>
		</TableBodyRow>
	);
}
