import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountProject, setAccountProjectsLocalesCreatedItem, accountProjectLocaleCreateApi, useAppDispatch, useAccountProjectLocaleCreateSelector } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_ACCOUNT_PROJECTS } from 'src/routes';

import { AccountProjectDescriptionField } from '../../../../components';
import { ACCOUNT_PROJECTS_LOCALES_CREATE_LOCALIZATION } from '../../localization';
import { LocaleField } from 'src/pages/components';


export function AccountProjectsLocalesFormCreate(props: TAccountProject) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountProjectLocaleCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(ACCOUNT_PROJECTS_LOCALES_CREATE_LOCALIZATION);

	const {
		locale = '',
		alias = '',
		description = '',
	} = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const descriptionField = useInputFormField({
		name: 'description',
		defaultValue: description,
		validation: value => formValidateText(value, { max: 512 }),
	});

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				localeField,
				descriptionField,
			);
		}

		if (item) {
			dispatch(setAccountProjectsLocalesCreatedItem(item));
			navigate(`${PATH_ACCOUNT_PROJECTS}/${alias}/${item.locale}`);
		}
	}, [error, item]);

	const onSubmit = () => {
		const errors = [
			localeField.validate(),
			descriptionField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			locale: localeField.value,
			description: descriptionField.value,
		};

		dispatch(accountProjectLocaleCreateApi({ data: dataRequest, alias }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading } />

			<AccountProjectDescriptionField { ...descriptionField } isLoading={ isLoading } />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
