import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EXPERIENCE } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { TAccountExperience, clearAccountExperienceLocaleCreate, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION } from '../../localization';

import { AccountExperienceLocaleFormCreate } from './components';
import { ACCOUNT_EXPERIENCE_LOCALE_CREATE_LOCALIZATION } from './localization';


export function AccountExperienceLocaleCreateWidget(props: TAccountExperience) {
	const dispatch = useAppDispatch();
	const { lang } = useCredentialsSelector();
	const { pathname, hash } = useLocation();
	const { breadCrumbList } = useLocalization(ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION);
	const { title, headline, breadCrumbNew } = useLocalization(ACCOUNT_EXPERIENCE_LOCALE_CREATE_LOCALIZATION);

	const { companyName } = props;

	const initialItem = {
		...props,
		locale: lang,
		companyCity: '',
		positionName: '',
		description: '',
		updatedAt: '',
		createdAt: '',
	};

	useEffect(() => {
		return () => {
			dispatch(clearAccountExperienceLocaleCreate());
		};
	}, []);

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EXPERIENCE, text: breadCrumbList },
		{ to: `${pathname}`, text: companyName },
		{ to: `${pathname}${hash}`, text: breadCrumbNew },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountExperienceLocaleFormCreate { ...initialItem } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
