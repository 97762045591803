import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountExperience, TAccountExperienceBodyRequest, TAccountExperienceResponse, adaptAccountExperienceFromResponse, adaptAccountExperienceToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';


export const accountExperienceCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_EXPERIENCE}`,
	(payload: TAccountExperienceBodyRequest, thunkApi) => {
		const dataRequest = adaptAccountExperienceToRequest(payload);
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return HttpService.post<TAccountExperienceResponse>(BACKEND_ACCOUNT_EXPERIENCE, dataRequest, config).then(result => {
			return adaptAccountExperienceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountExperience | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExperienceCreateSlice = createSlice({
	name: 'accountExperienceCreateSlice',
	initialState,
	reducers: {
		clearAccountExperienceCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExperienceCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountExperienceCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperience>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExperienceCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountExperienceCreateSelector = () => useAppSelector(state => state[accountExperienceCreateSlice.name]);

export const { clearAccountExperienceCreate } = accountExperienceCreateSlice.actions;
