import { LocalizationTypes } from 'src/common/types';

import { USER_IMAGE_FORM_RU_LOCALIZATION } from './ru.localization';
import { USER_IMAGE_FORM_EN_LOCALIZATION } from './en.localization';


export const USER_IMAGE_FORM_LOCALIZATION = {
	[LocalizationTypes.RU]: USER_IMAGE_FORM_RU_LOCALIZATION,
	[LocalizationTypes.EN]: USER_IMAGE_FORM_EN_LOCALIZATION,
};
