import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearAccountExperienceUpdate, useAppDispatch, useAccountExperienceReadSelector, accountExperienceReadGetApi, clearAccountExperienceRead, useAccountExperiencesSelector, setAccountExperienceRead, clearAccountExperiencesCreatedItem, accountProjectsGetApi, useAccountProjectsSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidPid, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EXPERIENCE, PATH_ACCOUNT_EXPERIENCE_CREATE_END } from 'src/routes';

import { ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION } from '../../localization';

import { AccountExperienceFormUpdate, AccountExperienceDetails } from './components';
import { ACCOUNT_EXPERIENCE_UPDATE_LOCALIZATION } from './localization';
import { AccountExperienceLocaleCreateWidget } from '../../widgets';


export function AccountExperienceUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname, hash } = useLocation();
	const { pid = '' } = useParams();
	const { item: created } = useAccountExperiencesSelector();
	const { data: item, isLoading, error } = useAccountExperienceReadSelector();
	const { list: projectsSourceList, isLoading: isLoadingProjects } = useAccountProjectsSelector();
	const { breadCrumbList } = useLocalization(ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(ACCOUNT_EXPERIENCE_UPDATE_LOCALIZATION);

	const isInvalidId = isInvalidPid(pid);

	useEffect(() => {
		dispatch(accountProjectsGetApi());

		const isExisted = created && created.pid === pid;
		const isNotRequest = isInvalidId || isExisted;

		if (isExisted) {
			dispatch(setAccountExperienceRead(created));
		}

		if (!isNotRequest) {
			dispatch(accountExperienceReadGetApi(pid));
		}

		return () => {
			dispatch(clearAccountExperienceUpdate());
			dispatch(clearAccountExperienceRead());
			dispatch(clearAccountExperiencesCreatedItem());
		};
	}, []);

	if (isInvalidId) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || isLoadingProjects || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	if (hash === PATH_ACCOUNT_EXPERIENCE_CREATE_END) {
		return (
			<AccountExperienceLocaleCreateWidget { ...item } />
		);
	}

	const { companyName } = item;

	setTitle(`${breadCrumbItem} ${companyName}`);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EXPERIENCE, text: breadCrumbList },
		{ to: pathname, text: companyName },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { companyName }</PageHeadline>

				<PageRow>
					<PageCol>
						<AccountExperienceFormUpdate { ...item } projectsSource={ projectsSourceList.items } />
					</PageCol>

					<PageCol>
						<AccountExperienceDetails { ...item } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
