import { Icon } from 'src/components';
import { TAccountCvLanguage } from 'src/store';

import styles from './AccountCvLanguageItem.module.scss';


type TProps = {
	item: TAccountCvLanguageItem;
	onClick: (value: TAccountCvLanguageItem) => void;
};

export type TAccountCvLanguageItem = TAccountCvLanguage & {
	languageName: string;
	levelName: string;
};

export function AccountCvLanguageItem(props: TProps) {
	const { item, onClick, ...restProps } = props;
	const { languageName, levelName } = item;

	return (
		<div className={ styles.item } { ...restProps }>
			<span className={ styles.text }>{ languageName } — { levelName }</span>
			<Icon className={ styles.remove } onClick={ e => onClick(item) } icon="icon-cancel" />
		</div>
	);
}
