import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountEducation, setAccountEducationCreatedItem, accountEducationLocaleCreateApi, useAppDispatch, useAccountEducationLocaleCreateSelector } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';

import { AccountEducationFacultyField, AccountEducationSpecializationField, AccountEducationEducationalInstitutionField, AccountEducationCityField, AccountEducationShortNameField } from '../../../../components';
import { ACCOUT_EDUCATION_LOCALE_CREATE_LOCALIZATION } from '../../localization';
import { LocaleField } from 'src/pages/components';


export function AccountEducationLocaleFormCreate(props: TAccountEducation) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountEducationLocaleCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(ACCOUT_EDUCATION_LOCALE_CREATE_LOCALIZATION);

	const {
		pid = '',
		locale = '',
		city = '',
		shortName = '',
		educationalInstitution = '',
		faculty = '',
		specialization = '',
	} = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const cityField = useInputFormField({
		name: 'city',
		defaultValue: city,
		validation: value => formValidateText(value),
	});

	const shortNameField = useInputFormField({
		name: 'short_name',
		defaultValue: shortName,
		validation: value => formValidateText(value),
	});

	const educationalInstitutionField = useInputFormField({
		name: 'educational_institution',
		defaultValue: educationalInstitution,
		validation: value => formValidateText(value),
	});

	const facultyField = useInputFormField({
		name: 'faculty',
		defaultValue: faculty,
		validation: value => formValidateText(value),
	});

	const specializationField = useInputFormField({
		name: 'specialization',
		defaultValue: specialization,
		validation: value => formValidateText(value),
	});

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				localeField,
				cityField,
				shortNameField,
				educationalInstitutionField,
				facultyField,
				specializationField,
			);
		}

		if (item) {
			dispatch(setAccountEducationCreatedItem(item));
			navigate(`${PATH_ACCOUNT_EDUCATION}/${item.pid}/${item.locale}`);
		}
	}, [error, item]);

	const onSubmit = () => {
		const errors = [
			localeField.validate(),
			cityField.validate(),
			shortNameField.validate(),
			educationalInstitutionField.validate(),
			facultyField.validate(),
			specializationField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			locale: localeField.value,
			city: cityField.value,
			shortName: shortNameField.value,
			educationalInstitution: educationalInstitutionField.value,
			faculty: facultyField.value,
			specialization: specializationField.value,
		};

		dispatch(accountEducationLocaleCreateApi({ data: dataRequest, pid }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading } />

			<AccountEducationCityField { ...cityField } isLoading={ isLoading } />

			<AccountEducationShortNameField { ...shortNameField } isLoading={ isLoading } />

			<AccountEducationEducationalInstitutionField { ...educationalInstitutionField } isLoading={ isLoading } />

			<AccountEducationFacultyField { ...facultyField } isLoading={ isLoading } />

			<AccountEducationSpecializationField { ...specializationField } isLoading={ isLoading } />

			<FormNotice errorText={ notice } />

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
