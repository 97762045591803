// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSelectBoxLabelContent_formSelectBoxLabelContent__4gKrv {\n  box-sizing: border-box;\n  display: block;\n  float: left;\n  line-height: 1.8em;\n  height: 2em;\n  width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-select-box/components/label/components/content/FormSelectBoxLabelContent.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EAEA,WAAA;EAEA,gBAAA;EACA,mBAAA;EACA,uBAAA;AADD","sourcesContent":[".formSelectBoxLabelContent {\n\tbox-sizing: border-box;\n\tdisplay: block;\n\tfloat: left;\n\tline-height: 1.8em;\n\theight: 2em;\n\n\twidth: 100%;\n\n\toverflow: hidden;\n\twhite-space: nowrap;\n\ttext-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSelectBoxLabelContent": "FormSelectBoxLabelContent_formSelectBoxLabelContent__4gKrv"
};
export default ___CSS_LOADER_EXPORT___;
