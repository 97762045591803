import { ReactNode, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { TCredentials, signOutApi, useAppDispatch } from 'src/store';
import { PATH_ACCOUNT, PATH_ACCOUNT_CV, PATH_ACCOUNT_EDUCATION, PATH_ACCOUNT_EXPERIENCE, PATH_ACCOUNT_PASSPORT, PATH_ACCOUNT_PASSWORD, PATH_ACCOUNT_PROJECTS, PATH_STACK, PATH_USERS, ROLES } from 'src/routes';
import { useLocalization } from 'src/toolkit';

import { AsideItemList } from '../aside-item-list';
import { NavIcon } from '../nav-icon';
import navStyles from '../nav/Nav.module.scss';
import asideItemStyles from '../aside-item/AsideItem.module.scss';
import asideItemListStyles from '../aside-item-list/AsideItemList.module.scss';
import { TAsideItem } from '../aside-item';

import { ASIDE_LOCALIZATION } from './localization';

import navMenuStyles from '../NavMenu.module.scss';
import styles from './Aside.module.scss';


type TAsideProps = {
	children?: ReactNode;
	style?: Record<string, string>;
	menu?: TAsideItem[][];
	isVisible?: boolean;
	credentials: TCredentials;
	onClick?: () => void;
};

export function Aside(props: TAsideProps) {
	const dispatch = useAppDispatch();
	const {
		account,
		cv,
		education,
		projects,
		experience,
		stack,
		users,
		logout,
	} = useLocalization(ASIDE_LOCALIZATION);

	const {
		isVisible = false,
		style = {},
		credentials,
		onClick: hideAside = () => {},
	} = props;

	const { name = '', image = '' } = credentials;

	const onClick = () => {
		const width = window.innerWidth || document.body.clientWidth;

		if (width <= 767) {
			hideAside();
		}
	}

	const onLogout = () => {
		onClick();

		dispatch(signOutApi());
	};

	const accountEducationsRe = new RegExp(`^${PATH_ACCOUNT_EDUCATION}(/|#).*$`, 'ig');
	const accountProjectsRe = new RegExp(`^${PATH_ACCOUNT_PROJECTS}(/|#).*$`, 'ig');
	const accountExperienceRe = new RegExp(`^${PATH_ACCOUNT_EXPERIENCE}(/|#).*$`, 'ig');
	const stackRe = new RegExp(`^${PATH_STACK}(/|#).*$`, 'ig');

	const menu = [
		[
			{ text: account, icon: 'icon-gears', href: PATH_ACCOUNT, links: [PATH_ACCOUNT_PASSPORT, PATH_ACCOUNT_PASSWORD] },
			{ text: cv, icon: 'icon-orders', role: ROLES.ACCOUNT_CVS, href: PATH_ACCOUNT_CV },
			{ text: education, icon: 'icon-books', role: ROLES.ACCOUNT_EDUCATIONS, href: PATH_ACCOUNT_EDUCATION, re: accountEducationsRe },
			{ text: projects, icon: 'icon-briefcase', role: ROLES.ACCOUNT_PROJECTS, href: PATH_ACCOUNT_PROJECTS, re: accountProjectsRe },
			{ text: experience, icon: 'icon-embed', role: ROLES.ACCOUNT_EXPERIENCES, href: PATH_ACCOUNT_EXPERIENCE, re: accountExperienceRe },
		],

		[
			{ text: stack, icon: 'icon-stack', role: ROLES.RETRIEVE_STACKS, href: PATH_STACK, re: stackRe },
			{ text: users, icon: 'icon-users', role: ROLES.RETRIEVE_USERS, href: PATH_USERS },
		],
	];

	const protectedMenu = useMemo(() => {
		const { roles } = credentials;

		return menu.filter(item => {
			const links = item.filter(item => {
				const { role } = item;

				return role ? roles.includes(role) : true;
			});

			return links.length > 0;
		});
	}, [menu, credentials]);

	const { navItemStyle } = navStyles;
	const classNames = [styles.aside, navMenuStyles.asideShow];

	if (isVisible) {
		classNames.push(styles.active);
	}

	const wrapClassNames = [navItemStyle, styles.wrap];
	const linkClassNames = [asideItemStyles.asideLinkStyle, styles.avatarLink];
	const shellClassNames = ['scroll-box', styles.asideShell];

	return (
		<aside className={ classNames.join(' ') } style={ style }>
			<div className={ shellClassNames.join(' ') }>
				<div className={ styles.header }>
					<div className={ wrapClassNames.join(' ') }>
						<NavLink
							className={ linkClassNames.join(' ') }
							to={ PATH_ACCOUNT }
							onClick={ onClick }
						>
							<span className={ styles.avatarWrap }>
								{ image && <img src={ image } className={ styles.avatarImage } alt="user_image" /> }
								{ !image && <span className={ styles.symbol }>{ name.slice(0, 1).toUpperCase() }</span> }
							</span>
							{ name }
						</NavLink>
					</div>
				</div>

				{
					protectedMenu.map((items, index) => <AsideItemList items={ items } key={ index } onClick={ onClick } />)
				}

				<ul className={ asideItemListStyles.list }>
					<li
						className={ navItemStyle }
						onClick={ onLogout }
					>
						<span className={ asideItemStyles.asideLinkStyle }>
							<NavIcon icon="icon-exit" className={ asideItemStyles.iconLink }></NavIcon>
							{ logout }
						</span>
					</li>
				</ul>
			</div>
		</aside>
	);
}
