import { LocalizationTypes } from 'src/common/types';

import { LOCALE_BOX_RU_LOCALIZATION } from './ru.localization';
import { LOCALE_BOX_EN_LOCALIZATION } from './en.localization';


export const LOCALE_BOX_LOCALIZATION = {
	[LocalizationTypes.RU]: LOCALE_BOX_RU_LOCALIZATION,
	[LocalizationTypes.EN]: LOCALE_BOX_EN_LOCALIZATION,
};
