import { LocalizationTypes } from 'src/common/types';

import { NAV_MENU_RU_LOCALIZATION } from './ru.localization';
import { NAV_MENU_EN_LOCALIZATION } from './en.localization';


export const NAV_MENU_LOCALIZATION = {
	[LocalizationTypes.RU]: NAV_MENU_RU_LOCALIZATION,
	[LocalizationTypes.EN]: NAV_MENU_EN_LOCALIZATION,
};
