import { ReactNode } from 'react';

import styles from './FormRow.module.scss';


type TProps = {
	className?: string;
	style?: Record<string, string>;
	children?: ReactNode;
};

export function FormRow(props: TProps) {
	const {
		className = '',
		style = {},
		children,
	} = props;

	const classNames = [styles.formRow];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } style={ style }>
			{ children }
		</div>
	);
}
