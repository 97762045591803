import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountExperience, TAccountExperienceLocalePayloadUpdate, TAccountExperienceResponse, adaptAccountExperienceFromResponse, adaptAccountExperienceLocaleToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';


export const accountExperienceLocaleUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_EXPERIENCE}/:pid/:locale`,
	(payload: TAccountExperienceLocalePayloadUpdate, thunkApi) => {
		const { data, pid, locale } = payload;
		const path = `${BACKEND_ACCOUNT_EXPERIENCE}/${pid}/${locale}`;
		const dataRequest = adaptAccountExperienceLocaleToRequest(data);

		return HttpService.put<TAccountExperienceResponse>(path, dataRequest).then(result => {
			return adaptAccountExperienceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountExperience | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExperienceLocaleUpdateSlice = createSlice({
	name: 'accountExperienceLocaleUpdateSlice',
	initialState,
	reducers: {
		clearAccountExperienceLocaleUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExperienceLocaleUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExperienceLocaleUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperience>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExperienceLocaleUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountExperienceLocaleUpdateSelector = () => useAppSelector(state => state[accountExperienceLocaleUpdateSlice.name]);

export const { clearAccountExperienceLocaleUpdate } = accountExperienceLocaleUpdateSlice.actions;
