import { useEffect, useState } from 'react';

import { FormCheckboxInfo, Icon } from 'src/components';
import { TUserRole } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { USERS_ROLES_FORM_ITEM_LOCALIZATION } from './localization';

import styles from './UserRolesFormItem.module.scss';


type TProps = TUserRole & {
	onChange: (value: TUserRole) => void;
	onFocus: (value: TUserRole) => void;
	onBlur: () => void;
	isLoading: boolean;
};

export function UserRolesFormItem(props: TProps) {
	const { hintStart, hintEnd } = useLocalization(USERS_ROLES_FORM_ITEM_LOCALIZATION);
	const {
		alias,
		title,
		value: defaultValue,

		onChange,
		onFocus,
		onBlur,
		isLoading = false,
	} = props;

	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		onChange({ ...props, value });
	}, [value]);

	const iconHint = `${hintStart}${title}${hintEnd}`;

	return (
		<FormCheckboxInfo
			required
			name={ alias }
			id={ alias }
			checked={ value }
			onChange={ () => setValue(!value) }
			disabled={ isLoading }
			nodeBefore={
				<Icon
					icon="icon-info"
					tabIndex={ -1 }
					title={ iconHint }
					className={ styles.icon }
					onFocus={ e => onFocus({ ...props, value }) }
					onBlur={ onBlur }
				/>
			}
		>
			{ title }
		</FormCheckboxInfo>
	);
}
