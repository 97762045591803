import styles from './NavLogo.module.scss';


export type TNavLogo = {
	href?: string;
	src?: string;
	alt?: string;
	style?: Record<string, string>;
};

export function NavLogo(props: TNavLogo) {
	const {
		alt = '',
		href = '/',
		src = '/images/logo.svg',
		style = {},
	} = props;

	return (
		<a href={ href } className={ styles.logo } style={ style }>
			<img src={ src } alt={ alt } />
		</a>
	);
}
