import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButton, FormNotice, Modal, ModalButtonWrap, ModalHeadline } from 'src/components';
import { PATH_STACK } from 'src/routes';
import { TStack, clearStackDelete, stackDeleteApi, useAppDispatch, useStackDeleteSelector } from 'src/store';
import { formHandleServerError, useLocalization } from 'src/toolkit';
import { STACK_MODAL_DELETE_LOCALIZATION } from './localization';


type TProps = {
	data: TStack;
	onClose: () => void;
};

export function StackModalDelete(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useStackDeleteSelector();
	const { data, onClose } = props;
	const [notice, setNotice] = useState('');
	const { questionText, buttonRemove, buttonCancel } = useLocalization(STACK_MODAL_DELETE_LOCALIZATION);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice);
		}

		if (item) {
			onClose();
			navigate(PATH_STACK);
		}

		return () => {
			dispatch(clearStackDelete());
		};
	}, [error, item]);

	const { name, alias } = data;

	const onClick = () => {
		dispatch(stackDeleteApi(alias));
	};

	return (
		<Modal onClose={ onClose }>
			<ModalHeadline>{ questionText } «{ name }»?</ModalHeadline>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<ModalButtonWrap>
				<FormButton cancel className="first" type="button" busy={ isLoading } onClick={ onClick }>{ buttonRemove }</FormButton>
				<FormButton className="last" type="button" onClick={ onClose }>{ buttonCancel }</FormButton>
			</ModalButtonWrap>
		</Modal>
	);
}
