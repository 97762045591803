// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountProjectStackOption_image__AOOQn {\n  max-height: 1.5em;\n  width: 2em;\n  margin-right: 0.25em;\n  vertical-align: middle;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-projects/components/stack-option/AccountProjectStackOption.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,UAAA;EAEA,oBAAA;EAEA,sBAAA;AADD","sourcesContent":[".image {\n\tmax-height: 1.5em;\n\twidth: 2em;\n\n\tmargin-right: 0.25em;\n\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "AccountProjectStackOption_image__AOOQn"
};
export default ___CSS_LOADER_EXPORT___;
