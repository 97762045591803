import { useEffect, useState } from 'react';


export function useDebounce<T = string>(value: T, delay?: number): T {
	const [debounced, setDebounced] = useState<T>(value);

	useEffect(() => {
		const timerId = setTimeout(() => setDebounced(value), delay || 700);

		return () => clearTimeout(timerId);
	}, [value, delay]);

	return debounced;
}
