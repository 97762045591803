export const USERS_DETAILS_EN_LOCALIZATION = {
	phoneNumberLegend: 'Phone number',
	phoneNumberPlaceholder: '—',
	phoneNumberConfirmedTitle: 'Phone number of the user confirmed',
	phoneNumberNoneTitle: 'The user`s phone number is not set',
	phoneNumberButtonTitle: 'Go to phone number settings',

	emailLegend: 'E-Mail',
	emailPlaceholder: '—',
	emailConfirmedTitle: 'E-Mail of the user confirmed',
	emailNoneTitle: 'The user`s E-Mail is not set',
	emailButtonTitle: 'Go to E-Mail settings',

	createdAtLegend: 'Date of creation',
	createdAtTitle: 'Date of creation the user',

	updatedAtLegend: 'Date of update',
	updatedAtTitle: 'Date of updating the user',

	removeLinkText: 'Remove user',

	passwordButton: 'Password',
	rolesButton: 'Roles',
};
