import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './FormNotice.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	successText?: string;
	errorText?: string;
	error?: boolean;
	success?: boolean;
};

export function FormNotice(props: TProps) {
	const {
		className = '',
		children,
		successText = '',
		errorText = '',
		error = false,
		success = false,
		...restProps
	} = props;

	const text = successText || errorText || children;

	if (!text) {
		return (
			<></>
		);
	}

	const classNames = [styles.formNotice];

	if (success || successText) {
		classNames.push(styles.success);
	}

	if (error || errorText) {
		classNames.push(styles.error);
	}

	if (className && styles[className]) {
		classNames.push(styles[className]);
	} else if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			{ text }
		</div>
	);
}
