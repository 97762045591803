// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvStatusField_lowerSelectField__NDjwf {\n  position: relative;\n  z-index: 9;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/status-field/AccountCvStatusField.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,UAAA;AACD","sourcesContent":[".lowerSelectField {\n\tposition: relative;\n\tz-index: 9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lowerSelectField": "AccountCvStatusField_lowerSelectField__NDjwf"
};
export default ___CSS_LOADER_EXPORT___;
