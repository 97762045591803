import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { UsersSortCol, TUser, TUserList, TUserListResponse, TUserQuery, TUsersQueryRequest, TUsersSortCol, adaptUserFromListResponse } from '../../types';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const usersGetApi = createAsyncThunk(
	BACKEND_ADMIN_USERS,
	(payload: TUserQuery | undefined, thunkApi) => {
		const params = payload ? toUsersQueryRequest(payload) : {};

		return HttpService.get<TUserListResponse>(BACKEND_ADMIN_USERS, { params }).then(result => {
			return adaptUserFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toUsersQueryRequest(data: TUserQuery): TUsersQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	item?: TUser,
	itemLocale?: TUser,
	isLoading: boolean,
	list: TUserList,
	query: TUserQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	itemLocale: undefined,
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: UsersSortCol.CREATED_AT,
	},
	error: undefined,
};

export const usersSlice = createSlice({
	name: 'usersSlice',
	initialState,
	reducers: {
		setUserLocaleCreatedItem(state, action: PayloadAction<TUser>) {
			state.itemLocale = action.payload;
		},

		setUserCreatedItem(state, action: PayloadAction<TUser>) {
			state.item = action.payload;
		},

		setUsersQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setUsersQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setUsersQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setUsersQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setUsersQuerySortCol(state, action: PayloadAction<TUsersSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearUsers(state) {
			return { ...initialState, list: state.list, query: state.query };
		},

		clearUserCreatedItem(state) {
			state.item = undefined;
		},

		clearUserLocaleCreatedItem(state) {
			state.itemLocale = undefined;
		},
	},

	extraReducers: {
		[usersGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[usersGetApi.fulfilled.type]: (state, action: PayloadAction<TUserList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[usersGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useUsersSelector = () => useAppSelector(state => state[usersSlice.name]);

export const { clearUsers, setUsersQueryLimit, setUsersQueryOffset, setUsersQuerySort, setUsersQuerySortCol, setUsersQuerySearch, setUserCreatedItem, setUserLocaleCreatedItem, clearUserCreatedItem, clearUserLocaleCreatedItem } = usersSlice.actions;
