import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_SOURCE_USERS_SEX } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserSex, TUserSexListResponse, adaptUserSexsFromListResponse } from '../../types';


export const accountSourceUserSexGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SOURCE_USERS_SEX}/:locale`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SOURCE_USERS_SEX}/${payload}`;

		return HttpService.get<TUserSexListResponse>(path).then(result => {
			return adaptUserSexsFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TUserSex[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	items: undefined,
	error: undefined,
};

export const accountSourceUserSexSlice = createSlice({
	name: 'accountSourceUserSexSlice',
	initialState,
	reducers: {
		clearAccountSourceUserSex(state) {
			return { ...initialState, list: state.items };
		},
	},

	extraReducers: {
		[accountSourceUserSexGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountSourceUserSexGetApi.fulfilled.type]: (state, action: PayloadAction<TUserSex[]>) => {
			state.isLoading = false;
			state.items = action.payload;
			state.error = undefined;
		},

		[accountSourceUserSexGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountSourceUserSexSelector = () => useAppSelector(state => state[accountSourceUserSexSlice.name]);

export const { clearAccountSourceUserSex } = accountSourceUserSexSlice.actions;
