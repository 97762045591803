import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormCheckbox, FormField, FormInput, FormInputMask, FormNotice, FormPassword, FormRadio } from 'src/components';
import { signUpEmailApi, signUpPhoneNumberApi, useAppDispatch, useSignUpSelector } from 'src/store';
import { formHandleServerError, formValidateEmail, formValidateLogin, formValidatePassword, formValidatePasswordConfirm, formValidatePhoneNumber, formValidateRadio, libClearIntstr, useInputField, useLocalization } from 'src/toolkit';

import { SIGN_UP_MAIN_FORM_LOCALIZATION } from './localization';

import styles from '../../../../../../../../components/stages/SignUpStages.module.scss';


export function SignUpFormMain() {
	const dispatch = useAppDispatch();
	const { isLoading, error } = useSignUpSelector();
	const {
		nicknameLegend,
		nicknamePlaceholder,
		nicknameTitle,

		registrationTypeLegend,
		phoneNumberTypeText,
		emailTypeText,

		phoneNumberLegend,
		phoneNumberTitle,

		emailLegend,
		emailPlaceholder,
		emailTitle,

		passwordLegend,
		passwordTitle,

		passwordConfirmLegend,
		passwordConfirmTitle,

		acceptPolicyText,

		buttonText,
	} = useLocalization(SIGN_UP_MAIN_FORM_LOCALIZATION);

	const nicknameField = useInputField('nickname');
	const registrationTypeField = useInputField('registration_type');
	const phoneNumberField = useInputField('phone_number_expected');
	const emailField = useInputField('email');
	const passwordField = useInputField('password');
	const passwordConfirmField = useInputField('password_confirm');

	const [policyOfIntent, setPolicyOfIntent] = useState(false);
	const [notice, setNotice] = useState('');

	const registrationTypes = [phoneNumberField.name, emailField.name];
	const isPhoneNumberType = registrationTypeField.value === registrationTypes[0];
	const isEmailType = registrationTypeField.value === registrationTypes[1];

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice, nicknameField, phoneNumberField, emailField, passwordField);
		}
	}, [error]);

	const submit = () => {
		const errors = [];

		errors.push(formValidateLogin(nicknameField.value));
		nicknameField.setErrors(errors[errors.length - 1]);

		errors.push(formValidateRadio(registrationTypeField.value, registrationTypes));
		registrationTypeField.setErrors(errors[errors.length - 1]);

		if (isPhoneNumberType) {
			errors.push(formValidatePhoneNumber(phoneNumberField.value));
			phoneNumberField.setErrors(errors[errors.length - 1]);
		}

		if (isEmailType) {
			errors.push(formValidateEmail(emailField.value));
			emailField.setErrors(errors[errors.length - 1]);
		}

		errors.push(formValidatePassword(passwordField.value));
		passwordField.setErrors(errors[errors.length - 1]);

		errors.push(formValidatePasswordConfirm(passwordField.value, passwordConfirmField.value));
		passwordConfirmField.setErrors(errors[errors.length - 1]);

		const isInvalidForm = !!errors.find(items => items.length > 0);

		if (isInvalidForm) {
			return;
		}

		if (isEmailType) {
			const dataRequest = {
				nickname: nicknameField.value,
				password: passwordField.value,
				emailExpected: emailField.value,
			};

			return dispatch(signUpEmailApi(dataRequest));
		}

		const dataRequest = {
			nickname: nicknameField.value,
			password: passwordField.value,
			phoneNumberExpected: libClearIntstr(phoneNumberField.value),
		};

		dispatch(signUpPhoneNumberApi(dataRequest));
	};

	return (
		<Form className={ styles.signUpForm } onSubmit={ submit }>
			<FormField
				legend={ nicknameLegend }
				errors={ nicknameField.errors }
				required
			>
				<FormInput
					placeholder={ nicknamePlaceholder }
					title={ nicknameTitle }
					name={ nicknameField.name }
					value={ nicknameField.value }
					onChange={ e => nicknameField.setValue(e.target.value) }
					disabled={ isLoading }
				/>
			</FormField>

			<FormField
				legend={ registrationTypeLegend }
				errors={ registrationTypeField.errors }
				required
			>
				<div className={ styles.radioBox }>
					<FormRadio
						id="by-phone-number"
						value={ phoneNumberField.name }
						name={ registrationTypeField.name }
						checked={ isPhoneNumberType }
						onChange={ e => registrationTypeField.setValue(e.target.value) }
						disabled={ isLoading }
					>{ phoneNumberTypeText }</FormRadio>

					<FormRadio
						id="by-email"
						value={ emailField.name }
						name={ registrationTypeField.name }
						checked={ isEmailType }
						onChange={ e => registrationTypeField.setValue(e.target.value) }
						disabled={ isLoading }
					>{ emailTypeText }</FormRadio>
				</div>
			</FormField>

			{ isPhoneNumberType &&
				<FormField
					legend={ phoneNumberLegend }
					errors={ phoneNumberField.errors }
					required
				>
					<FormInputMask
						placeholder="+7 ( ___ ) ___ - __ - __"
						mask="+7 ( 999 ) 999 - 99 - 99"
						title={ phoneNumberTitle }
						name={ phoneNumberField.name }
						value={ phoneNumberField.value }
						onChange={ e => phoneNumberField.setValue(e.target.value) }
						disabled={ isLoading }
					/>
				</FormField>
			}

			{ isEmailType &&
				<FormField
					legend={ emailLegend }
					errors={ emailField.errors }
					required
				>
					<FormInput
						placeholder={ emailPlaceholder }
						title={ emailTitle }
						name={ emailField.name }
						value={ emailField.value }
						onChange={ e => emailField.setValue(e.target.value) }
						disabled={ isLoading }
					/>
				</FormField>
			}

			{ (isPhoneNumberType || isEmailType) &&
				<>
					<FormPassword
						legend={ passwordLegend }
						errors={ passwordField.errors }
						placeholder="••••••••"
						title={ passwordTitle }
						name={ passwordField.name }
						value={ passwordField.value }
						onChange={ e => passwordField.setValue(e.target.value) }
						disabled={ isLoading }
						required
					/>

					<FormPassword
						legend={ passwordConfirmLegend }
						errors={ passwordConfirmField.errors }
						placeholder="••••••••"
						title={ passwordConfirmTitle }
						name={ passwordConfirmField.name }
						value={ passwordConfirmField.value }
						onChange={ e => passwordConfirmField.setValue(e.target.value) }
						disabled={ isLoading }
						required
					/>

					<FormCheckbox
						required
						name="policy_of_intent"
						id="policy-of-intent"
						checked={ policyOfIntent }
						onChange={ () => setPolicyOfIntent(!policyOfIntent) }
						disabled={ isLoading }
					>{ acceptPolicyText }</FormCheckbox>
				</>
			}

			{ notice && <FormNotice className="error">{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton disabled={ !policyOfIntent } busy={ isLoading }>{ buttonText }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
