import { FormButton, Icon, PageRow } from 'src/components';
import { TAccountProject } from 'src/store';
import { formValidateNickname, useInputFormField, useLocalization } from 'src/toolkit';

import { AccountExperienceProjectsBox, AccountExperienceProjectSelectBox } from './components';
import { ACCOUNT_EXPERIENCE_PROJECT_FIELD_LOCALIZATION } from './localization';

import styles from './AccountExperienceProjectsField.module.scss';


type TProps = {
	isLoading?: boolean;
	items: TAccountProject[];
	setItems: (value: TAccountProject[]) => void;
	projectsSource: TAccountProject[];
};

export function AccountExperienceProjectsField(props: TProps) {
	const { headline, button } = useLocalization(ACCOUNT_EXPERIENCE_PROJECT_FIELD_LOCALIZATION);

	const projectField = useInputFormField({
		name: 'projects',
		validation: value => formValidateNickname(value),
	});

	const {
		isLoading,
		items,
		setItems,
		projectsSource,
	} = props;

	const onClick = () => {
		const errors = [
			projectField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const value = projectField.value;

		const existedItem = items.find(item => item.alias === value);

		projectField.clear();

		if (existedItem) {
			return;
		}

		const item = projectsSource.find(item => item.alias === value);
		if (!item) {
			return;
		}

		setItems([...items, item]);
	};


	return (
		<>
			<div className={ styles.headline }>{ headline }</div>

			<PageRow className={ styles.row }>
				<AccountExperienceProjectSelectBox { ...projectField } items={ projectsSource } isLoading={ isLoading } />

				<FormButton
					onClick={ onClick }
					type="button"
					title={ button }
					disabled={ isLoading || !projectField.value }
					mini
				>
					<Icon icon="icon-plus" />
				</FormButton>
			</PageRow>

			<AccountExperienceProjectsBox items={ items } setItems={ setItems } />
		</>
	);
}
