import { useEffect } from 'react';
import { BreadCrumbs, PageCol, PageHeadline, Preloader } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { PATH_ACCOUNT, PATH_ACCOUNT_PHONE_NUMBER } from 'src/routes';
import { useLocalization } from 'src/toolkit';
import { setTitle } from 'src/common/helpers';
import { accountPhoneNumberReadGetApi, clearAccountPhoneNumberRead, clearAccountPhoneNumberUpdate, clearAccountPhoneNumberUpdateConfirm, useAccountPhoneNumberReadSelector, useAppDispatch } from 'src/store';
import { ErrorWidget } from 'src/pages/components';

import { ACCOUNT_COMMON_LOCALIZATION } from '../../localization';

import { AccountPhoneNumberConfirmed, AccountPhoneNumberRouter } from './components';
import { ACCOUNT_PHONE_NUMBER_COMMON_LOCALIZATION } from './localization';


export function AccountPhoneNumberPage() {
	const dispatch = useAppDispatch();
	const { item, isLoading, error } = useAccountPhoneNumberReadSelector();
	const { breadCrumbAccount } = useLocalization(ACCOUNT_COMMON_LOCALIZATION);
	const {
		title,
		headline,
		breadCrumbPhoneNumber,
	} = useLocalization(ACCOUNT_PHONE_NUMBER_COMMON_LOCALIZATION);

	useEffect(() => {
		dispatch(accountPhoneNumberReadGetApi());

		return () => {
			dispatch(clearAccountPhoneNumberRead());
			dispatch(clearAccountPhoneNumberUpdate());
			dispatch(clearAccountPhoneNumberUpdateConfirm());
		};
	}, []);

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader />
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	setTitle(title);

	const items = [
		{ to: PATH_ACCOUNT, text: breadCrumbAccount },
		{ to: PATH_ACCOUNT_PHONE_NUMBER, text: breadCrumbPhoneNumber },
	];

	return (
		<AccountLayout>
			<BreadCrumbs items={ items } />

			<PageHeadline>{ headline }</PageHeadline>

			<PageCol>
				<AccountPhoneNumberConfirmed item={ item } />

				<AccountPhoneNumberRouter item={ item } />
			</PageCol>
		</AccountLayout>
	);
}
