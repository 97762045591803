import { ModalIcon } from '../modal-icon';

import styles from './ModalClose.module.scss';


type TProps = {
	onClick: () => void;
};

export function ModalClose(props: TProps) {
	const { onClick } = props;

	return (
		<div
			onClick={ onClick }
			className={ styles.modalClose }
		>
			<ModalIcon icon="icon-cancel"></ModalIcon>
		</div>
	);
}
