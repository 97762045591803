import { LinkProps } from 'react-router-dom';

import { FormButtonLink } from 'src/components';

import styles from './LocaleBoxItem.module.scss';


type TProps = LinkProps & {
	disabled?: boolean;
};

export function LocaleBoxItem(props: TProps) {
	return (
		<FormButtonLink className={ styles.buttonLink } { ...props } />
	);
}
