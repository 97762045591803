import { TPublicEducation } from 'src/store';

import styles from './PublicCvEducationItem.module.scss';

type TProps = {
	item: TPublicEducation;
};

export function PublicCvEducationItem(props: TProps) {
	const { item } = props;

	const {
		dateEnd,
		city,
		shortName,
		educationalInstitution,
		faculty,
		specialization,
	} = item;

	return (
		<div className={ styles.box }>
			<div className={ styles.year }>{ dateEnd }</div>

			<div className={ styles.wrap }>
				<div>{ shortName }, { city }</div>
				<div className={ styles.percepted }>{ educationalInstitution }</div>
				<div>{ faculty }</div>
				<div>{ specialization }</div>
			</div>
		</div>
	);
}
