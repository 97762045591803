import { ReactNode } from 'react';

import { HeaderCore } from '../header-core';

import styles from './HeaderHome.module.scss';


type TProps = {
	children?: ReactNode;
	style?: Record<string, string>;
};

export function HeaderHome(props: TProps) {
	const top = [styles.fade, styles.top];
	const bottom = [styles.fade, styles.bottom];

	return (
		<HeaderCore className={ styles.headerHome }>
			<div className={ styles.background }></div>

			<h2 className={ styles.title }>
				<div className={ styles.topText }>CV Frontend</div>
				<div className={ styles.bottomText }>REACT</div>
				<img src="/images/logo.svg" className={ styles.logo } alt="logo" />
			</h2>

			<div className={ styles.headline }>Peace ☮ Love ♥️ Code</div>

			<div className={ top.join(' ') }></div>
			<div className={ bottom.join(' ') }></div>
		</HeaderCore>
	);
}
