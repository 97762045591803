import { ChangeEventHandler, useState } from 'react';
import { Form, FormButton, FormField, FormInput, FormRow } from 'src/components';
import { FormIcon } from 'src/components/form/form-icon';
import { formValidateLogin } from 'src/toolkit';

import styles from './FormImage.module.scss';


type TProps = {
	nickname?: string;
	src?: string;
	localeText?: Record<string, string>;
};

export function FormImage(props: TProps) {
	const {
		src = '/images/logo.svg',
		nickname = 'admina',
		localeText = {},
	} = props;

	const {
		legend = 'Image',
		buttonTextChange = 'Change image',
		buttonTextSelect = 'Select image',
	} = localeText;
	const [image, setImage] = useState('');
	const [imageErrors, setImageErrors] = useState([] as string[]);

	console.log(image, setImage);

	const submit = () => {
		const errors = [];

		errors.push(formValidateLogin(nickname));
		setImageErrors(errors[errors.length - 1]);

		const isInvalidForm = !!errors.find(items => items.length > 0);

		if (isInvalidForm) {
			return;
		}

		console.log('REQUEST');
	};

	const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		console.log(e.target.files);
		console.log(e.target.files && e.target.files.length);
		console.log(e.target.files && e.target.files[0]);
	};

	const avatarSymbol = nickname.slice(0, 1).toUpperCase();

	const buttonText = src ? buttonTextChange : buttonTextSelect;

	return (
		<>
			<Form className={ styles.formImage }>
				<FormField
					legend={ legend }
					errors={ imageErrors }
					required
				>

				{ src && <img className={ styles.img } src={ src } alt="user_image" /> }

				{ !src && <div className={ styles.symbolBox }><span>{ avatarSymbol }</span></div> }

					<FormRow>
						<FormButton file>
							{ buttonText }
							<FormInput
								type="file"
								name="image"
								accept="image/jpeg, image/png"
								title="Select an image"
								value={ image }
								onChange={ onChange }
							/>
						</FormButton>

						<FormButton
							v-if="avatar"
							onClick={ submit }
							tabIndex={ -1 }

							type="button"
							mini
						>
							<FormIcon icon="icon-bin"></FormIcon>
						</FormButton>
					</FormRow>
				</FormField>
			</Form>
		</>
	);
}
