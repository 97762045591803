import { MutableRefObject, useRef } from 'react';


type TFunc = () => void;
type TElem = MutableRefObject<HTMLInputElement | null>;

export function useFocus(): [TElem, TFunc] {
	const htmlElRef = useRef<HTMLInputElement>(null);

	const setFocus = () => {
		htmlElRef.current && htmlElRef.current.focus();
	};

	return [htmlElRef, setFocus];
}
