import { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useEffect, useState } from 'react';

import { FormSearchList } from './components';
import { TFormSearchBoxOption } from './types';

import styles from './FormSearchBox.module.scss';


type TProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
	onClickItem?: (value: string) => void;
	items?: TFormSearchBoxOption[];
};

const TRANSITION_DURATION = 100;

export const FormSearchBox = forwardRef<HTMLInputElement, TProps>((props, ref) => {
	const {
		onClickItem = () => {},
		items: itemsOuter = [],
		...restFields
	} = props;

	const [value, setValue] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [items, setItems] = useState(itemsOuter);

	useEffect(() => {
		if (value) {
			const valueLowerCase = value.toLowerCase();

			const itemsFiltered = itemsOuter.filter(item => {
				const { search } = item;

				const itemValue = search.toLowerCase();

				return itemValue.includes(valueLowerCase);
			});

			setItems(itemsFiltered);
		} else {
			setItems(itemsOuter);
		}
	}, [value]);

	const onChange = (value: string) => {
		setValue(value);
	};

	const onFocus = () => {
		setTimeout(() => setIsActive(true), TRANSITION_DURATION);
	};

	const onBlur = () => {
		setTimeout(() => setIsActive(false), TRANSITION_DURATION);
	};

	return (
		<div className={ styles.box }>
			<input
				ref={ ref }
				className={ styles.input }
				onChange={ e => onChange(e.target.value) }
				onFocus={ onFocus }
				onBlur={ onBlur }
				{ ...restFields }
			/>

			{ items.length > 0 && <FormSearchList items={ items } isActive={ isActive } onClick={ onClickItem }/> }
		</div>
	);
});
