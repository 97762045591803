import { FooterLine } from '../footer-line';

import styles from './FooterCol.module.scss';


type TProps = {
	items?: string[];
	style?: Record<string, string>;
};

export function FooterCol(props: TProps) {
	const {
		items = [],
		style = {},
	} = props;

	return (
		<div className={ styles.col } style={ style }>
			{
				items.map((item, index) => {
					const type = index > 0 ? 'line' : 'head';

					return <FooterLine item={ item } type={ type } key={ `${item}-${index}` } />
				})
			}
		</div>
	);
}
