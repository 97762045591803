export const ACCOUNT_EXPERIENCE_TABLE_RU_LOCALIZATION = {
	image: 'Иконка',
	title: 'Название компании',
	versions: 'Языковые версии',
	createdAt: 'Дата создания',
	updatedAt: 'Дата обновления',

	searchPlaceholder: 'Поиск',
	searchTitle: 'Введите текст для поиска',

	emptyText: 'Записи опыта не найдены',
};
