// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavLogo_logo__p22oZ {\n  border: 2px solid transparent;\n  border-radius: 0.4em;\n  box-sizing: border-box;\n  display: inline-block;\n  margin: 0;\n  height: 2.5em;\n  width: 2.5em;\n  overflow: hidden;\n  white-space: nowrap;\n  transition: background-color 0.1s ease-in-out, border 0.1s ease-in-out;\n}\n.NavLogo_logo__p22oZ:hover, .NavLogo_logo__p22oZ:active {\n  background-color: rgb(225, 225, 225);\n}\n.NavLogo_logo__p22oZ:hover {\n  border-color: rgb(200, 200, 200);\n}\n.NavLogo_logo__p22oZ:active {\n  border-color: rgb(90, 90, 90);\n}\n\n.NavLogo_logo__p22oZ img {\n  box-sizing: border-box;\n  display: block;\n  max-height: 100%;\n  max-width: 100%;\n}\n\n@media screen and (max-width: 360px) {\n  .NavLogo_logo__p22oZ {\n    display: none;\n    min-width: 2em;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/nav/nav-logo/NavLogo.module.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACA,oBAAA;EACA,sBAAA;EAEC,qBAAA;EAED,SAAA;EAEC,aAAA;EACA,YAAA;EAED,gBAAA;EACA,mBAAA;EAEA,sEAAA;AAJD;AAMC;EAEC,oCAAA;AALF;AAQC;EACC,gCAAA;AANF;AASC;EACC,6BAAA;AAPF;;AAYA;EACE,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AATF;;AAYA;EACC;IACC,aAAA;IACA,cAAA;EATA;AACF","sourcesContent":[".logo {\n\tborder: 2px solid transparent;\n\tborder-radius: 0.4em;\n\tbox-sizing: border-box;\n\n  display: inline-block;\n\n\tmargin: 0;\n\n  height: 2.5em;\n  width: 2.5em;\n\n\toverflow: hidden;\n\twhite-space: nowrap;\n\n\ttransition: background-color 0.1s ease-in-out, border 0.1s ease-in-out;\n\n\t&:hover,\n\t&:active {\n\t\tbackground-color: rgba(225, 225, 225, 1);\n\t}\n\n\t&:hover {\n\t\tborder-color: rgba(200, 200, 200, 1);\n\t}\n\n\t&:active {\n\t\tborder-color: rgba(90, 90, 90, 1);\n\t}\n\n}\n\n.logo img {\n  box-sizing: border-box;\n  display: block;\n  max-height: 100%;\n  max-width: 100%;\n}\n\n@media screen and (max-width: 360px) {\n\t.logo {\n\t\tdisplay: none;\n\t\tmin-width: 2em;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "NavLogo_logo__p22oZ"
};
export default ___CSS_LOADER_EXPORT___;
