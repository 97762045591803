import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { libFormatDate, useLocalization } from 'src/toolkit';

import styles from './PublicCvExperienceDateTimeRange.module.scss';
import { LOCALIZATION } from 'src/localization';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	start: string;
	end?: string;
	className?: string;
	classNames?: string[];
};

export function PublicCvExperienceDateTimeRange(props: TProps) {
	const {
		until,
		januaryParental,
		februaryParental,
		marchParental,
		aprilParental,
		mayParental,
		juneParental,
		julyParental,
		augustParental,
		septemberParental,
		octoberParental,
		novemberParental,
		decemberParental,
	} = useLocalization(LOCALIZATION);

	const {
		start,
		end = null,
		className = '',
		classNames: classNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.text, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	const months = [
		januaryParental,
		februaryParental,
		marchParental,
		aprilParental,
		mayParental,
		juneParental,
		julyParental,
		augustParental,
		septemberParental,
		octoberParental,
		novemberParental,
		decemberParental,
	];

	const template = 'MM yyyy';
	const dateStartFormat = libFormatDate(start, { template, months });
	const dateEndFormat = end ? libFormatDate(end, { template, months }) : until;

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			{ dateStartFormat } — { dateEndFormat.toLowerCase() }
		</div>
	);
}
