import { useEffect } from 'react';

import { TableBox, TableRowEmpty, Icon } from 'src/components';
import { AccountExperienceSortCol, TAccountExperienceSortCol, setAccountExperiencesQueryLimit, setAccountExperiencesQueryOffset, setAccountExperiencesQuerySearch, setAccountExperiencesQuerySort, setAccountExperiencesQuerySortCol, accountExperiencesGetApi, useAppDispatch, useAccountExperiencesSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { useDebounce, useIsMobile, useLocalization } from 'src/toolkit/hooks';
import { TTableSort } from 'src/components/table/thead/col';

import { AccountExperienceListRow } from './components';
import { ACCOUNT_EXPERIENCE_TABLE_LOCALIZATION } from './localization';

import styles from './AccountExperienceList.module.scss';


export function AccountExperienceList() {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();

	const { query, list, isLoading, error } = useAccountExperiencesSelector();
	const { limit, offset, search, sort, sortCol } = query;
	const { items, count = 0 } = list;

	const searchDebounced = useDebounce(search || '');
	const {
		image,
		title,
		versions,
		createdAt,
		updatedAt,
		emptyText,
		searchPlaceholder,
		searchTitle,
	} = useLocalization(ACCOUNT_EXPERIENCE_TABLE_LOCALIZATION);

	useEffect(() => {
		dispatch(accountExperiencesGetApi(query));
	}, [limit, offset, sort, sortCol, searchDebounced]);

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const theadSettings = {
		sort,
		setSort: (value: TTableSort) => dispatch(setAccountExperiencesQuerySort(value)),
		sortCol,
		setSortCol: (value: TAccountExperienceSortCol) => dispatch(setAccountExperiencesQuerySortCol(value)),
		items: [
			{ name: image },
			{ name: title, colName: AccountExperienceSortCol.COMPANY_NAME },
			{ name: versions },
			{ name: createdAt, colName: AccountExperienceSortCol.CREATED_AT },
			{ name: updatedAt, colName: AccountExperienceSortCol.UPDATED_AT },
		],
	};

	const controlSettings = {
		limit,
		setLimit: (value: number) => dispatch(setAccountExperiencesQueryLimit(value)),
		search,
		searchPlaceholder,
		searchTitle,
		setSearch: (value: string) => dispatch(setAccountExperiencesQuerySearch(value)),
		onSubmit: () => {},
		options: [1, 2, 3, 5, 10, 25, 50, 100],
	};

	const paginationSettings = {
		itemsLength: items.length,
		count,
		limit,
		offset,
		setOffset: (value: number) => dispatch(setAccountExperiencesQueryOffset(value)),
		prevLabel: <Icon icon="icon-chevron-left" className={ styles.iconChevron } />,
		nextLabel: <Icon icon="icon-chevron-right" className={ styles.iconChevron } />,
		isArrowsControls: !isMobile,
	};

	if (items.length === 0) {
		return (
			<TableBox
				isLoading={ isLoading }
				thead={ theadSettings }
				control={ controlSettings }
				pagination={ paginationSettings }
			>
				<TableRowEmpty colSpan={ 5 }>{ emptyText }</TableRowEmpty>
			</TableBox>
		);
	}

	return (
		<TableBox
			isLoading={ isLoading }
			thead={ theadSettings }
			control={ controlSettings }
			pagination={ paginationSettings }
		>
			{ items.map(item => <AccountExperienceListRow key={ item.pid } { ...item } />) }
		</TableBox>
	);
}
