import styles from './tablePreloader.module.scss';


export function TablePreloader() {
	return (
		<div className={ styles.tablePreloader }>
			<div className={ styles.tablePreloaderBox }>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}
