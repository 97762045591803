import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import styles from './FormButton.module.scss';


type TProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
	mini?: boolean;
	busy?: boolean;
	file?: boolean;
	cancel?: boolean;
	margin?: boolean;
	first?: boolean;
	middle?: boolean;
	last?: boolean;
};

export function FormButton(props: TProps) {
	const {
		children,
		mini = false,
		busy = false,
		file = false,
		cancel = false,
		margin = false,
		first = false,
		last = false,
		middle = false,
		className = '',
		...restProps
	} = props;

	const classNameArr = [styles.formButton];

	if (mini) {
		classNameArr.push(styles.mini);
	}

	if (cancel) {
		classNameArr.push(styles.cancel);
	}

	if (margin) {
		classNameArr.push(styles.margin);
	}

	if (first) {
		classNameArr.push(styles.first);
	}

	if (last) {
		classNameArr.push(styles.last);
	}

	if (middle) {
		classNameArr.push(styles.middle);
	}

	if (className && styles[className]) {
		classNameArr.push(styles[className]);
	} else if (className) {
		classNameArr.push(className);
	}

	if (file) {
		return (
			<button type="button" className={ classNameArr.join(' ') } { ...restProps }>
				<label>
					{ children }
				</label>
			</button>
		);
	}

	if (busy) {
		classNameArr.push(styles.busy);
	}

	return (
		<button className={ classNameArr.join(' ') } { ...restProps }>
			<span>
				{ children }
			</span>

			<div className={ styles.animation }></div>
		</button>
	);
}
