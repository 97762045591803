import { Link } from 'src/components';

import { TableBodyCol, TableBodyRow } from 'src/components';

import styles from './AccountProjectsListRow.module.scss';
import { TAccountProject } from 'src/store';
import { libFormatDate } from 'src/toolkit';

import { LocaleBox } from 'src/pages/components';
import { PATH_ACCOUNT_PROJECTS } from 'src/routes';


type TProps = TAccountProject & {
	to: string;
};

export function AccountProjectsListRow(props: TProps) {
	const {
		alias,
		title,
		to,
		image = '',
		locales,
		createdAt,
		updatedAt,
	} = props;

	return (
		<TableBodyRow>
			<TableBodyCol className={ styles.imageCol } center>
				{ image && <img className={ styles.image } src={ image } alt={ alias } /> }
			</TableBodyCol>

			<TableBodyCol><Link to={ to }>{ title }</Link></TableBodyCol>

			<TableBodyCol left>
				<LocaleBox pathStart={ `${PATH_ACCOUNT_PROJECTS}/${alias}` } items={ locales }/>
			</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(createdAt) }</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(updatedAt) }</TableBodyCol>
		</TableBodyRow>
	);
}
