import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import styles from './PublicCvExperienceItemLogo.module.scss';


type TProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	classNames?: string[];
	boxClassName?: string;
	boxClassNames?: string[];
};

export function PublicCvExperienceItemLogo(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		boxClassName = '',
		boxClassNames: boxClassNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.image, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	const boxClassNames = [styles.box, ...boxClassNamesOuter];
	if (boxClassName) {
		boxClassNames.push(boxClassName);
	}


	return (
		<div className={ boxClassNames.join(' ') }>
			<img className={ classNames.join(' ') } { ...restProps } />
		</div>
	);
}
