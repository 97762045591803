import { useEffect } from 'react';

import { BreadCrumbs, Icon, PageCol, PageHeadline, PageRow, Preloader } from 'src/components';
import { PageButtonLink } from 'src/components/page/page-button-list';
import { AccountLayout } from 'src/layouts';
import { PATH_ACCOUNT, PATH_ACCOUNT_EMAIL, PATH_ACCOUNT_PASSWORD, PATH_ACCOUNT_PHONE_NUMBER } from 'src/routes';
import { EmailPageLink, ErrorWidget, PhoneNumberPageLink } from 'src/pages/components';
import { setTitle } from 'src/common/helpers';
import { accountReadGetApi, clearAccountRead, useAccountReadSelector, useAppDispatch } from 'src/store';
import { formValidateEmail, formValidatePhoneNumber, libNumberMask, useInputFormField, useLocalization } from 'src/toolkit';

import { ACCOUNT_COMMON_LOCALIZATION } from '../../localization';

import { AccountForm } from './components';
import { ACCOUNT_MAIN_COMMON_LOCALIZATION } from './localization';

import styles from './AccountPage.module.scss';


export function AccountPage() {
	const dispatch = useAppDispatch();
	const { data, isLoading, error } = useAccountReadSelector();
	const { breadCrumbAccount } = useLocalization(ACCOUNT_COMMON_LOCALIZATION);
	const {
		title,
		headline,
		passwordButton,

		phoneNumberLegend,
		phoneNumberConfirmedTitle,
		phoneNumberUnconfirmedTitle,
		phoneNumberButtonTitle,

		emailLegend,
		emailConfirmedTitle,
		emailUnconfirmedTitle,
		emailButtonTitle,
	} = useLocalization(ACCOUNT_MAIN_COMMON_LOCALIZATION);

	const phoneNumberField = useInputFormField({
		name: 'phone_number',
		defaultValue: '',
		validation: value => formValidatePhoneNumber(value),
	});

	const emailField = useInputFormField({
		name: 'email',
		defaultValue: '',
		validation: value => formValidateEmail(value),
	});

	useEffect(() => {
		dispatch(accountReadGetApi());

		return () => {
			dispatch(clearAccountRead());
		};
	}, []);

	useEffect(() => {
		if (data) {
			const { phoneNumber: phoneNumberRaw, email: emailRaw } = data;
			const phoneNumber = phoneNumberRaw ? libNumberMask(phoneNumberRaw) : '';
			const email = emailRaw ? emailRaw : '';

			phoneNumberField.setValue(phoneNumber);
			emailField.setValue(email);
		}
	}, [data]);

	if (isLoading || !data) {
		return (
			<AccountLayout>
				<div>
					<Preloader />
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	setTitle(title);

	const items = [
		{ to: PATH_ACCOUNT, text: breadCrumbAccount },
	];

	const isConfirmedPhoneNumber = !!phoneNumberField.value;
	const titlePhoneNumber = isConfirmedPhoneNumber ? phoneNumberConfirmedTitle : phoneNumberUnconfirmedTitle;

	const isConfirmedEmail = !!emailField.value;
	const titleEmail = isConfirmedEmail ? emailConfirmedTitle : emailUnconfirmedTitle;

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ items } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageRow>
					<PageCol>
						<AccountForm { ...data } />
					</PageCol>

					<PageCol>
						<div className={ styles.linkBox }>
							<PhoneNumberPageLink
								legend={ phoneNumberLegend }
								title={ titlePhoneNumber }
								buttonTitle={ phoneNumberButtonTitle }
								placeholder="—"
								confirmed={ isConfirmedPhoneNumber }
								to={ PATH_ACCOUNT_PHONE_NUMBER }
								{ ...phoneNumberField }
							/>

							<EmailPageLink
								legend={ emailLegend }
								title={ titleEmail }
								buttonTitle={ emailButtonTitle }
								placeholder="—"
								confirmed={ isConfirmedEmail }
								to={ PATH_ACCOUNT_EMAIL }
								{ ...emailField }
							/>

							<PageButtonLink text={ passwordButton } href={ PATH_ACCOUNT_PASSWORD }>
								<Icon icon="icon-lock" className={ styles.icon } />
							</PageButtonLink>
						</div>
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
