// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountPage_linkBox__XTde- {\n  padding: 1em;\n}\n\n.AccountPage_icon__urVl4 {\n  font-size: 1.4em;\n  width: 1.25em;\n  min-width: 1.25em;\n  max-width: 1.25em;\n  vertical-align: middle;\n}", "",{"version":3,"sources":["webpack://./src/pages/account/views/main/AccountPage.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;;AAEA;EACC,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,sBAAA;AACD","sourcesContent":[".linkBox {\n\tpadding: 1em;\n}\n\n.icon {\n\tfont-size: 1.4em;\n\twidth: 1.25em;\n\tmin-width: 1.25em;\n\tmax-width: 1.25em;\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkBox": "AccountPage_linkBox__XTde-",
	"icon": "AccountPage_icon__urVl4"
};
export default ___CSS_LOADER_EXPORT___;
