import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearUserUpdate, useAppDispatch, useUserReadSelector, userReadGetApi, clearUserRead, useUsersSelector, setUserRead, clearUserCreatedItem } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidNickname, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_USERS } from 'src/routes';

import { USERS_COMMON_LOCALIZATION } from '../../localization';

import { UserFormUpdate, UserDetails } from './components';
import { USERS_UPDATE_LOCALIZATION } from './localization';


export function UserUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const { nickname: nicknameParam = '' } = useParams();
	const { item: created } = useUsersSelector();
	const { data: item, isLoading, error } = useUserReadSelector();
	const { breadCrumbList } = useLocalization(USERS_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(USERS_UPDATE_LOCALIZATION);

	const isInvalidNick = isInvalidNickname(nicknameParam);

	useEffect(() => {
		const isExisted = created && created.nickname === nicknameParam;
		const isNotRequest = isInvalidNick || isExisted;

		if (isExisted) {
			dispatch(setUserRead(created));
		}

		if (!isNotRequest) {
			dispatch(userReadGetApi(nicknameParam));
		}

		return () => {
			dispatch(clearUserUpdate());
			dispatch(clearUserRead());
			dispatch(clearUserCreatedItem());
		};
	}, []);

	if (isInvalidNick) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const { nickname } = item;

	setTitle(`${breadCrumbItem} ${nickname}`);

	const breadCrumbs = [
		{ to: PATH_USERS, text: breadCrumbList },
		{ to: pathname, text: nickname },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { nickname }</PageHeadline>

				<PageRow>
					<PageCol>
						<UserFormUpdate { ...item } />
					</PageCol>

					<PageCol>
						<UserDetails { ...item } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
