// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkDelete_link__ZY\\+mI {\n  border-bottom: 1px solid rgb(180, 70, 70);\n  color: rgb(180, 70, 70);\n  cursor: pointer;\n  display: inline;\n  font-size: 1em;\n  font-weight: 200;\n  line-height: 1.2em;\n  height: 1.2em;\n  white-space: normal;\n  text-decoration: none;\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n}\n.LinkDelete_link__ZY\\+mI:active, .LinkDelete_link__ZY\\+mI:hover {\n  border-bottom: 1px solid transparent;\n  color: rgb(200, 50, 50);\n}\n.LinkDelete_link__ZY\\+mI:active {\n  transition: none;\n}\n\n.LinkDelete_linkContainer__neghU {\n  text-align: right;\n  padding: 1em;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/link-delete/LinkDelete.module.scss"],"names":[],"mappings":"AAGA;EACC,yCAAA;EACA,uBALO;EAMP,eAAA;EACA,eAAA;EAEA,cAAA;EACA,gBAAA;EAEA,kBAAA;EACA,aAAA;EAEA,mBAAA;EAEA,qBAAA;EAEC,2DAAA;AAPF;AASC;EAEC,oCAAA;EACA,uBAvBW;AAeb;AAWC;EACC,gBAAA;AATF;;AAaA;EACC,iBAAA;EACA,YAAA;AAVD","sourcesContent":["$color: rgba(180, 70, 70, 1);\n$colorHover: rgba(200, 50, 50, 1);\n\n.link {\n\tborder-bottom: 1px solid $color;\n\tcolor: $color;\n\tcursor: pointer;\n\tdisplay: inline;\n\n\tfont-size: 1em;\n\tfont-weight: 200;\n\n\tline-height: 1.2em;\n\theight: 1.2em;\n\n\twhite-space: normal;\n\n\ttext-decoration: none;\n\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n\n\t&:active,\n\t&:hover {\n\t\tborder-bottom: 1px solid transparent;\n\t\tcolor: $colorHover;\n\t}\n\n\t&:active {\n\t\ttransition: none;\n\t}\n}\n\n.linkContainer {\n\ttext-align: right;\n\tpadding: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "LinkDelete_link__ZY+mI",
	"linkContainer": "LinkDelete_linkContainer__neghU"
};
export default ___CSS_LOADER_EXPORT___;
