import { getExperienceTime } from 'src/common/helpers';
import { useLocalization } from 'src/toolkit';

import { CV_EXPERIENCE_COUNT_LOCALIZATION } from './localization';

import styles from './CvExperienceCount.module.scss';


type TProps = {
	headline?: string;
	className?: string;
	classNames?: string[];
	value: number[];
};

export function CvExperienceCount(props: TProps) {
	const {
		none,
		year1,
		year2,
		year5,
		month1,
		month2,
		month5,
		day1,
		day2,
		day5,
	} = useLocalization(CV_EXPERIENCE_COUNT_LOCALIZATION);

	const {
		headline,
		className = '',
		classNames: classNamesOuter = [],
		value,
	} = props;

	const count = getExperienceTime(value, none, [year1, year2, year5], [month1, month2, month5], [day1, day2, day5]);

	const classNames = [styles.box, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') }>
			{ headline && <div className={ styles.headline }>{ headline }</div> }

			<div className={ styles.text }>{ count }</div>
		</div>
	);
}
