import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PATH_ACCOUNT_EDUCATION, PATH_ACCOUNT_EDUCATION_CREATE_END } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountEducation, accountEducationUpdateApi, setAccountEducationRead, useAppDispatch, useAccountEducationUpdateSelector, TEducationType } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';

import { AccountEducationDateEndField, AccountEducationTypeField } from '../../../../components';
import { ACCOUNT_EDUCATION_UPDATE_LOCALIZATION } from '../../localization';

import { LocaleBoxField } from 'src/pages/components';


type TProps = TAccountEducation & {
	items: TEducationType[];
};

export function AcountEducationFormUpdate(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { item, isLoading, error } = useAccountEducationUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(ACCOUNT_EDUCATION_UPDATE_LOCALIZATION);

	const {
		pid = '',
		locale = '',
		type = '',
		dateEnd = '',
		locales = [],
		items = [],
	} = props;

	const typeField = useInputFormField({
		name: 'type',
		defaultValue: type,
		validation: value => formValidateText(value),
	});

	const dateEndField = useInputFormField({
		name: 'date_end',
		defaultValue: dateEnd.slice(0, 10),
		validation: value => formValidateText(value),
	});

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				typeField,
				dateEndField,
			);
		}

		if (item) {
			setNotice(noticeSuccess);
			dispatch(setAccountEducationRead(item));

			const { pid, locale } = item;
			if (pathname !== pid) {
				navigate(`${PATH_ACCOUNT_EDUCATION}/${pid}/${locale}`);
			}
		}
	}, [error, item, noticeSuccess]);

	const onSubmit = () => {
		const errors = [
			typeField.validate(),
			dateEndField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			type: typeField.value,
			dateEnd: dateEndField.value,
		};

		dispatch(accountEducationUpdateApi({ data: dataRequest, pid }))
	};

	const to = `${PATH_ACCOUNT_EDUCATION}/${pid}${PATH_ACCOUNT_EDUCATION_CREATE_END}`;

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleBoxField to={ to } path={ PATH_ACCOUNT_EDUCATION } alias={ pid } locales={ locales } isLoading={ isLoading } />

			<AccountEducationTypeField { ...typeField } isLoading={ isLoading } locale={ locale } items={ items } />

			<AccountEducationDateEndField { ...dateEndField } isLoading={ isLoading } />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
