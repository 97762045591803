// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvDescription_text__5Naq6 {\n  color: rgb(150, 150, 150);\n  font-size: 1em;\n  margin-top: 0.5em;\n  text-indent: 1em;\n  text-align: justify;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/description/PublicCvDescription.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,cAAA;EAEA,iBAAA;EAEA,gBAAA;EACA,mBAAA;AADD","sourcesContent":[".text {\n\tcolor: rgba(150, 150, 150, 1);\n\tfont-size: 1em;\n\n\tmargin-top: 0.5em;\n\n\ttext-indent: 1em;\n\ttext-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "PublicCvDescription_text__5Naq6"
};
export default ___CSS_LOADER_EXPORT___;
