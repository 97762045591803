import { useEffect, useState } from 'react';

import { FormField, FormSelectBox } from 'src/components';
import { TAccountExperience } from 'src/store';
import { TInputFormField, libFormatDate, useLocalization } from 'src/toolkit';

import { ACCOUNT_CV_EXPERIENCE_SELECT_BOX_LOCALIZATION } from './localization';

import styles from './AccountCvExperienceSelectBox.module.scss';


type TProps = TInputFormField & {
	items: TAccountExperience[];
	isLoading?: boolean;
};

type TOptionItem = {
	text: string;
	value: string;
};

function createOptionItem(item: TAccountExperience): TOptionItem {
	const { pid, dateEnd, companyName } = item;

	const dateFormat = libFormatDate(dateEnd, 'yyyy');

	return { text: `${dateFormat} ${companyName}`, value: pid };
}

export function AccountCvExperienceSelectBox(props: TProps) {
	const { legend, placeholder, title } = useLocalization(ACCOUNT_CV_EXPERIENCE_SELECT_BOX_LOCALIZATION);
	const {
		name,
		value,
		setValue,
		errors,
		items,
		isLoading,
	} = props;

	const [options, setOptions] = useState<TOptionItem[]>([]);

	useEffect(() => {
		if (items.length > 0) {
			const arr = items.map(item => createOptionItem(item));

			setOptions(arr);
		}
	}, [items]);

	const classNames = [styles.selectBox, styles.box];

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			className={ styles.box }
		>
			<FormSelectBox
				title={ title }
				classNames={ classNames }
				items={ options }
				onChange={ val => setValue(val) }
				value={ value }
				name={ name }
				placeholder={ placeholder }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
