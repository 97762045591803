export const ACCOUNT_MAIN_COMMON_RU_LOCALIZATION = {
	title: 'Аккаунт',
	headline: 'Настройки аккаунта',

	phoneNumberLegend: 'Номер телефона',
	phoneNumberConfirmedTitle: 'Номер телефона подтверждён',
	phoneNumberUnconfirmedTitle: 'Номер телефона не подтверждён',
	phoneNumberButtonTitle: 'Перейти в настройки номера телефона',

	emailLegend: 'E-Mail',
	emailConfirmedTitle: 'E-Mail подтверждён',
	emailUnconfirmedTitle: 'E-Mail не подтверждён',
	emailButtonTitle: 'Перейти в настройки E-Mail',

	passwordButton: 'Пароль',
};
