import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './Table.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function Table(props: TProps) {
	const { children, className = '' } = props;
	const classNames = [styles.table];

	if (className) {
		classNames.push(className);
	}

	return (
		<table className={ classNames.join(' ') }>
			{ children }
		</table>
	);
}
