import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserPhoneNumber, TUserPhoneNumberResponse, adaptUserPhoneNumberFromResponse } from '../../types';


export const userPhoneNumberReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/phone-number`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/phone-number`;

		return HttpService.get<TUserPhoneNumberResponse>(path).then(result => {
			return adaptUserPhoneNumberFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TUserPhoneNumber | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPhoneNumberReadSlice = createSlice({
	name: 'userPhoneNumberReadSlice',
	initialState,
	reducers: {
		setUserPhoneNumberRead(state, action: PayloadAction<TUserPhoneNumber>) {
			state.item = action.payload;
		},

		clearUserPhoneNumberRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPhoneNumberReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPhoneNumberReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserPhoneNumber>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPhoneNumberReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useUserPhoneNumberReadSelector = () => useAppSelector(state => state[userPhoneNumberReadSlice.name]);

export const { clearUserPhoneNumberRead, setUserPhoneNumberRead } = userPhoneNumberReadSlice.actions;
