// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableBox_tableBox__U0o3V {\n  box-sizing: border-box;\n  margin: auto;\n  max-width: 800px;\n}", "",{"version":3,"sources":["webpack://./src/components/table/box/TableBox.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EAEA,YAAA;EACC,gBAAA;AAAF","sourcesContent":[".tableBox {\n\tbox-sizing: border-box;\n\n\tmargin: auto;\n  max-width: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": "TableBox_tableBox__U0o3V"
};
export default ___CSS_LOADER_EXPORT___;
