// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormImageSvgDisplay_image__M9Z05 {\n  box-sizing: border-box;\n  display: none;\n  margin-bottom: 0.25em;\n  text-align: center;\n  width: 100%;\n  max-height: 360px;\n  max-width: 360px;\n}\n\n.FormImageSvgDisplay_loaded__jPM3T {\n  display: block;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-image-svg/components/display/FormImageSvgDisplay.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,aAAA;EAEA,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AAAD;;AAGA;EACC,cAAA;AAAD","sourcesContent":[".image {\n\tbox-sizing: border-box;\n\tdisplay: none;\n\n\tmargin-bottom: 0.25em;\n\ttext-align: center;\n\twidth: 100%;\n\tmax-height: 360px;\n\tmax-width: 360px;\n}\n\n.loaded {\n\tdisplay: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "FormImageSvgDisplay_image__M9Z05",
	"loaded": "FormImageSvgDisplay_loaded__jPM3T"
};
export default ___CSS_LOADER_EXPORT___;
