// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvCurrencyField_box__iGthk {\n  margin-left: 0.1em;\n  max-width: 6em;\n}\n\n.AccountCvCurrencyField_selectBox__88SX8 {\n  width: 9em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/currency-field/AccountCvCurrencyField.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,cAAA;AACD;;AAEA;EACC,UAAA;AACD","sourcesContent":[".box {\n\tmargin-left: 0.1em;\n\tmax-width: 6em;\n}\n\n.selectBox {\n\twidth: 9em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvCurrencyField_box__iGthk",
	"selectBox": "AccountCvCurrencyField_selectBox__88SX8"
};
export default ___CSS_LOADER_EXPORT___;
