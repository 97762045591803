import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserPhoneNumber, TUserPhoneNumberResponse, TUserPhoneNumberPayloadUpdate, adaptUserPhoneNumberFromResponse, adaptUserPhoneNumberToRequest } from '../../types';


export const userPhoneNumberUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/phone-number`,
	(payload: TUserPhoneNumberPayloadUpdate, thunkApi) => {
		const { data, nickname } = payload;
		const dataRequest = adaptUserPhoneNumberToRequest(data);
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/phone-number`;

		return HttpService.put<TUserPhoneNumberResponse>(path, dataRequest).then(result => {
			return adaptUserPhoneNumberFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TUserPhoneNumber | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userPhoneNumberUpdateSlice = createSlice({
	name: 'userPhoneNumberUpdateSlice',
	initialState,
	reducers: {
		clearUserPhoneNumberUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPhoneNumberUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userPhoneNumberUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUserPhoneNumber>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userPhoneNumberUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserPhoneNumberUpdateSelector = () => useAppSelector(state => state[userPhoneNumberUpdateSlice.name]);

export const { clearUserPhoneNumberUpdate } = userPhoneNumberUpdateSlice.actions;
