import { DefaultLanguage, useCredentialsSelector } from 'src/store';

type TValueOf<T> = T[keyof T];

type TDictionary = Record<string, string>;

type TLocaleDictionary = {
	[key: string]: TDictionary;
};

const defaultLang = DefaultLanguage.alias;

export function useLocalization(dictionary: TLocaleDictionary): TValueOf<TLocaleDictionary> {
	const { lang } = useCredentialsSelector();

	return dictionary[lang] || dictionary[defaultLang];
}
