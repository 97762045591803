import { TListResponse } from 'src/common/types';


export type TPublicCvLanguage = {
  language: string;
  level: string;
};

export type TPublicCvLanguageList = TListResponse<TPublicCvLanguage>;

export type TPublicCvLanguageResponse = TPublicCvLanguage;
export type TPublicCvLanguageListResponse = TListResponse<TPublicCvLanguageResponse>;

export function adaptPublicCvLanguageFromListResponse(data: TPublicCvLanguageListResponse): TPublicCvLanguageList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptPublicCvLanguageFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptPublicCvLanguageFromResponse(data: TPublicCvLanguageResponse): TPublicCvLanguage {
	return { ...data };
}
