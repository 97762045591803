import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUser, TUserUpdate, TUserResponse, adaptUserFromResponse } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname`,
	(payload: TUserUpdate, thunkApi) => {
		const { data, nickname } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}`;

		return HttpService.put<TUserResponse>(path, data).then(result => {
			return adaptUserFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TUser | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userUpdateSlice = createSlice({
	name: 'userUpdateSlice',
	initialState,
	reducers: {
		clearUserUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userUpdateApi.fulfilled.type]: (state, action: PayloadAction<TUser>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useUserUpdateSelector = () => useAppSelector(state => state[userUpdateSlice.name]);

export const { clearUserUpdate } = userUpdateSlice.actions;
