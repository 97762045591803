import { DetailedHTMLProps, FormHTMLAttributes } from 'react';

import { Form } from 'src/components';

import styles from './FormBox.module.scss';


type TProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export function FormBox(props: TProps) {
	const {
		children,
		className = '',
		...restProps
	} = props;

	const classNames = [styles.formBox];

	if (className) {
		classNames.push(className);
	}

	return (
		<Form className={ classNames.join(' ') } { ...restProps }>
			{ children }
		</Form>
	);
}
