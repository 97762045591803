import { useLocalization } from 'src/toolkit';
import { TAccountEmail } from 'src/store';

import { AccountEmailField } from '../../../../components';

import { ACCOUNT_EMAIL_CONFIRMED_LOCALIZATION } from './localization';


type TProps = {
	item: TAccountEmail;
};

export function AccountEmailConfirmed(props: TProps) {
	const { item } = props;

	const { legend, title } = useLocalization(ACCOUNT_EMAIL_CONFIRMED_LOCALIZATION);

	const { email } = item;

	if (!email) {
		return (
			<></>
		);
	}

	return (
		<AccountEmailField
			value={ email }
			setValue={ () => {} }
			errors={ [] }
			setErrors={ () => {} }
			name="email"
			validate={ () => false }
			clear={ () => {} }

			readOnly
			confirmed={ !!email }
			required={ false }
			legend={ legend }
			title={ title }
		/>
	);
}
