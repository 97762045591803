import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_SOURCE_LANGUAGE_LEVELS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TLanguageLevel, TLanguageLevelListResponse, adaptLanguageLevelsFromListResponse } from '../../types';


export const accountSourceLanguageLevelGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SOURCE_LANGUAGE_LEVELS}/:locale`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SOURCE_LANGUAGE_LEVELS}/${payload}`;

		return HttpService.get<TLanguageLevelListResponse>(path).then(result => {
			return adaptLanguageLevelsFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TLanguageLevel[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	items: undefined,
	error: undefined,
};

export const accountSourceLanguageLevelSlice = createSlice({
	name: 'accountSourceLanguageLevelSlice',
	initialState,
	reducers: {
		clearAccountSourceLanguageLevel(state) {
			return { ...initialState, list: state.items };
		},
	},

	extraReducers: {
		[accountSourceLanguageLevelGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountSourceLanguageLevelGetApi.fulfilled.type]: (state, action: PayloadAction<TLanguageLevel[]>) => {
			state.isLoading = false;
			state.items = action.payload;
			state.error = undefined;
		},

		[accountSourceLanguageLevelGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountSourceLanguageLevelSelector = () => useAppSelector(state => state[accountSourceLanguageLevelSlice.name]);

export const { clearAccountSourceLanguageLevel } = accountSourceLanguageLevelSlice.actions;
