// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvExperienceSelectBox_box__UyjP6 {\n  margin-left: 0.1em;\n  margin-bottom: 0;\n  width: 100%;\n  max-width: 100%;\n}\n\n.AccountCvExperienceSelectBox_selectBox__QWMNg {\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/experience-field/components/experience/AccountCvExperienceSelectBox.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AACD;;AAEA;EACC,eAAA;AACD","sourcesContent":[".box {\n\tmargin-left: 0.1em;\n\tmargin-bottom: 0;\n\twidth: 100%;\n\tmax-width: 100%;\n}\n\n.selectBox {\n\tmax-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvExperienceSelectBox_box__UyjP6",
	"selectBox": "AccountCvExperienceSelectBox_selectBox__QWMNg"
};
export default ___CSS_LOADER_EXPORT___;
