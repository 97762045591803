import { LocalizationTypes } from 'src/common/types';

import { STACK_TABLE_RU_LOCALIZATION } from './ru.localization';
import { STACK_TABLE_EN_LOCALIZATION } from './en.localization';


export const STACK_TABLE_LOCALIZATION = {
	[LocalizationTypes.RU]: STACK_TABLE_RU_LOCALIZATION,
	[LocalizationTypes.EN]: STACK_TABLE_EN_LOCALIZATION,
};
