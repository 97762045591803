// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvExperiencePositionName_name__CYRUb {\n  color: rgb(90, 90, 90);\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/last-work/components/experience-position-name/PublicCvExperiencePositionName.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;AACD","sourcesContent":[".name {\n\tcolor: rgba(90, 90, 90, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "PublicCvExperiencePositionName_name__CYRUb"
};
export default ___CSS_LOADER_EXPORT___;
