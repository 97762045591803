import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';

import styles from './FormInput.module.scss';


type TProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
	classNames?: string[],
};

export const FormInput = forwardRef<HTMLInputElement, TProps>((props, ref) => {
	const {
		classNames: classNamesOuter = [],
		className = '',
		onChange = () => {},
		...restFields
	} = props;

	const classNames = [styles.formInput, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<input
			ref={ ref }
			className={ classNames.join(' ') }
			onChange={ onChange }
			{ ...restFields }
		/>
	);
});
