import { LocalizationTypes } from 'src/common/types';

import { RU_LOCALIZATION } from './ru.localization';
import { EN_LOCALIZATION } from './en.localization';


export const LOCALIZATION = {
	[LocalizationTypes.RU]: RU_LOCALIZATION,
	[LocalizationTypes.EN]: EN_LOCALIZATION,
};
