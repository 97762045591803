// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvBirthday_box__GCK2v {\n  margin-top: 0.25em;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/data/components/birthday/PublicCvBirthday.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD","sourcesContent":[".box {\n\tmargin-top: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvBirthday_box__GCK2v"
};
export default ___CSS_LOADER_EXPORT___;
