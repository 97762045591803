import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountExperience, setAccountExperienceCreatedItem, accountExperienceCreateApi, useAppDispatch, useAccountExperienceCreateSelector } from 'src/store';
import { formHandleServerError, formValidateDate, formValidateLink, formValidateText, libExtractFileName, useInputFileField, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_ACCOUNT_EXPERIENCE } from 'src/routes';

import { AccountExperienceCompanyUriField, AccountExperienceCompanyLogoField, AccountExperienceCompanyNameField, AccountExperienceDateStartField, AccountExperienceDateEndField, AccountExperienceFieldsRow } from '../../../../components';
import { ACCOUNT_EXPERIENCE_CREATE_LOCALIZATION } from '../../localization';


export function AccountExperienceFormCreate(props: TAccountExperience) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountExperienceCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(ACCOUNT_EXPERIENCE_CREATE_LOCALIZATION);

	const {
		companyName = '',
		companyUri = '',
		companyLogo = '',
		dateStart = '',
		dateEnd = '',
	} = props;

	const companyNameField = useInputFormField({
		name: 'name',
		defaultValue: companyName,
		validation: value => formValidateText(value),
	});

	const companyUriField = useInputFormField({
		name: 'company_uri',
		defaultValue: companyUri,
		validation: value => formValidateLink(value),
	});

	const dateStartField = useInputFormField({
		name: 'date_start',
		defaultValue: dateStart,
		validation: value => formValidateDate(value, { required: true }),
	});

	const dateEndField = useInputFormField({
		name: 'date_end',
		defaultValue: dateEnd,
		validation: value => formValidateDate(value),
	});

	const companyLogoField = useInputFileField('image', companyLogo);
	const [file, setFile] = useState<File>();

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				companyNameField,
				companyUriField,
				dateStartField,
			);
		}

		if (item) {
			dispatch(setAccountExperienceCreatedItem(item));
			navigate(`${PATH_ACCOUNT_EXPERIENCE}/${item.pid}`);
		}
	}, [error, item]);

	const onSubmit = () => {
		const errors = [
			companyNameField.validate(),
			companyUriField.validate(),
			dateStartField.validate(),
		];

		console.log(errors);

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			dateStart: dateStartField.value,
			dateEnd: dateEndField.value || undefined,
			companyName: companyNameField.value,
			companyUri: companyUriField.value || undefined,
			companyLogo: file || libExtractFileName(companyLogoField.value),
		};

		dispatch(accountExperienceCreateApi(dataRequest));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<AccountExperienceCompanyLogoField { ...companyLogoField } setFile={ setFile } />

			<AccountExperienceFieldsRow>
				<AccountExperienceDateStartField { ...dateStartField } />

				<AccountExperienceDateEndField { ...dateEndField } />
			</AccountExperienceFieldsRow>

			<AccountExperienceCompanyNameField { ...companyNameField } isLoading={ isLoading } />

			<AccountExperienceCompanyUriField { ...companyUriField } isLoading={ isLoading } />

			<FormNotice errorText={ notice } />

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
