import { DetailedHTMLProps, HTMLAttributes, useState } from 'react';

import { CvHeadline } from 'src/components';
import { TAccountStack } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { PublicStackDescription, PublicStackItem } from './components';
import { PUBLIC_STACK_BOX_LOCALIZATION } from './localization';

import styles from './PublicStackBox.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	className?: string;
	classNames?: string[];
	items: TAccountStack[];
};

export function PublicStackBox(props: TProps) {
	const { headline } = useLocalization(PUBLIC_STACK_BOX_LOCALIZATION);
	const {
		className = '',
		classNames: classNamesOuter = [],
		items,
		...restProps
	} = props;

	const [itemDetail, setItemDetail] = useState<TAccountStack>();

	if (items.length === 0) {
		return (
			<></>
		);
	}

	const onFocus = (value: TAccountStack) => {
		setItemDetail(value);
	};

	const onBlur = () => {
		setItemDetail(undefined);
	};

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			<CvHeadline>{ headline }</CvHeadline>

			<div className={ styles.items }>
				{
					items.map(item => {
						return (
							<PublicStackItem
								key={ item.alias }
								item={ item }
								onFocus={ onFocus }
								onBlur={ onBlur }
							/>
						);
					})
				}
			</div>

			{ itemDetail && <PublicStackDescription item={ itemDetail }/> }
		</div>
	);
}
