import { ReactNode } from 'react';

import styles from './HeaderCore.module.scss';


type TProps = {
	children?: ReactNode;
	style?: Record<string, string>;
	className?: string;
};

export function HeaderCore(props: TProps) {
	const { children, className = '', style = {} } = props;

	const classNames = [styles.headerCore];
	if (className) {
		classNames.push(className);
	}

	return (
		<header className={ classNames.join(' ') } style={{ ...style }}>
			{ children }
		</header>
	);
}
