// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountPhoneNumberConfirmStage_text__uJ5cS {\n  color: rgb(130, 130, 130);\n  text-align: justify;\n  text-indent: 1em;\n}\n\n.AccountPhoneNumberConfirmStage_percepted__QvPG8 {\n  background-color: rgb(250, 250, 250);\n  border: 1px solid rgb(230, 230, 230);\n  color: rgb(130, 130, 130);\n  text-align: justify;\n  text-indent: 1em;\n  padding: 0 0.25em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account/views/phone-number/components/router/components/confirm-stage/AccountPhoneNumberConfirmStage.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,mBAAA;EACA,gBAAA;AACD;;AAEA;EACC,oCAAA;EACA,oCAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AACD","sourcesContent":[".text {\n\tcolor: rgba(130, 130, 130, 1);\n\ttext-align: justify;\n\ttext-indent: 1em;\n}\n\n.percepted {\n\tbackground-color: rgba(250, 250, 250, 1);\n\tborder: 1px solid rgba(230, 230, 230, 1);\n\tcolor: rgba(130, 130, 130, 1);\n\ttext-align: justify;\n\ttext-indent: 1em;\n\tpadding: 0 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "AccountPhoneNumberConfirmStage_text__uJ5cS",
	"percepted": "AccountPhoneNumberConfirmStage_percepted__QvPG8"
};
export default ___CSS_LOADER_EXPORT___;
