import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TStack, TStackUpdate, TStackResponse, adaptStackFromResponse } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';


export const stackUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_STACKS}/:alias`,
	(payload: TStackUpdate, thunkApi) => {
		const { data, alias } = payload;
		const path = `${BACKEND_ADMIN_STACKS}/${alias}`;

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return HttpService.put<TStackResponse>(path, data, config).then(result => {
			return adaptStackFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TStack | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const stackUpdateSlice = createSlice({
	name: 'stackUpdateSlice',
	initialState,
	reducers: {
		clearStackUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[stackUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[stackUpdateApi.fulfilled.type]: (state, action: PayloadAction<TStack>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[stackUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useStackUpdateSelector = () => useAppSelector(state => state[stackUpdateSlice.name]);

export const { clearStackUpdate } = stackUpdateSlice.actions;
