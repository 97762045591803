import { DetailedHTMLProps, LabelHTMLAttributes, forwardRef } from 'react';

import styles from './FormSelectBoxLabel.module.scss';
import { FormSelectBoxLabelContent } from './components';

type TProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
	classNames?: string[];
	active?: boolean;
	disabled?: boolean;
};

export const FormSelectBoxLabel = forwardRef<HTMLLabelElement, TProps>((props, ref) => {
	const {
		classNames: classNamesOuter = [],
		className = '',
		children,
		onBlur: onBlurOuter,
		active,
		disabled,
		...restProps
	} = props;

	const classNames = [styles.formSelectBoxLabel, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	if (active) {
		classNames.push(styles.active);
	}

	if (disabled) {
		classNames.push(styles.disabled);
	}

	const onBlur = onBlurOuter;

	if (typeof children === 'string') {
		return (
			<label ref={ ref } className={ classNames.join(' ') } { ...restProps } tabIndex={ 0 } onBlur={ onBlur }>
				<FormSelectBoxLabelContent>{ children }</FormSelectBoxLabelContent>
			</label>
		);
	}

	return (
		<label ref={ ref } className={ classNames.join(' ') } { ...restProps } tabIndex={ 0 } onBlur={ onBlur }>
			{ children }
		</label>
	);
});
