import { FormButtonLink, Link } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';

import styles from '../styles/RecoveryStage.module.scss';
import { useLocalization } from 'src/toolkit';
import { RECOVERY_STAGE_SUCCESS_LOCALIZATION } from './localization';


export function RecoveryStageSuccess() {
	const {
		successText,
		successTextLeftHalf,
		successTextRightHalf,
		linkText,
		buttonText,
	} = useLocalization(RECOVERY_STAGE_SUCCESS_LOCALIZATION);

	const classNames = [styles.paragraph, styles.percepted];

	return (
		<div className={ styles.recoveryPageStage }>
			<p className={ classNames.join(' ') }>
				{ successText }
			</p>

			<p className={ styles.paragraph }>
				{ successTextLeftHalf } <Link to={ PATH_SIGN_IN }>{ linkText }</Link> { successTextRightHalf }
			</p>

			<FormButtonLink
				className={ styles.button }
				to={ PATH_SIGN_IN }
			>{ buttonText }</FormButtonLink>
		</div>
	);
}

