import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountExperience, TAccountExperiencePayloadUpdate, TAccountExperienceResponse, adaptAccountExperienceFromResponse, adaptAccountExperienceToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';


export const accountExperienceUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_EXPERIENCE}/:pid`,
	(payload: TAccountExperiencePayloadUpdate, thunkApi) => {
		const { data, pid, projects } = payload;
		const path = `${BACKEND_ACCOUNT_EXPERIENCE}/${pid}`;
		const dataRequest = adaptAccountExperienceToRequest(data);

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return HttpService.put<TAccountExperienceResponse>(path, dataRequest, config).then(result => {
			const pathParojects = `${path}/projects`;
			const dataRequest = { items: projects };

			return HttpService.put<TAccountExperienceResponse>(pathParojects, dataRequest).then(result => {
				return adaptAccountExperienceFromResponse(result.data);
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountExperience | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExperienceUpdateSlice = createSlice({
	name: 'accountExperienceUpdateSlice',
	initialState,
	reducers: {
		clearAccountExperienceUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExperienceUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExperienceUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperience>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExperienceUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountExperienceUpdateSelector = () => useAppSelector(state => state[accountExperienceUpdateSlice.name]);

export const { clearAccountExperienceUpdate } = accountExperienceUpdateSlice.actions;
