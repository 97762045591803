import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TStackLocale, setStackLocaleCreatedItem, stackLocaleCreateApi, useAppDispatch, useStackLocaleCreateSelector } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_STACK } from 'src/routes';

import { StackDescriptionField } from '../../../../components';
import { STACK_LOCALE_CREATE_LOCALIZATION } from '../../localization';
import { LocaleField } from 'src/pages/components';


type TProps = TStackLocale & {
	alias: string;
};

export function StackLocaleCreateForm(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useStackLocaleCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(STACK_LOCALE_CREATE_LOCALIZATION);

	const { description = '', locale = '', alias } = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const descriptionField = useInputFormField({
		name: 'description',
		defaultValue: description,
		validation: value => formValidateText(value, { max: 512 }),
	});

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				localeField,
				descriptionField,
			);
		}

		if (item) {
			dispatch(setStackLocaleCreatedItem(item));
			navigate(`${PATH_STACK}/${alias}/${item.locale}`);
		}
	}, [error, item]);

	const onSubmit = () => {
		const errors = [
			descriptionField.validate(),
			localeField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			locale: localeField.value,
			description: descriptionField.value,
		};

		dispatch(stackLocaleCreateApi({ data: dataRequest, alias }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading } />

			<StackDescriptionField { ...descriptionField } isLoading={ isLoading } />

			<FormNotice errorText={ notice } />

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
