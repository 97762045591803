import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './TableBody.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function TableBody(props: TProps) {
	const { children } = props;

	return (
		<tbody className={ styles.tableBody }>
			{ children }
		</tbody>
	);
}


{/* <table>
<TableHead
	v-if="headCols"
	@[EMIT_TABLE_QUERY_UPDATE]="updateQuery"
	:items="headCols"
/>

<tbody>
	<component
		:is="rowsComponent"
		:items="bodyItems"
	/>
</tbody>
</table> */}
