import { useEffect } from 'react';

import { TAccountPhoneNumber, setAccountPhoneNumberRead, setAccountPhoneNumberTimeExpiredRead, useAccountPhoneNumberReadSelector, useAppDispatch } from 'src/store';
import { libFormatClock, useLocalization } from 'src/toolkit';

import { ACCOUNT_PHONE_NUMBER_CODE_TIMER_LOCALIZATION } from './localization';

import styles from './AccountPhoneNumberCodeTimer.module.scss';


type TProps = {
	item: TAccountPhoneNumber;
};

export function AccountPhoneNumberCodeTimer(props: TProps) {
	const dispatch = useAppDispatch();
	const { expiredText } = useLocalization(ACCOUNT_PHONE_NUMBER_CODE_TIMER_LOCALIZATION);
	const { item: itemParam } = props;
	const { item = itemParam, timeExpired } = useAccountPhoneNumberReadSelector();

	useEffect(() => {
		const step = 1000;

		const timerId = setInterval(() => {
			const nextTime = timeExpired - step;

			if (nextTime > 0) {
				dispatch(setAccountPhoneNumberTimeExpiredRead(nextTime));
			} else {
				dispatch(setAccountPhoneNumberRead({ ...item, timeExpired: 0 }))
				clearTimeout(timerId);
			}
		}, step);

		return () => {
			clearTimeout(timerId);
		};
	}, [timeExpired]);

	const timeFormat = libFormatClock(timeExpired, 'mm:ss');

	return (
		<div className={ styles.box }>
			<span className={ styles.text }>{ expiredText }:</span>
			<span className={ styles.timer }>{ timeFormat }</span>
		</div>
	);
}
