import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountEducation, TAccountEducationParam, TAccountEducationResponse, adaptAccountEducationFromResponse } from '../../types';


export const accountEducationLocaleReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_EDUCATIONS}/:pid/:locale`,
	(payload: TAccountEducationParam, thunkApi) => {
		const { pid, locale } = payload;
		const path = `${BACKEND_ACCOUNT_EDUCATIONS}/${pid}/${locale}`;

		return HttpService.get<TAccountEducationResponse>(path).then(result => {
			return adaptAccountEducationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TAccountEducation | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEducationLocaleReadSlice = createSlice({
	name: 'accountEducationLocaleReadSlice',
	initialState,
	reducers: {
		setAccountEducationLocaleRead(state, action: PayloadAction<TAccountEducation>) {
			state.data = action.payload;
		},

		clearAccountEducationLocaleRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEducationLocaleReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEducationLocaleReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducation>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountEducationLocaleReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountEducationLocaleReadSelector = () => useAppSelector(state => state[accountEducationLocaleReadSlice.name]);

export const { clearAccountEducationLocaleRead, setAccountEducationLocaleRead } = accountEducationLocaleReadSlice.actions;
