// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormNotice_formNotice__HL4wv {\n  box-sizing: border-box;\n  color: rgb(90, 90, 90);\n  font-size: 0.875em;\n  font-weight: bold;\n  text-align: center;\n  width: 100%;\n  margin: 2em auto 1em;\n}\n\n.FormNotice_success__dfA0w {\n  color: rgb(0, 150, 0);\n}\n\n.FormNotice_error__Fx5wm {\n  color: rgb(255, 50, 50);\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-notice/FormNotice.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EAEA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EAEA,WAAA;EAEA,oBAAA;AAFD;;AAKA;EACC,qBAAA;AAFD;;AAKA;EACC,uBAAA;AAFD","sourcesContent":[".formNotice {\n\tbox-sizing: border-box;\n\n\tcolor: rgba(90, 90, 90, 1);\n\tfont-size: 0.875em;\n\tfont-weight: bold;\n\ttext-align: center;\n\n\twidth: 100%;\n\n\tmargin: 2em auto 1em;\n}\n\n.success {\n\tcolor: rgba(0, 150, 0, 1);\n}\n\n.error {\n\tcolor: rgba(255, 50, 50, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formNotice": "FormNotice_formNotice__HL4wv",
	"success": "FormNotice_success__dfA0w",
	"error": "FormNotice_error__Fx5wm"
};
export default ___CSS_LOADER_EXPORT___;
