import { InputHTMLAttributes } from 'react';

import styles from './FormCheckbox.module.scss';


type TProps = InputHTMLAttributes<HTMLInputElement> & {
	classNameBox?: string;
};

export function FormCheckbox(props: TProps) {
	const {
		id,
		classNameBox = '',
		className = '',
		children,
		...restProps
	} = props;

	const classNamesBox = [styles.row];
	if (classNameBox) {
		classNamesBox.push(classNameBox);
	}

	const classNames = [styles.formCheckbox];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNamesBox.join(' ') }>
			<input
				id={ id }
				className={ classNames.join(' ') }
				{ ...restProps }
				type="checkbox"
			/>
			<label htmlFor={ id }>{ children }</label>
		</div>
	);
}
