// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contact_box__Gus0W {\n  margin: 0.25em 0 0 0.5em;\n}\n\n.Contact_text__VrP2f {\n  color: rgb(150, 150, 150);\n  font-size: 0.875em;\n  font-style: italic;\n}\n\n.Contact_value__aoydD {\n  background-color: rgb(245, 245, 245);\n  border: 1px solid rgb(230, 230, 230);\n  box-sizing: border-box;\n  color: rgb(130, 130, 130);\n  display: inline-block;\n  margin-left: 0.5em;\n  padding: 0 0.25em;\n  position: relative;\n  -webkit-user-select: all;\n          user-select: all;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/contact/Contact.module.scss"],"names":[],"mappings":"AAAA;EACC,wBAAA;AACD;;AAEA;EACC,yBAAA;EACA,kBAAA;EACA,kBAAA;AACD;;AAEA;EACC,oCAAA;EACA,oCAAA;EACA,sBAAA;EACA,yBAAA;EACA,qBAAA;EAEA,kBAAA;EAEA,iBAAA;EACA,kBAAA;EAEA,wBAAA;UAAA,gBAAA;AAFD","sourcesContent":[".box {\n\tmargin: 0.25em 0 0 0.5em;\n}\n\n.text {\n\tcolor: rgba(150, 150, 150, 1);\n\tfont-size: 0.875em;\n\tfont-style: italic;\n}\n\n.value {\n\tbackground-color: rgba(245, 245, 245, 1);\n\tborder: 1px solid rgba(230, 230, 230, 1);\n\tbox-sizing: border-box;\n\tcolor: rgba(130, 130, 130, 1);\n\tdisplay: inline-block;\n\n\tmargin-left: 0.5em;\n\n\tpadding: 0 0.25em;\n\tposition: relative;\n\n\tuser-select: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "Contact_box__Gus0W",
	"text": "Contact_text__VrP2f",
	"value": "Contact_value__aoydD"
};
export default ___CSS_LOADER_EXPORT___;
