import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, FormButtonLink, Icon, PageHeadline } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_STACK, PATH_STACK_CREATE, ROLES } from 'src/routes';
import { clearStacks, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { STACK_COMMON_LOCALIZATION } from '../../localization';
import { StackCreateWidget } from '../../widgets';

import { StacksList } from './components';
import { STACK_LIST_LOCALIZATION } from './localization';

import styles from './StacksPage.module.scss';


export function StacksPage() {
	const dispatch = useAppDispatch();
	const { roles } = useCredentialsSelector();
	const { pathname, hash } = useLocation();
	const { title, headline, addItemButton } = useLocalization(STACK_LIST_LOCALIZATION);
	const { breadCrumbList } = useLocalization(STACK_COMMON_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearStacks());
		};
	}, []);

	const isCreate = roles.includes(ROLES.CREATE_STACKS);

	const uri = `${pathname}${hash}`;
	if (isCreate && uri === PATH_STACK_CREATE) {
		return (
			<StackCreateWidget />
		);
	}

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_STACK, text: breadCrumbList },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<div className={ styles.actionBox }>
					{ isCreate &&
						<FormButtonLink to={ PATH_STACK_CREATE }>
							<Icon icon="icon-plus" />
							{ addItemButton }
						</FormButtonLink>
					}
				</div>

				<StacksList />
			</div>
		</AccountLayout>
	);
}
