// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvExperience_box__UD7TL {\n  margin: 1em 0;\n}\n\n.PublicCvExperience_headline__HAJi6 {\n  color: rgb(120, 120, 120);\n  font-size: 1.5em;\n}\n\n.PublicCvExperience_totalExperience__3qaNe {\n  margin-left: 0.5em;\n}\n\n.PublicCvExperience_items__rMiNH {\n  margin-top: 0.5em;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/experience/PublicCvExperience.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AAEA;EACC,yBAAA;EACA,gBAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".box {\n\tmargin: 1em 0;\n}\n\n.headline {\n\tcolor: rgba(120, 120, 120, 1);\n\tfont-size: 1.5em;\n}\n\n.totalExperience {\n\tmargin-left: 0.5em;\n}\n\n.items {\n\tmargin-top: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvExperience_box__UD7TL",
	"headline": "PublicCvExperience_headline__HAJi6",
	"totalExperience": "PublicCvExperience_totalExperience__3qaNe",
	"items": "PublicCvExperience_items__rMiNH"
};
export default ___CSS_LOADER_EXPORT___;
