import { libDeclOfNum } from 'src/toolkit';

export function getExperienceTime(countExperience: number[], none: string, years: string[], months: string[], days: string[]): string {
	const yearsNum = countExperience[0];
	let experienceString = '';

	if (yearsNum > 0) {
		const yearsString = libDeclOfNum(yearsNum, years);
		experienceString = `${yearsNum} ${yearsString}`;
	}

	const monthsNum = countExperience[1];
	if (monthsNum > 0) {
		const monthsString = libDeclOfNum(monthsNum, months);
		experienceString += ` ${monthsNum} ${monthsString}`;
	}

	const daysNum = countExperience[2];
	if (daysNum > 0) {
		const daysString = libDeclOfNum(daysNum, days);
		experienceString += ` ${daysNum} ${daysString}`;
	}

	return experienceString || none;
}
