import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_DATE_END_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountEducationDateEndField(props: TProps) {
	const {
		dateEndLegend,
		dateEndTitle,
	} = useLocalization(ACCOUNT_EDUCATION_DATE_END_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ dateEndLegend }
			errors={ errors }
			required
		>
			<FormInput
				type="date"
				name={ name }
				title={ dateEndTitle }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
