import { PageCol, PageRow } from 'src/components';
import { CvExperienceCount, PublicCvAge } from 'src/pages/components';
import { PATH_PUBLIC_CV } from 'src/routes';
import { TPublicCv } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { PublicCvImage, PublicCvLastWork, PublicCvPositionName, PublicCvPreferredSalary, PublicCvUpdatedAt } from '../../../../../../components';

import { CV_EXPERIENCE_ROW_LOCALIZATION } from './localization';

import styles from './PublicCvsListRow.module.scss';


export function PublicCvsListRow(props: TPublicCv) {
	const { headline } = useLocalization(CV_EXPERIENCE_ROW_LOCALIZATION);

	const {
		alias,
		title,
		image = '',
		birthday,
		preferredSalary,
		currency,
		countExperience,
		nickname,
		locale,
		experience,
		status,
		updatedAt,
	} = props;

	const path = `${PATH_PUBLIC_CV}/${nickname}/${alias}/${locale}`;

	return (
		<PageCol className={ styles.row }>
			<PageRow>
				<div>
					<PublicCvImage src={ image } alt={ alias } />

					<PublicCvAge date={ birthday } />

					{ status && <div>{ status.name }</div> }
				</div>

				<div className={ styles.info }>
					<PublicCvPositionName path={ path }>{ title }</PublicCvPositionName>

					<PublicCvPreferredSalary value={ preferredSalary } currency={ currency }/>

					<CvExperienceCount value={ countExperience } headline={ headline }/>

					{ experience.length && <PublicCvLastWork item={ experience[0] }/> }
				</div>
			</PageRow>
			<PublicCvUpdatedAt date={ updatedAt } />
		</PageCol>
	);
}
