import { DetailedHTMLProps, HTMLAttributes, useEffect } from 'react';

import { TAccountCv, useCredentialsSelector } from 'src/store';
import { FormButton, FormField, FormIcon, FormInput, FormRow } from 'src/components';
import { useInputFormField, useLocalization } from 'src/toolkit';

import { PUBLIC_CV_PUBLIC_LINK_LOCALIZATION } from './localization';

import styles from './AccountCvPublicLink.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	className?: string;
	classNames?: string[];
	item: TAccountCv;
};

export function AccountCvPublicLink(props: TProps) {
	const { credentials } = useCredentialsSelector();
	const { legend, buttonTitle } = useLocalization(PUBLIC_CV_PUBLIC_LINK_LOCALIZATION);

	const linkField = useInputFormField({
		name: 'link',
		defaultValue: '',
	});

	const {
		className = '',
		classNames: classNamesOuter = [],
		item,
	} = props;

	useEffect(() => {
		if (credentials) {
			const { alias, locale, pid } = item;
			const { name } = credentials;

			const path = `${window.location.origin}/cv/${name}/${alias}/${locale}/${pid}`;

			linkField.setValue(path);
		}
	}, [credentials]);

	if (!credentials) {
		return (
			<></>
		);
	}

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	const onClick = () => {
		const value = linkField.value;

		if (value) {
			window.navigator.clipboard.writeText(value);
		}
	};

	return (
		<FormField
			legend={ legend }
		>
			<FormRow>
				<FormInput
					placeholder="—"
					value={ linkField.value }
					title={ linkField.value }
					readOnly
				/>

				<FormButton
					type="button"
					onClick={ onClick }
					title={ buttonTitle }
					tabIndex={ -1 }
					mini
				>
					<FormIcon icon="icon-files-empty" />
				</FormButton>
			</FormRow>
		</FormField>
	);
}
