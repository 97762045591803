// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CvHeadline_headline__Iwtcm {\n  color: rgb(150, 150, 150);\n  white-space: normal;\n}", "",{"version":3,"sources":["webpack://./src/components/cv-headline/CvHeadline.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EAEA,mBAAA;AAAD","sourcesContent":[".headline {\n\tcolor: rgba(150, 150, 150, 1);\n\n\twhite-space: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": "CvHeadline_headline__Iwtcm"
};
export default ___CSS_LOADER_EXPORT___;
