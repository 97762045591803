import { useEffect } from 'react';

import { TableBox, TableRowEmpty, Icon } from 'src/components';
import { AccountEducationSortCol, TAccountEducationSortCol, setAccountEducationQueryLimit, setAccountEducationQueryOffset, setAccountEducationQuerySearch, setAccountEducationQuerySort, setAccountEducationQuerySortCol, accountEducationsGetApi, useAppDispatch, useAccountEducationsSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { useDebounce, useIsMobile, useLocalization } from 'src/toolkit/hooks';
import { TTableSort } from 'src/components/table/thead/col';

import { AccountEducationListRow } from './components';
import { ACCOUNT_EDUCATION_TABLE_LOCALIZATION } from './localization';

import styles from './AccountEducationList.module.scss';


export function AccountEducationList() {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();

	const { query, list, isLoading, error } = useAccountEducationsSelector();
	const { locale, limit, offset, search, sort, sortCol } = query;
	const { items, count = 0 } = list;

	const searchDebounced = useDebounce(search || '');
	const {
		educationName,
		locales,
		createdAt,
		updatedAt,
		emptyText,
		searchPlaceholder,
		searchTitle,
	} = useLocalization(ACCOUNT_EDUCATION_TABLE_LOCALIZATION);

	useEffect(() => {
		dispatch(accountEducationsGetApi());
		// dispatch(accountEducationsGetApi(query));
	}, [locale, limit, offset, sort, sortCol, searchDebounced]);

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const theadSettings = {
		sort,
		setSort: (value: TTableSort) => dispatch(setAccountEducationQuerySort(value)),
		sortCol,
		setSortCol: (value: TAccountEducationSortCol) => dispatch(setAccountEducationQuerySortCol(value)),
		items: [
			{ name: educationName },
			{ name: locales },
			{ name: createdAt, colName: AccountEducationSortCol.CREATED_AT },
			{ name: updatedAt, colName: AccountEducationSortCol.UPDATED_AT },
		],
	};

	const controlSettings = {
		limit,
		setLimit: (value: number) => dispatch(setAccountEducationQueryLimit(value)),
		search,
		searchPlaceholder,
		searchTitle,
		setSearch: (value: string) => dispatch(setAccountEducationQuerySearch(value)),
		onSubmit: () => {},
		options: [1, 2, 3, 5, 10, 25, 50, 100],
	};

	const paginationSettings = {
		itemsLength: items.length,
		count,
		limit,
		offset,
		setOffset: (value: number) => dispatch(setAccountEducationQueryOffset(value)),
		prevLabel: <Icon icon="icon-chevron-left" className={ styles.iconChevron } />,
		nextLabel: <Icon icon="icon-chevron-right" className={ styles.iconChevron } />,
		isArrowsControls: !isMobile,
	};

	if (items.length === 0) {
		return (
			<TableBox
				isLoading={ isLoading }
				thead={ theadSettings }
				control={ controlSettings }
				pagination={ paginationSettings }
			>
				<TableRowEmpty colSpan={ 4 }>{ emptyText }</TableRowEmpty>
			</TableBox>
		);
	}

	return (
		<TableBox
			isLoading={ isLoading }
			thead={ theadSettings }
			control={ controlSettings }
			pagination={ paginationSettings }
		>
			{ items.map(item => <AccountEducationListRow key={ item.pid } { ...item } />) }
		</TableBox>
	);
}
