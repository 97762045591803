import { TListResponse } from 'src/common/types';


export const DefaultLanguage = { alias: 'en', name: 'English' };

export type TLanguage = {
	alias: string;
	name: string;
};

export type TLanguageListResponse = TListResponse<TLanguage>;

export type TCredentials = {
	name: string;
	image?: string;
	roles: string[];
};

export type TCredentialResponse = {
	nickname: string;
	image?: string;
	roles: string[];
};

export type TSignIn = {
	login: string;
	password: string;
};

export function adaptCredentialsfromResponse(data: TCredentialResponse): TCredentials {
	const {
		nickname: name,
		image = undefined,
		roles = [],
	} = data;

	return { name, image, roles };
}
