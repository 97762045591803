import { LocalizationTypes } from 'src/common/types';

import { SIGN_UP_PHONE_NUMBER_SUCCESS_RU_LOCALIZATION } from './ru.localization';
import { SIGN_UP_PHONE_NUMBER_SUCCESS_EN_LOCALIZATION } from './en.localization';


export const SIGN_UP_PHONE_NUMBER_SUCCESS_LOCALIZATION = {
	[LocalizationTypes.RU]: SIGN_UP_PHONE_NUMBER_SUCCESS_RU_LOCALIZATION,
	[LocalizationTypes.EN]: SIGN_UP_PHONE_NUMBER_SUCCESS_EN_LOCALIZATION,
};
