import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountCv, TAccountCvLocaleUpdate, TAccountCvResponse, adaptAccountCvFromResponse, adaptAccountCvLocaleToRequest } from '../../types';
import { BACKEND_ACCOUNT_CVS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';


export const accountCvLocaleUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_CVS}/:alias/:locale`,
	(payload: TAccountCvLocaleUpdate, thunkApi) => {
		const { data, alias, locale } = payload;
		const path = `${BACKEND_ACCOUNT_CVS}/${alias}/${locale}`;
		const dataRequest = adaptAccountCvLocaleToRequest(data);

		return HttpService.put<TAccountCvResponse>(path, dataRequest).then(result => {
			return adaptAccountCvFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountCv | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCvLocaleUpdateSlice = createSlice({
	name: 'accountCvLocaleUpdateSlice',
	initialState,
	reducers: {
		clearAccountCvLocaleUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCvLocaleUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCvLocaleUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountCv>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountCvLocaleUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountCvLocaleUpdateSelector = () => useAppSelector(state => state[accountCvLocaleUpdateSlice.name]);

export const { clearAccountCvLocaleUpdate } = accountCvLocaleUpdateSlice.actions;
