// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocaleBox_box__\\+nPTa {\n  display: flex;\n}\n\n.LocaleBox_emptyBox__Z0ESp {\n  color: rgb(180, 180, 180);\n  display: block;\n  font-size: 0.875em;\n  line-height: 1em;\n  text-align: center;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/locale-box/LocaleBox.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AAEA;EACC,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACD","sourcesContent":[".box {\n\tdisplay: flex;\n}\n\n.emptyBox {\n\tcolor: rgba(180, 180, 180, 1);\n\tdisplay: block;\n\tfont-size: 0.875em;\n\tline-height: 1em;\n\ttext-align: center;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "LocaleBox_box__+nPTa",
	"emptyBox": "LocaleBox_emptyBox__Z0ESp"
};
export default ___CSS_LOADER_EXPORT___;
