import { LocalizationTypes } from 'src/common/types';

import { ACCOUNT_CV_EDUCATION_BOX_RU_LOCALIZATION } from './ru.localization';
import { ACCOUNT_CV_EDUCATION_BOX_EN_LOCALIZATION } from './en.localization';


export const ACCOUNT_CV_EDUCATION_BOX_LOCALIZATION = {
	[LocalizationTypes.RU]: ACCOUNT_CV_EDUCATION_BOX_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ACCOUNT_CV_EDUCATION_BOX_EN_LOCALIZATION,
};
