import { DetailedHTMLProps, FormEvent, FormEventHandler, FormHTMLAttributes } from 'react';

import styles from './Form.module.scss';


type TProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
	className?: string;
	onSubmit?: FormEventHandler<HTMLElement>;
};

export function Form(props: TProps) {
	const {
		children,
		className = '',
		onSubmit: handler,
		...restFields
	} = props;

	const onSubmit = (e: FormEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (handler) {
			handler(e);
		}
	};

	const classNames = [styles.form];
	if (className) {
		classNames.push(className);
	}

	return (
		<form className={ classNames.join(' ') } onSubmit={ onSubmit } { ...restFields }>
			{ children }
		</form>
	);
}
