import { LocalizationTypes } from 'src/common/types';

import { USERS_PASSWORD_FIELD_RU_LOCALIZATION } from './ru.localization';
import { USERS_PASSWORD_FIELD_EN_LOCALIZATION } from './en.localization';


export const USERS_PASSWORD_FIELD_LOCALIZATION = {
	[LocalizationTypes.RU]: USERS_PASSWORD_FIELD_RU_LOCALIZATION,
	[LocalizationTypes.EN]: USERS_PASSWORD_FIELD_EN_LOCALIZATION,
};
