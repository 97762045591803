import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH_ACCOUNT_EDUCATION } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountEducation, accountEducationLocaleUpdateApi, setAccountEducationLocaleRead, useAppDispatch, useAccountEducationLocaleUpdateSelector } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';

import { AccountEducationCityField, AccountEducationEducationalInstitutionField, AccountEducationFacultyField, AccountEducationShortNameField, AccountEducationSpecializationField } from '../../../../components';
import { ACCOUNT_EDUCATION_LOCALE_UPDATE_LOCALIZATION } from '../../localization';

import { LocaleField } from 'src/pages/components';


export function AcountEducationLocaleFormUpdate(props: TAccountEducation) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountEducationLocaleUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(ACCOUNT_EDUCATION_LOCALE_UPDATE_LOCALIZATION);

	const {
		pid = '',
		locale = '',
		city = '',
		shortName = '',
		educationalInstitution = '',
		faculty = '',
		specialization = '',
	} = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const cityField = useInputFormField({
		name: 'city',
		defaultValue: city,
		validation: value => formValidateText(value),
	});

	const shortNameField = useInputFormField({
		name: 'short_name',
		defaultValue: shortName,
		validation: value => formValidateText(value),
	});

	const educationalInstitutionField = useInputFormField({
		name: 'educational_institution',
		defaultValue: educationalInstitution,
		validation: value => formValidateText(value),
	});

	const facultyField = useInputFormField({
		name: 'faculty',
		defaultValue: faculty,
		validation: value => formValidateText(value),
	});

	const specializationField = useInputFormField({
		name: 'specialization',
		defaultValue: specialization,
		validation: value => formValidateText(value),
	});

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				localeField,
				cityField,
				shortNameField,
				educationalInstitutionField,
				facultyField,
				specializationField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setAccountEducationLocaleRead(item));

			const { pid, locale: changedLocale } = item;
			if (locale !== changedLocale) {
				navigate(`${PATH_ACCOUNT_EDUCATION}/${pid}/${changedLocale}`);
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			localeField.validate(),
			cityField.validate(),
			shortNameField.validate(),
			educationalInstitutionField.validate(),
			facultyField.validate(),
			specializationField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			pid,
			locale: localeField.value,
			city: cityField.value,
			shortName: shortNameField.value,
			educationalInstitution: educationalInstitutionField.value,
			faculty: facultyField.value,
			specialization: specializationField.value,
		};

		dispatch(accountEducationLocaleUpdateApi({ data: dataRequest, pid, locale }))
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading } />

			<AccountEducationCityField { ...cityField } isLoading={ isLoading } />

			<AccountEducationShortNameField { ...shortNameField } isLoading={ isLoading } />

			<AccountEducationEducationalInstitutionField { ...educationalInstitutionField } isLoading={ isLoading } />

			<AccountEducationFacultyField { ...facultyField } isLoading={ isLoading } />

			<AccountEducationSpecializationField { ...specializationField } isLoading={ isLoading } />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
