// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvData_age__prTWh {\n  margin-top: 0.25em;\n}\n\n.PublicCvData_age__prTWh,\n.PublicCvData_birthday__f4w1c {\n  margin-left: 0.5em;\n}\n\n.PublicCvData_birthday__f4w1c {\n  color: rgb(130, 130, 130);\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/data/PublicCvData.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;;AAEA;;EAEC,kBAAA;AACD;;AAEA;EACC,yBAAA;AACD","sourcesContent":[".age {\n\tmargin-top: 0.25em;\n}\n\n.age,\n.birthday {\n\tmargin-left: 0.5em;\n}\n\n.birthday {\n\tcolor: rgba(130, 130, 130, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"age": "PublicCvData_age__prTWh",
	"birthday": "PublicCvData_birthday__f4w1c"
};
export default ___CSS_LOADER_EXPORT___;
