import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, FormButton, FormButtonWrap, FormField, FormInput, FormNotice, FormPassword, Link } from 'src/components';
import { formValidateLogin, formValidatePassword, useInputFormField, useFocus, useLocalization, formHandleServerError, formValidateLocaleLogin } from 'src/toolkit';
import { useCredentialsSelector, useAppDispatch, signInApi, useSignUpSelector } from 'src/store';
import { PATH_ACCOUNT } from 'src/routes';

import styles from './SignInForm.module.scss';
import { SIGN_IN_FORM_LOCALIZATION } from './localization';


export function SignInForm() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { credentials, isLoading, error, lang } = useCredentialsSelector();
	const { login } = useSignUpSelector();
	const [notice, setNotice] = useState('');

	const {
		loginLegend,
		loginPlaceholder,
		loginTitle,
		passwordLegend,
		passwordTitle,
		recoveryText,
		buttonText,
	} = useLocalization(SIGN_IN_FORM_LOCALIZATION);

	const loginField = useInputFormField({
		name: 'login',
		defaultValue: login,
		validation: formValidateLogin,
		validationLocale: [formValidateLocaleLogin, lang],
	});

	const passwordField = useInputFormField({
		name: 'password',
		validation: formValidatePassword,
	});

	const [loginRef, setLoginFocus] = useFocus();

	useEffect(() => {
		setLoginFocus();
	}, []);

	useEffect(() => {
		if (loginField.errors.length > 0) {
			loginField.validate();
		}

		if (passwordField.errors.length > 0) {
			passwordField.validate();
		}
	}, [lang]);

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				loginField,
				passwordField
			);
		}
	}, [error]);

	useEffect(() => {
		if (credentials) {
			navigate(PATH_ACCOUNT);
		}
	}, [credentials]);

	const submit = () => {
		const errors = [
			loginField.validate(),
			passwordField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			login: loginField.value,
			password: passwordField.value,
		};

		dispatch(signInApi(dataRequest));
	};

	return (
		<Form className={ styles.signInForm } onSubmit={ submit }>
			<FormField
				legend={ loginLegend }
				errors={ loginField.errors }
				required
			>
				<FormInput
					ref={ loginRef }
					placeholder={ loginPlaceholder }
					name={ loginField.name }
					title={ loginTitle }
					value={ loginField.value }
					onChange={ e => loginField.setValue(e.target.value) }
					disabled={ isLoading }
				/>
			</FormField>

			<FormPassword
				legend={ passwordLegend }
				errors={ passwordField.errors }
				placeholder="••••••••"
				name={ passwordField.name }
				title={ passwordTitle }
				value={ passwordField.value }
				onChange={ e => passwordField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			<p className={ styles.recoveryText }>
				<Link to="/account/recovery">{ recoveryText }</Link>
			</p>

			{ notice && <FormNotice error>{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ buttonText }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
