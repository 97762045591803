import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountExperienceSortCol, TAccountExperience, TAccountExperienceList, TAccountExperienceListResponse, TAccountExperienceQuery, TAccountExperienceQueryRequest, TAccountExperienceSortCol, adaptAccountExperienceFromListResponse } from '../../types';
import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';
import { LocalizationTypes } from 'src/common/types';


export const accountExperiencesGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_EXPERIENCE,
	(payload: TAccountExperienceQuery | undefined, thunkApi) => {
		const params = payload ? toAccountExperiencesQueryRequest(payload) : {};

		return HttpService.get<TAccountExperienceListResponse>(BACKEND_ACCOUNT_EXPERIENCE, { params }).then(result => {
			return adaptAccountExperienceFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toAccountExperiencesQueryRequest(data: TAccountExperienceQuery): TAccountExperienceQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	item?: TAccountExperience,
	itemLocale?: TAccountExperience,
	isLoading: boolean,
	list: TAccountExperienceList,
	query: TAccountExperienceQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	itemLocale: undefined,
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		locale: LocalizationTypes.EN,
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: AccountExperienceSortCol.CREATED_AT,
	},
	error: undefined,
};

export const accountExperiencesSlice = createSlice({
	name: 'accountExperiencesSlice',
	initialState,
	reducers: {
		setAccountExperienceCreatedItem(state, action: PayloadAction<TAccountExperience>) {
			state.item = action.payload;
		},

		setAccountExperiencesLocalesCreatedItem(state, action: PayloadAction<TAccountExperience>) {
			state.itemLocale = action.payload;
		},

		setAccountExperiencesQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountExperiencesQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountExperiencesQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountExperiencesQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountExperiencesQuerySortCol(state, action: PayloadAction<TAccountExperienceSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountExperiences(state) {
			return { ...initialState, list: state.list, query: state.query };
		},

		clearAccountExperiencesCreatedItem(state) {
			state.item = undefined;
		},

		clearAccountExperiencesLocalesCreatedItem(state) {
			state.itemLocale = undefined;
		},
	},

	extraReducers: {
		[accountExperiencesGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExperiencesGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperienceList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountExperiencesGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountExperiencesSelector = () => useAppSelector(state => state[accountExperiencesSlice.name]);

export const { clearAccountExperiences, setAccountExperiencesQueryLimit, setAccountExperiencesQueryOffset, setAccountExperiencesQuerySort, setAccountExperiencesQuerySortCol, setAccountExperiencesQuerySearch, setAccountExperienceCreatedItem, clearAccountExperiencesCreatedItem, setAccountExperiencesLocalesCreatedItem, clearAccountExperiencesLocalesCreatedItem } = accountExperiencesSlice.actions;
