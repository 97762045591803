// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvLanguagesField_headline__8uubA {\n  font-weight: bold;\n  margin: 2em auto 0;\n}\n\n.AccountCvLanguagesField_box__9LCF\\+ {\n  margin: 1em auto;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.AccountCvLanguagesField_row__Ynn0T {\n  align-items: flex-end;\n  position: relative;\n  z-index: 6;\n}\n\n@media screen and (max-width: 767px) {\n  .AccountCvLanguagesField_row__Ynn0T {\n    flex-direction: row !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/languages-field/AccountCvLanguagesField.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,kBAAA;AACD;;AAEA;EACC,gBAAA;EAEA,yBAAA;UAAA,iBAAA;AAAD;;AAGA;EACC,qBAAA;EACA,kBAAA;EACA,UAAA;AAAD;;AAGA;EACC;IACC,8BAAA;EAAA;AACF","sourcesContent":[".headline {\n\tfont-weight: bold;\n\tmargin: 2em auto 0;\n}\n\n.box {\n\tmargin: 1em auto;\n\n\tuser-select: none;\n}\n\n.row {\n\talign-items: flex-end;\n\tposition: relative;\n\tz-index: 6;\n}\n\n@media screen and (max-width: 767px) {\n\t.row {\n\t\tflex-direction: row !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": "AccountCvLanguagesField_headline__8uubA",
	"box": "AccountCvLanguagesField_box__9LCF+",
	"row": "AccountCvLanguagesField_row__Ynn0T"
};
export default ___CSS_LOADER_EXPORT___;
