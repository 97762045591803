import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearAccountProjectUpdate, useAppDispatch, useAccountProjectReadSelector, accountProjectReadGetApi, clearAccountProjectRead, useAccountProjectsSelector, setAccountProjectRead, clearAccountProjectsCreatedItem, useAccountSourceStacksSelector, sourceStacksGetApi, useCredentialsSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidNickname, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_PROJECTS, PATH_ACCOUNT_PROJECTS_CREATE_END } from 'src/routes';

import { ACCOUNT_PROJECTS_COMMON_LOCALIZATION } from '../../localization';

import { AccountProjectFormUpdate, AccountProjectsDetails } from './components';
import { ACCOUNT_PROJECTS_UPDATE_LOCALIZATION } from './localization';
import { AccountProjectsLocalesCreateWidget } from '../../widgets';


export function AccountProjectsUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname, hash } = useLocation();
	const { lang } = useCredentialsSelector();
	const { list, isLoading: isLoadingItems } = useAccountSourceStacksSelector();
	const { alias = '' } = useParams();
	const { item: created } = useAccountProjectsSelector();
	const { data: item, isLoading, error } = useAccountProjectReadSelector();
	const { breadCrumbList } = useLocalization(ACCOUNT_PROJECTS_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(ACCOUNT_PROJECTS_UPDATE_LOCALIZATION);

	const isInvalidAlias = isInvalidNickname(alias);

	useEffect(() => {
		const isExisted = created && created.alias === alias;
		const isNotRequest = isInvalidAlias || isExisted;

		if (!list) {
			dispatch(sourceStacksGetApi(lang));
		}

		if (isExisted) {
			dispatch(setAccountProjectRead(created));
		}

		if (!isNotRequest) {
			dispatch(accountProjectReadGetApi(alias));
		}

		return () => {
			dispatch(clearAccountProjectUpdate());
			dispatch(clearAccountProjectRead());
			dispatch(clearAccountProjectsCreatedItem());
		};
	}, []);

	if (isInvalidAlias) {
		return (
			<ErrorWidget />
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	if (isLoading || isLoadingItems || !item || !list) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (hash === PATH_ACCOUNT_PROJECTS_CREATE_END) {
		return (
			<AccountProjectsLocalesCreateWidget { ...item } />
		);
	}

	const { title } = item;
	const { items } = list;

	setTitle(`${breadCrumbItem} ${title}`);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_PROJECTS, text: breadCrumbList },
		{ to: pathname, text: title },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { title }</PageHeadline>

				<PageRow>
					<PageCol>
						<AccountProjectFormUpdate { ...item } items={ items } />
					</PageCol>

					<PageCol>
						<AccountProjectsDetails { ...item } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
