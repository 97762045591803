import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_DEFAULT_STACK, PATH_STACK } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { clearStackCreate, useAppDispatch } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { STACK_COMMON_LOCALIZATION } from '../../localization';

import { StackFormCreate } from './components';
import { STACK_CREATE_LOCALIZATION } from './localization';


export function StackCreateWidget() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const { title, headline, breadCrumbNew } = useLocalization(STACK_CREATE_LOCALIZATION);
	const { breadCrumbList } = useLocalization(STACK_COMMON_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearStackCreate());
		};
	}, []);

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_STACK, text: breadCrumbList },
		{ to: `${pathname}/`, text: breadCrumbNew },
	];

	const item = {
		name: '',
		alias: '',
		description: '',
		image: PATH_DEFAULT_STACK,
		locale: '',
		locales: [],
		updatedAt: '',
		createdAt: '',
	};

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<StackFormCreate { ...item } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
