// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterCol_col__3Mpkb {\n  margin: 0;\n  padding: 0 0.2em;\n  max-width: 200px;\n  width: 100%;\n}\n\n.FooterCol_col__3Mpkb:first-child {\n  padding: 0 0.2em 0 0;\n}\n\n.FooterCol_col__3Mpkb:last-child {\n  padding: 0 0 0 0.2em;\n}\n\n@media screen and (max-width: 480px) {\n  .FooterCol_col__3Mpkb {\n    margin: 1em 0 0 0;\n    width: 100%;\n    padding: 0;\n  }\n  .FooterCol_col__3Mpkb:first-child {\n    margin: 0;\n    padding: 0;\n  }\n  .FooterCol_col__3Mpkb:last-child {\n    padding: 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/footer/footer-col/FooterCol.module.scss"],"names":[],"mappings":"AAAA;EACC,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AACD;;AAEA;EACC,oBAAA;AACD;;AAEA;EACC,oBAAA;AACD;;AAEA;EACC;IACC,iBAAA;IACA,WAAA;IACA,UAAA;EACA;EACA;IACC,SAAA;IACA,UAAA;EACD;EAEA;IACC,UAAA;EAAD;AACF","sourcesContent":[".col {\n\tmargin: 0;\n\tpadding: 0 0.2em;\n\tmax-width: 200px;\n\twidth: 100%;\n}\n\n.col:first-child {\n\tpadding: 0 0.2em 0 0;\n}\n\n.col:last-child {\n\tpadding: 0 0 0 0.2em;\n}\n\n@media screen and (max-width: 480px) {\n\t.col {\n\t\tmargin: 1em 0 0 0;\n\t\twidth: 100%;\n\t\tpadding: 0;\n\n\t\t&:first-child {\n\t\t\tmargin: 0;\n\t\t\tpadding: 0;\n\t\t}\n\n\t\t&:last-child {\n\t\t\tpadding: 0;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col": "FooterCol_col__3Mpkb"
};
export default ___CSS_LOADER_EXPORT___;
