import { LocalizationTypes } from 'src/common/types';

import { USERS_EMAIL_COMMON_RU_LOCALIZATION } from './ru.localization';
import { USERS_EMAIL_COMMON_EN_LOCALIZATION } from './en.localization';


export const USERS_EMAIL_COMMON_LOCALIZATION = {
	[LocalizationTypes.RU]: USERS_EMAIL_COMMON_RU_LOCALIZATION,
	[LocalizationTypes.EN]: USERS_EMAIL_COMMON_EN_LOCALIZATION,
};
