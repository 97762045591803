import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_PUBLIC_CVS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TPublicCv, TPublicCvLocaleParam, TPublicCvResponse, adaptPublicCvFromResponse } from '../../types';


export const publicCvReadGetApi = createAsyncThunk(
	`${BACKEND_PUBLIC_CVS}/:nickname/:alias/:locale/:pid`,
	(payload: TPublicCvLocaleParam, thunkApi) => {
		const { nickname, alias, locale, pid } = payload;
		const pathPart = `${BACKEND_PUBLIC_CVS}/${nickname}/${alias}/${locale}`;
		const path = pid ? `${pathPart}/${pid}` : pathPart;

		return HttpService.get<TPublicCvResponse>(path).then(result => {
			return adaptPublicCvFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TPublicCv | undefined,
	isLoading: boolean,
	error: HttpError | undefined,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

 export const publicCvSlice = createSlice({
	name: 'publicCvSlice',
	initialState,
	reducers: {},

	extraReducers: {
		[publicCvReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[publicCvReadGetApi.fulfilled.type]: (state, action: PayloadAction<TPublicCv>) => {
			state.data = { ...action.payload };
			state.isLoading = false;
			state.error = undefined;
		},

		[publicCvReadGetApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const usePublicCvReadSelector = () => useAppSelector(state => state[publicCvSlice.name]);
