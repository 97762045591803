// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvReadPage_imageBox__u7uRL {\n  min-width: 6em !important;\n  margin-left: 0.5em !important;\n  width: auto !important;\n}\n\n.PublicCvReadPage_section__0F\\+Si {\n  margin: 1em auto !important;\n  max-width: 100% !important;\n}\n\n.PublicCvReadPage_dataRow__vK9sz {\n  width: 100%;\n}\n\n.PublicCvReadPage_noticeBlock__usmL8 {\n  margin: 1em auto 2em !important;\n}\n\n@media screen and (max-width: 767px) {\n  .PublicCvReadPage_imageBox__u7uRL {\n    max-width: 8em !important;\n  }\n  .PublicCvReadPage_row__Ayo4q {\n    flex-direction: row !important;\n  }\n  .PublicCvReadPage_contacts__NX\\+1T {\n    margin: 1em 0;\n  }\n}\n@media screen and (max-width: 480px) {\n  .PublicCvReadPage_imageBox__u7uRL {\n    max-width: 6em !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/views/read/PublicCvReadPage.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,6BAAA;EACA,sBAAA;AACD;;AAEA;EACC,2BAAA;EACA,0BAAA;AACD;;AAEA;EACC,WAAA;AACD;;AAEA;EACC,+BAAA;AACD;;AAEA;EACC;IACC,yBAAA;EACA;EAED;IACC,8BAAA;EAAA;EAGD;IACC,aAAA;EADA;AACF;AAIA;EACC;IACC,yBAAA;EAFA;AACF","sourcesContent":[".imageBox {\n\tmin-width: 6em !important;\n\tmargin-left: 0.5em !important;\n\twidth: auto !important;\n}\n\n.section {\n\tmargin: 1em auto !important;\n\tmax-width: 100% !important;\n}\n\n.dataRow {\n\twidth: 100%;\n}\n\n.noticeBlock {\n\tmargin: 1em auto 2em !important;\n}\n\n@media screen and (max-width: 767px) {\n\t.imageBox {\n\t\tmax-width: 8em !important;\n\t}\n\n\t.row {\n\t\tflex-direction: row !important;\n\t}\n\n\t.contacts {\n\t\tmargin: 1em 0;\n\t}\n}\n\n@media screen and (max-width: 480px) {\n\t.imageBox {\n\t\tmax-width: 6em !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageBox": "PublicCvReadPage_imageBox__u7uRL",
	"section": "PublicCvReadPage_section__0F+Si",
	"dataRow": "PublicCvReadPage_dataRow__vK9sz",
	"noticeBlock": "PublicCvReadPage_noticeBlock__usmL8",
	"row": "PublicCvReadPage_row__Ayo4q",
	"contacts": "PublicCvReadPage_contacts__NX+1T"
};
export default ___CSS_LOADER_EXPORT___;
