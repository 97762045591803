import { useLocalization } from 'src/toolkit';

import { AccountCvLanguageItem, TAccountCvLanguageItem } from './components';

import { ACCOUNT_CV_LANGUAGE_BOX_LOCALIZATION } from './localization';

import styles from './AccountCvLanguageBox.module.scss';
import { TAccountCvLanguage } from 'src/store';


type TProps = {
	isLoading?: boolean;
	items: TAccountCvLanguageItem[];
	setItems: (value: TAccountCvLanguage[]) => void;
	setLanguageItems: (value: TAccountCvLanguageItem[]) => void;
};

export function AccountCvLanguageBox(props: TProps) {
	const { items, setItems, setLanguageItems } = props;
	const { emptyText } = useLocalization(ACCOUNT_CV_LANGUAGE_BOX_LOCALIZATION);

	if (items.length === 0) {
		const classNames = [styles.box, styles.emptyBox];

		return (
			<div className={ classNames.join(' ') }>
				{ emptyText }
			</div>
		);
	}

	const onClick = (value: TAccountCvLanguageItem) => {
		const { language } = value;
		const itemsFiltered = items.filter(item => item.language !== language);

		const languageItems = itemsFiltered.map(item => {
			const { language, level } = item;

			return { language, level };
		});

		setItems(languageItems);
		setLanguageItems(itemsFiltered);
	};

	return (
		<div className={ styles.box }>
			{
				items.map(item => {
					return (
						<AccountCvLanguageItem
							key={ item.language }
							item={ item }
							onClick={ onClick }
						/>
					);
				})
			}
		</div>
	);
}
