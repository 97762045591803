import { useEffect, useState } from 'react';

import { PATH_ACCOUNT_EXPERIENCE, PATH_ACCOUNT_EXPERIENCE_CREATE_END } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountExperience, accountExperienceUpdateApi, setAccountExperienceRead, useAppDispatch, useAccountExperienceUpdateSelector, TAccountProject } from 'src/store';
import { formHandleServerError, formValidateDate, formValidateLink, formValidateText, libExtractFileName, useInputFileField, useInputFormField, useLocalization } from 'src/toolkit';
import { LocaleBoxField } from 'src/pages/components';

import { AccountExperienceCompanyLogoField, AccountExperienceCompanyNameField, AccountExperienceCompanyUriField, AccountExperienceDateEndField, AccountExperienceDateStartField, AccountExperienceFieldsRow, AccountExperienceProjectsField } from '../../../../components';
import { ACCOUNT_EXPERIENCE_UPDATE_LOCALIZATION } from '../../localization';


type TProps = TAccountExperience & {
	projectsSource?: TAccountProject[];
};

export function AccountExperienceFormUpdate(props: TProps) {
	const dispatch = useAppDispatch();
	const { item, isLoading, error } = useAccountExperienceUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(ACCOUNT_EXPERIENCE_UPDATE_LOCALIZATION);

	const {
		pid = '',
		dateStart = '',
		dateEnd = '',
		companyName = '',
		companyUri = '',
		companyLogo = '',
		locales = [],
		projects = [],
		projectsSource = [],
	} = props;

	const [projectsItems, setProjectsItems] = useState<TAccountProject[]>(projects);

	const dateStartField = useInputFormField({
		name: 'date_start',
		defaultValue: dateStart,
		validation: value => formValidateDate(value),
	});

	const dateEndField = useInputFormField({
		name: 'date_end',
		defaultValue: dateEnd,
		validation: value => formValidateDate(value),
	});

	const companyNameField = useInputFormField({
		name: 'name',
		defaultValue: companyName,
		validation: value => formValidateText(value),
	});

	const companyUriField = useInputFormField({
		name: 'company_uri',
		defaultValue: companyUri,
		validation: value => formValidateLink(value),
	});

	const companyLogoField = useInputFileField('company_logo', companyLogo);
	const [logo, setLogo] = useState<File>();

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				dateStartField,
				dateEndField,
				companyNameField,
				companyUriField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setAccountExperienceRead(item));
			setLogo(undefined);
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			dateStartField.validate(),
			companyNameField.validate(),
			companyUriField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			dateStart: dateStartField.value,
			dateEnd: dateEndField.value || undefined,
			companyName: companyNameField.value,
			companyUri: companyUriField.value,
			companyLogo: logo || libExtractFileName(companyLogoField.value),
		};

		const projects = projectsItems.map(item => item.alias);

		dispatch(accountExperienceUpdateApi({ data: dataRequest, pid, projects }))
	};

	const to = `${PATH_ACCOUNT_EXPERIENCE}/${pid}${PATH_ACCOUNT_EXPERIENCE_CREATE_END}`;

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleBoxField to={ to } path={ PATH_ACCOUNT_EXPERIENCE } alias={ pid } locales={ locales } isLoading={ isLoading } />

			<AccountExperienceCompanyLogoField { ...companyLogoField } setFile={ setLogo } />

			<AccountExperienceFieldsRow>
				<AccountExperienceDateStartField { ...dateStartField } isLoading={ isLoading } />

				<AccountExperienceDateEndField { ...dateEndField } isLoading={ isLoading } />
			</AccountExperienceFieldsRow>

			<AccountExperienceCompanyNameField { ...companyNameField } isLoading={ isLoading } />

			<AccountExperienceCompanyUriField { ...companyUriField } isLoading={ isLoading } />

			<AccountExperienceProjectsField
				items={ projectsItems }
				setItems={ setProjectsItems }
				projectsSource={ projectsSource }
				isLoading={ isLoading }
			/>

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
