import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_EDUCATIONAL_INSTITUTION_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountEducationEducationalInstitutionField(props: TProps) {
	const {
		educationalInstitutionLegend,
		educationalInstitutionPlaceholder,
		educationalInstitutionTitle,
	} = useLocalization(ACCOUNT_EDUCATION_EDUCATIONAL_INSTITUTION_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ educationalInstitutionLegend }
			errors={ errors }
			required
		>
			<FormInput
				placeholder={ educationalInstitutionPlaceholder }
				name={ name }
				title={ educationalInstitutionTitle }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
