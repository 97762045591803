export const ACCOUNT_PASSWORD_FORM_RU_LOCALIZATION = {
	currentPasswordLegend: 'Текущий пароль',
	currentPasswordTitle: 'Введите текущий пароль от аккаунта',

	newPasswordLegend: 'Новый пароль',
	newPasswordTitle: 'Введите новый пароль',

	newPasswordConfirmLegend: 'Подтвердите новый пароль',
	newPasswordConfirmTitle: 'Повторите новый пароль',

	successNotice: 'Новый пароль установлен успешно',

	buttonText: 'Сменить пароль',
};
