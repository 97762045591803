// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvAge_box__BBnQe {\n  color: rgb(150, 150, 150);\n  white-space: nowrap;\n}\n.PublicCvAge_box__BBnQe.PublicCvAge_male__\\+UM\\+8::before, .PublicCvAge_box__BBnQe.PublicCvAge_female__Zo8K1::before {\n  border: 0.375em solid transparent;\n  content: \"\";\n  display: inline-block;\n  margin-right: 0.25em;\n  width: 0;\n  height: 0;\n}\n.PublicCvAge_box__BBnQe.PublicCvAge_female__Zo8K1::before {\n  border-top: 0;\n  border-bottom: 0.75em solid rgb(255, 100, 100);\n}\n.PublicCvAge_box__BBnQe.PublicCvAge_male__\\+UM\\+8::before {\n  border-bottom: 0;\n  border-top: 0.75em solid rgb(0, 150, 150);\n}", "",{"version":3,"sources":["webpack://./src/pages/components/age/PublicCvAge.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EAEA,mBAAA;AAAD;AAEC;EAEC,iCAAA;EAEA,WAAA;EACA,qBAAA;EAEA,oBAAA;EAEA,QAAA;EACA,SAAA;AAJF;AAOC;EACC,aAAA;EACA,8CAAA;AALF;AAQC;EACC,gBAAA;EACA,yCAAA;AANF","sourcesContent":[".box {\n\tcolor: rgba(150, 150, 150, 1);\n\n\twhite-space: nowrap;\n\n\t&.male::before,\n\t&.female::before {\n\t\tborder: 0.375em solid transparent;\n\n\t\tcontent: \"\";\n\t\tdisplay: inline-block;\n\n\t\tmargin-right: 0.25em;\n\n\t\twidth: 0;\n\t\theight: 0;\n\t}\n\n\t&.female::before {\n\t\tborder-top: 0;\n\t\tborder-bottom: 0.75em solid rgba(255, 100, 100, 1);\n\t}\n\n\t&.male::before {\n\t\tborder-bottom: 0;\n\t\tborder-top: 0.75em solid rgba(0, 150, 150, 1);\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvAge_box__BBnQe",
	"male": "PublicCvAge_male__+UM+8",
	"female": "PublicCvAge_female__Zo8K1"
};
export default ___CSS_LOADER_EXPORT___;
