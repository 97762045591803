import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ADMIN_USERS } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TUserEmail, TUserEmailResponse, adaptUserEmailFromResponse } from '../../types';


export const userEmailReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_USERS}/:nickname/email`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_USERS}/${payload}/email`;

		return HttpService.get<TUserEmailResponse>(path).then(result => {
			return adaptUserEmailFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TUserEmail | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const userEmailReadSlice = createSlice({
	name: 'userEmailReadSlice',
	initialState,
	reducers: {
		setUserEmailRead(state, action: PayloadAction<TUserEmail>) {
			state.item = action.payload;
		},

		clearUserEmailRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userEmailReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[userEmailReadGetApi.fulfilled.type]: (state, action: PayloadAction<TUserEmail>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[userEmailReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useUserEmailReadSelector = () => useAppSelector(state => state[userEmailReadSlice.name]);

export const { clearUserEmailRead, setUserEmailRead } = userEmailReadSlice.actions;
