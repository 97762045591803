// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconText_container__JfE0U {\n  box-sizing: border-box;\n  line-height: 1.8em;\n  height: 100%;\n  min-height: 100%;\n  max-height: 100%;\n}\n\n.IconText_image__r2SIa {\n  border: 1px solid rgb(120, 120, 120);\n  box-sizing: border-box;\n  margin-right: 0.25em;\n  min-height: 1.2em;\n  max-height: 1.2em;\n  height: 1.2em;\n  vertical-align: middle;\n}", "",{"version":3,"sources":["webpack://./src/components/icon-text/IconText.module.scss"],"names":[],"mappings":"AAGA;EACC,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AAFD;;AAKA;EACC,oCAAA;EACA,sBAAA;EACA,oBAAA;EAEA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;AAHD","sourcesContent":["$color: rgba(120, 120, 120, 1);\n$colorHover: rgba(90, 90, 90, 1);\n\n.container {\n\tbox-sizing: border-box;\n\tline-height: 1.8em;\n\theight: 100%;\n\tmin-height: 100%;\n\tmax-height: 100%;\n}\n\n.image {\n\tborder: 1px solid rgba(120, 120, 120, 1);\n\tbox-sizing: border-box;\n\tmargin-right: 0.25em;\n\n\tmin-height: 1.2em;\n\tmax-height: 1.2em;\n\theight: 1.2em;\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IconText_container__JfE0U",
	"image": "IconText_image__r2SIa"
};
export default ___CSS_LOADER_EXPORT___;
