import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EXPERIENCE, PATH_DEFAULT_EXPERIENCE } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { clearAccountExperienceCreate, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION } from '../../localization';

import { AccountExperienceFormCreate } from './components';
import { ACCOUNT_EXPERIENCE_CREATE_LOCALIZATION } from './localization';


export function AccountExperienceCreateWidget() {
	const dispatch = useAppDispatch();
	const { lang } = useCredentialsSelector();
	const { pathname, hash } = useLocation();
	const { breadCrumbList } = useLocalization(ACCOUNT_EXPERIENCE_COMMON_LOCALIZATION);
	const { title, headline, breadCrumbNew } = useLocalization(ACCOUNT_EXPERIENCE_CREATE_LOCALIZATION);

	const initialItem = {
		locale: lang,
		pid: '',
		dateStart: '',
		dateEnd: '',
		companyName: '',
		companyUri: '',
		companyCity: '',
		positionName: '',
		description: '',
		companyLogo: PATH_DEFAULT_EXPERIENCE,
		locales: [],
		updatedAt: '',
		createdAt: '',

		projects: [],
		countExperience: [],
	};

	useEffect(() => {
		return () => {
			dispatch(clearAccountExperienceCreate());
		};
	}, []);

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EXPERIENCE, text: breadCrumbList },
		{ to: `${pathname}/${hash}`, text: breadCrumbNew },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountExperienceFormCreate { ...initialItem } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
