import { TListResponse } from 'src/common/types';


export const DefaultCurrency = '643';

export type TCurrencyResponse = {
	alias: string;
	sign: string;
	name: string;
};

export type TCurrency = {
	alias: string;
	sign: string;
	name: string;
};

export type TCurrencyListResponse = TListResponse<TCurrency>;

export function adaptCurrencyFromResponse(data: TCurrencyResponse): TCurrency {
	const { alias, sign, name } = data;

	return { alias, sign, name };
}

export function adaptCurrenciesFromListResponse(data: TCurrencyListResponse): TCurrency[] {
	const { items } = data;

	return items.map(item => adaptCurrencyFromResponse(item));
}
