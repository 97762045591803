export const CV_EXPERIENCE_COUNT_RU_LOCALIZATION = {
	headline: 'Опыт работы',
	none: 'Нет',
	year1: 'год',
	year2: 'года',
	year5: 'лет',
	month1: 'месяц',
	month2: 'месяца',
	month5: 'месяцев',
	day1: 'день',
	day2: 'дня',
	day5: 'дней',
};
