import { ComponentType } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ErrorWidget } from 'src/pages/components';
import { useCredentialsSelector } from 'src/store';

import { PATH_ACCOUNT } from './paths';


export type TRoute = {
	path: string;
	element: ComponentType;
	isAuth?: boolean;
	role?: string;
	props?: Record<string, unknown>;
	redirect?: string;
};

type TProps = {
	items?: TRoute[];
};

function protectItems(items: TRoute[], isAuth: boolean | undefined, rolesParam: string[] | undefined) {
	const roles = rolesParam || [];

	return items.map(item => {
		const isAuthItem = item.isAuth;

		if (typeof isAuthItem !== 'boolean') {
			return item;
		}

		if (isAuthItem === isAuth) {
			const itemRole = item.role;

			if (itemRole) {
				if (roles.includes(itemRole)) {
					return item;
				}
			} else {
				return item;
			}
		}

		const redirect = item.redirect;
		if (redirect) {
			return {
				...item,
				element: () => <Navigate to={ PATH_ACCOUNT } replace />,
			};
		}

		return {
			...item,
			element: ErrorWidget,
			props: {
				status: 403,
				statusText: 'Forbidden',
				statusTextRu: 'Страница недоступна',
			},
		};
	});
}


export function Router(props: TProps) {
	const { items = [] } = props;
	const { credentials } = useCredentialsSelector();
	const { roles } = credentials || {};
	const isAuth = !!credentials;

	const protectedItems = protectItems(items, isAuth, roles);

	return (
		<Routes>
			{
				protectedItems.map(item => {
					const {
						element: Element,
						props = {},
						...restProps
					} = item;

					return <Route key={ item.path } element={ <Element { ...props } /> } { ...restProps } />
				})
			}
		</Routes>
	);
}
