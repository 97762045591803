import { useState } from 'react';

import { FormField, FormInput } from 'src/components';
import { libFormatDate, libNumberMask, useLocalization } from 'src/toolkit';
import { LinkDelete } from 'src/pages/components';

import { TUserPhoneNumberModalDeleteProps, UserPhoneNumberModalDelete } from './components';
import { USERS_PHONE_NUMBER_DETAILS_LOCALIZATION } from './localization';

import styles from 'src/components/form/form-box/FormBox.module.scss';


export function UserPhoneNumberDetails(props: TUserPhoneNumberModalDeleteProps) {
	const {
		createdAt = '',
		updatedAt = '',
		phoneNumberExpected = '',
	} = props;

	const [modal, setModal] = useState(false);
	const {
		createdAtLegend,
		createdAtTitle,
		updatedAtLegend,
		updatedAtTitle,
		removeLinkText,
		unconfirmedLegend,
		unconfirmedTitle,
	} = useLocalization(USERS_PHONE_NUMBER_DETAILS_LOCALIZATION);

	const openModal = () => {
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
	};

	const phoneNumberExpectedFormat = phoneNumberExpected ? libNumberMask(phoneNumberExpected) : phoneNumberExpected;

	return (
		<div className={ styles.formBox }>
			<FormField
				legend={ unconfirmedLegend }
				unconfirmed
			>
				<FormInput
					placeholder="+7 ( ___ ) ___ - __ - __"
					title={ unconfirmedTitle }
					value={ phoneNumberExpectedFormat }
					readOnly
				/>
			</FormField>

			<FormField
				legend={ createdAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ createdAtTitle }
					value={ libFormatDate(createdAt) }
					readOnly
				/>
			</FormField>

			<FormField
				legend={ updatedAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ updatedAtTitle }
					value={ libFormatDate(updatedAt) }
					readOnly
				/>
			</FormField>

			<LinkDelete onClick={ openModal }>{ removeLinkText }</LinkDelete>

			{ modal && <UserPhoneNumberModalDelete data={ props } onClose={ closeModal } /> }
		</div>
	);
}
