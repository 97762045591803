import { DetailedHTMLProps, HTMLAttributes, useState } from 'react';

import { CvHeadline, Icon, PageCol, PageRow } from 'src/components';
import { HeadlineLink, PublicFileBox, PublicStackBox, RepositoryUri } from 'src/pages/components';
import { TPublicProject } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { PublicCvExperienceItemLogo } from '../../../../../';

import { PublicCvDescription } from '../../../../../../../../../';

import { PUBLIC_CV_EXPERIENCE_PROJECTS_ITEM_LOCALIZATION } from './localization';

import styles from './PublicCvExperienceProjectsItem.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	item: TPublicProject;
};

export function PublicCvExperienceProjectsItem(props: TProps) {
	const { text } = useLocalization(PUBLIC_CV_EXPERIENCE_PROJECTS_ITEM_LOCALIZATION);
	const [isVisible, setIsVisible] = useState(false);

	const { item, className = '' } = props;

	const {
		alias,
		title,
		image,
		description,
		uri,
		repositoryUri,
		files,
		stack,
	} = item;

	const classNames = [styles.box];
	if (className) {
		classNames.push(className);
	}

	const iconClassNames = [styles.icon];

	if (isVisible) {
		iconClassNames.push(styles.active);
	}

	const onClick = () => {
		setIsVisible(!isVisible);
	};

	return (
		<div className={ classNames.join(' ') }>
			<div className={ styles.back }></div>

			<PageRow className={ styles.row }>
				<div className="imageBox">
					<PublicCvExperienceItemLogo src={ image } alt={ alias } />

					<div className={ styles.details }>
						<CvHeadline className={ styles.detailsText } onClick={ onClick }>{ text }</CvHeadline>
						<Icon className={ iconClassNames.join(' ') } onClick={ onClick } icon="icon-chevron-bottom" />
					</div>
				</div>

				<PageCol className={ styles.col }>
					<HeadlineLink className={ styles.headline } path={ uri } target="_blank" >{ title }</HeadlineLink>

					<PublicCvDescription>{ description }</PublicCvDescription>
				</PageCol>
			</PageRow>

			{ isVisible && <RepositoryUri className={ styles.repositoryUri }>{ repositoryUri }</RepositoryUri> }

			{ isVisible && <PublicFileBox items={ files } /> }

			{ isVisible && <PublicStackBox className={ styles.stack } items={ stack } /> }
		</div>
	);
}
