import { Icon } from 'src/components';
import { TAccountProject } from 'src/store';

import styles from './AccountExperienceProjectItem.module.scss';


type TProps = {
	item: TAccountProject;
	onClick: (value: TAccountProject) => void;
};

export function AccountExperienceProjectItem(props: TProps) {
	const { item, onClick, ...restProps } = props;
	const { title } = item;

	return (
		<div className={ styles.item } { ...restProps }>
			<span className={ styles.text }>{ title }</span>
			<Icon className={ styles.remove } onClick={ e => onClick(item) } icon="icon-cancel" />
		</div>
	);
}
