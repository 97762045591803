// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvTextLink_text__m3c2u {\n  color: rgb(150, 150, 150);\n  display: inline;\n  font-size: 1em;\n  font-weight: 400;\n  line-height: 1.2em;\n  height: 1.2em;\n  white-space: normal;\n}\n\n.PublicCvTextLink_link__5Hwqk {\n  border-bottom: 1px solid rgb(150, 150, 150);\n  cursor: pointer;\n  text-decoration: none;\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n}\n.PublicCvTextLink_link__5Hwqk:active, .PublicCvTextLink_link__5Hwqk:hover {\n  border-bottom: 1px solid transparent;\n  color: rgb(130, 130, 130);\n}\n.PublicCvTextLink_link__5Hwqk:active {\n  transition: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/text-link/PublicCvTextLink.module.scss"],"names":[],"mappings":"AAEA;EACC,yBAHO;EAIP,eAAA;EAEA,cAAA;EACA,gBAAA;EAEA,kBAAA;EACA,aAAA;EAEA,mBAAA;AAJD;;AAOA;EACC,2CAAA;EACA,eAAA;EAEA,qBAAA;EAEC,2DAAA;AANF;AAQC;EAEC,oCAAA;EACA,yBAAA;AAPF;AAUC;EACC,gBAAA;AARF","sourcesContent":["$color: rgba(150, 150, 150, 1);\n\n.text {\n\tcolor: $color;\n\tdisplay: inline;\n\n\tfont-size: 1em;\n\tfont-weight: 400;\n\n\tline-height: 1.2em;\n\theight: 1.2em;\n\n\twhite-space: normal;\n}\n\n.link {\n\tborder-bottom: 1px solid $color;\n\tcursor: pointer;\n\n\ttext-decoration: none;\n\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n\n\t&:active,\n\t&:hover {\n\t\tborder-bottom: 1px solid transparent;\n\t\tcolor: rgba(130, 130, 130, 1);\n\t}\n\n\t&:active {\n\t\ttransition: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "PublicCvTextLink_text__m3c2u",
	"link": "PublicCvTextLink_link__5Hwqk"
};
export default ___CSS_LOADER_EXPORT___;
