// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableControl_tableControl__23zO0 {\n  color: rgb(180, 180, 180);\n  display: flex;\n  justify-content: flex-end;\n  font-family: inherit;\n  font-size: 1em;\n  font-weight: 100;\n  margin: 0 auto;\n  text-align: center;\n  padding: 0.25em 0;\n  max-width: 800px;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.TableControl_tableControl__23zO0 > * {\n  margin: 0 0.125em;\n}\n.TableControl_tableControl__23zO0 > *:last-child {\n  margin-right: 0;\n}\n\n.TableControl_col__YYyew {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}", "",{"version":3,"sources":["webpack://./src/components/table/controls/TableControl.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,aAAA;EACA,yBAAA;EAEA,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EAEA,iBAAA;EAEA,gBAAA;EAEA,yBAAA;UAAA,iBAAA;AAHD;;AAMA;EACC,iBAAA;AAHD;AAKC;EACC,eAAA;AAHF;;AAOA;EACC,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;AAJD","sourcesContent":[".tableControl {\n\tcolor: rgba(180, 180, 180, 1);\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\n\tfont-family: inherit;\n\tfont-size: 1em;\n\tfont-weight: 100;\n\tmargin: 0 auto;\n\ttext-align: center;\n\n\tpadding: 0.25em 0;\n\n\tmax-width: 800px;\n\n\tuser-select: none;\n}\n\n.tableControl > * {\n\tmargin: 0 0.125em;\n\n\t&:last-child {\n\t\tmargin-right: 0;\n\t}\n}\n\n.col {\n\tbox-sizing: border-box;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableControl": "TableControl_tableControl__23zO0",
	"col": "TableControl_col__YYyew"
};
export default ___CSS_LOADER_EXPORT___;
