import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice, FormPassword } from 'src/components';
import { formHandleServerError, formValidatePassword, useInputFormField, useLocalization } from 'src/toolkit';
import { TUser, setAccountPasswordHideSuccessAction, useAppDispatch, useUserPasswordUpdateSelector, userPasswordUpdateApi } from 'src/store';

import styles from './UserPasswordForm.module.scss';
import { ACCOUNT_PASSWORD_FORM_LOCALIZATION } from './localization';


type TProps = {
	item: TUser;
};

export function UserPasswordForm(props: TProps) {
	const dispatch = useAppDispatch();
	const { isSuccess, isLoading, error } = useUserPasswordUpdateSelector();
	const [notice, setNotice] = useState('');
	const { item } = props;
	const { nickname } = item;

	const {
		legend,
		title,
		successNotice,
		buttonText,
	} = useLocalization(ACCOUNT_PASSWORD_FORM_LOCALIZATION);

	const passwordNewField = useInputFormField({
		name: 'password',
		validation: value => ([
			...formValidatePassword(value),
		]),
	});

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice,
				passwordNewField
			);
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			setNotice(successNotice);

			passwordNewField.clear();

			return () => {
				dispatch(setAccountPasswordHideSuccessAction())
			};
		}
	}, [isSuccess]);

	const submit = () => {
		const errors = [
			passwordNewField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			password: passwordNewField.value,
		};

		dispatch(userPasswordUpdateApi({ data: dataRequest, nickname }));
	};

	return (
		<Form className={ styles.UserPasswordForm } onSubmit={ submit }>
			<FormPassword
				legend={ legend }
				errors={ passwordNewField.errors }
				placeholder="••••••••"
				name={ passwordNewField.name }
				title={ title }
				value={ passwordNewField.value }
				onChange={ e => passwordNewField.setValue(e.target.value) }
				disabled={ isLoading }
				required
			/>

			{ isSuccess && <FormNotice success={ isSuccess } error={ !!error }>{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ buttonText }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
