// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvLanguageLevelBox_box__YC6qI {\n  margin-left: 0.1em;\n  max-width: 12.1em;\n  min-width: 12.1em;\n  width: 100%;\n  margin-bottom: 0;\n}\n\n.AccountCvLanguageLevelBox_selectBox__X2ku7 {\n  max-width: 12.1em;\n  min-width: 12.1em;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/languages-field/components/level-box/AccountCvLanguageLevelBox.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;EAEA,gBAAA;AAAD;;AAGA;EACC,iBAAA;EACA,iBAAA;EACA,WAAA;AAAD","sourcesContent":[".box {\n\tmargin-left: 0.1em;\n\tmax-width: 12.1em;\n\tmin-width: 12.1em;\n\twidth: 100%;\n\n\tmargin-bottom: 0;\n}\n\n.selectBox {\n\tmax-width: 12.1em;\n\tmin-width: 12.1em;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvLanguageLevelBox_box__YC6qI",
	"selectBox": "AccountCvLanguageLevelBox_selectBox__X2ku7"
};
export default ___CSS_LOADER_EXPORT___;
