export const ASIDE_RU_LOCALIZATION = {
	account: 'Аккаунт',
	cv: 'Мои резюме',
	education: 'Мои образования',
	projects: 'Мои проекты',
	experience: 'Мой опыт',
	stack: 'Стек',
	users: 'Пользователи',
	logout: 'Выход',
};
