import { TAccountEducation } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { AccountCvEducationItem } from './components';
import { ACCOUNT_CV_EDUCATION_BOX_LOCALIZATION } from './localization';

import styles from './AccountCvEducationBox.module.scss';


type TProps = {
	isLoading?: boolean;
	items: TAccountEducation[];
	setItems: (value: TAccountEducation[]) => void;
};

export function AccountCvEducationBox(props: TProps) {
	const { emptyText } = useLocalization(ACCOUNT_CV_EDUCATION_BOX_LOCALIZATION);
	const { items, setItems } = props;

	if (items.length === 0) {
		const classNames = [styles.box, styles.emptyBox];

		return (
			<div className={ classNames.join(' ') }>
				{ emptyText }
			</div>
		);
	}

	const onClick = (value: TAccountEducation) => {
		const { pid } = value;
		const itemsFiltered = items.filter(item => item.pid !== pid);

		setItems(itemsFiltered);
	};

	return (
		<div className={ styles.box }>
			{
				items.map(item => {
					return (
						<AccountCvEducationItem
							key={ item.pid }
							item={ item }
							onClick={ onClick }
						/>
					);
				})
			}
		</div>
	);
}
