// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvVersions_box__YniFR {\n  margin: 0 0.5em;\n}\n\n.PublicCvVersions_headline__OnNGI {\n  font-size: 0.875em;\n}\n\n.PublicCvVersions_versions__ZoS96 {\n  font-size: 0.9em;\n  margin-top: 0.25em;\n  margin-left: 0.5em;\n}\n\n@media screen and (max-width: 480px) {\n  .PublicCvVersions_box__YniFR {\n    margin: 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/versions/PublicCvVersions.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,gBAAA;EACA,kBAAA;EACA,kBAAA;AACD;;AAEA;EACC;IACC,SAAA;EACA;AACF","sourcesContent":[".box {\n\tmargin: 0 0.5em;\n}\n\n.headline {\n\tfont-size: 0.875em;\n}\n\n.versions {\n\tfont-size: 0.9em;\n\tmargin-top: 0.25em;\n\tmargin-left: 0.5em;\n}\n\n@media screen and (max-width: 480px) {\n\t.box {\n\t\tmargin: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvVersions_box__YniFR",
	"headline": "PublicCvVersions_headline__OnNGI",
	"versions": "PublicCvVersions_versions__ZoS96"
};
export default ___CSS_LOADER_EXPORT___;
