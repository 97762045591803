import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TableHeadSortWrap } from '../sort-wrap';

import styles from './TableHeadCol.module.scss';


export const enum TableSort {
	ASC = 'ASC',
	DESC = 'DESC',
};

export type TTableSortCols<T> = T[keyof T];

export type TTableSort = typeof TableSort[keyof typeof TableSort];

export type TTableHeadItem = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	name: string;
	colName?: string;
};

type TTableHeadCol = TTableHeadItem & {
	sort?: TTableSort;
	setSort?: (value: TTableSort) => void;
	sortCol?: string;
	setSortCol?: <T extends string>(value: T) => void;
};

export function TableHeadCol(props: TTableHeadCol) {
	const {
		className,
		name = '',
		colName,
		sort,
		setSort,
		sortCol,
		setSortCol,
	} = props;

	const classNames = [styles.tableHeadCol];

	if (className) {
		classNames.push(className);
	}

	if (colName === undefined) {
		return (
			<th className={ classNames.join(' ') }>
				{ name }
			</th>
		);
	}

	const onClick = () => {
		if (setSortCol && setSort) {
			if (sortCol === colName) {
				const type = sort === TableSort.DESC ? TableSort.ASC : TableSort.DESC;

				setSort(type);
			} else {
				setSort(TableSort.ASC);
			}

			setSortCol(colName);
		}
	};

	const params = sortCol === colName ? { sort } : undefined;

	return (
		<th className={ classNames.join(' ') }>
			<TableHeadSortWrap { ...params } onClick={ onClick }>
				{ name }
			</TableHeadSortWrap>
		</th>
	);
}
