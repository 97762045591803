import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TStack, TStackRequest, TStackResponse, adaptStackFromResponse } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';


export const stackCreateApi = createAsyncThunk(
	`POST/${BACKEND_ADMIN_STACKS}`,
	(payload: TStackRequest, thunkApi) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return HttpService.post<TStackResponse>(BACKEND_ADMIN_STACKS, payload, config).then(result => {
			return adaptStackFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TStack | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const stackCreateSlice = createSlice({
	name: 'stackCreateSlice',
	initialState,
	reducers: {
		clearStackCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[stackCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[stackCreateApi.fulfilled.type]: (state, action: PayloadAction<TStack>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[stackCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useStackCreateSelector = () => useAppSelector(state => state[stackCreateSlice.name]);

export const { clearStackCreate } = stackCreateSlice.actions;
