import { LocalizationTypes } from 'src/common/types';

import { PUBLIC_CV_PREFERRED_SALARY_RU_LOCALIZATION } from './ru.localization';
import { PUBLIC_CV_PREFERRED_SALARY_EN_LOCALIZATION } from './en.localization';


export const PUBLIC_CV_PREFERRED_SALARY_LOCALIZATION = {
	[LocalizationTypes.RU]: PUBLIC_CV_PREFERRED_SALARY_RU_LOCALIZATION,
	[LocalizationTypes.EN]: PUBLIC_CV_PREFERRED_SALARY_EN_LOCALIZATION,
};
