import { useEffect, useState } from 'react';

import { FormSelectBox, IconText } from 'src/components';
import { DefaultLanguage, TLanguage, setCredentialsLang, useAppDispatch, useCredentialsSelector } from 'src/store';

import { NavButton } from '../nav-button';
import { NavItem, TNavItem } from '../nav-item';
import { NavLogo, TNavLogo } from '../nav-logo';

import styles from './Nav.module.scss';


type TNavProps = {
	onClick?: () => void;
	style?: Record<string, string>;
	logo?: TNavLogo;
	login?: TNavItem;
	items?: TNavItem[];
	isVisibleButton?: boolean;
	active?: boolean;
};

type TOptionItem = {
	text: JSX.Element;
	value: string;
};

function createOptionItem(item: TLanguage): TOptionItem {
	const { alias, name } = item;

	return { text: <IconText textClassName={ styles.iconText } path={ `/images/languages/${alias}.svg` } text={ name } />, value: alias };
}

const initOptions = [
	{ text: <IconText textClassName={ styles.iconText } path={ '/images/languages/uk.svg' } text="English" />, value: DefaultLanguage.alias },
];

export function Nav(props: TNavProps) {
	const dispatch = useAppDispatch();
	const { lang, languages } = useCredentialsSelector();
	const [options, setOptions] = useState(initOptions);

	useEffect(() => {
		if (languages.length > 0) {
			const arr = languages.map(item => createOptionItem(item));

			setOptions(arr);
		}
	}, [languages]);

	const {
		onClick = () => {},
		logo = null,
		isVisibleButton = false,
		active = false,
		items = [],
		login = null,
		style = {},
	} = props;

	const onChange = (value: string) => {
		dispatch(setCredentialsLang(value));
	};

	return (
		<nav className={ styles.nav } style={ style }>
			<div className={ styles.wrap }>
				<div className={ styles.relative }>
					{ logo && <NavLogo { ...logo } /> }

					{ isVisibleButton && <NavButton active={ active } onClick={ onClick } /> }
				</div>

				<div className={ styles.menu }>
					{
						items.map(item => <NavItem { ...item } key={ item.href } />)
					}
				</div>

				{ !isVisibleButton && login &&
					<div className={ styles.menu }>
						<NavItem { ...login }/>
					</div>
				}

				<FormSelectBox
					className={ styles.selectBox }
					items={ options }
					value={ lang }
					onChange={ onChange }
				/>
			</div>
		</nav>
	);
}
