// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Link_link__lMaCH {\n  border-bottom: 1px solid rgb(120, 120, 120);\n  color: rgb(120, 120, 120);\n  cursor: pointer;\n  display: inline;\n  font-size: 1em;\n  font-weight: 200;\n  line-height: 1.2em;\n  height: 1.2em;\n  white-space: normal;\n  text-decoration: none;\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n}\n.Link_link__lMaCH:active, .Link_link__lMaCH:hover {\n  border-bottom: 1px solid transparent;\n  color: rgb(90, 90, 90);\n}\n.Link_link__lMaCH:active {\n  transition: none;\n}", "",{"version":3,"sources":["webpack://./src/components/link/Link.module.scss"],"names":[],"mappings":"AAGA;EACC,2CAAA;EACA,yBALO;EAMP,eAAA;EACA,eAAA;EAEA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EAEA,mBAAA;EACA,qBAAA;EAEC,2DAAA;AALF;AAOC;EAEC,oCAAA;EACA,sBArBW;AAeb;AASC;EACC,gBAAA;AAPF","sourcesContent":["$color: rgba(120, 120, 120, 1);\n$colorHover: rgba(90, 90, 90, 1);\n\n.link {\n\tborder-bottom: 1px solid $color;\n\tcolor: $color;\n\tcursor: pointer;\n\tdisplay: inline;\n\n\tfont-size: 1em;\n\tfont-weight: 200;\n\tline-height: 1.2em;\n\theight: 1.2em;\n\n\twhite-space: normal;\n\ttext-decoration: none;\n\n  transition: border 0.1s ease-in-out, color 0.1s ease-in-out;\n\n\t&:active,\n\t&:hover {\n\t\tborder-bottom: 1px solid transparent;\n\t\tcolor: $colorHover;\n\t}\n\n\t&:active {\n\t\ttransition: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Link_link__lMaCH"
};
export default ___CSS_LOADER_EXPORT___;
