import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TFormFileExistedValue } from '../../../../FormFile';

import styles from '../../../styles/FormFileListValueRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	file: TFormFileExistedValue;
	onDelete: (value: TFormFileExistedValue) => void;
};

export function FormFileListValueRow(props: TProps) {
	const { onDelete, file, children } = props;

	const classNames = [styles.row];

	return (
		<div className={ classNames.join(' ') }>
			{ children }

			<span className={ styles.nowrap }>
				<span className={ styles.delete } onClick={ e => onDelete(file) }>X</span>
			</span>
		</div>
	);
}
