import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormField, FormInputMask, FormNotice } from 'src/components';
import { signUpPhoneNumberConfirmApi, useAppDispatch, useSignUpSelector } from 'src/store';
import { formHandleServerError, formValidateCode, libNumberMask, useInputField, useLocalization } from 'src/toolkit';

import { SIGN_UP_PHONE_NUMBER_CONFIRM_LOCALIZATION } from './localization';

import styles from '../../../../../../components/stages/SignUpStages.module.scss';


export function SignUpStagePhoneNumberConfirm() {
	const dispatch = useAppDispatch();
	const { phoneNumber, isLoading, error } = useSignUpSelector();
	const {
		sendTextLeftHalf,
		sendTextRightHalf,
		codeLegend,
		codeTitle,
		buttonText,
	} = useLocalization(SIGN_UP_PHONE_NUMBER_CONFIRM_LOCALIZATION);

	const codeField = useInputField('code');
	const [notice, setNotice] = useState('');

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice, codeField);
		}
	}, [error]);

	const submit = () => {
		const errors = [];

		errors.push(formValidateCode(codeField.value));
		codeField.setErrors(errors[errors.length - 1]);

		const isInvalidForm = !!errors.find(items => items.length > 0);

		if (isInvalidForm || !phoneNumber) {
			return;
		}

		const dataRequest = {
			code: codeField.value,
			phoneNumberExpected: phoneNumber,
		};

		dispatch(signUpPhoneNumberConfirmApi(dataRequest));
	};

	const phoneNumberformat = libNumberMask(phoneNumber, { template: '+_ ___ *** ** __' });

	return (
		<Form className={ styles.form } onSubmit={ submit }>
			<p className={ styles.paragraph }>{ sendTextLeftHalf } { phoneNumberformat } { sendTextRightHalf }:</p>

			<FormField
				legend={ codeLegend }
				errors={ codeField.errors }
				required
			>
				<FormInputMask
					placeholder="___ - ___"
					mask="999 - 999"
					title={ codeTitle }
					name={ codeField.name }
					value={ codeField.value }
					onChange={ e => codeField.setValue(e.target.value) }
					disabled={ isLoading }
				/>
			</FormField>

			{ notice && <FormNotice className="error">{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ buttonText }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
