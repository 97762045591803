import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_COMPANY_URI_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountExperienceCompanyUriField(props: TProps) {
	const {
		companyUriLegend,
		companyUriPlaceholder,
		companyUriTitle,
	} = useLocalization(ACCOUNT_EXPERIENCE_COMPANY_URI_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ companyUriLegend }
			errors={ errors }
		>
			<FormInput
				placeholder={ companyUriPlaceholder }
				name={ name }
				title={ companyUriTitle }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
