import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TPublicProject } from 'src/store';

import { PublicCvExperienceProjectsItem } from './components';

import styles from './PublicCvExperienceProjectsItems.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	items: TPublicProject[];
	classNames?: string[];
};

export function PublicCvExperienceProjectsItems(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		items,
	} = props;

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') }>
			{ items.map(item => <PublicCvExperienceProjectsItem key={ item.alias } item={ item } />) }
		</div>
	);
}
