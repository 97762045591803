import { useEffect, useState } from 'react';

import { FormField, FormSelectBox, IconText } from 'src/components';
import { TLanguage, useCredentialsSelector } from 'src/store';
import { TInputField, useLocalization } from 'src/toolkit';

import { LOCALE_FIELD_LOCALIZATION } from './localization';

import styles from './LocaleField.module.scss';


type TProps = TInputField & {
	isLoading?: boolean;
};

type TOptionItem = {
	text: JSX.Element;
	value: string;
};

function createOptionItem(item: TLanguage): TOptionItem {
	const { alias, name } = item;

	return { text: <IconText path={ `/images/languages/${alias}.svg` } text={ name } />, value: alias };
}

export function LocaleField(props: TProps) {
	const { languages } = useCredentialsSelector();
	const {
		localeLegend,
		localeTitle,
	} = useLocalization(LOCALE_FIELD_LOCALIZATION);

	const [options, setOptions] = useState<TOptionItem[]>([]);

	useEffect(() => {
		if (languages.length > 0) {
			const arr = languages.map(item => createOptionItem(item));

			setOptions(arr);
		}
	}, [languages]);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ localeLegend }
			errors={ errors }
			required
		>
			<FormSelectBox
				title={ localeTitle }
				className={ styles.selectBox }
				items={ options }
				onChange={ val => setValue(val) }
				value={ value }
				name={ name }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
