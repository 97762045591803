import { FormField, FormInput, FormRow } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_NICKNAME_FIELD_LOCALIZATION } from './localization';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountNicknameField(props: TProps) {
	const { legend, placeholder, title } = useLocalization(ACCOUNT_NICKNAME_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required
		>
			<FormRow>
				<FormInput
					placeholder={ placeholder }
					name={ name }
					title={ title }
					value={ value }
					autoComplete="off"
					onChange={ e => setValue(e.target.value) }
					disabled={ isLoading }
				/>
			</FormRow>
		</FormField>
	);
}
