import { FormField, FormInputMask } from 'src/components';
import { TInputFormField } from 'src/toolkit';


type TProps = TInputFormField & {
	isLoading?: boolean;
	readOnly?: boolean;
	legend: string;
	confirmed?: boolean;
	unconfirmed?: boolean;
	title: string;
};

export function AccountPhoneNumberField(props: TProps) {
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		readOnly,
		required = false,
		confirmed = false,
		unconfirmed = false,
		legend,
		title,
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
			confirmed={ confirmed }
			unconfirmed={ unconfirmed }
			requiredMark={ false }
		>
			<FormInputMask
				placeholder="+7 ( ___ ) ___ - __ - __"
				mask="+7 ( 999 ) 999 - 99 - 99"
				title={ title }
				name={ name }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
				readOnly={ readOnly }
			/>
		</FormField>
	);
}
