import { InputHTMLAttributes } from 'react';

import { FormButtonLink, FormField, FormIcon, FormInput, FormRow } from 'src/components/form';
import { TInputField } from 'src/toolkit';


type TProps = TInputField & InputHTMLAttributes<HTMLInputElement> & {
	legend: string;
	title: string;
	buttonTitle: string;
	to: string;
	confirmed?: boolean;
};

export function EmailPageLink(props: TProps) {
	const {
		name,
		errors,
		value,
		setValue,

		to,
		confirmed = false,

		legend,
		title,
		placeholder,
		buttonTitle,
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			confirmed={ confirmed }
		>
			<FormRow>
				<FormInput
					placeholder={ placeholder }
					title={ title }
					name={ name }
					value={ value }
					onChange={ e => setValue(e.target.value) }
					autoComplete="off"
					readOnly
				/>

				<FormButtonLink
					mini
					to={ to }
					tabIndex={ -1 }
					title={ buttonTitle }
				>
					<FormIcon icon="icon-gears" />
				</FormButtonLink>
			</FormRow>
		</FormField>
	);
};
