import { LocalizationTypes } from 'src/common/types';

import { ACCOUNT_EXPERIENCE_UPDATE_RU_LOCALIZATION } from './ru.localization';
import { ACCOUNT_EXPERIENCE_UPDATE_EN_LOCALIZATION } from './en.localization';


export const ACCOUNT_EXPERIENCE_UPDATE_LOCALIZATION = {
	[LocalizationTypes.RU]: ACCOUNT_EXPERIENCE_UPDATE_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ACCOUNT_EXPERIENCE_UPDATE_EN_LOCALIZATION,
};
