export const ACCOUNT_PHONE_NUMBER_CONFIRMED_RU_LOCALIZATION = {
	confirmedLegend: 'Текущий пароль',
	confirmedTitle: 'Введите текущий пароль от аккаунта',

	unconfirmedLegend: 'Новый пароль',
	unconfirmedTitle: 'Введите новый пароль',

	successNotice: 'Новый номер подтверждён успешно',
	errorAlreadyConfirmed: 'Данный номер телефона уже подтверждён',

	buttonSend: 'Отправить',
	buttonConfirm: 'Подтвердить',
};
