import { FormImageSvg } from 'src/components';
import { PATH_DEFAULT_PROJECT } from 'src/routes';
import { TInputFileField, useLocalization } from 'src/toolkit';

import { ACCOUNT_PROJECT_IMAGE_FIELD_LOCALIZATION } from './localization';


type TProps = TInputFileField & {
	setFile: (value: File) => void;
	isLoading?: boolean;
};

export function AccountProjectImageField(props: TProps) {
	const {
		imageLegend,
		selectImageText,
		changeImageText,
	} = useLocalization(ACCOUNT_PROJECT_IMAGE_FIELD_LOCALIZATION);

	const { isLoading, ...restProps } = props;

	return (
		<FormImageSvg
			legend={ imageLegend }
			{ ...restProps }
			selectImageText={selectImageText}
			changeImageText={changeImageText}
			defaultSrc={ PATH_DEFAULT_PROJECT }
		/>
	);
}
