import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TAccountProject, TAccountProjectLocaleParam, TAccountProjectResponse, adaptAccountProjectFromResponse } from '../../types';
import { BACKEND_ACCOUNT_PROJECTS } from '../../backend-paths';


export const accountProjectLocaleReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_PROJECTS}/:alias/:locale`,
	(payload: TAccountProjectLocaleParam, thunkApi) => {
		const { alias, locale } = payload;
		const path = `${BACKEND_ACCOUNT_PROJECTS}/${alias}/${locale}`;

		return HttpService.get<TAccountProjectResponse>(path).then(result => {
			return adaptAccountProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TAccountProject | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountProjectLocaleReadSlice = createSlice({
	name: 'accountProjectLocaleReadSlice',
	initialState,
	reducers: {
		setAccountProjectLocaleRead(state, action: PayloadAction<TAccountProject>) {
			state.data = action.payload;
		},

		clearAccountProjectLocaleRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountProjectLocaleReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountProjectLocaleReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountProject>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountProjectLocaleReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountProjectLocaleReadSelector = () => useAppSelector(state => state[accountProjectLocaleReadSlice.name]);

export const { clearAccountProjectLocaleRead, setAccountProjectLocaleRead } = accountProjectLocaleReadSlice.actions;
