import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_SOURCE_CURRENCIES } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TCurrency, TCurrencyListResponse, adaptCurrenciesFromListResponse } from '../../types';


export const accountSourceCurrencyGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_SOURCE_CURRENCIES}`,
	(payload, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SOURCE_CURRENCIES}`;

		return HttpService.get<TCurrencyListResponse>(path).then(result => {
			return adaptCurrenciesFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	items?: TCurrency[],
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	items: undefined,
	error: undefined,
};

export const accountSourceCurrencySlice = createSlice({
	name: 'accountSourceCurrencySlice',
	initialState,
	reducers: {
		clearAccountSourceCurrency(state) {
			return { ...initialState, list: state.items };
		},
	},

	extraReducers: {
		[accountSourceCurrencyGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountSourceCurrencyGetApi.fulfilled.type]: (state, action: PayloadAction<TCurrency[]>) => {
			state.isLoading = false;
			state.items = action.payload;
			state.error = undefined;
		},

		[accountSourceCurrencyGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountSourceCurrencySelector = () => useAppSelector(state => state[accountSourceCurrencySlice.name]);

export const { clearAccountSourceCurrency } = accountSourceCurrencySlice.actions;
