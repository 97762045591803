export const SIGN_UP_MAIN_FORM_EN_LOCALIZATION = {
	nicknameLegend: 'Nickname',
	nicknamePlaceholder: 'Nickname',
	nicknameTitle: 'Enter your Nickname',

	registrationTypeLegend: 'Registration by',
	phoneNumberTypeText: 'Phone number',
	emailTypeText: 'E-Mail',

	phoneNumberLegend: 'Phone number',
	phoneNumberTitle: 'Enter your Phone number',

	emailLegend: 'E-Mail',
	emailPlaceholder: 'E-Mail',
	emailTitle: 'Enter your E-Mail',

	passwordLegend: 'Password',
	passwordTitle: 'Enter your password',

	passwordConfirmLegend: 'Confirm password',
	passwordConfirmTitle: 'Repeat password',

	acceptPolicyText: 'I accept the Policy of intent',

	buttonText: 'Sign up',
};
