import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_DEFAULT_EXPERIENCE, PATH_STATIC_EXPERIENCE_IMAGES } from 'src/routes';
import { TPublicProject, TPublicProjectResponse, adaptPublicProjectFromResponse } from './public-project.type';


export const PublicExperienceSortCol = {
	COMPANY_NAME: 'company_name',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TPublicExperienceSortCol = TTableSortCols<typeof PublicExperienceSortCol>;

export type TPublicExperienceQuery = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TPublicExperienceSortCol;
};

export type TPublicExperienceQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};


export type TPublicExperienceLocale = {
	locale: string;
  companyCity: string;
  positionName: string;
  description: string;
	createdAt: string;
  updatedAt: string;
};

export type TPublicExperience = TPublicExperienceLocale & {
	pid: string;
	companyName: string;
  companyUri: string;
  companyLogo: string;
  dateStart: string;
  dateEnd: string;
	locales: string[];
	projects: TPublicProject[];
	countExperience: number[];
};

export type TPublicExperienceResponse = {
	pid: string;
  company_name: string;
  company_uri: string;
  company_city: string;
  position_name: string;
  description: string;
  company_logo: string;
  locale: string;
  locales: string[];
  date_start: string;
  date_end: string;
  created_at: string;
  updated_at: string;

  projects: TPublicProjectResponse[];
	count_experience: number[];
};

export type TPublicExperienceLocaleBodyRequest = Omit<TPublicExperienceLocale, 'createdAt' | 'updatedAt'>;

export type TPublicExperienceBodyRequest = Pick<TPublicExperience, 'companyName' | 'companyUri' | 'dateStart'> & {
	companyLogo?: File | string;
	dateEnd?: string;
};

export type TPublicExperienceLocaleRequest = Pick<TPublicExperienceResponse, 'locale' | 'company_city' | 'position_name' | 'description'>;

export type TPublicExperienceRequest = Pick<TPublicExperienceResponse, 'company_name' | 'company_uri' | 'date_start'> & {
	company_logo?: File | string;
	date_end?: string;
};

export type TPublicExperienceLocaleParam = {
  pid: string;
  locale: string;
};

export type TPublicExperienceListResponse = TListResponse<TPublicExperienceResponse>;

export type TPublicExperienceList = TListResponse<TPublicExperience>;

export type TPublicExperienceLocalePayloadCreate = {
	pid: string;
	data: TPublicExperienceLocaleBodyRequest;
};

export type TPublicExperienceLocalePayloadUpdate = TPublicExperienceLocaleParam & {
	data: TPublicExperienceLocaleBodyRequest;
};

export type TPublicExperiencePayloadUpdate = {
	pid: string;
	data: TPublicExperienceBodyRequest;
};


export function adaptPublicExperienceFromListResponse(data: TPublicExperienceListResponse): TPublicExperienceList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptPublicExperienceFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptPublicExperienceFromResponse(data: TPublicExperienceResponse): TPublicExperience {
	const {
		pid,
		company_name: companyName,
		company_uri: companyUri,
		company_city: companyCity,
		date_start: dateStart,
		date_end: dateEnd,
		position_name: positionName,
		description,
		company_logo: companyLogoRaw,
		created_at: createdAt,
		updated_at: updatedAt,
		locales = [],
		locale = '',
		projects: projectsRaw = [],
		count_experience: countExperience,
	} = data;

	const companyLogo = companyLogoRaw ? `${PATH_STATIC_EXPERIENCE_IMAGES}/${companyLogoRaw}` : PATH_DEFAULT_EXPERIENCE;

	const projects = projectsRaw.map(item => adaptPublicProjectFromResponse(item));

	return {
		pid,
		companyLogo,
		companyName,
		companyUri,
		companyCity,
		positionName,
		description,
		dateStart,
		dateEnd,
		locale,
		locales,
		createdAt,
		updatedAt,
		projects,
		countExperience,
	};
}

export function adaptPublicExperienceToRequest(data: TPublicExperienceBodyRequest): TPublicExperienceRequest {
	const {
		dateStart,
		dateEnd,
		companyName,
		companyUri,
		companyLogo,
	} = data;

	return {
		date_start: dateStart,
		date_end: dateEnd,
		company_name: companyName,
		company_uri: companyUri,
		company_logo: companyLogo,
	};
}

export function adaptPublicExperienceLocaleToRequest(data: TPublicExperienceLocaleBodyRequest): TPublicExperienceLocaleRequest {
	const {
		locale,
		companyCity,
		positionName,
		description,
	} = data;

	return {
		locale,
		company_city: companyCity,
		position_name: positionName,
		description,
	};
}
