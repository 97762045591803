type TMetaName = {
	name: string;
	content: string;
};

type TMetaProperty = {
	property: string;
	content: string;
};

type TSetHeadMeta = TMetaName | TMetaProperty;


type TSetHeadProps = {
	title?: string;
	titlePostfix?: string;
	meta?: TSetHeadMeta[];
};


function isMetaName(value: TSetHeadMeta): value is TMetaName {
	return !!(value as TMetaName).name;
}

export function setHead(props: TSetHeadProps): void {
	const {
		title,
		titlePostfix,
		meta = [],
	} = props;

	const doc = window.document;

	if (title) {
		doc.title = title;
	}

	if (titlePostfix) {
		doc.title = doc.title + titlePostfix;
	}

	const head = doc.head;

	meta.forEach(item => {
		const { content = '' } = item;
		const isName = isMetaName(item);
		const key = isName ? 'name' : 'property';
		const value = isName ? item.name : item.property;
		const existed = doc.querySelector(`meta[${key}=${value}]`);

		if (existed) {
			existed.setAttribute('content', content);
			return;
		}

		const meta = doc.createElement('meta');
		meta.setAttribute(key, value);
		meta.setAttribute('content', content);

		head.appendChild(meta);
	});
}
