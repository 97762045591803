export * from './account-education-create.slice';
export * from './account-education-delete.slice';
export * from './account-education-read.slice';
export * from './account-education-update.slice';
export * from './account-educations.slice';

export * from './account-education-locale-create.slice';
export * from './account-education-locale-read.slice';
export * from './account-education-locale-update.slice';
export * from './account-education-locale-delete.slice';
