import { InputHTMLAttributes } from 'react';

import styles from './FormRadio.module.scss';


type TProps = InputHTMLAttributes<HTMLInputElement>;

export function FormRadio(props: TProps) {
	const {
		id,
		children,
		...restProps
	} = props;

	const classNameArr = [styles.formRadio];

	return (
		<div className={ styles.row}>
			<input
				id={ id }
				type="radio"
				className={ classNameArr.join(' ') }
				{ ...restProps }
			/>
			<label htmlFor={ id }>{ children }</label>
		</div>
	);
}
