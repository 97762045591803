import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LC_KEY_IS_AUTH, LocalStorage, HttpError, HttpService, LC_KEY_LANG } from 'src/toolkit';
import { DefaultLanguage, TCredentialResponse, TCredentials, TLanguage, TLanguageListResponse, TSignIn, adaptCredentialsfromResponse } from 'src/store/types';
import { BACKEND_ACCOUNT, BACKEND_SIGN_IN, BACKEND_SIGN_OUT, BACKEND_SOURCE_LANGUAGES } from 'src/store/backend-paths';

import { useAppSelector } from '../../hooks';
import { setLang } from 'src/common/helpers';


export const credentialsApi = createAsyncThunk(
	BACKEND_ACCOUNT,
	(payload, thunkApi) => {
		return HttpService.get<TCredentialResponse>(BACKEND_ACCOUNT).then(result => {
			return adaptCredentialsfromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

export const languagesApi = createAsyncThunk(
	BACKEND_SOURCE_LANGUAGES,
	(payload, thunkApi) => {
		return HttpService.get<TLanguageListResponse>(BACKEND_SOURCE_LANGUAGES).then(result => {
			return result.data.items;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

export const signInApi = createAsyncThunk(
	BACKEND_SIGN_IN,
	(payload: TSignIn, thunkApi) => {
		return HttpService.post<TCredentialResponse>(BACKEND_SIGN_IN, payload).then(result => {
			return adaptCredentialsfromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

export const signOutApi = createAsyncThunk(
	BACKEND_SIGN_OUT,
	(payload, thunkApi) => {
		return HttpService.post(BACKEND_SIGN_OUT, payload).then(result => {
			return result.data;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	credentials: TCredentials | undefined,
	roles: string[],
	languages: TLanguage[],
	lang: string,
	email: string | undefined,
	phoneNumber: string | undefined,
	isAppLoading: boolean,
	isLoading: boolean,
	error: HttpError | undefined,
};

const initialState: TInitialState = {
	credentials: undefined,
	roles: [],
	languages: [],
	lang: DefaultLanguage.alias,
	email: undefined,
	phoneNumber: undefined,
	isAppLoading: true,
	isLoading: false,
	error: undefined,
};

export const credentialsSlice = createSlice({
	name: 'credentialsSlice',
	initialState,
	reducers: {
		setCredentialsLangInit(state) {
			const localeLang = LocalStorage.get(LC_KEY_LANG);

			const existedLanguage = state.languages.find(item => item.alias === localeLang) || DefaultLanguage;
			const lang = existedLanguage.alias;

			state.lang = lang;

			setLang(lang);
		},

		setCredentialsLang(state, action: PayloadAction<string>) {
			state.lang = action.payload;

			setLang(action.payload);
		},

		setIsAppLoadingAction(state) {
			state.isAppLoading = false;
		},

		setCredentialsName(state, action: PayloadAction<string>) {
			if (state.credentials) {
				state.credentials.name = action.payload;
			}
		},

		setCredentialsRoles(state, action: PayloadAction<string[]>) {
			if (state.credentials) {
				state.credentials.roles = action.payload;
				state.roles = action.payload;
			}
		},

		clearSignInAction(state) {
			const { credentials, roles, languages, isAppLoading } = state;

			return { ...initialState, credentials, roles, languages, isAppLoading };
		},
	},

	extraReducers: {
		[credentialsApi.pending.type]: (state) => {
			state.error = undefined;
			state.isAppLoading = true;
		},

		[credentialsApi.fulfilled.type]: (state, action: PayloadAction<TCredentials>) => {
			state.isAppLoading = false;
			state.credentials = action.payload;
			state.roles = action.payload.roles;

			LocalStorage.set(LC_KEY_IS_AUTH, true);
		},

		[credentialsApi.rejected.type]: (state) => {
			state.isAppLoading = false;

			LocalStorage.set(LC_KEY_IS_AUTH, false);
		},

		[languagesApi.fulfilled.type]: (state, action: PayloadAction<TLanguage[]>) => {
			state.languages = action.payload;
		},

		[signInApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[signInApi.fulfilled.type]: (state, action: PayloadAction<TCredentials>) => {
			state.error = undefined;
			state.isLoading = false;
			state.credentials = action.payload;
			state.roles = action.payload.roles;

			LocalStorage.set(LC_KEY_IS_AUTH, true);
		},

		[signInApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
			LocalStorage.set(LC_KEY_IS_AUTH, false);
		},

		[signOutApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[signOutApi.fulfilled.type]: (state) => {
			state.isLoading = false;
			state.credentials = undefined;
			state.roles = [];
		},

		[signOutApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useCredentialsSelector = () => useAppSelector(state => state[credentialsSlice.name]);

export const { clearSignInAction, setIsAppLoadingAction, setCredentialsLang, setCredentialsLangInit, setCredentialsName, setCredentialsRoles } = credentialsSlice.actions;
