import { HomePage, ErrorPage, SignInPage, SignUpPage, SignUpEmailConfirmPage, AccountPage, RecoveryPage, AccountPasswordPage, StacksPage, StackUpdatePage, RecoveryEmailConfirmPage, AccountEducationPage, AccountEducationUpdatePage, AccountProjectsPage, AccountProjectsUpdatePage, AccountExperiencePage, AccountExperienceUpdatePage, StackLocaleUpdatePage, AccountEducationLocaleUpdatePage, AccountExperienceLocaleUpdatePage, AccountCvsPage, AccountProjectsLocalesUpdatePage, AccountCvsLocalesUpdatePage, AccountCvsUpdatePage, PublicCvsPage, PublicCvReadPage, UsersPage, UserUpdatePage, UserPasswordPage, AccountPhoneNumberPage, AccountEmailConfirmPage, AccountEmailPage, UserPhoneNumberPage, UserEmailPage, UserRolesPage } from 'src/pages';

import { PATH_HOME, PATH_SIGN_IN, PATH_SIGN_UP, PATH_ACCOUNT, PATH_ERROR, PATH_ACCOUNT_CV, PATH_ACCOUNT_EDUCATION, PATH_ACCOUNT_PROJECTS, PATH_ACCOUNT_EXPERIENCE, PATH_STACK, PATH_USERS, PATH_ACCOUNT_PASSWORD, PATH_RECOVERY, PATH_PUBLIC_CV, PATH_ACCOUNT_PHONE_NUMBER, PATH_ACCOUNT_EMAIL } from './paths';
import { ROLES } from './roles';

// const HomePage2 = lazy(() => import('src/pages/home/HomePage'));

export const routes = [
	{ path: PATH_HOME, element: HomePage },

	{ path: PATH_PUBLIC_CV, element: PublicCvsPage },
	{ path: `${PATH_PUBLIC_CV}/:nickname/:alias/:locale`, element: PublicCvReadPage },
	{ path: `${PATH_PUBLIC_CV}/:nickname/:alias/:locale/:pid`, element: PublicCvReadPage },

	{ path: PATH_SIGN_IN, element: SignInPage, isAuth: false, redirect: PATH_ACCOUNT },
	{ path: PATH_SIGN_UP, element: SignUpPage, isAuth: false, redirect: PATH_ACCOUNT },
	{ path: `${PATH_SIGN_UP}/email/confirm/:hashcode`, element: SignUpEmailConfirmPage, isAuth: false, redirect: PATH_ACCOUNT },
	{ path: PATH_RECOVERY, element: RecoveryPage, isAuth: false, redirect: PATH_ACCOUNT },
	{ path: `${PATH_RECOVERY}/email/confirm/:hashcode`, element: RecoveryEmailConfirmPage, isAuth: false, redirect: PATH_ACCOUNT },

	{ path: PATH_ACCOUNT, element: AccountPage, isAuth: true },
	{ path: PATH_ACCOUNT_PASSWORD, element: AccountPasswordPage, isAuth: true },
	{ path: PATH_ACCOUNT_PHONE_NUMBER, element: AccountPhoneNumberPage, isAuth: true },
	{ path: PATH_ACCOUNT_EMAIL, element: AccountEmailPage, isAuth: true },
	{ path: `${PATH_ACCOUNT_EMAIL}/confirm/:hashcode`, element: AccountEmailConfirmPage },

	{ path: PATH_ACCOUNT_CV, element: AccountCvsPage, isAuth: true, role: ROLES.ACCOUNT_CVS },
	{ path: `${PATH_ACCOUNT_CV}/:alias`, element: AccountCvsUpdatePage, isAuth: true, role: ROLES.ACCOUNT_CVS },
	{ path: `${PATH_ACCOUNT_CV}/:alias/:locale`, element: AccountCvsLocalesUpdatePage, isAuth: true, role: ROLES.ACCOUNT_CVS },

	{ path: PATH_ACCOUNT_EDUCATION, element: AccountEducationPage, isAuth: true, role: ROLES.ACCOUNT_EDUCATIONS },
	{ path: `${PATH_ACCOUNT_EDUCATION}/:pid`, element: AccountEducationUpdatePage, isAuth: true, role: ROLES.ACCOUNT_EDUCATIONS },
	{ path: `${PATH_ACCOUNT_EDUCATION}/:pid/:locale`, element: AccountEducationLocaleUpdatePage, isAuth: true, role: ROLES.ACCOUNT_EDUCATIONS },

	{ path: PATH_ACCOUNT_EXPERIENCE, element: AccountExperiencePage, isAuth: true, role: ROLES.ACCOUNT_EXPERIENCES },
	{ path: `${PATH_ACCOUNT_EXPERIENCE}/:pid`, element: AccountExperienceUpdatePage, isAuth: true, role: ROLES.ACCOUNT_EXPERIENCES },
	{ path: `${PATH_ACCOUNT_EXPERIENCE}/:pid/:locale`, element: AccountExperienceLocaleUpdatePage, isAuth: true, role: ROLES.ACCOUNT_EXPERIENCES },

	{ path: PATH_ACCOUNT_PROJECTS, element: AccountProjectsPage, isAuth: true, role: ROLES.ACCOUNT_PROJECTS },
	{ path: `${PATH_ACCOUNT_PROJECTS}/:alias`, element: AccountProjectsUpdatePage, isAuth: true, role: ROLES.ACCOUNT_PROJECTS },
	{ path: `${PATH_ACCOUNT_PROJECTS}/:alias/:locale`, element: AccountProjectsLocalesUpdatePage, isAuth: true, role: ROLES.ACCOUNT_PROJECTS },

	{ path: PATH_USERS, element: UsersPage, isAuth: true, role: ROLES.RETRIEVE_USERS },
	{ path: `${PATH_USERS}/:nickname`, element: UserUpdatePage, isAuth: true, role: ROLES.RETRIEVE_USERS },
	{ path: `${PATH_USERS}/:nickname/roles`, element: UserRolesPage, isAuth: true, role: ROLES.UPDATE_USERS },
	{ path: `${PATH_USERS}/:nickname/password`, element: UserPasswordPage, isAuth: true, role: ROLES.UPDATE_USERS },
	{ path: `${PATH_USERS}/:nickname/phone-number`, element: UserPhoneNumberPage, isAuth: true, role: ROLES.UPDATE_USERS },
	{ path: `${PATH_USERS}/:nickname/email`, element: UserEmailPage, isAuth: true, role: ROLES.UPDATE_USERS },

	{ path: PATH_STACK, element: StacksPage, isAuth: true, role: ROLES.RETRIEVE_STACKS },
	{ path: `${PATH_STACK}/:alias`, element: StackUpdatePage, isAuth: true, role: ROLES.RETRIEVE_STACKS },
	{ path: `${PATH_STACK}/:alias/:locale`, element: StackLocaleUpdatePage, isAuth: true, role: ROLES.RETRIEVE_STACKS },

	{ path: PATH_ERROR, element: ErrorPage },
];
