import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { Link } from 'src/components';
import { EmptyLayout } from 'src/layouts';

import styles from './AuthLayer.module.scss';

export type TAuthContent = {
	headline: string;
	contentLeftHalf: string;
	contentLink: string;
	contentLinkPath: string;
	contentRightHalf: string;
};

type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & TAuthContent;

export function AuthLayer(props: TProps) {
	const {
		children,
		headline,
		contentLeftHalf,
		contentLink,
		contentLinkPath,
		contentRightHalf,
	} = props;

	return (
		<EmptyLayout>
			<div className={ styles.signUpPage }>
				<div className={ styles.content }>
					<h1>{ headline }</h1>
					<p>
						<span>{ contentLeftHalf }</span>
						<Link to={ contentLinkPath }>{ contentLink }</Link>
						<span>{ contentRightHalf }</span>
					</p>
				</div>

				<div className={ styles.container }>
					{ children }
				</div>
			</div>
		</EmptyLayout>
	);
}

