// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSelectBoxWrap_formSelectBoxWrap__HpbBy {\n  background-color: rgb(255, 255, 255);\n  box-sizing: border-box;\n  color: rgb(0, 0, 0);\n  font-family: inherit;\n  font-size: 1em;\n  font-weight: 100;\n  font-style: inherit;\n  height: 2em;\n  min-height: 2em;\n  max-height: 2em;\n  position: relative;\n  z-index: 10;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-select-box/components/wrap/FormSelectBoxWrap.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,sBAAA;EACA,mBAAA;EAEA,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EAEA,WAAA;EACA,eAAA;EACA,eAAA;EAEA,kBAAA;EAEA,WAAA;AAHD","sourcesContent":[".formSelectBoxWrap {\n\tbackground-color: rgba(255, 255, 255, 1);\n\tbox-sizing: border-box;\n\tcolor: rgba(0, 0, 0, 1);\n\n\tfont-family: inherit;\n\tfont-size: 1em;\n\tfont-weight: 100;\n\tfont-style: inherit;\n\n\theight: 2em;\n\tmin-height: 2em;\n\tmax-height: 2em;\n\n\tposition: relative;\n\n\tz-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSelectBoxWrap": "FormSelectBoxWrap_formSelectBoxWrap__HpbBy"
};
export default ___CSS_LOADER_EXPORT___;
