import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormField, FormInput, FormNotice } from 'src/components';
import { recoveryEmailApi, recoveryPhoneNumberApi, useAppDispatch, useRecoverySelector } from 'src/store';
import { formValidatePhoneNumberEmail, formValidateEmail, useInputField, formHandleServerError, libClearPhone, useLocalization } from 'src/toolkit';

import { RECOVERY_FORM_MAIN_LOCALIZATION } from './localization';

import styles from '../../../../../../components/styles/RecoveryStage.module.scss';


export function RecoveryFormMain() {
	const dispatch = useAppDispatch();
	const { isLoading, error } = useRecoverySelector();

	const {
		loginLegend,
		loginPlaceholder,
		loginTitle,
		buttonText,
	} = useLocalization(RECOVERY_FORM_MAIN_LOCALIZATION);

	const loginField = useInputField('login');

	const [notice, setNotice] = useState('');

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice, loginField);
		}
	}, [error]);

	const submit = async () => {
		const errors = [];

		errors.push(formValidatePhoneNumberEmail(loginField.value));
		loginField.setErrors(errors[errors.length - 1]);

		const isInvalidForm = !!errors.find(items => items.length > 0);

		if (isInvalidForm) {
			return;
		}

		const byPhoneNumber = formValidateEmail(loginField.value).length > 0;

		const value = loginField.value;

		if (byPhoneNumber) {
			const clearValue = libClearPhone(value);

			return dispatch(recoveryPhoneNumberApi(clearValue));
		}

		dispatch(recoveryEmailApi(value));
	};

	return (
		<Form className={ styles.recoveryPageStageForm } onSubmit={ submit }>
			<FormField
				legend={ loginLegend }
				errors={ loginField.errors }
				required
			>
				<FormInput
					placeholder={ loginPlaceholder }
					name={ loginField.name }
					title={ loginTitle }
					value={ loginField.value }
					onChange={ e => loginField.setValue(e.target.value) }
					disabled={ isLoading }
				/>
			</FormField>

			{ notice && <FormNotice className="error">{ notice }</FormNotice> }

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ buttonText }</FormButton>
			</FormButtonWrap>
		</Form>
	);
}
