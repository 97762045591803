// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageCol_pageCol__d0b4B {\n  box-sizing: border-box;\n  margin: 0;\n  max-width: 360px;\n  width: 100%;\n}\n\n@media screen and (max-width: 767px) {\n  * > .PageCol_pageCol__d0b4B {\n    margin: 0 auto;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/page/page-col/PageCol.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;AACD;;AAEA;EACC;IACC,cAAA;EACA;AACF","sourcesContent":[".pageCol {\n\tbox-sizing: border-box;\n\tmargin: 0;\n\tmax-width: 360px;\n\twidth: 100%;\n}\n\n@media screen and (max-width: 767px) {\n\t* > .pageCol {\n\t\tmargin: 0 auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageCol": "PageCol_pageCol__d0b4B"
};
export default ___CSS_LOADER_EXPORT___;
