import { useMemo } from 'react';

import { TAccountEmail, useAccountEmailReadSelector } from 'src/store';

import { AccountEmailConfirmStage, AccountEmailSendStage } from './components';


type TProps = {
	item: TAccountEmail;
};

export function AccountEmailRouter(props: TProps) {
	const { item: itemParam } = props;
	const { item = itemParam, timeExpired } = useAccountEmailReadSelector();

	const isConfirmation = useMemo(() => {
		const { emailExpected } = item;

		return emailExpected && timeExpired > 0 ? true : false;
	}, [item, timeExpired]);

	if (isConfirmation) {
		return (
			<AccountEmailConfirmStage item={ item } />
		);
	}

	return (
		<AccountEmailSendStage item={ item } />
	);
}
