import { HeadlineLink } from 'src/pages/components';
import { useLocalization } from 'src/toolkit';

import { PublicCvEmail, PublicCvLinkedIn, PublicCvPhoneNumber, PublicCvTelegram, PublicCvHeadHunter } from './components';
import { PUBLIC_CV_CONTACTS_LOCALIZATION } from './localization';

import styles from './PublicCvContacts.module.scss';


type TProps = {
	className?: string;
	classNames?: string[];

	phoneNumber?: string;
	email?: string;
	telegram?: string;
	linkedin?: string;
	headhunter?: string;
};

export function PublicCvContacts(props: TProps) {
	const { headline } = useLocalization(PUBLIC_CV_CONTACTS_LOCALIZATION);
	const {
		className = '',
		classNames: classNamesOuter = [],

		phoneNumber = '',
		email = '',
		telegram = '',
		linkedin = '',
		headhunter = '',

		...restProps
	} = props;

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			<HeadlineLink>{ headline }</HeadlineLink>

			<div className={ styles.contactBox }>
				{ phoneNumber && <PublicCvPhoneNumber className={ styles.contact } value={ phoneNumber } /> }
				{ email && <PublicCvEmail className={ styles.contact } value={ email } /> }
				{ telegram && <PublicCvTelegram className={ styles.contact } value={ telegram } /> }
				{ linkedin && <PublicCvLinkedIn className={ styles.linkedin } value={ linkedin } /> }
				{ headhunter && <PublicCvHeadHunter className={ styles.headhunter } value={ headhunter } /> }
			</div>
		</div>
	);
}
