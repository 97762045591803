import { useState } from 'react';


type TErrors = string[];

export type TInputFilesField<T = string> = {
	required: boolean;
	name: string;
	value: T[];
	setValue: (value: T[]) => void;
	errors: TErrors;
	setErrors: (value: TErrors) => void;
	clear: (value?: T[]) => void;
};

export function useInputFilesField<T = string>(name: string, defaultValue?: T[], defaultRequired?: boolean, defaultErrors?: TErrors): TInputFilesField<T> {
	const defaultVal = defaultValue || [];
	const err = defaultErrors || [];
	const defaultReq = defaultRequired || false;

	const [required] = useState(defaultReq);
	const [value, setValue] = useState(defaultVal);
	const [errors, setErrors] = useState(err as TErrors);

	const onChange = (value: T[]) => {
		setValue(value);
	};

	const onErrors = (err: TErrors) => {
		setErrors(err);
	};

	const clear = (value?: T[]) => {
		const clearValue = value ?? defaultVal;
		setValue(clearValue);
	};

	return {
		required,
		name,
		value,
		setValue: onChange,
		errors,
		setErrors: onErrors,
		clear,
	};
}
