type TStorage = Storage | StorageMock;

type TValue = any;


export const LC_KEY_IS_AUTH = 'IS_AUTH';
export const LC_KEY_LANG = 'LANG';
export const LC_KEY_IS_VISIBLE_ASIDE = 'LC_KEY_IS_VISIBLE_ASIDE';

export class LocalStorage {
	static storage: TStorage = window.localStorage;

	public static setStorage(storage: TStorage): void {
		this.storage = storage;
	}

	public static get(key: string): TValue {
		const value = this.storage.getItem(key);

		if (value === null) {
			return '';
		}

		const check = /^[\],:{}\s]*$/.test(
			value.replace(/\\["\\\/bfnrtu]/g, '@')
			.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
			.replace(/(?:^|:|,)(?:\s*\[)+/g, '')
		);

		if (check) {
			return JSON.parse(value);
		}

		return value;
	}

	public static set(key: string, value: TValue): void {
		const stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();

		this.storage.setItem(key, stringValue);
	}

	public static removeFrom(key: string): void {
		this.storage.removeItem(key);
	}
}

export class StorageMock {
	private storage: Record<string, string> = {};

	constructor(storage: Record<string, string>) {
		this.storage = storage;
	}

	public getItem(key: string): string | null {
		return this.storage[key] || null;
	}

	public setItem(key: string, value: string): void {
		this.storage[key] = value;
	}

	public removeItem(key: string): void {
		delete this.storage[key];
	}

	public get length(): number {
		return Object.values(this.storage).length;
	}

	public clear(): void {
		this.storage = {};
	}
}
