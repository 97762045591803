// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CvExperienceCount_headline__1dvjW {\n  color: rgb(150, 150, 150);\n  font-size: 0.9em;\n  line-height: 1.2em;\n  height: 1.2em;\n  white-space: normal;\n}\n\n.CvExperienceCount_text__FWyb8 {\n  color: rgb(150, 150, 150);\n  font-size: 1em;\n  line-height: 1.2em;\n  height: 1.2em;\n  white-space: normal;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/experience-count/CvExperienceCount.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EAEA,gBAAA;EAEA,kBAAA;EACA,aAAA;EAEA,mBAAA;AAFD;;AAKA;EACC,yBAAA;EAEA,cAAA;EAEA,kBAAA;EACA,aAAA;EAEA,mBAAA;AALD","sourcesContent":[".headline {\n\tcolor: rgba(150, 150, 150, 1);\n\n\tfont-size: 0.9em;\n\n\tline-height: 1.2em;\n\theight: 1.2em;\n\n\twhite-space: normal;\n}\n\n.text {\n\tcolor: rgba(150, 150, 150, 1);\n\n\tfont-size: 1em;\n\n\tline-height: 1.2em;\n\theight: 1.2em;\n\n\twhite-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": "CvExperienceCount_headline__1dvjW",
	"text": "CvExperienceCount_text__FWyb8"
};
export default ___CSS_LOADER_EXPORT___;
