export type TUserEmail = {
  email: string;
  emailExpected: string;
  timeExpired: number;
	createdAt: string;
	updatedAt: string;
};

export type TUserEmailResponse = {
  email: string;
  email_expected: string;
  time_expected: number;
	created_at: string;
	updated_at: string;
};

export type TUserEmailPayloadUpdate = {
  nickname: string;
  data: TUserEmailBodyRequest;
};

export type TUserEmailBodyRequest = {
  emailExpected: string;
};

export type TUserEmailRequest = {
  email_expected: string;
};

export function adaptUserEmailToRequest(data: TUserEmailBodyRequest): TUserEmailRequest {
	const { emailExpected } = data;

	return {
		email_expected: emailExpected,
	};
}

export function adaptUserEmailFromResponse(data: TUserEmailResponse): TUserEmail {
	const {
		email: email,
		email_expected: emailExpected,
		time_expected: timeExpired,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	return {
		email: email || '',
		emailExpected: emailExpected || '',
		timeExpired: timeExpired || 0,
		createdAt,
		updatedAt,
	};
}
