import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import { Contact } from 'src/pages/components';
import { useLocalization } from 'src/toolkit';

import { PUBLIC_CV_TELEGRAM_LOCALIZATION } from './localization';
import { PublicCvTelegramIconName } from './components';

// import styles from './PublicCvTelegram.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
	value?: ReactNode;
};

export function PublicCvTelegram(props: TProps) {
	const { text } = useLocalization(PUBLIC_CV_TELEGRAM_LOCALIZATION);

	const {
		value,
		...restProps
	} = props;


	return (
		<Contact { ...restProps } value={ value } text={ text }>
			<PublicCvTelegramIconName />
		</Contact>
	);
}
