export const RECOVERY_STAGE_FORM_PHONE_NUMBER_EN_LOCALIZATION = {
	codeLegend: 'Confirmation code',
	codeTitle: 'Enter code for confirmation',

	newPasswordLegend: 'New password',
	newPasswordTitle: 'Enter your new password',

	newPasswordConfirmLegend: 'Confirm new password',
	newPasswordConfirmTitle: 'Repeat new password',

	buttonText: 'Set password',
};
