// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderCore_headerCore__8Yn9f {\n  background-color: rgb(255, 255, 255);\n  color: rgb(0, 0, 0);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 0;\n  min-height: 750px;\n  position: relative;\n}\n@media screen and (max-width: 480px) {\n  .HeaderCore_headerCore__8Yn9f {\n    min-height: 480px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/header/header-core/HeaderCore.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,mBAAA;EACC,aAAA;EACD,sBAAA;EACC,uBAAA;EACA,mBAAA;EAED,SAAA;EACC,iBAAA;EACD,kBAAA;AAAD;AAEC;EAZD;IAaE,iBAAA;EACA;AACF","sourcesContent":[".headerCore {\n\tbackground-color: rgba(255, 255, 255, 1);\n\tcolor: rgba(0, 0, 0, 1);\n  display: flex;\n\tflex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n\tmargin: 0;\n  min-height: 750px;\n\tposition: relative;\n\n\t@media screen and (max-width: 480px) {\n\t\tmin-height: 480px;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerCore": "HeaderCore_headerCore__8Yn9f"
};
export default ___CSS_LOADER_EXPORT___;
