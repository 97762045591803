import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './Icon.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
	icon: string;
	classNames?: string[];
};

export function Icon(props: TProps) {
	const {
		icon = '',
		className = '',
		classNames: classNamesOuter = [],
		children = '',
		...restProps
	} = props;

	const classNames = [styles.icon, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	const iconClass = styles[icon];

	if (iconClass) {
		classNames.push(iconClass);
	}

	return (
		<i className={ classNames.join(' ') } { ...restProps }>
			{ children }
		</i>
	);
}
