import { ReactNode, MouseEventHandler } from 'react';

import navStyles from '../nav/Nav.module.scss';
import styles from './NavButton.module.scss';


type TProps = {
	active?: boolean;
	children?: ReactNode;
	onClick?: MouseEventHandler<HTMLElement>;
};

export function NavButton(props: TProps) {
	const { active = false, onClick = () => {} } = props;

	const { navButton, lines, line, line1, line2, line3 } = styles;

	const classNames = [navStyles.navItemStyle, navButton];

	if (active) {
		classNames.push(styles.active);
	}

	return (
		<button
			className={ classNames.join(' ') }
			onClick={ onClick }
			type="button"
		>

			<div className={ lines }>
				<span className={ `${line} ${line1}` }></span>
				<span className={ `${line} ${line2}` }></span>
				<span className={ `${line} ${line3}` }></span>
			</div>
		</button>
	);
}
