import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_DEFAULT_PROJECT, PATH_STATIC_PROJECTS_IMAGES } from 'src/routes';
import { TAccountStack, TAccountStackResponse, adaptAccountStackFromResponse } from './stack.type';

export const AccountProjectSortCol = {
	ALIAS: 'alias',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export type TAccountProjectSortCol = TTableSortCols<typeof AccountProjectSortCol>;

export type TAccountProjectQuery = {
	locale: string;
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountProjectSortCol;
};

export type TAccountProjectQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountProjectFile = {
	name: string;
	path: string;
};

export type TAccountProject = TAccountProjectLocale & {
	alias: string;
  title: string;
  image: string;
  uri: string;
  repositoryUri: string;
  files: TAccountProjectFile[];
  stack: TAccountStack[];
  locales: string[];
};

export type TAccountProjectLocale = {
  locale: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type TAccountProjectLocaleRequest = Omit<TAccountProjectLocale, 'createdAt' | 'updatedAt'>;

export type TAccountProjectLocaleCreate = Omit<TAccountProjectLocaleUpdate, 'locale'>;

export type TAccountProjectLocaleBodyRequest = TAccountProjectLocaleRequest;

export type TAccountProjectFileRequest = Array<File | string>;

export type TAccountProjectBodyRequest = Omit<TAccountProject, 'description' | 'stack' | 'files' | 'image' | 'uri' | 'repositoryUri' | 'locale' | 'locales' | 'createdAt' | 'updatedAt'> & {
	stack?: string[];
	files?: TAccountProjectFileRequest;
	image?: File | string;
	uri?: string;
	repositoryUri?: string;
};

export type TAccountProjectResponse = {
  locale: string;
  alias: string;
  title: string;
  description: string;
  image: string;
  uri: string;
  repository_uri: string;
  files: TAccountProjectFile[];
  stack: TAccountStackResponse[];
  locales: string[];
  created_at: string;
  updated_at: string;
};

export type TAccountProjectLocaleParam = {
  alias: string;
  locale: string;
};

export type TAccountProjectRequest = Omit<TAccountProjectResponse, 'description' | 'stack' | 'files' | 'image' | 'repository_uri' | 'uri' | 'locale' | 'locales' | 'created_at' | 'updated_at'> & {
	stack?: string[];
	files?: TAccountProjectFileRequest;
	image?: File | string;
	uri?: string;
	repository_uri?: string;
};

export type TAccountProjectListResponse = TListResponse<TAccountProjectResponse>;

export type TAccountProjectList = TListResponse<TAccountProject>;

export type TAccountProjectPayloadCreate = {
	data: TAccountProjectBodyRequest;
	stack: string[];
	files: TAccountProjectFileRequest;
};

export type TAccountProjectPayloadUpdate = TAccountProjectPayloadCreate & {
	alias: string;
};

export type TAccountProjectLocaleUpdate = TAccountProjectLocaleParam & {
	data: TAccountProjectLocaleBodyRequest;
};

export function adaptAccountProjectFromListResponse(data: TAccountProjectListResponse): TAccountProjectList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountProjectFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountProjectFromResponse(data: TAccountProjectResponse): TAccountProject {
	const {
		locale,
		alias,
		title,
		description,
		image: imageRaw = '',
		uri = '',
		repository_uri: repositoryUri = '',
		files = [],
		locales = [],
		created_at: createdAt,
		updated_at: updatedAt,
		stack: stackRaw = [],
	} = data;

	const image = imageRaw ? `${PATH_STATIC_PROJECTS_IMAGES}/${imageRaw}` : PATH_DEFAULT_PROJECT;
	const stack = stackRaw.map(item => adaptAccountStackFromResponse(item));

	return {
		locale,
		alias,
		title,
		description,
		image,
		uri,
		repositoryUri,
		files,
		locales,
		createdAt,
		updatedAt,
		stack,
	};
}

export function adaptAccountProjectToRequest(data: TAccountProjectBodyRequest): TAccountProjectRequest {
	const {
		alias,
		title,
		image,
		uri,
		repositoryUri,
	} = data;

	return {
		alias,
		title,
		image,
		uri,
		repository_uri: repositoryUri,
	};
}

export function adaptAccountProjectLocaleToRequest(data: TAccountProjectLocaleBodyRequest): TAccountProjectLocaleRequest {
	const {
		locale,
		description,
	} = data;

	return {
		locale,
		description,
	};
}
