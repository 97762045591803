import { TFormSearchBoxOption } from '../../types';

import { FormSearchListItem } from './components';

import styles from './FormSearchList.module.scss';


type TProps =  {
	items?: TFormSearchBoxOption[];
	isActive?: boolean;
	onClick: (value: string) => void;
	classNames?: string[];
};

export function FormSearchList(props: TProps) {
	const {
		onClick = () => {},
		classNames: classNamesOuter = [],
		items = [],
		isActive = false,
		...restFields
	} = props;

	const classNames = [styles.list, 'scroll-box', ...classNamesOuter];

	if (isActive) {
		classNames.push(styles.active);
	}

	return (
		<div className={ classNames.join(' ') } { ...restFields }>
			{
				items.map(item => <FormSearchListItem key={ item.value } onClick={ e => onClick(item.value) }>{ item.text }</FormSearchListItem>)
			}
		</div>
	);
}
