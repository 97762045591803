// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountLayout_contentWrap__4amvX {\n  display: flex;\n  flex-direction: column;\n  align-items: space-between;\n  margin: 3.25em 0 0 auto;\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n}\n\n.AccountLayout_layout__AeqR4 {\n  box-sizing: border-box;\n}\n\n.AccountLayout_main__bMnAZ {\n  box-sizing: border-box;\n  font-size: 1em;\n  height: 100%;\n  width: 100%;\n  max-width: 1280px;\n  padding: 0 4em;\n  margin: auto;\n}\n@media screen and (max-width: 1280px) {\n  .AccountLayout_main__bMnAZ {\n    padding: 0 2em;\n  }\n}\n@media screen and (max-width: 480px) {\n  .AccountLayout_main__bMnAZ {\n    padding: 0 0.5em;\n  }\n}", "",{"version":3,"sources":["webpack://./src/layouts/account/AccountLayout.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,0BAAA;EAEA,uBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AAAD;;AAGA;EACC,sBAAA;AAAD;;AAGA;EACE,sBAAA;EACD,cAAA;EAEA,YAAA;EACA,WAAA;EACA,iBAAA;EAEA,cAAA;EACA,YAAA;AAFD;AAIC;EAXD;IAYE,cAAA;EADA;AACF;AAGC;EAfD;IAgBE,gBAAA;EAAA;AACF","sourcesContent":[".contentWrap {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: space-between;\n\n\tmargin: 3.25em 0 0 auto;\n\toverflow: hidden;\n\tposition: relative;\n\twidth: 100%;\n}\n\n.layout {\n\tbox-sizing: border-box;\n}\n\n.main {\n  box-sizing: border-box;\n\tfont-size: 1em;\n\n\theight: 100%;\n\twidth: 100%;\n\tmax-width: 1280px;\n\n\tpadding: 0 4em;\n\tmargin: auto;\n\n\t@media screen and (max-width: 1280px) {\n\t\tpadding: 0 2em;\n\t}\n\n\t@media screen and (max-width: 480px) {\n\t\tpadding: 0 0.5em;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrap": "AccountLayout_contentWrap__4amvX",
	"layout": "AccountLayout_layout__AeqR4",
	"main": "AccountLayout_main__bMnAZ"
};
export default ___CSS_LOADER_EXPORT___;
