import { LocalizationTypes } from 'src/common/types';

import { REPOSITORY_URI_RU_LOCALIZATION } from './ru.localization';
import { REPOSITORY_URI_EN_LOCALIZATION } from './en.localization';


export const REPOSITORY_URI_LOCALIZATION = {
	[LocalizationTypes.RU]: REPOSITORY_URI_RU_LOCALIZATION,
	[LocalizationTypes.EN]: REPOSITORY_URI_EN_LOCALIZATION,
};
