import { HttpError, TInputField } from 'src/toolkit';

import { TFormFieldValidation } from './validation';


type TFormMapFieldsNum = {
	[name: string]: TInputField;
};

export function formHandleServerError(error: HttpError, setNotice: (value: string) => void, ...fields: TInputField[]) {
	const { data, text } = error;

	if (!data) {
		if (text) {
			setNotice(text);
		}

		return;
	}

	const { validation = [], message } = data;

	if (validation.length > 0) {
		return formMapErrors(validation, ...fields);
	}

	const str = message || text;

	if (str) {
		setNotice(str);
	}
}

export function formMapErrors(errors: TFormFieldValidation[], ...fields: TInputField[]) {
	const fieldsNum: TFormMapFieldsNum = {};

	fields.forEach(item => fieldsNum[item.name] = item);

	errors.forEach(item => {
		const { message, field_name: fieldName } = item;

		const field = fieldsNum[fieldName];

		if (field) {
			field.setErrors([message]);
		}
	});
}

export function formMapError(errors: TFormFieldValidation[], field: TInputField) {
	errors.forEach(item => {
		const { message } = item;

		if (field) {
			field.setErrors([message]);
		}
	});
}
