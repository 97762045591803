import { useEffect } from 'react';

import { Preloader } from 'src/components';
import { routes, Router } from 'src/routes';
import { useCredentialsSelector, credentialsApi, useAppDispatch, languagesApi } from 'src/store';


function App() {
	const { isAppLoading } = useCredentialsSelector();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(languagesApi());
		dispatch(credentialsApi());
	}, []);

	if (isAppLoading) {
		return (
			<Preloader noLayout />
		);
	}

	return (
		<Router items={ routes } />
	);
}

export default App;
