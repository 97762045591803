// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageRow_pageRow__woiCL {\n  box-sizing: border-box;\n  display: flex;\n}\n\n@media screen and (max-width: 767px) {\n  .PageRow_pageRow__woiCL {\n    flex-direction: column;\n    margin: 0 auto;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/page/page-row/PageRow.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,aAAA;AACD;;AAEA;EACC;IACC,sBAAA;IACA,cAAA;EACA;AACF","sourcesContent":[".pageRow {\n\tbox-sizing: border-box;\n\tdisplay: flex;\n}\n\n@media screen and (max-width: 767px) {\n\t.pageRow {\n\t\tflex-direction: column;\n\t\tmargin: 0 auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageRow": "PageRow_pageRow__woiCL"
};
export default ___CSS_LOADER_EXPORT___;
