import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { FormFileListRow } from './components';

import styles from '../styles/FormFileListValue.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	files: File[];
	onDelete: (value: File) => void;
};

export function FormFileList(props: TProps) {
	const { onDelete, files = [] } = props;

	if (files.length <= 0) {
		return (
			<></>
		);
	}

	const classNames = [styles.list];

	return (
		<div className={ classNames.join(' ') }>
			{
				files.map(item => {
					const key = `${item.name}-${item.size}-${item.lastModified}`;

					return (
						<FormFileListRow key={ key } file={ item } onDelete={ onDelete }>{ item.name }</FormFileListRow>
					);
				})
			}
		</div>
	);
}
