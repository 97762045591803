import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TStack, TStackResponse, adaptStackFromResponse } from '../../types';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';


export const stackReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ADMIN_STACKS}/:alias`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ADMIN_STACKS}/${payload}`;

		return HttpService.get<TStackResponse>(path).then(result => {
			return adaptStackFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TStack | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const stackReadSlice = createSlice({
	name: 'stackReadSlice',
	initialState,
	reducers: {
		setStackRead(state, action: PayloadAction<TStack>) {
			state.data = action.payload;
		},

		clearStackRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[stackReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[stackReadGetApi.fulfilled.type]: (state, action: PayloadAction<TStack>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[stackReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useStackReadSelector = () => useAppSelector(state => state[stackReadSlice.name]);

export const { clearStackRead, setStackRead } = stackReadSlice.actions;
