import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TUserPasswordUpdate, TUserResponse } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_USERS } from '../../backend-paths';


export const userPasswordUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_USERS}/:nickname/password`,
	(payload: TUserPasswordUpdate, thunkApi) => {
		const { data, nickname } = payload;
		const path = `${BACKEND_ADMIN_USERS}/${nickname}/password`;

		return HttpService.put<TUserResponse>(path, data).then(result => {
			return result.data;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	isSuccess: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	isSuccess: false,
	error: undefined,
};

export const userPasswordUpdateSlice = createSlice({
	name: 'userPasswordUpdateSlice',
	initialState,
	reducers: {
		clearUserPasswordUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[userPasswordUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.error = undefined;
		},

		[userPasswordUpdateApi.fulfilled.type]: (state, action: PayloadAction<string>) => {
			state.isLoading = false;
			state.isSuccess = true;
		},

		[userPasswordUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.error = action.payload;
		},
	},
});

export const useUserPasswordUpdateSelector = () => useAppSelector(state => state[userPasswordUpdateSlice.name]);

export const { clearUserPasswordUpdate } = userPasswordUpdateSlice.actions;
