import styles from './FormIcon.module.scss';


type TProps = {
	children?: React.ReactNode;
	className?: string;
	style?: Record<string, string>;
	icon: string;
};

export function FormIcon(props: TProps) {
	const { icon = '', className = '', children = '', style = {} } = props;

	const classNameArr = [styles.formIcon];
	const iconClass = styles[icon];

	if (iconClass) {
		classNameArr.push(iconClass);
	}

	if (className) {
		classNameArr.push(className);
	}

	return (
		<i
			className={ classNameArr.join(' ') }
			style={ style }
		>
			{ children }
		</i>
	);
}
