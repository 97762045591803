import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountProject, TAccountProjectPayloadCreate, TAccountProjectResponse, adaptAccountProjectFromResponse, adaptAccountProjectToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_PROJECTS } from '../../backend-paths';


export const accountProjectCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_PROJECTS}`,
	(payload: TAccountProjectPayloadCreate, thunkApi) => {
		const {
			files = [],
			stack = [],
			data,
		} = payload;

		const dataRequest = adaptAccountProjectToRequest(data);
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return HttpService.post<TAccountProjectResponse>(BACKEND_ACCOUNT_PROJECTS, dataRequest, config).then(result => {
			const { alias } = result.data;
			const pathStack = `${BACKEND_ACCOUNT_PROJECTS}/${alias}/stack`;
			const stackDataRequest = { items: stack };

			return HttpService.put<TAccountProjectResponse>(pathStack, stackDataRequest).then(result => {
				const pathFiles = `${BACKEND_ACCOUNT_PROJECTS}/${alias}/files`;
				const filesDataRequest = { files };

				return HttpService.put<TAccountProjectResponse>(pathFiles, filesDataRequest, config).then(result => {
					return adaptAccountProjectFromResponse(result.data);
				}).catch((error: HttpError) => {
					return thunkApi.rejectWithValue({ ...error });
				});
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountProject | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountProjectCreateSlice = createSlice({
	name: 'accountProjectCreateSlice',
	initialState,
	reducers: {
		clearAccountProjectCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountProjectCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountProjectCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountProjectCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountProjectCreateSelector = () => useAppSelector(state => state[accountProjectCreateSlice.name]);

export const { clearAccountProjectCreate } = accountProjectCreateSlice.actions;
