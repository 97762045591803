export const BACKEND_PUBLIC_CVS = '/cv';

export const BACKEND_ACCOUNT = '/account';
export const BACKEND_ACCOUNT_PHONE_NUMBER = '/account/phone-number';
export const BACKEND_ACCOUNT_PHONE_NUMBER_CONFIRM = '/account/phone-number/confirm';

export const BACKEND_ACCOUNT_EMAIL = '/account/email';
export const BACKEND_ACCOUNT_EMAIL_CONFIRM = '/account/email/confirm';

export const BACKEND_SOURCE_LANGUAGES = '/source/languages';

export const BACKEND_ACCOUNT_SOURCE_EDUCATION_TYPES = '/account/source/education-types';

export const BACKEND_ACCOUNT_SOURCE_LANGUAGE_LEVELS = '/account/source/language-levels';

export const BACKEND_ACCOUNT_SOURCE_USERS_SEX = '/account/source/sex';

export const BACKEND_ACCOUNT_SOURCE_USERS_CVS_STATUSES = '/account/source/cvs-statuses';

export const BACKEND_ACCOUNT_SOURCE_CURRENCIES = '/account/source/currencies';

export const BACKEND_ACCOUNT_SOURCE_STACK = '/account/source/stack';

export const BACKEND_SIGN_IN = '/auth/sign-in';

export const BACKEND_SIGN_OUT = '/auth/sign-out';

export const BACKEND_ACCOUNT_CVS = '/account/cvs';

export const BACKEND_ACCOUNT_EDUCATIONS = '/account/educations';

export const BACKEND_ACCOUNT_PROJECTS = '/account/projects';

export const BACKEND_ACCOUNT_EXPERIENCE = '/account/experience';

export const BACKEND_ADMIN_STACKS = '/admin/stacks';

export const BACKEND_ADMIN_USERS = '/admin/users';
