import { Link, LinkProps } from 'react-router-dom';

import styles from './BreadCrumbsItem.module.scss';


export type TBreadCrumbsItem = LinkProps & {
	text?: string;
	to: string;
};

export function BreadCrumbsItem(props: TBreadCrumbsItem) {
	const { text = '', ...restProps } = props;

	return (
		<>
			<span className={ styles.division }></span>
			<Link { ...restProps } className={ styles.breadCrumbsItem }>{ text }</Link>
		</>
	);
}
