export const ASIDE_EN_LOCALIZATION = {
	account: 'Account',
	cv: 'CV',
	education: 'Education',
	projects: 'Projects',
	experience: 'Experience',
	stack: 'Stacks',
	users: 'Users',
	logout: 'Logout',
};
