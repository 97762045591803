import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountExperience, TAccountExperienceLocaleParam, adaptAccountExperienceFromResponse } from 'src/store/types';

import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';


export const accountExperienceLocaleDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_EXPERIENCE}/:pid/:locale`,
	(payload: TAccountExperienceLocaleParam, thunkApi) => {
		const { pid, locale } = payload;
		const path = `${BACKEND_ACCOUNT_EXPERIENCE}/${pid}/${locale}`;

		return HttpService.delete(path).then(result => {
			return adaptAccountExperienceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item?: TAccountExperience,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExperienceLocaleDeleteSlice = createSlice({
	name: 'accountExperienceLocaleDeleteSlice',
	initialState,
	reducers: {
		clearAccountExperienceLocaleDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExperienceLocaleDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExperienceLocaleDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperience>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountExperienceLocaleDeleteApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountExperienceLocaleDeleteSelector = () => useAppSelector(state => state[accountExperienceLocaleDeleteSlice.name]);

export const { clearAccountExperienceLocaleDelete } = accountExperienceLocaleDeleteSlice.actions;
