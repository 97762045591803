import { LocalizationTypes } from 'src/common/types';

import { ACCOUNT_EDUCATION_LIST_RU_LOCALIZATION } from './ru.localization';
import { ACCOUNT_EDUCATION_LIST_EN_LOCALIZATION } from './en.localization';
import { ACCOUNT_EDUCATION_LIST_DE_LOCALIZATION } from './de.localization';


export const ACCOUNT_EDUCATION_LIST_LOCALIZATION = {
	[LocalizationTypes.RU]: ACCOUNT_EDUCATION_LIST_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ACCOUNT_EDUCATION_LIST_EN_LOCALIZATION,
	[LocalizationTypes.DE]: ACCOUNT_EDUCATION_LIST_DE_LOCALIZATION,
};
