import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import styles from './PublicCvTextLink.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
	children: string;
	path?: string;
	target?: '_blank' | '_parent' | '_self' | '_top';
	className?: string;
	classNames?: string[];
};

export function PublicCvTextLink(props: TProps) {
	const {
		children,
		path = '',
		target = '_self',
		className = '',
		classNames: classNamesOuter = [],
	} = props;

	const classNames = [styles.text, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	if (path) {
		classNames.push(styles.link);

		return (
			<Link to={ path } target={ target } className={ classNames.join(' ') }>{ children }</Link>
		);
	}

	return (
		<span className={ classNames.join(' ') }>{ children }</span>
	);
}
