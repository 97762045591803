export const USERS_TABLE_RU_LOCALIZATION = {
	image: 'Image',
	nickname: 'Никнейм',
	phoneNumber: 'Номер телефона',
	email: 'E-Mail',
	createdAt: 'Дата создания',
	updatedAt: 'Дата обновления',

	searchPlaceholder: 'Поиск',
	searchTitle: 'Введите текст для поиска',

	emptyText: 'Пользователи не найдены',
};
