import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { TPublicExperience } from 'src/store';
import { CvHeadline } from 'src/components';
import { useLocalization } from 'src/toolkit';
import { CvExperienceCount } from 'src/pages/components';

import { PUBLIC_CV_LOCALIZATION } from '../../views/read/localization';

import { PublicCvExperienceItem } from './components';

import styles from './PublicCvExperience.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	className?: string;
	classNames?: string[];
	items: TPublicExperience[];
	countExperience: number[];
};

export function PublicCvExperience(props: TProps) {
	const { experienceHeadline } = useLocalization(PUBLIC_CV_LOCALIZATION);
	const {
		className = '',
		classNames: classNamesOuter = [],
		items,
		countExperience,
		...restProps
	} = props;

	if (items.length === 0) {
		return (
			<></>
		);
	}

	const classNames = [styles.text, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			<CvHeadline className={ styles.headline }>{ experienceHeadline }</CvHeadline>

			<CvExperienceCount className={ styles.totalExperience } value={ countExperience }/>

			<div className={ styles.items }>
				{ items.map(item => <PublicCvExperienceItem key={ item.pid } item={ item } />) }
			</div>
		</div>
	);
}
