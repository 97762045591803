import { ReactNode } from 'react';

import { FormError } from '../form-error';

import styles from './FormField.module.scss';


type TProps = {
	className?: string;
	classNames?: string[];
	legend?: ReactNode;
	required?: boolean;
	requiredMark?: boolean;
	confirmed?: boolean;
	unconfirmed?: boolean;
	errors?: string[];
	children?: ReactNode;
};

export function FormField(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		legend = '',
		required = false,
		requiredMark: requiredMarkPassed,
		confirmed = false,
		unconfirmed = false,
		errors = [],
		children,
	} = props;

	const legendClassName = [];

	const requiredMark = requiredMarkPassed === undefined ? required : requiredMarkPassed;

	if (requiredMark && styles.required) {
		legendClassName.push(styles.required);
	}

	if (confirmed && styles.confirmed) {
		legendClassName.push(styles.confirmed);
	}

	if (unconfirmed && styles.unconfirmed) {
		legendClassName.push(styles.unconfirmed);
	}

	const classNames = [...classNamesOuter, styles.formFieldset];

	if (className) {
		classNames.push(className);
	}

	return (
		<fieldset className={ classNames.join(' ') }>
			<legend className={ legendClassName.join(' ') }>
				{ legend }
			</legend>

			{ children }

			{ required && errors.length > 0 && <FormError items={ errors } /> }
		</fieldset>
	);
}
