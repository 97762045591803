import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountEducation, setAccountEducationLocaleCreatedItem, accountEducationCreateApi, useAppDispatch, useAccountEducationCreateSelector, TEducationType } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';

import { AccountEducationDateEndField, AccountEducationTypeField } from '../../../../components';
import { ACCOUT_EDUCATION_CREATE_LOCALIZATION } from '../../localization';


type TProps = TAccountEducation & {
	items: TEducationType[];
};

export function AccountEducationFormCreate(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountEducationCreateSelector();
	const [notice, setNotice] = useState('');
	const { button } = useLocalization(ACCOUT_EDUCATION_CREATE_LOCALIZATION);
	const { items, ...restProps} = props;

	const {
		pid = '',
		locale = '',
		type = '',
		dateEnd = '',
	} = restProps;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const typeField = useInputFormField({
		name: 'type',
		defaultValue: type,
		validation: value => formValidateText(value),
	});

	const dateEndField = useInputFormField({
		name: 'date_end',
		defaultValue: dateEnd.slice(0, 10),
		validation: formValidateText,
	});

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				typeField,
				dateEndField,
			);
		}

		if (item) {
			dispatch(setAccountEducationLocaleCreatedItem(item));
			navigate(`${PATH_ACCOUNT_EDUCATION}/${item.pid}`);
		}
	}, [error, item]);

	const onSubmit = () => {
		const errors = [
			typeField.validate(),
			dateEndField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			pid: pid ? pid : undefined,
			type: typeField.value,
			dateEnd: dateEndField.value,
		};

		dispatch(accountEducationCreateApi(dataRequest));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<AccountEducationTypeField { ...typeField } isLoading={ isLoading } locale={ localeField.value } items={ items } />

			<AccountEducationDateEndField { ...dateEndField } isLoading={ isLoading } />

			<FormNotice errorText={ notice } />

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
