import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PATH_USERS, ROLES } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TUser, userUpdateApi, setUserRead, useAppDispatch, useUserUpdateSelector, useCredentialsSelector } from 'src/store';
import { formHandleServerError, formValidateNickname, useInputFormField, useLocalization } from 'src/toolkit';

import { UsersNicknameField } from '../../../../components';

import { USERS_FORM_UPDATE_LOCALIZATION } from './localization';


export function UserFormUpdate(props: TUser) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { roles } = useCredentialsSelector();
	const { nickname: nicknameParam } = useParams();
	const { item, isLoading, error } = useUserUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(USERS_FORM_UPDATE_LOCALIZATION);

	const { nickname = '' } = props;

	const nicknameField = useInputFormField({
		name: 'nickname',
		defaultValue: nickname,
		validation: formValidateNickname,
	});

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice,
				nicknameField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setUserRead(item));

			const { nickname: changedNickname } = item;

			if (nicknameParam !== changedNickname) {
				navigate(`${PATH_USERS}/${changedNickname}`);
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			nicknameField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			nickname: nicknameField.value,
		};

		dispatch(userUpdateApi({ data: dataRequest, nickname }))
	};

	const isUpdate = roles.includes(ROLES.UPDATE_USERS);

	return (
		<FormBox onSubmit={ onSubmit }>
			<UsersNicknameField { ...nicknameField } isLoading={ isLoading } readOnly={ !isUpdate } />

			{ isUpdate && <FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice> }

			{ isUpdate &&
				<FormButtonWrap>
					<FormButton busy={ isLoading }>{ button }</FormButton>
				</FormButtonWrap>
			}
		</FormBox>
	);
}
