import { LocalizationTypes } from 'src/common/types';

import { ACCOUNT_PROJECT_IMAGE_FIELD_RU_LOCALIZATION } from './ru.localization';
import { ACCOUNT_PROJECT_IMAGE_FIELD_EN_LOCALIZATION } from './en.localization';


export const ACCOUNT_PROJECT_IMAGE_FIELD_LOCALIZATION = {
	[LocalizationTypes.RU]: ACCOUNT_PROJECT_IMAGE_FIELD_RU_LOCALIZATION,
	[LocalizationTypes.EN]: ACCOUNT_PROJECT_IMAGE_FIELD_EN_LOCALIZATION,
};
