export const ACCOUNT_EXPERIENCE_TABLE_EN_LOCALIZATION = {
	image: 'Image',
	title: 'Company name',
	versions: 'Language versions',
	createdAt: 'Created at',
	updatedAt: 'Updated at',

	searchPlaceholder: 'Search in database',
	searchTitle: 'Type text for searching in database',

	emptyText: 'Experience items not found',
};
