import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EDUCATION } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { TAccountEducation, clearAccountEducationLocaleCreate, useAppDispatch, useCredentialsSelector } from 'src/store';
import { libFormatDate, useLocalization } from 'src/toolkit';

import { ACCOUNT_EDUCATION_COMMON_LOCALIZATION } from '../../localization';

import { AccountEducationLocaleFormCreate } from './components';
import { ACCOUT_EDUCATION_LOCALE_CREATE_LOCALIZATION } from './localization';


export function AccountEducationLocaleCreateWidget(props: TAccountEducation) {
	const dispatch = useAppDispatch();
	const { lang } = useCredentialsSelector();
	const { pathname, hash } = useLocation();
	const { breadCrumbList } = useLocalization(ACCOUNT_EDUCATION_COMMON_LOCALIZATION);

	const { pid, type, dateEnd } = props;

	const { title, headline, breadCrumbNew } = useLocalization(ACCOUT_EDUCATION_LOCALE_CREATE_LOCALIZATION);

	const initialItem = {
		locale: lang,
		city: '',
		shortName: '',
		educationalInstitution: '',
		faculty: '',
		specialization: '',
		createdAt: '',
		updatedAt: '',

		pid: '',
		type: '',
		dateEnd: '',
		locales: [],
	};

	useEffect(() => {
		return () => {
			dispatch(clearAccountEducationLocaleCreate());
		};
	}, []);

	const year = libFormatDate(dateEnd, 'yyyy');
	const typeItem = `${type} ${year}`;
	const titleItem = `${title} ${typeItem}`;

	setTitle(titleItem);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EDUCATION, text: breadCrumbList },
		{ to: `${pathname}/`, text: typeItem },
		{ to: `${pathname}${hash}`, text: breadCrumbNew },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountEducationLocaleFormCreate { ...initialItem } pid={ pid } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
