// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecoveryLayer_recoveryPage__p\\+\\+qP {\n  flex-direction: column;\n  margin: 4em auto;\n}\n\n.RecoveryLayer_logo__f4Mhp {\n  text-align: center;\n}\n\n.RecoveryLayer_image__z34EN {\n  border: 1px solid rgb(90, 90, 90);\n  border-radius: 50%;\n  margin: auto;\n  height: 10em;\n  width: 10em;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.RecoveryLayer_headline__xcIYC {\n  color: rgb(90, 90, 90);\n  font-size: 1.25em;\n  margin: 0.5em auto;\n  text-align: center;\n}\n\n.RecoveryLayer_container__diqgN {\n  margin: 2em auto 0;\n  max-width: 360px;\n}\n\n@media screen and (max-width: 480px) {\n  .RecoveryLayer_logo__f4Mhp {\n    margin: 3em auto 2em;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/recovery/components/layer/RecoveryLayer.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,gBAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,iCAAA;EACA,kBAAA;EAEA,YAAA;EAEA,YAAA;EACA,WAAA;EAEA,yBAAA;UAAA,iBAAA;AAFD;;AAKA;EACC,sBAAA;EACA,iBAAA;EAEA,kBAAA;EACA,kBAAA;AAHD;;AAMA;EACC,kBAAA;EACA,gBAAA;AAHD;;AAMA;EACC;IACC,oBAAA;EAHA;AACF","sourcesContent":[".recoveryPage {\n\tflex-direction: column;\n\tmargin: 4em auto;\n}\n\n.logo {\n\ttext-align: center;\n}\n\n.image {\n\tborder: 1px solid rgba(90, 90, 90, 1);\n\tborder-radius: 50%;\n\n\tmargin: auto;\n\n\theight: 10em;\n\twidth: 10em;\n\n\tuser-select: none;\n}\n\n.headline {\n\tcolor: rgba(90, 90, 90, 1);\n\tfont-size: 1.25em;\n\n\tmargin: 0.5em auto;\n\ttext-align: center;\n}\n\n.container {\n\tmargin: 2em auto 0;\n\tmax-width: 360px;\n}\n\n@media screen and (max-width: 480px) {\n\t.logo {\n\t\tmargin: 3em auto 2em;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recoveryPage": "RecoveryLayer_recoveryPage__p++qP",
	"logo": "RecoveryLayer_logo__f4Mhp",
	"image": "RecoveryLayer_image__z34EN",
	"headline": "RecoveryLayer_headline__xcIYC",
	"container": "RecoveryLayer_container__diqgN"
};
export default ___CSS_LOADER_EXPORT___;
