import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountEducation, TAccountEducationParam, adaptAccountEducationFromResponse } from 'src/store/types';

import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';


export const accountEducationLocaleDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_EDUCATIONS}/:pid/:locale`,
	(payload: TAccountEducationParam, thunkApi) => {
		const { pid, locale } = payload;
		const path = `${BACKEND_ACCOUNT_EDUCATIONS}/${pid}/${locale}`;

		return HttpService.delete(path).then(result => {
			return adaptAccountEducationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item?: TAccountEducation,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEducationLocaleDeleteSlice = createSlice({
	name: 'accountEducationLocaleDeleteSlice',
	initialState,
	reducers: {
		clearAccountEducationLocaleDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEducationLocaleDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEducationLocaleDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEducationLocaleDeleteApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountEducationLocaleDeleteSelector = () => useAppSelector(state => state[accountEducationLocaleDeleteSlice.name]);

export const { clearAccountEducationLocaleDelete } = accountEducationLocaleDeleteSlice.actions;
