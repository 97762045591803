import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountEducationSortCol, TAccountEducation, TAccountEducationList, TAccountEducationListResponse, TAccountEducationQuery, TAccountEducationQueryRequest, TAccountEducationSortCol, adaptAccountEducationFromListResponse } from '../../types';
import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';
import { LocalizationTypes } from 'src/common/types';


export const accountEducationsGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_EDUCATIONS,
	(payload: TAccountEducationQuery | undefined, thunkApi) => {
		const params = payload ? toAccountEducationsQueryRequest(payload) : {};

		return HttpService.get<TAccountEducationListResponse>(BACKEND_ACCOUNT_EDUCATIONS, { params }).then(result => {
			return adaptAccountEducationFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toAccountEducationsQueryRequest(data: TAccountEducationQuery): TAccountEducationQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	item?: TAccountEducation,
	isLoading: boolean,
	list: TAccountEducationList,
	query: TAccountEducationQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		locale: LocalizationTypes.EN,
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: AccountEducationSortCol.DATE_END,
	},
	error: undefined,
};

export const accountEducationSlice = createSlice({
	name: 'accountEducationSlice',
	initialState,
	reducers: {
		setAccountEducationCreatedItem(state, action: PayloadAction<TAccountEducation>) {
			state.item = action.payload;
		},

		setAccountEducationLocaleCreatedItem(state, action: PayloadAction<TAccountEducation>) {
			state.item = action.payload;
		},

		setAccountEducationQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountEducationQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountEducationQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountEducationQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountEducationQuerySortCol(state, action: PayloadAction<TAccountEducationSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountEducations(state) {
			return { ...initialState, list: state.list, query: state.query };
		},

		clearAccountEducationCreatedItem(state) {
			state.item = undefined;
		},

		clearAccountEducationLocaleCreatedItem(state) {
			state.item = undefined;
		},
	},

	extraReducers: {
		[accountEducationsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEducationsGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducationList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountEducationsGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountEducationsSelector = () => useAppSelector(state => state[accountEducationSlice.name]);

export const { clearAccountEducations, setAccountEducationQueryLimit, setAccountEducationQueryOffset, setAccountEducationQuerySort, setAccountEducationQuerySortCol, setAccountEducationQuerySearch, setAccountEducationLocaleCreatedItem, clearAccountEducationLocaleCreatedItem, setAccountEducationCreatedItem, clearAccountEducationCreatedItem } = accountEducationSlice.actions;
