import { getBirthdayHelper } from 'src/common/helpers';
import { LOCALIZATION } from 'src/localization';
import { useLocalization } from 'src/toolkit';

import { PUBLIC_CV_BIRTHDAY_LOCALIZATION } from './localization';

import styles from './PublicCvBirthday.module.scss';


type TProps = {
	className?: string;
	classNames?: string[];
	sex: string;
	date: string;
};

export function PublicCvBirthday(props: TProps) {
	const { year, ...restFields } = useLocalization(PUBLIC_CV_BIRTHDAY_LOCALIZATION);
	const {
		className = '',
		classNames: classNamesOuter = [],

		date,
		sex,

		...restProps
	} = props;

	const born = restFields[sex];

	const {
		januaryNominative,
		februaryNominative,
		marchNominative,
		aprilNominative,
		mayNominative,
		juneNominative,
		julyNominative,
		augustNominative,
		septemberNominative,
		octoberNominative,
		novemberNominative,
		decemberNominative,
	} = useLocalization(LOCALIZATION);

	const months = [
		januaryNominative,
		februaryNominative,
		marchNominative,
		aprilNominative,
		mayNominative,
		juneNominative,
		julyNominative,
		augustNominative,
		septemberNominative,
		octoberNominative,
		novemberNominative,
		decemberNominative,
	];

	const text = getBirthdayHelper(date, born, year, months);

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div { ...restProps } className={ classNames.join(' ') }>{ text }</div>
	);
}
