import { FormField, FormInputMask } from 'src/components';
import { TInputFormField, useLocalization } from 'src/toolkit';
import { USERS_PHONE_NUMBER_FIELD_LOCALIZATION } from './localization';


type TProps = TInputFormField & {
	isLoading?: boolean;
	readOnly?: boolean;
	confirmed?: boolean;
	unconfirmed?: boolean;
	requiredMark?: boolean;
};

export function UserPhoneNumberField(props: TProps) {
	const { legend, title } = useLocalization(USERS_PHONE_NUMBER_FIELD_LOCALIZATION);
	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
		readOnly,
		required = false,
		confirmed = false,
		unconfirmed = false,
		requiredMark = false,
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required={ required }
			confirmed={ confirmed }
			unconfirmed={ unconfirmed }
			requiredMark={ requiredMark }
		>
			<FormInputMask
				placeholder="+7 ( ___ ) ___ - __ - __"
				mask="+7 ( 999 ) 999 - 99 - 99"
				title={ title }
				name={ name }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
				readOnly={ readOnly }
			/>
		</FormField>
	);
}
