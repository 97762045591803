import { Link } from 'src/components';

import { TableBodyCol, TableBodyRow } from 'src/components';
import { libFormatDate, libNumberMask } from 'src/toolkit';

import styles from './UsersListRow.module.scss';
import { TUser } from 'src/store';


type TProps = TUser & {
	to: string;
};

export function UsersListRow(props: TProps) {
	const {
		to,
		nickname,
		// image,
		phoneNumber,
		email = '—',
		createdAt,
		updatedAt,
	} = props;

	return (
		<TableBodyRow>
			{/* <TableBodyCol className={ styles.imageCol } center>
				{ image && <img className={ styles.image } src={ image } alt={ nickname } /> }
			</TableBodyCol> */}

			<TableBodyCol><Link to={ to }>{ nickname } </Link></TableBodyCol>

			<TableBodyCol className={ styles.phoneNumberCol } right>{ libNumberMask(phoneNumber) }</TableBodyCol>

			<TableBodyCol className={ styles.emailCol } right>{ email }</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(createdAt) }</TableBodyCol>

			<TableBodyCol className={ styles.dateCol } right>{ libFormatDate(updatedAt) }</TableBodyCol>
		</TableBodyRow>
	);
}
