import { useEffect, useState } from 'react';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TUserPhoneNumber, setUserPhoneNumberRead, useAppDispatch, useUserPhoneNumberUpdateSelector, userPhoneNumberUpdateApi } from 'src/store';
import { formHandleServerError, formValidatePhoneNumber, isInvalidNickname, isInvalidPhoneNumber, libClearIntstr, useInputFormField, useLocalization } from 'src/toolkit';

import { UserPhoneNumberField } from '../../../../components';

import { USERS_PHONE_NUMBER_UPDATE_LOCALIZATION } from './localization';


type TProps = TUserPhoneNumber & {
	nickname: string;
};

export function UserPhoneNumberForm(props: TProps) {
	const dispatch = useAppDispatch();
	const { item, isLoading, error } = useUserPhoneNumberUpdateSelector();
	const [notice, setNotice] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const { button, noticeSuccess } = useLocalization(USERS_PHONE_NUMBER_UPDATE_LOCALIZATION);

	const { phoneNumber, nickname } = props;

	const phoneNumberField = useInputFormField({
		name: 'phone_number_expected',
		defaultValue: phoneNumber,
		validation: value => formValidatePhoneNumber(value),
	});

	useEffect(() => {
		const isInvalid = isInvalidPhoneNumber(phoneNumberField.value);

		setIsDisabled(isInvalid);
	}, [phoneNumberField.value]);

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				phoneNumberField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setUserPhoneNumberRead(item));
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			phoneNumberField.validate(),
			isInvalidNickname(nickname),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			phoneNumberExpected: libClearIntstr(phoneNumberField.value),
		};

		dispatch(userPhoneNumberUpdateApi({ data: dataRequest, nickname }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<UserPhoneNumberField { ...phoneNumberField } isLoading={ isLoading } confirmed />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading } disabled={ isDisabled }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
