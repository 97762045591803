// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AsideItemList_list__TElXo {\n  list-style: none;\n  padding: 1em 0.5em;\n  margin: 0;\n  -webkit-user-select: none;\n          user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/components/nav/aside-item-list/AsideItemList.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,yBAAA;UAAA,iBAAA;AACD","sourcesContent":[".list {\n\tlist-style: none;\n\tpadding: 1em 0.5em;\n\tmargin: 0;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "AsideItemList_list__TElXo"
};
export default ___CSS_LOADER_EXPORT___;
