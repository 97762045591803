// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountPhoneNumberCodeTimer_box__a4E6Y {\n  color: rgb(150, 150, 150);\n  font-size: 0.875em;\n  font-style: italic;\n  margin: 0.5em;\n  text-align: right;\n}\n\n.AccountPhoneNumberCodeTimer_text__FTox6 {\n  margin-right: 0.25em;\n}\n\n.AccountPhoneNumberCodeTimer_timer__DZlkl {\n  color: rgb(255, 50, 50);\n}", "",{"version":3,"sources":["webpack://./src/pages/account/views/phone-number/components/router/components/confirm-stage/components/timer/AccountPhoneNumberCodeTimer.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;AACD;;AAEA;EACC,oBAAA;AACD;;AAEA;EACC,uBAAA;AACD","sourcesContent":[".box {\n\tcolor: rgba(150, 150, 150, 1);\n\tfont-size: 0.875em;\n\tfont-style: italic;\n\tmargin: 0.5em;\n\ttext-align: right;\n}\n\n.text {\n\tmargin-right: 0.25em;\n}\n\n.timer {\n\tcolor: rgba(255, 50, 50, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountPhoneNumberCodeTimer_box__a4E6Y",
	"text": "AccountPhoneNumberCodeTimer_text__FTox6",
	"timer": "AccountPhoneNumberCodeTimer_timer__DZlkl"
};
export default ___CSS_LOADER_EXPORT___;
