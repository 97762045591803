// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormBox_formBox__laS2D {\n  box-sizing: border-box;\n  padding: 0.5em;\n  max-width: 480px;\n}\n\n@media screen and (max-width: 767px) {\n  .FormBox_formBox__laS2D {\n    flex-direction: column;\n    justify-content: flex-start;\n    padding: 2em;\n  }\n}\n@media screen and (max-width: 480px) {\n  .FormBox_formBox__laS2D {\n    padding: 1em 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-box/FormBox.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,cAAA;EACA,gBAAA;AACD;;AAEA;EACC;IACC,sBAAA;IACA,2BAAA;IACA,YAAA;EACA;AACF;AAEA;EACC;IACC,cAAA;EAAA;AACF","sourcesContent":[".formBox {\n\tbox-sizing: border-box;\n\tpadding: 0.5em;\n\tmax-width: 480px;\n}\n\n@media screen and (max-width: 767px) {\n\t.formBox {\n\t\tflex-direction: column;\n\t\tjustify-content: flex-start;\n\t\tpadding: 2em;\n\t}\n}\n\n@media screen and (max-width: 480px) {\n\t.formBox {\n\t\tpadding: 1em 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formBox": "FormBox_formBox__laS2D"
};
export default ___CSS_LOADER_EXPORT___;
