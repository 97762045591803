// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvPreferredSalary_box__aCw8Q {\n  margin: 0 0 0.5em 1em;\n}\n\n.PublicCvPreferredSalary_text__HvM1A {\n  color: rgb(150, 150, 150);\n  font-size: 0.875em;\n}\n\n.PublicCvPreferredSalary_currency__tzWt2,\n.PublicCvPreferredSalary_value__vhfuU {\n  color: rgb(150, 150, 150);\n  font-size: 1em;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/preferred-salary/PublicCvPreferredSalary.module.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;AACD;;AAEA;EACC,yBAAA;EACA,kBAAA;AACD;;AAEA;;EAEC,yBAAA;EAEA,cAAA;EACA,iBAAA;AAAD","sourcesContent":[".box {\n\tmargin: 0 0 0.5em 1em;\n}\n\n.text {\n\tcolor: rgba(150, 150, 150, 1);\n\tfont-size: 0.875em;\n}\n\n.currency,\n.value {\n\tcolor: rgba(150, 150, 150, 1);\n\n\tfont-size: 1em;\n\tfont-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvPreferredSalary_box__aCw8Q",
	"text": "PublicCvPreferredSalary_text__HvM1A",
	"currency": "PublicCvPreferredSalary_currency__tzWt2",
	"value": "PublicCvPreferredSalary_value__vhfuU"
};
export default ___CSS_LOADER_EXPORT___;
