export function libClearIntstr(val: number | string | boolean = ''): string {
	const value = val.toString() || '';

	return value.replace(/\D/ig, '');
}

export function libFormatPrice(value: string | number = '', scale: number = 0): string {
	let val = '';

	if (scale > 0) {
		const isNumber = typeof value === 'number';
		const num = isNumber ? value : parseFloat(value);

		val = num.toFixed(scale);
	} else {
		val = value.toString();
	}

	val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	val = val.replace(/\./i, ',');
	return val;
}
