import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import { Contact } from 'src/pages/components';
import { useLocalization } from 'src/toolkit';

import { PUBLIC_CV_PHONE_NUMBER_LOCALIZATION } from './localization';

import styles from './PublicCvPhoneNumber.module.scss';
import { Icon } from 'src/components';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
	value?: ReactNode;
};

export function PublicCvPhoneNumber(props: TProps) {
	const { text } = useLocalization(PUBLIC_CV_PHONE_NUMBER_LOCALIZATION);

	const {
		value,
		className = '',
		classNames: classNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.headline, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<Contact { ...restProps } value={ value } text={ text }>
			<Icon className={ styles.icon } icon="icon-phone" />
		</Contact>
	);
}
