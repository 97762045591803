import { useEffect } from 'react';
import { BreadCrumbs, PageCol, PageHeadline, Preloader } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { PATH_ACCOUNT, PATH_ACCOUNT_EMAIL } from 'src/routes';
import { useLocalization } from 'src/toolkit';
import { setTitle } from 'src/common/helpers';
import { accountEmailReadGetApi, clearAccountEmailRead, clearAccountEmailUpdate, clearAccountEmailUpdateConfirm, useAccountEmailReadSelector, useAppDispatch } from 'src/store';
import { ErrorWidget } from 'src/pages/components';

import { ACCOUNT_COMMON_LOCALIZATION } from '../../localization';

import { AccountEmailConfirmed, AccountEmailRouter } from './components';
import { ACCOUNT_EMAIL_COMMON_LOCALIZATION } from './localization';


export function AccountEmailPage() {
	const dispatch = useAppDispatch();
	const { item, isLoading, error } = useAccountEmailReadSelector();
	const { breadCrumbAccount } = useLocalization(ACCOUNT_COMMON_LOCALIZATION);
	const {
		title,
		headline,
		breadCrumbItem,
	} = useLocalization(ACCOUNT_EMAIL_COMMON_LOCALIZATION);

	useEffect(() => {
		dispatch(accountEmailReadGetApi());

		return () => {
			dispatch(clearAccountEmailRead());
			dispatch(clearAccountEmailUpdate());
			dispatch(clearAccountEmailUpdateConfirm());
		};
	}, []);

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader />
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	setTitle(title);

	const items = [
		{ to: PATH_ACCOUNT, text: breadCrumbAccount },
		{ to: PATH_ACCOUNT_EMAIL, text: breadCrumbItem },
	];

	return (
		<AccountLayout>
			<BreadCrumbs items={ items } />

			<PageHeadline>{ headline }</PageHeadline>

			<PageCol>
				<AccountEmailConfirmed item={ item } />

				<AccountEmailRouter item={ item } />
			</PageCol>
		</AccountLayout>
	);
}
