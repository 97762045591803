import { MouseEventHandler } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useCredentialsSelector } from 'src/store';

import { NavIcon } from '../nav-icon';
import navStyles from '../nav/Nav.module.scss';

import styles from './AsideItem.module.scss';


export type TAsideItem = {
	links?: string[];
	role?: string;
	re?: RegExp;
	href?: string;
	text?: string;
	icon?: string;
	onClick?: MouseEventHandler<HTMLElement>;
};

export function AsideItem(props: TAsideItem) {
	const { roles } = useCredentialsSelector();
	const { pathname } = useLocation();

	const {
		icon = 'icon-home',
		href = '#',
		text = 'Default',
		links = [],
		re = '',
		role,
		onClick = () => {},
	} = props;

	if (role) {
		if (!roles.includes(role)) {
			return (
				<></>
			);
		}
	}

	const classNamesLi = [navStyles.navItemStyle];
	const classNamesLink = [styles.asideLinkStyle];

	const isActive = href === pathname || links.includes(pathname) || (re && re.test(pathname));

	if (isActive) {
		classNamesLi.push(navStyles.active);
		classNamesLink.push(styles.active);
	}

	return (
		<li className={ classNamesLi.join(' ') }>
			<NavLink
				className={ classNamesLink.join(' ') }
				onClick={ onClick }
				to={ href }
			>
				<NavIcon icon={ icon } className={ styles.iconLink }></NavIcon>
				{ text }
			</NavLink>
		</li>
	);
}
