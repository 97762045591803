export const ACCOUNT_PASSWORD_FORM_EN_LOCALIZATION = {
	currentPasswordLegend: 'Current password',
	currentPasswordTitle: 'Enter your current password',

	newPasswordLegend: 'New password',
	newPasswordTitle: 'Enter your new password',

	newPasswordConfirmLegend: 'Confirm new password',
	newPasswordConfirmTitle: 'Repeat new password',

	successNotice: 'New password was changed successfully',

	buttonText: 'Change password',
};
