import { TAccountProject } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { AccountExperienceProjectItem } from './components';
import { ACCOUNT_EXPERIENCE_PROJECTS_BOX_LOCALIZATION } from './localization';

import styles from './AccountExperienceProjectsBox.module.scss';


type TProps = {
	isLoading?: boolean;
	items: TAccountProject[];
	setItems: (value: TAccountProject[]) => void;
};

export function AccountExperienceProjectsBox(props: TProps) {
	const { emptyText } = useLocalization(ACCOUNT_EXPERIENCE_PROJECTS_BOX_LOCALIZATION);
	const { items, setItems } = props;

	if (items.length === 0) {
		const classNames = [styles.box, styles.emptyBox];

		return (
			<div className={ classNames.join(' ') }>
				{ emptyText }
			</div>
		);
	}

	const onClick = (value: TAccountProject) => {
		const { alias } = value;
		const itemsFiltered = items.filter(item => item.alias !== alias);

		setItems(itemsFiltered);
	};

	return (
		<div className={ styles.box }>
			{
				items.map(item => {
					return (
						<AccountExperienceProjectItem
							key={ item.alias }
							item={ item }
							onClick={ onClick }
						/>
					);
				})
			}
		</div>
	);
}
