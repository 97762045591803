// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserDetails_linkBox__AdaqB {\n  margin: 2em 0 1.5em;\n}\n\n.UserDetails_icon__xmacF {\n  font-size: 1.4em;\n  width: 1.25em;\n  min-width: 1.25em;\n  max-width: 1.25em;\n  vertical-align: middle;\n}", "",{"version":3,"sources":["webpack://./src/pages/users/views/update/components/details/UserDetails.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;AACD;;AAEA;EACC,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,sBAAA;AACD","sourcesContent":[".linkBox {\n\tmargin: 2em 0 1.5em;\n}\n\n.icon {\n\tfont-size: 1.4em;\n\twidth: 1.25em;\n\tmin-width: 1.25em;\n\tmax-width: 1.25em;\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkBox": "UserDetails_linkBox__AdaqB",
	"icon": "UserDetails_icon__xmacF"
};
export default ___CSS_LOADER_EXPORT___;
