import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { useLocalization } from 'src/toolkit';
import { getAgeHelper } from 'src/common/helpers';

import { PUBLIC_CV_AGE_LOCALIZATION } from './localization';

import styles from './PublicCvAge.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
	sign?: string;
	sex?: string;
	date: string;
};

export function PublicCvAge(props: TProps) {
	const { year1, year2, year5 } = useLocalization(PUBLIC_CV_AGE_LOCALIZATION);

	const {
		className = '',
		classNames: classNamesOuter = [],
		date,
		sex,
		sign
	} = props;

	const age = getAgeHelper(date, [year1, year2, year5]);
	const classNames = [styles.box, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	if (sex) {
		if (sign) {
			classNames.push(styles[sign]);
		}

		return (
			<div className={ classNames.join(' ') }>
				{ sex }, { age }
			</div>
		);
	}

	return (
		<div className={ styles.box }>
			{ age }
		</div>
	);
}
