import { useEffect, useState } from 'react';

import { Form, FormButton, FormButtonWrap, FormNotice } from 'src/components';
import { formHandleServerError, isInvalidNickname, useLocalization } from 'src/toolkit';
import { TUserRole, adaptUserRoleToRequest, clearUserRolesUpdate, setCredentialsRoles, useAppDispatch, useCredentialsSelector, useUserRolesUpdateSelector, userRolesUpdateApi } from 'src/store';

import { USERS_ROLES_FORM_LOCALIZATION } from './localization';

import styles from './UserRolesForm.module.scss';
import { UserRolesFormItem, UserRolesFormItemDetails } from './components';


type TProps = {
	nickname: string;
	items: TUserRole[];
};

export function UserRolesForm(props: TProps) {
	const dispatch = useAppDispatch();
	const { lang, credentials } = useCredentialsSelector();
	const { items: updatedItems, isLoading, error } = useUserRolesUpdateSelector();
	const [notice, setNotice] = useState('');
	const { nickname, items: itemsParam } = props;

	const [roleDetailed, setRoleDetaied] = useState<TUserRole>();
	const [items, setItems] = useState(itemsParam);

	const {
		successNotice,
		buttonText,
	} = useLocalization(USERS_ROLES_FORM_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearUserRolesUpdate());
		}
	}, []);

	useEffect(() => {
		if (error) {
			setNotice('');
			formHandleServerError(error, setNotice);
		}
	}, [error]);

	useEffect(() => {
		if (updatedItems) {
			setNotice(successNotice);

			if (credentials && credentials.name === nickname) {
				const filteredRoles = updatedItems.filter(item => item.value);
				const updatedRoles = filteredRoles.map(item => item.alias);

				dispatch(setCredentialsRoles(updatedRoles));
			}
		}
	}, [updatedItems]);

	const onBlur = () => {
		setRoleDetaied(undefined);
	};

	const onFocus = (value: TUserRole) => {
		setRoleDetaied(value);
	};

	const onchange = (value: TUserRole) => {
		const changedItems = items.map(item => {
			if (item.alias === value.alias) {
				return { ...value };
			}

			return { ...item };
		});

		setItems(changedItems);
	};

	const submit = () => {
		const errors = items.map(item => {
			return isInvalidNickname(item.alias) || typeof item.value !== 'boolean';
		});

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const itemsRequest = items.map(item => adaptUserRoleToRequest(item));

			dispatch(userRolesUpdateApi({ items: itemsRequest, nickname, locale: lang }));
	};

	return (
		<Form className={ styles.form } onSubmit={ submit }>
			<div className={ styles.items }>
				{
					items.map(item =>
						<UserRolesFormItem
							key={ item.alias }
							{ ...item }
							onChange={ onchange }
							onFocus={ onFocus }
							onBlur={ onBlur }
							isLoading={ isLoading }
						/>
					)
				}
			</div>

			<FormNotice className={ styles.notice } success={ !!updatedItems } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap className={ styles.buttonWrap }>
				<FormButton busy={ isLoading }>{ buttonText }</FormButton>
			</FormButtonWrap>

			{ roleDetailed && <UserRolesFormItemDetails item={ roleDetailed }/> }
		</Form>
	);
}
