// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserRolesForm_form__O1NoJ {\n  box-sizing: border-box;\n  margin: auto;\n  padding: 1em;\n}\n\n.UserRolesForm_items__yGuSL {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0 1em 2em;\n}\n.UserRolesForm_items__yGuSL > div {\n  flex: 1 1 22em;\n}\n\n.UserRolesForm_buttonWrap__Q939t {\n  box-sizing: border-box;\n  margin: 0;\n  max-width: 360px;\n}\n\n.UserRolesForm_notice__S0Esa {\n  max-width: 360px;\n  margin: 0 0 1em 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/users/views/roles/components/form/UserRolesForm.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,YAAA;EACA,YAAA;AACD;;AAEA;EACC,aAAA;EACA,eAAA;EACA,iBAAA;AACD;AACC;EACC,cAAA;AACF;;AAGA;EACC,sBAAA;EACA,SAAA;EACA,gBAAA;AAAD;;AAGA;EACC,gBAAA;EACA,iBAAA;AAAD","sourcesContent":[".form {\n\tbox-sizing: border-box;\n\tmargin: auto;\n\tpadding: 1em;\n}\n\n.items {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tmargin: 0 1em 2em;\n\n\t& > div {\n\t\tflex: 1 1 22em;\n\t}\n}\n\n.buttonWrap {\n\tbox-sizing: border-box;\n\tmargin: 0;\n\tmax-width: 360px;\n}\n\n.notice {\n\tmax-width: 360px;\n\tmargin: 0 0 1em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "UserRolesForm_form__O1NoJ",
	"items": "UserRolesForm_items__yGuSL",
	"buttonWrap": "UserRolesForm_buttonWrap__Q939t",
	"notice": "UserRolesForm_notice__S0Esa"
};
export default ___CSS_LOADER_EXPORT___;
