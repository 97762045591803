import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, FormButtonLink, Icon, PageHeadline } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_USERS, PATH_USERS_CREATE, ROLES } from 'src/routes';
import { clearUsers, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { USERS_COMMON_LOCALIZATION } from '../../localization';
import { UserCreateWidget } from '../../widgets';

import { UsersList } from './components';
import { USERS_LIST_LOCALIZATION } from './localization';

import styles from './UsersPage.module.scss';


export function UsersPage() {
	const dispatch = useAppDispatch();
	const { roles } = useCredentialsSelector();
	const { pathname, hash } = useLocation();
	const { title, headline, addItemButton } = useLocalization(USERS_LIST_LOCALIZATION);
	const { breadCrumbList } = useLocalization(USERS_COMMON_LOCALIZATION);

	useEffect(() => {
		return () => {
			dispatch(clearUsers());
		};
	}, []);

	const isCreate = roles.includes(ROLES.CREATE_USERS);
	const uri = `${pathname}${hash}`;
	if (isCreate && uri === PATH_USERS_CREATE) {
		return (
			<UserCreateWidget />
		);
	}

	setTitle(title);

	const breadCrumbs = [
		{ to: PATH_USERS, text: breadCrumbList },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<div className={ styles.actionBox }>
					{ isCreate &&
						<FormButtonLink to={ PATH_USERS_CREATE }>
							<Icon icon="icon-plus" />
							{ addItemButton }
						</FormButtonLink>
					}
				</div>

				<UsersList />
			</div>
		</AccountLayout>
	);
}
