import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TAccountProject, TAccountProjectResponse, adaptAccountProjectFromResponse } from '../../types';
import { BACKEND_ACCOUNT_PROJECTS } from '../../backend-paths';


export const accountProjectReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_PROJECTS}/:alias`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_PROJECTS}/${payload}`;

		return HttpService.get<TAccountProjectResponse>(path).then(result => {
			return adaptAccountProjectFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TAccountProject | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountProjectReadSlice = createSlice({
	name: 'accountProjectReadSlice',
	initialState,
	reducers: {
		setAccountProjectRead(state, action: PayloadAction<TAccountProject>) {
			state.data = action.payload;
		},

		clearAccountProjectRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountProjectReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountProjectReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountProject>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountProjectReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountProjectReadSelector = () => useAppSelector(state => state[accountProjectReadSlice.name]);

export const { clearAccountProjectRead, setAccountProjectRead } = accountProjectReadSlice.actions;
