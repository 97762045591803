export function libFileToBase64(file: File): Promise<string> {
	return new Promise<string>((resolve: any) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (event: ProgressEvent<FileReader>) => {
			const result = event.target?.result || '';

			resolve(result);
		};
	});
}

export function libBase64ToFile(base64: string, fileName?: string): File {
	const dataUrl = base64 || '';
	const name = fileName || 'image.jpg';

	const mime = dataUrl.split(',')[0].split(':')[1].split(';')[0];

	const byteString = atob(dataUrl.split(',')[1]);
	const ab = new ArrayBuffer(byteString.length);

	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new File([ab], name, { type: mime });
}
