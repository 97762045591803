import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountCv, TAccountCvPayloadCreate, TAccountCvResponse, adaptAccountCvFromResponse, adaptAccountCvToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_CVS } from '../../backend-paths';


export const accountCvCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_CVS}`,
	(payload: TAccountCvPayloadCreate, thunkApi) => {
		const {
			experience = [],
			education = [],
			languages = [],
			data,
		} = payload;

		const dataRequest = adaptAccountCvToRequest(data);
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return HttpService.post<TAccountCvResponse>(BACKEND_ACCOUNT_CVS, dataRequest, config).then(result => {
			const alias = result.data.alias;
			const path = `${BACKEND_ACCOUNT_CVS}/${alias}`;
			const pathLanguages = `${path}/languages`;
			const languagesDataRequest = { items: languages };

			return HttpService.put<TAccountCvResponse>(pathLanguages, languagesDataRequest).then(result => {
				const pathEducation = `${path}/educations`;
				const educationDataRequest = { items: education };

				return HttpService.put<TAccountCvResponse>(pathEducation, educationDataRequest).then(result => {
					const pathExperience = `${path}/experience`;
					const experienceDataRequest = { items: experience };

					return HttpService.put<TAccountCvResponse>(pathExperience, experienceDataRequest).then(result => {
						return adaptAccountCvFromResponse(result.data);
					}).catch((error: HttpError) => {
						return thunkApi.rejectWithValue({ ...error });
					});
				}).catch((error: HttpError) => {
					return thunkApi.rejectWithValue({ ...error });
				});
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountCv | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCvCreateSlice = createSlice({
	name: 'accountCvCreateSlice',
	initialState,
	reducers: {
		clearAccountCvCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCvCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountCvCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountCv>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountCvCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountCvCreateSelector = () => useAppSelector(state => state[accountCvCreateSlice.name]);

export const { clearAccountCvCreate } = accountCvCreateSlice.actions;
