export type TAccount = {
  nickname: string;
  phoneNumber: string;
  email: string;
};

export type TAccountRequest = {
  nickname: string;
};

export type TAccountResponse = {
  nickname: string;
  phone_number: string;
  email: string;
};

export function adaptAccountFromResponse(data: TAccountResponse): TAccount {
	const {
		nickname,
		phone_number: phoneNumber,
		email,
	} = data;

	return {
		nickname,
		phoneNumber,
		email,
	};
}
