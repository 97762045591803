import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';


export const AccountEducationSortCol = {
	DATE_END: 'date_end',
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
};

export const EducationTypesSortCol = {
	ALIAS: 'alias',
};

export type TEducationType = {
	alias: string;
	name: string;
};

export type TEducationTypesListResponse = TListResponse<TEducationType>;

export type TEducationTypesList = TListResponse<TEducationType>;

export type TEducationTypesSortCol = TTableSortCols<typeof EducationTypesSortCol>;

export type TAccountEducationSortCol = TTableSortCols<typeof AccountEducationSortCol>;

export type TEducationTypesQuery = {
	locale: string;
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountEducationSortCol;
};

export type TEducationTypesQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountEducationQuery = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TAccountEducationSortCol;
};

export type TAccountEducationQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TAccountEducationType = {
  alias: string;
  name: string;
};

export type TAccountEducationTypeListResponse = TListResponse<TAccountEducationType>;

export type TAccountEducation = TAccountEducationLocale & {
  pid: string;
  type: string;
  dateEnd: string;
  locales: string[];
};

export type TAccountEducationLocale = {
  pid: string;
  locale: string;
  city: string;
  shortName: string;
  educationalInstitution: string;
  faculty: string;
  specialization: string;
  createdAt: string;
  updatedAt: string;
};

export type TAccountEducationLocaleResponse = {
  pid: string;
  locale: string;
  city: string;
  short_name: string;
  educational_institution: string;
  faculty: string;
  specialization: string;
  created_at: string;
  updated_at: string;
};

export type TAccountEducationLocaleRequest = Omit<TAccountEducationLocaleResponse, 'pid' | 'created_at' | 'updated_at'>;

export type TAccountEducationLocaleBodyRequest = Omit<TAccountEducationLocale, 'pid' | 'createdAt' | 'updatedAt'>;

export type TAccountEducationBodyRequest = Pick<TAccountEducation, 'type' | 'dateEnd'>;

export type TAccountEducationResponse = {
  pid: string;
  locale: string;
  city: string;
  type: string;
  short_name: string;
  educational_institution: string;
  faculty: string;
  specialization: string;
  date_end: string;
  locales: string[];
  created_at: string;
  updated_at: string;
};

export type TAccountEducationParam = {
  pid: string;
  locale: string;
};

export type TAccountEducationListResponse = TListResponse<TAccountEducationResponse>;

export type TAccountEducationList = TListResponse<TAccountEducation>;

export type TAccountEducationRequest = Pick<TAccountEducationResponse, 'type' | 'date_end'>;

export type TAccountEducationCreate = TAccountEducationBodyRequest;

export type TAccountEducationUpdate = {
	pid: string;
	data: TAccountEducationBodyRequest;
};

export type TAccountEducationLocaleUpdate = TAccountEducationParam & {
	data: TAccountEducationLocaleBodyRequest;
};

export type TAccountEducationLocaleCreate = Omit<TAccountEducationLocaleUpdate, 'locale'>;

export function adaptEducationTypesFromListResponse(data: TEducationTypesListResponse): TEducationTypesList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	return {
		items: [...itemsRaw],
		...restFields
	};
}

export function adaptAccountEducationFromListResponse(data: TAccountEducationListResponse): TAccountEducationList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptAccountEducationFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptAccountEducationFromResponse(data: TAccountEducationResponse): TAccountEducation {
	const {
		pid,
		locale,
		city,
		type,
		short_name: shortName,
		educational_institution: educationalInstitution,
		faculty = '',
		specialization,
		date_end: dateEnd,
		locales,
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	return {
		pid,
		locale,
		city,
		type,
		shortName,
		educationalInstitution,
		faculty,
		specialization,
		dateEnd,
		locales,
		createdAt,
		updatedAt,
	};
}

export function adaptAccountEducationToRequest(data: TAccountEducationBodyRequest): TAccountEducationRequest {
	const {
		type,
		dateEnd,
	} = data;

	return {
		type,
		date_end: dateEnd,
	};
}

export function adaptAccountEducationLocaleToRequest(data: TAccountEducationLocaleBodyRequest): TAccountEducationLocaleRequest {
	const {
		locale,
		city,
		shortName,
		educationalInstitution,
		faculty,
		specialization,
	} = data;

	return {
		locale,
		city,
		short_name: shortName,
		educational_institution: educationalInstitution,
		faculty,
		specialization,
	};
}
