import { ErrorWidget, TErrorPage } from 'src/pages/components';


export function ErrorPage(errorParam?: TErrorPage) {
	const error = errorParam ? errorParam : {};

	return (
		<ErrorWidget { ...error } />
	);
}
