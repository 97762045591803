// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountExperienceList_iconChevron__p77Rx {\n  font-size: 0.75em;\n  line-height: 1.2em;\n}\n\n.AccountExperienceList_iconChevron__p77Rx {\n  font-size: 0.75em;\n  line-height: 1.2em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-experience/views/list/components/table/AccountExperienceList.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,kBAAA;AACD;;AAEA;EACC,iBAAA;EACA,kBAAA;AACD","sourcesContent":[".iconChevron {\n\tfont-size: 0.75em;\n\tline-height: 1.2em;\n}\n\n.iconChevron {\n\tfont-size: 0.75em;\n\tline-height: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconChevron": "AccountExperienceList_iconChevron__p77Rx"
};
export default ___CSS_LOADER_EXPORT___;
