import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { PATH_STACK, PATH_STACK_CREATE_END, ROLES } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TStack, stackUpdateApi, setStackRead, useAppDispatch, useStackUpdateSelector, useCredentialsSelector } from 'src/store';
import { formHandleServerError, formValidateNickname, formValidateText, libExtractFileName, useInputFileField, useInputFormField, useLocalization } from 'src/toolkit';
import { LocaleBoxField } from 'src/pages/components';
import { StackImageField } from 'src/pages/stacks/components';

import { StackAliasField, StackNameField } from '../../../../components';
import { STACK_UPDATE_LOCALIZATION } from '../../localization';


export function StackFormUpdate(props: TStack) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { roles } = useCredentialsSelector();
	const { alias: aliasParam } = useParams();
	const { item, isLoading, error } = useStackUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(STACK_UPDATE_LOCALIZATION);

	const {
		name = '',
		alias = '',
		locales,
		image = '',
	} = props;

	const nameField = useInputFormField({
		name: 'name',
		defaultValue: name,
		validation: formValidateText,
	});

	const aliasField = useInputFormField({
		name: 'alias',
		defaultValue: alias,
		validation: formValidateNickname,
	});

	const imageField = useInputFileField('image', image);
	const [imageValue, setImageValue] = useState<File>();

	useEffect(() => {
		if (error) {
			formHandleServerError(error, setNotice,
				nameField,
				aliasField,
			);
		}

		if (item) {
			setNotice(noticeSuccess);
			dispatch(setStackRead(item));
			setImageValue(undefined);

			const { alias: changedAlias } = item;
			if (aliasParam !== changedAlias) {
				navigate(`${PATH_STACK}/${changedAlias}`);
			}
		}
	}, [error, item, noticeSuccess]);

	const onSubmit = () => {
		const errors = [
			nameField.validate(),
			aliasField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			name: nameField.value,
			alias: aliasField.value,
			image: imageValue || libExtractFileName(imageField.value),
		};

		dispatch(stackUpdateApi({ data: dataRequest, alias }))
	};

	const to = `${PATH_STACK}/${alias}${PATH_STACK_CREATE_END}`;

	const isUpdate = roles.includes(ROLES.UPDATE_STACKS);

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleBoxField to={ to } path={ PATH_STACK } alias={ alias } locales={ locales } isLoading={ isLoading } isButton={ isUpdate } />

			<StackImageField { ...imageField } setFile={ setImageValue } isUpdate={ isUpdate } />

			<StackNameField { ...nameField } isLoading={ isLoading } readOnly={ !isUpdate } />

			<StackAliasField { ...aliasField } isLoading={ isLoading } readOnly={ !isUpdate } />

			{ isUpdate && <FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice> }

			{ isUpdate &&
				<FormButtonWrap>
					<FormButton busy={ isLoading }>{ button }</FormButton>
				</FormButtonWrap>
			}
		</FormBox>
	);
}
