type TMask = {
	template?: string;
	charReplace?: string;
	charSkip?: string;
};

export function libClearPhone(val: number | string | boolean = ''): string {
	const value = val.toString() || '';

	const str = value.replace(/\D/ig, '');

	if (str.length > 0) {
		return '7' + str.slice(-10);
	}

	return str;
}

export function libNumberMask(value: string | undefined, options: TMask = {}): string {
	const {
		template = '+_ ___ ___ __ __',
		charReplace = '_',
		charSkip = '*',
	} = options;

	if (!value || typeof value === 'object') {
		return '—';
	}

	const val = value.toString();
	let temp = template;
	let pos = 0;
	for (let i = 0; i < val.length; i++) {
		const item = val[i];

		for (; pos < temp.length;) {
			const int = pos;
			pos += 1;
			const t = temp[int];

			if (t === charSkip) {
				break;
			}

			if (t === charReplace) {
				temp = temp.slice(0, int) + item + temp.slice(int + 1);
				break;
			}
		}
	}

	return temp;
}
