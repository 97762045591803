import { TAccountEducation } from 'src/store';
import { Icon } from 'src/components';

import styles from './AccountCvEducationItem.module.scss';
import { libFormatDate } from 'src/toolkit';


type TProps = {
	item: TAccountEducation;
	onClick: (value: TAccountEducation) => void;
};

export function AccountCvEducationItem(props: TProps) {
	const { item, onClick, ...restProps } = props;
	const { dateEnd, shortName } = item;

	const dateFormat = libFormatDate(dateEnd, 'yyyy');

	return (
		<div className={ styles.item } { ...restProps }>
			<span className={ styles.text }>{dateFormat} {shortName}</span>
			<Icon className={ styles.remove } onClick={ e => onClick(item) } icon="icon-cancel" />
		</div>
	);
}
