import { createPortal } from 'react-dom';

import { Icon } from 'src/components';
import { TUserRole } from 'src/store';

import styles from './UserRolesFormItemDetails.module.scss';
import { useLocalization } from 'src/toolkit';
import { USERS_ROLES_FORM_ITEM_DETAILS_LOCALIZATION } from './localization';


type TProps = {
	item: TUserRole;
	parent?: Element;
};

export function UserRolesFormItemDetails(props: TProps) {
	const { item, parent: parentElement } = props;
	const { title, description: descriptionRaw, value } = item;
	const { emptyText } = useLocalization(USERS_ROLES_FORM_ITEM_DETAILS_LOCALIZATION);

	const icon = value ? 'checkmark' : 'cancel';
	const iconName = `icon-${icon}`;

	const classNames = [styles.icon];

	if (value) {
		classNames.push(styles.active);
	}

	const description = descriptionRaw ? descriptionRaw : emptyText;
	const classNamesDescription = [styles.text];

	if (!descriptionRaw) {
		classNamesDescription.push(styles.empty);
	}

	const elem = (
		<div className={ styles.veil }>
			<div className={ styles.container }>
				<div className={ styles.headline }>
					<Icon icon={ iconName } className={ classNames.join(' ') } />
					<span className={ styles.title }>{ title }</span>
				</div>
				<p className={ classNamesDescription.join(' ') }>{ description }</p>
			</div>
		</div>
	);

	const parent = parentElement || document.body;

	return createPortal(elem, parent);
}
