import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { FooterRow } from './footer-row';

import styles from './Footer.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
};

export function Footer(props: TProps) {
	const title = process.env.REACT_APP_COMPANY_NAME || '';
	const items = [
		[
			[
				title,
				'Just do it',
			],
		],
	];

	const {
		className,
		classNames: classNamesOuter = [],
	} = props;

	const classNames = [styles.footer, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	const date = new Date();

	return (
		<footer className={ classNames.join() } { ...props }>
			{
				items.map((item, index) => <FooterRow items={ item } key={ index } />)
			}

			<div className={ styles.end }>
				<span className={ styles.root }>
					CopyRight
					<span className={ styles.copyRight }> © </span>
					{ title } { date.getFullYear() }
				</span>
			</div>
	</footer>
	);
}
