import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountEducation, TAccountEducationResponse, TAccountEducationBodyRequest, adaptAccountEducationFromResponse, adaptAccountEducationToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';


export const accountEducationCreateApi = createAsyncThunk(
	`POST/${BACKEND_ACCOUNT_EDUCATIONS}`,
	(payload: TAccountEducationBodyRequest, thunkApi) => {
		const dataRequest = adaptAccountEducationToRequest(payload);

		return HttpService.post<TAccountEducationResponse>(BACKEND_ACCOUNT_EDUCATIONS, dataRequest).then(result => {
			return adaptAccountEducationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountEducation | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEducationCreateSlice = createSlice({
	name: 'accountEducationCreateSlice',
	initialState,
	reducers: {
		clearAccountEducationCreate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEducationCreateApi.pending.type]: (state) => {
			state.error = undefined;
			state.isLoading = true;
		},

		[accountEducationCreateApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEducationCreateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.error = action.payload;
			state.isLoading = false;
		},
	},
});

export const useAccountEducationCreateSelector = () => useAppSelector(state => state[accountEducationCreateSlice.name]);

export const { clearAccountEducationCreate } = accountEducationCreateSlice.actions;
