// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSelectBoxOption_formSelectBoxOption__6QB9b {\n  border-top: 1px solid rgb(0, 0, 0);\n  box-sizing: border-box;\n  color: rgb(120, 120, 120);\n  line-height: 2em;\n  height: 2em;\n  padding: 0 0.5em;\n  animation-timing-function: ease-in-out;\n  transition-duration: 0.2s;\n  transition-property: background-color, color;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.FormSelectBoxOption_formSelectBoxOption__6QB9b:active, .FormSelectBoxOption_formSelectBoxOption__6QB9b:hover {\n  background-color: rgb(255, 255, 255);\n  color: rgb(90, 90, 90);\n}\n\ndiv > .FormSelectBoxOption_formSelectBoxOption__6QB9b:first-child {\n  border-top: none;\n  clear: both;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-select-box/components/option/FormSelectBoxOption.module.scss"],"names":[],"mappings":"AAAA;EACC,kCAAA;EACA,sBAAA;EAEA,yBAAA;EAEA,gBAAA;EACA,WAAA;EAEA,gBAAA;EAEA,sCAAA;EACA,yBAAA;EACA,4CAAA;EAEA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAJD;AAMC;EAEC,oCAAA;EACA,sBAAA;AALF;;AASA;EACC,gBAAA;EACA,WAAA;AAND","sourcesContent":[".formSelectBoxOption {\n\tborder-top: 1px solid rgba(0, 0, 0, 1);\n\tbox-sizing: border-box;\n\n\tcolor: rgba(120, 120, 120, 1);\n\n\tline-height: 2em;\n\theight: 2em;\n\n\tpadding: 0 0.5em;\n\n\tanimation-timing-function: ease-in-out;\n\ttransition-duration: 0.2s;\n\ttransition-property: background-color, color;\n\n\twhite-space: nowrap;\n\ttext-overflow: ellipsis;\n\toverflow: hidden;\n\n\t&:active,\n\t&:hover {\n\t\tbackground-color: rgba(255, 255, 255, 1);\n\t\tcolor: rgba(90, 90, 90, 1);\n\t}\n}\n\ndiv > .formSelectBoxOption:first-child {\n\tborder-top: none;\n\tclear: both;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSelectBoxOption": "FormSelectBoxOption_formSelectBoxOption__6QB9b"
};
export default ___CSS_LOADER_EXPORT___;
