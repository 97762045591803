import { FormFile } from 'src/components';
import { TInputFilesField, useLocalization } from 'src/toolkit';

import { ACCOUNT_PROJECT_FILE_FIELD_LOCALIZATION } from './localization';
import { TFormFileExistedValue } from 'src/components/form/form-file';


type TProps = TInputFilesField<TFormFileExistedValue> & {
	setFiles: (value: File[]) => void;
	isLoading?: boolean;
};

export function AccountProjectFilesField(props: TProps) {
	const {
		imageLegend,
		selectImageText,
		changeImageText,
	} = useLocalization(ACCOUNT_PROJECT_FILE_FIELD_LOCALIZATION);

	const { isLoading, ...restProps } = props;

	return (
		<FormFile
			legend={ imageLegend }
			{ ...restProps }
			selectImageText={selectImageText}
			changeImageText={changeImageText}
		/>
	);
}
