import { ReactNode } from 'react';

import styles from './FormButtonWrap.module.scss';


type TProps = {
	children?: ReactNode;
	className?: string;
};

export function FormButtonWrap(props: TProps) {
	const { children, className } = props;

	const classNames = [styles.formButtonWrap];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') }>
			{ children }
		</div>
	);
}
