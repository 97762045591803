import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH_ACCOUNT_PROJECTS, PATH_ACCOUNT_PROJECTS_CREATE_END } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountProject, accountProjectUpdateApi, setAccountProjectRead, useAppDispatch, useAccountProjectUpdateSelector, TAccountStack, TStack } from 'src/store';
import { formHandleServerError, formValidateLink, formValidateNickname, formValidateText, libExtractFileName, useInputFileField, useInputFilesField, useInputFormField, useLocalization } from 'src/toolkit';

import { AccountProjectAliasField, AccountProjectTitleField, AccountProjectImageField, AccountProjectUriField, AccountProjectRepositoryUriField, AccountProjectFilesField, AccountProjectStackField } from '../../../../components';
import { ACCOUNT_PROJECTS_UPDATE_LOCALIZATION } from '../../localization';

import { LocaleBoxField } from 'src/pages/components';


type TProps = TAccountProject & {
	items: TStack[];
};

export function AccountProjectFormUpdate(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountProjectUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(ACCOUNT_PROJECTS_UPDATE_LOCALIZATION);

	const {
		alias = '',
		uri = '',
		repositoryUri = '',
		title = '',
		image = '',
		files = [],
		locales = [],
		stack = [],
		items: stackSource = [],
	} = props;

	const [stackValue, setStackValue] = useState<TAccountStack[]>(stack);

	const stackField = useInputFormField({
		name: 'stack',
		validation: value => formValidateText(value),
	});

	const aliasField = useInputFormField({
		name: 'alias',
		defaultValue: alias,
		validation: value => formValidateNickname(value),
	});

	const titleField = useInputFormField({
		name: 'title',
		defaultValue: title,
		validation: value => formValidateText(value),
	});

	const uriField = useInputFormField({
		name: 'uri',
		defaultValue: uri,
		validation: value => formValidateLink(value),
	});

	const repositoryUriField = useInputFormField({
		name: 'repository_uri',
		defaultValue: repositoryUri,
		validation: value => formValidateLink(value),
	});

	const imageField = useInputFileField('image', image);
	const [imageValue, setImageValue] = useState<File>();

	const filesField = useInputFilesField('files', files);
	const [filesValue, setFilesValue] = useState<File[]>([]);

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				aliasField,
				titleField,
				uriField,
				repositoryUriField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setAccountProjectRead(item));
			setImageValue(undefined);

			const { alias: changedAlias } = item;

			if (changedAlias !== alias) {
				navigate(`${PATH_ACCOUNT_PROJECTS}/${alias}`);
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			aliasField.validate(),
			titleField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			alias: aliasField.value,
			title: titleField.value,
			uri: uriField.value || undefined,
			repositoryUri: repositoryUriField.value || undefined,
			image: imageValue || libExtractFileName(imageField.value),
		};

		const stack = stackValue.map(item => item.alias);
		const existedFiles = filesField.value.map(item => item.path);
		const files = [...filesValue, ...existedFiles];

		dispatch(accountProjectUpdateApi({ data: dataRequest, alias, stack, files }));
	};

	const to = `${PATH_ACCOUNT_PROJECTS}/${alias}${PATH_ACCOUNT_PROJECTS_CREATE_END}`;

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleBoxField to={ to } path={ PATH_ACCOUNT_PROJECTS } alias={ alias } locales={ locales } isLoading={ isLoading } />

			<AccountProjectImageField { ...imageField } setFile={ setImageValue } />

			<AccountProjectAliasField { ...aliasField } isLoading={ isLoading } />

			<AccountProjectTitleField { ...titleField } isLoading={ isLoading } />

			<AccountProjectUriField { ...uriField } isLoading={ isLoading } />

			<AccountProjectRepositoryUriField { ...repositoryUriField } isLoading={ isLoading } />

			<AccountProjectFilesField { ...filesField }  setFiles={ setFilesValue } />

			<AccountProjectStackField
				{ ...stackField }
				isLoading={ isLoading }
				items={ stackSource }
				stack={ stackValue }
				setStack={ setStackValue }
			/>

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
