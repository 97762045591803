// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableBodyCol_tableBodyCol__ewEm4 {\n  border: 1px solid rgb(180, 180, 180);\n  border-top: 1px solid rgb(180, 180, 180);\n  box-sizing: border-box;\n  color: rgb(0, 0, 0);\n  line-height: 1em;\n  padding: 0.25em 0.5em;\n  overflow: hidden;\n}\n.TableBodyCol_tableBodyCol__ewEm4:first-child {\n  border-left: 1px solid rgb(180, 180, 180);\n}\n.TableBodyCol_tableBodyCol__ewEm4:last-child {\n  border-right: 1px solid rgb(180, 180, 180);\n}\n\n.TableBodyCol_left__iPDBm {\n  text-align: left;\n}\n\n.TableBodyCol_center__pCeIR {\n  text-align: center;\n}\n\n.TableBodyCol_right__2MfJy {\n  text-align: right;\n}\n\n.TableBodyCol_justify__4pecN {\n  text-align: justify;\n}", "",{"version":3,"sources":["webpack://./src/components/table/tbody/col/TableBodyCol.module.scss"],"names":[],"mappings":"AAGA;EACC,oCAAA;EACA,wCAAA;EACA,sBAAA;EACA,mBAPO;EAQP,gBAAA;EACA,qBAAA;EACA,gBAAA;AAFD;AAIC;EACC,yCAAA;AAFF;AAKC;EACC,0CAAA;AAHF;;AAOA;EACC,gBAAA;AAJD;;AAOA;EACC,kBAAA;AAJD;;AAOA;EACC,iBAAA;AAJD;;AAOA;EACC,mBAAA;AAJD","sourcesContent":["$color: rgba(0, 0, 0, 1);\n$borderColor: rgba(180, 180, 180, 1);\n\n.tableBodyCol {\n\tborder: 1px solid $borderColor;\n\tborder-top: 1px solid $borderColor;\n\tbox-sizing: border-box;\n\tcolor: $color;\n\tline-height: 1em;\n\tpadding: 0.25em 0.5em;\n\toverflow: hidden;\n\n\t&:first-child {\n\t\tborder-left: 1px solid $borderColor;\n\t}\n\n\t&:last-child {\n\t\tborder-right: 1px solid $borderColor;\n\t}\n}\n\n.left {\n\ttext-align: left;\n}\n\n.center {\n\ttext-align: center;\n}\n\n.right {\n\ttext-align: right;\n}\n\n.justify {\n\ttext-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBodyCol": "TableBodyCol_tableBodyCol__ewEm4",
	"left": "TableBodyCol_left__iPDBm",
	"center": "TableBodyCol_center__pCeIR",
	"right": "TableBodyCol_right__2MfJy",
	"justify": "TableBodyCol_justify__4pecN"
};
export default ___CSS_LOADER_EXPORT___;
