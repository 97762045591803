import { useEffect } from 'react';

import { TableBox, TableRowEmpty, Icon } from 'src/components';
import { PATH_USERS } from 'src/routes';
import { UsersSortCol, TUsersSortCol, setUsersQueryLimit, setUsersQueryOffset, setUsersQuerySearch, setUsersQuerySort, setUsersQuerySortCol, usersGetApi, useAppDispatch, useUsersSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { useDebounce, useIsMobile, useLocalization } from 'src/toolkit/hooks';
import { TTableSort } from 'src/components/table/thead/col';

import { UsersListRow } from './components';

import { USERS_TABLE_LOCALIZATION } from './localization';

import styles from './UsersList.module.scss';


export function UsersList() {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();

	const { query, list, isLoading, error } = useUsersSelector();
	const { limit, offset, search, sort, sortCol } = query;
	const { items, count = 0 } = list;

	const searchDebounced = useDebounce(search || '');
	const {
		// image,
		nickname,
		updatedAt,
		createdAt,
		phoneNumber,
		email,
		emptyText,
		searchPlaceholder,
		searchTitle,
	} = useLocalization(USERS_TABLE_LOCALIZATION);

	useEffect(() => {
		dispatch(usersGetApi(query));
	}, [limit, offset, sort, sortCol, searchDebounced]);

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	const theadSettings = {
		sort,
		setSort: (value: TTableSort) => dispatch(setUsersQuerySort(value)),
		sortCol,
		setSortCol: (value: TUsersSortCol) => dispatch(setUsersQuerySortCol(value)),
		items: [
			// { name: image },
			{ name: nickname, colName: UsersSortCol.NICKNAME },
			{ name: phoneNumber, colName: UsersSortCol.PHONE_NUMBER },
			{ name: email, colName: UsersSortCol.EMAIL },
			{ name: createdAt, colName: UsersSortCol.CREATED_AT },
			{ name: updatedAt, colName: UsersSortCol.UPDATED_AT },
		],
	};

	const controlSettings = {
		limit,
		setLimit: (value: number) => dispatch(setUsersQueryLimit(value)),
		search,
		searchPlaceholder,
		searchTitle,
		setSearch: (value: string) => dispatch(setUsersQuerySearch(value)),
		onSubmit: () => {},
		options: [1, 2, 3, 5, 10, 25, 50, 100],
	};

	const paginationSettings = {
		itemsLength: items.length,
		count,
		limit,
		offset,
		setOffset: (value: number) => dispatch(setUsersQueryOffset(value)),
		prevLabel: <Icon icon="icon-chevron-left" className={ styles.iconChevron } />,
		nextLabel: <Icon icon="icon-chevron-right" className={ styles.iconChevron } />,
		isArrowsControls: !isMobile,
	};

	if (items.length === 0) {
		return (
			<TableBox
				isLoading={ isLoading }
				thead={ theadSettings }
				control={ controlSettings }
				pagination={ paginationSettings }
			>
				<TableRowEmpty colSpan={ 5 }>{ emptyText }</TableRowEmpty>
			</TableBox>
		);
	}

	return (
		<TableBox
			isLoading={ isLoading }
			thead={ theadSettings }
			control={ controlSettings }
			pagination={ paginationSettings }
		>
			{ items.map(item => <UsersListRow key={ item.nickname } { ...item } to={ `${PATH_USERS}/${item.nickname}` } />) }
		</TableBox>
	);
}
