import { useState } from 'react';


type TErrors = string[];

export type TLocaleError = {
	[key: string]: string;
};

export type TInputFormField = {
	required: boolean;
	name: string;
	value: string;
	setValue: (value: string) => void;
	errors: TErrors;
	setErrors: (value: TErrors) => void;
	clear: (value?: string) => void;
	validate: () => boolean;
};

type TRuleValidate = (value: string) => string[];
type TRuleValidateLocale = (value: string) => TLocaleError[];
type TValidationLocale = [TRuleValidateLocale, string];

type TProps = {
	name: string;
	defaultValue?: string;
	defaultRequired?: boolean;
	defaultErrors?: TErrors;
	validation?: TRuleValidate;
	validationLocale?: TValidationLocale;
	validateOnChange?: boolean;
};

export function useInputFormField(props: TProps): TInputFormField {
	const {
		name,
		defaultValue,
		defaultRequired,
		defaultErrors,
		validation,
		validationLocale,
		validateOnChange = false,
	} = props;

	const defaultVal = defaultValue || '';
	const err = defaultErrors || [];
	const defaultReq = defaultRequired || false;

	const [required, setRequired] = useState(defaultReq || !!defaultVal);
	const [value, setValue] = useState(defaultVal);
	const [errors, setErrors] = useState(err as TErrors);

	const validate = (value: string): boolean => {
		if (validationLocale) {
			const [validationRule, lang] = validationLocale;
			const localeErrors = validationRule(value);

			const errors = localeErrors.map(item => item[lang]);

			setErrors(errors);

			return errors.length > 0;
		}

		if (validation) {
			const errors = validation(value);

			setErrors(errors);

			return errors.length > 0;
		}

		return false;
	};

	const onChange = (value: string) => {
		setValue(value);

		if (validateOnChange) {
			validate(value);
		}

		if (!defaultRequired && value) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	};

	const onErrors = (err: TErrors) => {
		setErrors(err);
	};

	const clear = (value?: string) => {
		const clearValue = value ?? defaultVal;
		setValue(clearValue);

		if (!defaultRequired && clearValue) {
			setRequired(true);
		} else {
			setRequired(false);
		}
	};

	return {
		required,
		name,
		value,
		setValue: onChange,
		errors,
		setErrors: onErrors,
		clear,
		validate: () => validate(value),
	};
}
