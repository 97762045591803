import { LocalizationTypes } from 'src/common/types';

import { STACK_NAME_FIELD_RU_LOCALIZATION } from './ru.localization';
import { STACK_NAME_FIELD_EN_LOCALIZATION } from './en.localization';


export const STACK_NAME_FIELD_LOCALIZATION = {
	[LocalizationTypes.RU]: STACK_NAME_FIELD_RU_LOCALIZATION,
	[LocalizationTypes.EN]: STACK_NAME_FIELD_EN_LOCALIZATION,
};
