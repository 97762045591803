import { Link } from 'react-router-dom';

import styles from './HeadlineLink.module.scss';


type TProps = {
	children: string;
	path?: string;
	target?: '_blank' | '_parent' | '_self' | '_top';
	className?: string;
};

export function HeadlineLink(props: TProps) {
	const { children, path = '', target = '_self', className } = props;

	const classNames = className ? [className] : [];
	classNames.push(styles.text);

	if (path) {
		classNames.push(styles.link);

		return (
			<Link to={ path } target={ target } className={ classNames.join(' ') }>{ children }</Link>
		);
	}

	return (
		<span className={ classNames.join(' ') }>{ children }</span>
	);
}
