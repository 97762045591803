import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { PageRow } from 'src/components';

import styles from './AccountCvFieldsRow.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function AccountCvFieldsRow(props: TProps) {
	const { children } = props;

	return (
		<PageRow className={ styles.row }>
			{ children }
		</PageRow>
	);
}
