export const USERS_PHONE_NUMBER_DETAILS_RU_LOCALIZATION = {
	createdAtLegend: 'Дата создания',
	createdAtTitle: 'Дата создания номера телефона пользователя',

	updatedAtLegend: 'Дата обновления',
	updatedAtTitle: 'Дата обновления номера телефона пользователя',

	unconfirmedLegend: 'Не подтверждённый номер телефона',
	unconfirmedTitle: 'Номер телефона пользователя не подтверждён',

	removeLinkText: 'Удалить номер телефона',
};
