import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PATH_ACCOUNT_CVS, PATH_ACCOUNT_CVS_CREATE_END } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountCv, accountCvUpdateApi, setAccountCvRead, useAppDispatch, useAccountCvUpdateSelector, TLanguage, TLanguageLevel, TUserSex, TUserCvStatus, TAccountCvLanguage, TAccountEducation, TAccountExperience } from 'src/store';
import { formHandleServerError, formValidateDate, formValidateEmail, formValidateLink, formValidateNickname, formValidatePhoneNumber, libExtractFileName, useInputFileField, useInputFormField, useLocalization } from 'src/toolkit';
import { LocaleBoxField } from 'src/pages/components';

import { AccountCvAliasField, AccountCvImageField, AccountCvBirthdayField, AccountCvSexField, AccountCvStatusField, AccountCvFieldsRow, AccountCvLanguagesField, AccountCvEducationField, AccountCvExperienceField, AccountCvPhoneNumberField, AccountCvEmailField, AccountCvTelegramField, AccountCvLinkedInField, AccountCvHeadHunterField } from '../../../../components';
import { ACCOUNT_CVS_UPDATE_LOCALIZATION } from '../../localization';


type TProps = TAccountCv & {
	languagesSource: TLanguage[];
	languageLevelsSource?: TLanguageLevel[];
	usersSexSource?: TUserSex[];
	cvStatusesSource?: TUserCvStatus[];
	educationSource?: TAccountEducation[];
	experienceSource?: TAccountExperience[];
};

export function AccountCvsFormUpdate(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { alias: aliasParam } = useParams();
	const { item, isLoading, error } = useAccountCvUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(ACCOUNT_CVS_UPDATE_LOCALIZATION);

	const {
		alias = '',
		birthday = '',
		sex,
		status,
		locales = [],
		image = '',
		phoneNumber = '',
		email = '',
		telegram = '',
		linkedin = '',
		headhunter = '',
		experience = [],
		education = [],
		languages = [],
		cvStatusesSource = [],
		usersSexSource = [],
		languagesSource = [],
		languageLevelsSource = [],
		educationSource = [],
		experienceSource = [],
	} = props;

	const [languageValue, setLanguagesValue] = useState<TAccountCvLanguage[]>(languages);

	const [educationItems, setEducationItems] = useState<TAccountEducation[]>(education);

	const [experienceItems, setExperienceItems] = useState<TAccountExperience[]>(experience);

	const aliasField = useInputFormField({
		name: 'alias',
		defaultValue: alias,
		validation: value => formValidateNickname(value),
	});

	const birthdayField = useInputFormField({
		name: 'birthday',
		defaultValue: birthday,
		validation: value => formValidateDate(value),
	});

	const sexField = useInputFormField({
		name: 'sex',
		defaultValue: sex.alias,
		validation: value => formValidateNickname(value),
	});

	const statusField = useInputFormField({
		name: 'status',
		defaultValue: status.alias,
		validation: value => formValidateNickname(value),
	});

	const imageField = useInputFileField('image', image);
	const [imageValue, setImageValue] = useState<File>();

	const phoneNumberField = useInputFormField({
		name: 'phoneNumber',
		defaultValue: phoneNumber,
		validation: value => formValidatePhoneNumber(value),
	});

	const emailField = useInputFormField({
		name: 'email',
		defaultValue: email,
		validation: value => formValidateEmail(value),
	});

	const telegramField = useInputFormField({
		name: 'telegram',
		defaultValue: telegram,
		validation: value => formValidateNickname(value),
	});

	const linkedinField = useInputFormField({
		name: 'linkedin',
		defaultValue: linkedin,
		validation: value => formValidateLink(value),
	});

	const headhunterField = useInputFormField({
		name: 'headhunter',
		defaultValue: headhunter,
		validation: value => formValidateLink(value),
	});

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				aliasField,
				birthdayField,
				sexField,
				statusField,
				phoneNumberField,
				emailField,
				telegramField,
				linkedinField,
				headhunterField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setAccountCvRead(item));
			setImageValue(undefined);

			const { alias: changedAlias } = item;
			if (aliasParam !== changedAlias) {
				navigate(`${PATH_ACCOUNT_CVS}/${changedAlias}`);
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			aliasField.validate(),
			birthdayField.validate(),
			sexField.validate(),
			statusField.validate(),
			phoneNumberField.validate(),
			emailField.validate(),
			telegramField.validate(),
			linkedinField.validate(),
			headhunterField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			alias: aliasField.value,
			birthday: birthdayField.value,
			sex: sexField.value,
			status: statusField.value,
			image: imageValue || libExtractFileName(imageField.value),
			phoneNumber: phoneNumberField.value,
			email: emailField.value,
			telegram: telegramField.value,
			linkedin: linkedinField.value,
			headhunter: headhunterField.value,
		};

		const education = educationItems.map(item => item.pid);
		const experience = experienceItems.map(item => item.pid);

		dispatch(accountCvUpdateApi({
			data: dataRequest,
			alias,
			languages: languageValue,
			education,
			experience,
		}));
	};

	const to = `${PATH_ACCOUNT_CVS}/${alias}${PATH_ACCOUNT_CVS_CREATE_END}`;

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleBoxField to={ to } path={ PATH_ACCOUNT_CVS } alias={ alias } locales={ locales } isLoading={ isLoading } />

			<AccountCvImageField { ...imageField } setFile={ setImageValue } />

			<AccountCvAliasField { ...aliasField } isLoading={ isLoading } />

			<AccountCvFieldsRow>
				<AccountCvBirthdayField { ...birthdayField } isLoading={ isLoading } />

				<AccountCvSexField { ...sexField } isLoading={ isLoading } items={ usersSexSource } />
			</AccountCvFieldsRow>

			<AccountCvStatusField { ...statusField } isLoading={ isLoading } items={ cvStatusesSource } />

			<AccountCvPhoneNumberField { ...phoneNumberField } isLoading={ isLoading } />

			<AccountCvEmailField { ...emailField } isLoading={ isLoading } />

			<AccountCvTelegramField { ...telegramField } isLoading={ isLoading } />

			<AccountCvLinkedInField { ...linkedinField } isLoading={ isLoading } />

			<AccountCvHeadHunterField { ...headhunterField } isLoading={ isLoading } />

			<AccountCvExperienceField
				items={ experienceItems }
				setItems={ setExperienceItems }
				experienceSource={ experienceSource }
				isLoading={ isLoading }
			/>

			<AccountCvEducationField
				items={ educationItems }
				setItems={ setEducationItems }
				educationSource={ educationSource }
				isLoading={ isLoading }
			/>

			<AccountCvLanguagesField
				setItems={ setLanguagesValue }
				languages={ languageValue }
				languagesSource={ languagesSource }
				languageLevelsSource={ languageLevelsSource }
				isLoading={ isLoading }
			/>

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
