import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './PublicCvName.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
};

export function PublicCvName(props: TProps) {
	const {
		children = '',
		className = '',
		classNames: classNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.headline, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ styles.box } { ...restProps }>{ children }</div>
	);
}
