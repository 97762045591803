import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';
import { LocalizationTypes } from 'src/common/types';

import { useAppSelector } from '../../hooks';
import { StackSortCol, TStackList, TStackListResponse, TStackQuery, TStackSortCol, adaptStackFromListResponse } from '../../types';
import { BACKEND_ACCOUNT_SOURCE_STACK } from '../../backend-paths';


export const sourceStacksGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_SOURCE_STACK,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_SOURCE_STACK}/${payload}`;

		return HttpService.get<TStackListResponse>(path).then(result => {
			return adaptStackFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	isLoading: boolean,
	list?: TStackList,
	query: TStackQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	isLoading: false,
	list: undefined,
	query: {
		locale: LocalizationTypes.EN,
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: StackSortCol.NAME,
	},
	error: undefined,
};

export const accountSourceStacksSlice = createSlice({
	name: 'accountSourceStacksSlice',
	initialState,
	reducers: {
		setAccountSourceStackQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountSourceStackQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountSourceStackQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountSourceStackQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountSourceStackQuerySortCol(state, action: PayloadAction<TStackSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountSourceStacks(state) {
			return { ...initialState, list: state.list, query: state.query };
		},
	},

	extraReducers: {
		[sourceStacksGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[sourceStacksGetApi.fulfilled.type]: (state, action: PayloadAction<TStackList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[sourceStacksGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountSourceStacksSelector = () => useAppSelector(state => state[accountSourceStacksSlice.name]);

export const { clearAccountSourceStacks, setAccountSourceStackQueryLimit, setAccountSourceStackQueryOffset, setAccountSourceStackQuerySort, setAccountSourceStackQuerySortCol, setAccountSourceStackQuerySearch } = accountSourceStacksSlice.actions;
