import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_EXPERIENCE } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountExperience, TAccountExperienceLocaleParam, TAccountExperienceResponse, adaptAccountExperienceFromResponse } from '../../types';


export const accountExperienceLocaleReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_EXPERIENCE}/:pid/:locale`,
	(payload: TAccountExperienceLocaleParam, thunkApi) => {
		const { pid, locale } = payload;
		const path = `${BACKEND_ACCOUNT_EXPERIENCE}/${pid}/${locale}`;

		return HttpService.get<TAccountExperienceResponse>(path).then(result => {
			return adaptAccountExperienceFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TAccountExperience | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountExperienceLocaleReadSlice = createSlice({
	name: 'accountExperienceLocaleReadSlice',
	initialState,
	reducers: {
		setAccountExperienceLocaleRead(state, action: PayloadAction<TAccountExperience>) {
			state.data = action.payload;
		},

		clearAccountExperienceLocaleRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountExperienceLocaleReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountExperienceLocaleReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountExperience>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountExperienceLocaleReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountExperienceLocaleReadSelector = () => useAppSelector(state => state[accountExperienceLocaleReadSlice.name]);

export const { clearAccountExperienceLocaleRead, setAccountExperienceLocaleRead } = accountExperienceLocaleReadSlice.actions;
