import { LocalizationTypes } from 'src/common/types';

import { STACK_LIST_RU_LOCALIZATION } from './ru.localization';
import { STACK_LIST_EN_LOCALIZATION } from './en.localization';


export const STACK_LIST_LOCALIZATION = {
	[LocalizationTypes.RU]: STACK_LIST_RU_LOCALIZATION,
	[LocalizationTypes.EN]: STACK_LIST_EN_LOCALIZATION,
};
