import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { libFormatPrice, useLocalization } from 'src/toolkit';

import { PUBLIC_CV_PREFERRED_SALARY_LOCALIZATION } from './localization';

import styles from './PublicCvPreferredSalary.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
	className?: string;
	value: string;
	currency: string;
};

export function PublicCvPreferredSalary(props: TProps) {
	const { text } = useLocalization(PUBLIC_CV_PREFERRED_SALARY_LOCALIZATION);
	const {
		value,
		currency,
		className = '',
		classNames: classNamesOuter = [],
	} = props;

	const classNames = [...classNamesOuter, styles.box];

	if (className) {
		classNames.push(className);
	}

	const formatValue = libFormatPrice(value);

	if (currency === '₽') {
		return (
			<div className={ classNames.join(' ') }>
				<span className={ styles.text }>{ text }: </span>
				<span className={ styles.value }>{ formatValue }</span>
				<span className={ styles.currency }> { currency }</span>
			</div>
		);
	}

	return (
		<div className={ classNames.join(' ') }>
			<span className={ styles.text }>{ text }: </span>
			<span className={ styles.currency }>{ currency } </span>
			<span className={ styles.value }>{ formatValue }</span>
		</div>
	);
}
