// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountForm_form__7p1CO {\n  box-sizing: border-box;\n  margin: auto;\n  padding: 1em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account/views/main/components/form/AccountForm.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,YAAA;EACA,YAAA;AACD","sourcesContent":[".form {\n\tbox-sizing: border-box;\n\tmargin: auto;\n\tpadding: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "AccountForm_form__7p1CO"
};
export default ___CSS_LOADER_EXPORT___;
