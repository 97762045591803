// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormImage_formImage__ejAel {\n  box-sizing: border-box;\n  margin: auto;\n}\n\n.FormImage_img__7FVEp,\n.FormImage_symbolBox__sK4Zr {\n  border: 1px solid rgb(200, 200, 200);\n  border-radius: 1em 1em 0 0;\n  box-sizing: border-box;\n  margin-bottom: 0.25em;\n  text-align: center;\n  width: 100%;\n  max-height: 360px;\n  max-width: 360px;\n}\n\n.FormImage_symbolBox__sK4Zr span {\n  color: rgb(90, 90, 90);\n  font-size: 10em;\n  line-height: 1.4em;\n  pointer-events: none;\n  -webkit-user-select: none;\n          user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-image/FormImage.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,YAAA;AACD;;AAEA;;EAEC,oCAAA;EACA,0BAAA;EACA,sBAAA;EAEA,qBAAA;EAEA,kBAAA;EACA,WAAA;EAEA,iBAAA;EACA,gBAAA;AAFD;;AAKA;EACC,sBAAA;EAEA,eAAA;EACA,kBAAA;EAEA,oBAAA;EACA,yBAAA;UAAA,iBAAA;AAJD","sourcesContent":[".formImage {\n\tbox-sizing: border-box;\n\tmargin: auto;\n}\n\n.img,\n.symbolBox {\n\tborder: 1px solid rgba(200, 200, 200, 1);\n\tborder-radius: 1em 1em 0 0;\n\tbox-sizing: border-box;\n\n\tmargin-bottom: 0.25em;\n\n\ttext-align: center;\n\twidth: 100%;\n\n\tmax-height: 360px;\n\tmax-width: 360px;\n}\n\n.symbolBox span {\n\tcolor: rgba(90, 90, 90, 1);\n\n\tfont-size: 10em;\n\tline-height: 1.4em;\n\n\tpointer-events: none;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formImage": "FormImage_formImage__ejAel",
	"img": "FormImage_img__7FVEp",
	"symbolBox": "FormImage_symbolBox__sK4Zr"
};
export default ___CSS_LOADER_EXPORT___;
