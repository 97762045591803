// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvBirthdayField_box__N7y07 {\n  margin-right: 0.1em;\n  max-width: 10em;\n  width: 100%;\n  min-width: 8em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/components/birthday-field/AccountCvBirthdayField.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,eAAA;EACA,WAAA;EACA,cAAA;AACD","sourcesContent":[".box {\n\tmargin-right: 0.1em;\n\tmax-width: 10em;\n\twidth: 100%;\n\tmin-width: 8em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvBirthdayField_box__N7y07"
};
export default ___CSS_LOADER_EXPORT___;
