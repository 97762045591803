import { FormField, FormInput } from 'src/components';
import { TInputField, useLocalization } from 'src/toolkit';

import { ACCOUNT_EXPERIENCE_DATE_END_FIELD_LOCALIZATION } from './localization';

import styles from './AccountExperienceDateEndField.module.scss';


type TProps = TInputField & {
	isLoading?: boolean;
};

export function AccountExperienceDateEndField(props: TProps) {
	const {
		dateEndLegend,
		dateEndTitle,
	} = useLocalization(ACCOUNT_EXPERIENCE_DATE_END_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ dateEndLegend }
			errors={ errors }
			className={ styles.box }
		>
			<FormInput
				type="date"
				name={ name }
				title={ dateEndTitle }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
