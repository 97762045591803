// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormError_formError__tSGgZ {\n  color: rgb(255, 50, 50);\n  display: block;\n  font-size: 0.85em;\n  line-height: 1em;\n  margin-top: 0.5em;\n}", "",{"version":3,"sources":["webpack://./src/components/form/form-error/FormError.module.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AACD","sourcesContent":[".formError {\n\tcolor: rgba(255, 50, 50, 1);\n\tdisplay: block;\n\tfont-size: 0.85em;\n\tline-height: 1em;\n\tmargin-top: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formError": "FormError_formError__tSGgZ"
};
export default ___CSS_LOADER_EXPORT___;
