import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TAccountProject, TAccountProjectPayloadUpdate, TAccountProjectResponse, adaptAccountProjectFromResponse, adaptAccountProjectToRequest } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_PROJECTS } from '../../backend-paths';


export const accountProjectUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ACCOUNT_PROJECTS}/:alias/:locale`,
	(payload: TAccountProjectPayloadUpdate, thunkApi) => {
		const {
			data,
			alias,
			stack = [],
			files = [],
		} = payload;

		const path = `${BACKEND_ACCOUNT_PROJECTS}/${alias}`;
		const dataRequest = adaptAccountProjectToRequest(data);

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return HttpService.put<TAccountProjectResponse>(path, dataRequest, config).then(result => {
			const alias = result.data.alias;
			const path = `${BACKEND_ACCOUNT_PROJECTS}/${alias}`;
			const pathStack = `${path}/stack`;
			const stackDataRequest = { items: stack };

			return HttpService.put<TAccountProjectResponse>(pathStack, stackDataRequest).then(result => {
				if (files.length === 0) {
					return adaptAccountProjectFromResponse(result.data);
				}

				const pathFiles = `${BACKEND_ACCOUNT_PROJECTS}/${alias}/files`;
				const filesDataRequest = { files };

				return HttpService.put<TAccountProjectResponse>(pathFiles, filesDataRequest, config).then(result => {
					return adaptAccountProjectFromResponse(result.data);
				}).catch((error: HttpError) => {
					return thunkApi.rejectWithValue({ ...error });
				});
			}).catch((error: HttpError) => {
				return thunkApi.rejectWithValue({ ...error });
			});
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountProject | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountProjectUpdateSlice = createSlice({
	name: 'accountProjectUpdateSlice',
	initialState,
	reducers: {
		clearAccountProjectUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountProjectUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountProjectUpdateApi.fulfilled.type]: (state, action: PayloadAction<TAccountProject>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountProjectUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useAccountProjectUpdateSelector = () => useAppSelector(state => state[accountProjectUpdateSlice.name]);

export const { clearAccountProjectUpdate } = accountProjectUpdateSlice.actions;
