// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvLastWork_box__uNWDo {\n  margin: 0.5em 0;\n}\n\n.PublicCvLastWork_headline__ymAzr {\n  color: rgb(150, 150, 150);\n  font-size: 0.9em;\n}\n\n.PublicCvLastWork_name__zaYA0 {\n  color: rgb(90, 90, 90);\n  font-size: 1em;\n}\n\n.PublicCvLastWork_content__fq\\+Px {\n  font-size: 0.9em;\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/last-work/PublicCvLastWork.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;AACD;;AAEA;EACC,yBAAA;EACA,gBAAA;AACD;;AAEA;EACC,sBAAA;EACA,cAAA;AACD;;AAEA;EACC,gBAAA;EACA,mBAAA;AACD","sourcesContent":[".box {\n\tmargin: 0.5em 0;\n}\n\n.headline {\n\tcolor: rgba(150, 150, 150, 1);\n\tfont-size: 0.9em;\n}\n\n.name {\n\tcolor: rgba(90, 90, 90, 1);\n\tfont-size: 1em;\n}\n\n.content {\n\tfont-size: 0.9em;\n\twhite-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvLastWork_box__uNWDo",
	"headline": "PublicCvLastWork_headline__ymAzr",
	"name": "PublicCvLastWork_name__zaYA0",
	"content": "PublicCvLastWork_content__fq+Px"
};
export default ___CSS_LOADER_EXPORT___;
