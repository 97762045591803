// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvHeadHunter_box__\\+5\\+Bs {\n  margin-top: 0.25em;\n}\n\n.PublicCvHeadHunter_text__mt\\+lY {\n  color: rgb(130, 130, 130);\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/contacts/components/headhunter/PublicCvHeadHunter.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;;AAEA;EACC,yBAAA;AACD","sourcesContent":[".box {\n\tmargin-top: 0.25em;\n}\n\n.text {\n\tcolor: rgba(130, 130, 130, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvHeadHunter_box__+5+Bs",
	"text": "PublicCvHeadHunter_text__mt+lY"
};
export default ___CSS_LOADER_EXPORT___;
