import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import styles from './PublicCvImage.module.scss';


type TProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	classNames?: string[];
};

export function PublicCvImage(props: TProps) {
	const {
		className = '',
		classNames: classNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.image, ...classNamesOuter];

	if (className) {
		classNames.push(className);
	}

	return (
		<img className={ classNames.join(' ') } { ...restProps } />
	);
}
