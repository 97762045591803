import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TTableSort, TableSort } from 'src/components/table/thead/col';

import { useAppSelector } from '../../hooks';
import { AccountProjectSortCol, TAccountProject, TAccountProjectList, TAccountProjectListResponse, TAccountProjectQuery, TAccountProjectQueryRequest, TAccountProjectSortCol, adaptAccountProjectFromListResponse } from '../../types';
import { BACKEND_ACCOUNT_PROJECTS } from '../../backend-paths';
import { LocalizationTypes } from 'src/common/types';


export const accountProjectsGetApi = createAsyncThunk(
	BACKEND_ACCOUNT_PROJECTS,
	(payload: TAccountProjectQuery | undefined, thunkApi) => {
		const params = payload ? toAccountProjectsQueryRequest(payload) : {};

		return HttpService.get<TAccountProjectListResponse>(BACKEND_ACCOUNT_PROJECTS, { params }).then(result => {
			return adaptAccountProjectFromListResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toAccountProjectsQueryRequest(data: TAccountProjectQuery): TAccountProjectQueryRequest {
	const {
		sortCol,
		...restFields
	} = data;

	return {
		sort_col: sortCol,
		...restFields
	};
}

type TInitialState = {
	item?: TAccountProject,
	itemLocale?: TAccountProject,
	isLoading: boolean,
	list: TAccountProjectList,
	query: TAccountProjectQuery,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	itemLocale: undefined,
	isLoading: false,
	list: {
		items: [],
		count: 0,
	},
	query: {
		locale: LocalizationTypes.EN,
		limit: 10,
		offset: 0,
		search: undefined,
		sort: TableSort.ASC,
		sortCol: AccountProjectSortCol.UPDATED_AT,
	},
	error: undefined,
};

export const accountProjectsSlice = createSlice({
	name: 'accountProjectsSlice',
	initialState,
	reducers: {
		setAccountProjectsCreatedItem(state, action: PayloadAction<TAccountProject>) {
			state.item = action.payload;
		},

		setAccountProjectsLocalesCreatedItem(state, action: PayloadAction<TAccountProject>) {
			state.itemLocale = action.payload;
		},

		setAccountProjectsQueryLimit(state, action: PayloadAction<number>) {
			state.query.limit = action.payload;
		},

		setAccountProjectsQueryOffset(state, action: PayloadAction<number>) {
			state.query.offset = action.payload;
		},

		setAccountProjectsQuerySearch(state, action: PayloadAction<string>) {
			const search = action.payload || undefined;
			const isSearch = search && search.length > 0;

			if (isSearch && state.query.offset !== 0) {
				state.query.offset = 0;
				return;
			}

			state.query.search = search;
		},

		setAccountProjectsQuerySort(state, action: PayloadAction<TTableSort>) {
			state.query.sort = action.payload;
		},

		setAccountProjectsQuerySortCol(state, action: PayloadAction<TAccountProjectSortCol>) {
			state.query.sortCol = action.payload;
		},

		clearAccountProjects(state) {
			return { ...initialState, list: state.list, query: state.query };
		},

		clearAccountProjectsCreatedItem(state) {
			state.item = undefined;
		},

		clearAccountProjectsLocalesCreatedItem(state) {
			state.itemLocale = undefined;
		},
	},

	extraReducers: {
		[accountProjectsGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountProjectsGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountProjectList>) => {
			state.isLoading = false;
			state.list = action.payload;
			state.error = undefined;
		},

		[accountProjectsGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountProjectsSelector = () => useAppSelector(state => state[accountProjectsSlice.name]);

export const { clearAccountProjects, setAccountProjectsQueryLimit, setAccountProjectsQueryOffset, setAccountProjectsQuerySort, setAccountProjectsQuerySortCol, setAccountProjectsQuerySearch, setAccountProjectsCreatedItem, setAccountProjectsLocalesCreatedItem, clearAccountProjectsCreatedItem, clearAccountProjectsLocalesCreatedItem } = accountProjectsSlice.actions;
