import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { BACKEND_ACCOUNT_PHONE_NUMBER } from '../../backend-paths';
import { useAppSelector } from '../../hooks';
import { TAccountPhoneNumber, TAccountPhoneNumberResponse, adaptAccountPhoneNumberFromResponse } from '../../types';


export const accountPhoneNumberReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_PHONE_NUMBER}`,
	(payload, thunkApi) => {
		return HttpService.get<TAccountPhoneNumberResponse>(BACKEND_ACCOUNT_PHONE_NUMBER).then(result => {
			return adaptAccountPhoneNumberFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TAccountPhoneNumber | undefined,
	timeExpired: number,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	timeExpired: 0,
	isLoading: false,
	error: undefined,
};

export const accountPhoneNumberReadSlice = createSlice({
	name: 'accountPhoneNumberReadSlice',
	initialState,
	reducers: {
		setAccountPhoneNumberRead(state, action: PayloadAction<TAccountPhoneNumber>) {
			state.item = action.payload;
			state.timeExpired = action.payload.timeExpired;
		},

		setAccountPhoneNumberTimeExpiredRead(state, action: PayloadAction<number>) {
			state.timeExpired = action.payload;
		},

		clearAccountPhoneNumberRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountPhoneNumberReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountPhoneNumberReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountPhoneNumber>) => {
			state.isLoading = false;
			state.item = action.payload;
			state.timeExpired = action.payload.timeExpired;
		},

		[accountPhoneNumberReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountPhoneNumberReadSelector = () => useAppSelector(state => state[accountPhoneNumberReadSlice.name]);

export const { clearAccountPhoneNumberRead, setAccountPhoneNumberRead, setAccountPhoneNumberTimeExpiredRead } = accountPhoneNumberReadSlice.actions;
