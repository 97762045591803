import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_STATIC_CV_IMAGES } from 'src/routes';
import { libFormatDate } from 'src/toolkit';
import { TPublicEducation, TPublicEducationResponse, adaptPublicEducationFromResponse } from './public-education.type';
import { TPublicExperience, TPublicExperienceResponse, adaptPublicExperienceFromResponse } from './public-experience.type';
import { TUserSex } from './account-source-user-sex.type';
import { TUserCvStatus } from './account-source-user-cv-status.type';
import { TPublicCvLanguage, TPublicCvLanguageResponse, adaptPublicCvLanguageFromResponse } from './public-language.type';

export const PublicCvSortCol = {
	UPDATED_AT: 'updated_at',
};

export type TPublicCvSortCol = TTableSortCols<typeof PublicCvSortCol>;

export type TPublicCvQuery = {
	locale: string;
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TPublicCvSortCol;
};

export type TPublicCvQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TPublicCv = TPublicCvLocale & {
	alias: string;
	birthday: string;
	sex: TUserSex;
	status: TUserCvStatus;
	image: string;
	phoneNumber: string;
	email: string;
	telegram: string;
	linkedin: string;
	headhunter: string;

	countExperience: number[];
	experience: TPublicExperience[];
	education: TPublicEducation[];
	languages: TPublicCvLanguage[];

	locales: string[];

	nickname: string;
};

export type TPublicCvLocale = {
  locale: string;

  title: string;
  firstName: string;
  lastName: string;
  city: string;
  description: string;
  preferredSalary: string;
  currency: string;

  updatedAt: string;
};

export type TPublicCvResponse = {
	alias: string;
	birthday: string;
	sex: TUserSex;
	status: TUserCvStatus;
	image: string;
	phone_number: string;
	email: string;
	telegram: string;
	linkedin: string;
	headhunter: string;

	count_experience: number[];
	experience: TPublicExperienceResponse[];
	education: TPublicEducationResponse[];
	languages: TPublicCvLanguageResponse[];
	locales: string[];

  title: string;
  first_name: string;
  last_name: string;
  city: string;
  description: string;
  preferred_salary: number;
  currency: string;

	locale: string;
	updated_at: string;

	nickname: string;
};

export type TPublicCvLocaleParam = {
  nickname: string;
  alias: string;
  locale: string;
  pid?: string;
};

export type TPublicCvRequest = Pick<TPublicCvResponse, 'alias' | 'birthday'> & {
	image?: File | string;
	sex: string;
	status: string;
};

export type TPublicCvListResponse = TListResponse<TPublicCvResponse>;

export type TPublicCvList = TListResponse<TPublicCv>;

export function adaptPublicCvFromListResponse(data: TPublicCvListResponse): TPublicCvList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptPublicCvFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptPublicCvFromResponse(data: TPublicCvResponse): TPublicCv {
	const {
		locale,
		alias,
		title,
		description,
		image: imageRaw = '',
		phone_number: phoneNumber = '',
		email = '',
		telegram = '',
		linkedin = '',
		headhunter = '',
		locales = [],
		updated_at: updatedAt,

		sex,
		birthday,
		status,

		count_experience: countExperience,
		experience: experienceRaw = [],
		education: educationRaw = [],
		languages: languagesRaw = [],

		first_name: firstName,
		last_name: lastName,
		city,
		preferred_salary: preferredSalaryRaw,
		currency,

		nickname,
	} = data;

	const preferredSalary = preferredSalaryRaw ? preferredSalaryRaw.toString() : '';

	const image = imageRaw ? `${PATH_STATIC_CV_IMAGES}/${imageRaw}` : `/images/user-${sex.alias}.svg`;
	const experience = experienceRaw.map(item => adaptPublicExperienceFromResponse(item));
	const education = educationRaw.map(item => adaptPublicEducationFromResponse(item));
	const languages = languagesRaw.map(item => adaptPublicCvLanguageFromResponse(item));

	return {
		locale,
		alias,
		title,
		description,
		image,
		phoneNumber,
		email,
		telegram,
		linkedin,
		headhunter,
		locales,
		updatedAt,
		experience,
		education,
		languages,
		countExperience,
		firstName,
		lastName,
		city,
		sex,
		status,
		birthday: libFormatDate(birthday, 'yyyy-MM-dd'),
		preferredSalary,
		currency,
		nickname,
	};
}
