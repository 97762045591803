import { TListResponse } from 'src/common/types';


export const DefaultLanguageLevel = { alias: 'a1', name: 'A1 (Elementary)' };

export type TLanguageLevelResponse = {
	alias: string;
	name: string;
};

export type TLanguageLevel = {
	alias: string;
	name: string;
};

export type TLanguageLevelListResponse = TListResponse<TLanguageLevel>;

export function adaptLanguageLevelFromResponse(data: TLanguageLevelResponse): TLanguageLevel {
	const { name, alias } = data;

	return { name, alias };
}

export function adaptLanguageLevelsFromListResponse(data: TLanguageLevelListResponse): TLanguageLevel[] {
	const { items } = data;

	return items.map(item => adaptLanguageLevelFromResponse(item));
}
