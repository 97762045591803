import styles from './FormImagePreloader.module.scss';


export function FormImagePreloader() {
	return (
		<div className={ styles.formImagePreloader }>
			<div className={ styles.formImagePreloaderContainer }>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}
