import { createPortal } from 'react-dom';

import { TAccountStack } from 'src/store';

import styles from './PublicStackDescription.module.scss';


type TProps = {
	item: TAccountStack;
	parent?: Element;
};

export function PublicStackDescription(props: TProps) {
	const { item, parent: parentElement } = props;
	const { image, name, description } = item;

	const elem = (
		<div className={ styles.veil }>
			<div className={ styles.container }>
				<img className={ styles.image } src={ image } />
				<span className={ styles.name }>{ name }</span>
				<p className={ styles.text }>{ description }</p>
			</div>
		</div>
	);

	const parent = parentElement || document.body;

	return createPortal(elem, parent);
}
