import { useLocalization } from 'src/toolkit';
import { TPublicExperience } from 'src/store';

import { PublicCvExperiencePositionName, PublicCvLastWorkCompanyName, PublicCvLastWorkExperienceCount } from './components';
import { PUBLIC_CV_LAST_WORK_LOCALIZATION } from './localization';

import styles from './PublicCvLastWork.module.scss';


type TProps = {
	item: TPublicExperience;
};

export function PublicCvLastWork(props: TProps) {
	const { headline } = useLocalization(PUBLIC_CV_LAST_WORK_LOCALIZATION);
	const { item } = props;
	const {
		companyName,
		companyUri,
		positionName,
		dateStart,
		dateEnd,
	} = item;

	return (
		<div className={ styles.box }>
			<div className={ styles.headline }>{ headline }</div>
			<PublicCvExperiencePositionName name={ positionName }/>

			<div className={ styles.content }>
				<PublicCvLastWorkCompanyName name={ companyName } path={ companyUri } />
				<span>, </span>
				<PublicCvLastWorkExperienceCount dateStart={ dateStart } dateEnd={ dateEnd } />
			</div>
		</div>
	);
}
