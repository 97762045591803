import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { TStack, TStackLocaleUpdate, TStackResponse, adaptStackFromResponse } from '../../types';
import { useAppSelector } from '../../hooks';
import { BACKEND_ADMIN_STACKS } from '../../backend-paths';


export const stackLocaleUpdateApi = createAsyncThunk(
	`PUT/${BACKEND_ADMIN_STACKS}/:alias/:locale`,
	(payload: TStackLocaleUpdate, thunkApi) => {
		const { data, alias, locale } = payload;
		const path = `${BACKEND_ADMIN_STACKS}/${alias}/${locale}`;

		return HttpService.put<TStackResponse>(path, data).then(result => {
			return adaptStackFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item: TStack | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const stackLocaleUpdateSlice = createSlice({
	name: 'stackLocaleUpdateSlice',
	initialState,
	reducers: {
		clearStackLocaleUpdate(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[stackLocaleUpdateApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[stackLocaleUpdateApi.fulfilled.type]: (state, action: PayloadAction<TStack>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[stackLocaleUpdateApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useStackLocaleUpdateSelector = () => useAppSelector(state => state[stackLocaleUpdateSlice.name]);

export const { clearStackLocaleUpdate } = stackLocaleUpdateSlice.actions;
