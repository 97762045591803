import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../../hooks';
import { TAccountCv, TAccountCvResponse, adaptAccountCvFromResponse } from '../../types';
import { BACKEND_ACCOUNT_CVS } from '../../backend-paths';


type TAccountCvReadParams = {
	alias: string;
	locale?: string;
}

export const accountCvReadGetApi = createAsyncThunk(
	`FETCH/${BACKEND_ACCOUNT_CVS}/:alias`,
	(payload: TAccountCvReadParams, thunkApi) => {
		const { alias, locale } = payload;
		const path = `${BACKEND_ACCOUNT_CVS}/${alias}`;
		const params = locale ? { locale } : {};

		return HttpService.get<TAccountCvResponse>(path, { params }).then(result => {
			return adaptAccountCvFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	data: TAccountCv | undefined,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	data: undefined,
	isLoading: false,
	error: undefined,
};

export const accountCvReadSlice = createSlice({
	name: 'accountCvReadSlice',
	initialState,
	reducers: {
		setAccountCvRead(state, action: PayloadAction<TAccountCv>) {
			state.data = action.payload;
		},

		clearAccountCvRead(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountCvReadGetApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountCvReadGetApi.fulfilled.type]: (state, action: PayloadAction<TAccountCv>) => {
			state.isLoading = false;
			state.data = action.payload;
		},

		[accountCvReadGetApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountCvReadSelector = () => useAppSelector(state => state[accountCvReadSlice.name]);

export const { clearAccountCvRead, setAccountCvRead } = accountCvReadSlice.actions;
