import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TStackLocale, setStackLocaleRead, stackLocaleUpdateApi, useAppDispatch, useCredentialsSelector, useStackLocaleUpdateSelector } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { LocaleField } from 'src/pages/components';
import { PATH_STACK, ROLES } from 'src/routes';

import { StackDescriptionField } from '../../../../components';
import { STACK_LOCALE_UPDATE_LOCALIZATION } from '../../localization';


type TProps = TStackLocale & {
	alias: string;
};

export function StackLocaleFormUpdate(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { roles } = useCredentialsSelector();
	const { item, isLoading, error } = useStackLocaleUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(STACK_LOCALE_UPDATE_LOCALIZATION);

	const {
		description = '',
		locale = '',
		alias,
	} = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const descriptionField = useInputFormField({
		name: 'description',
		defaultValue: description,
		validation: value => formValidateText(value, { max: 512 }),
	});

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				descriptionField,
				localeField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setStackLocaleRead(item));

			const { alias: changedAlias, locale: changedLocale } = item;
			if (alias !== changedAlias || locale !== changedLocale) {
				navigate(`${PATH_STACK}/${changedAlias}/${changedLocale}`);
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			localeField.validate(),
			descriptionField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			locale: localeField.value,
			description: descriptionField.value,
		};

		dispatch(stackLocaleUpdateApi({ data: dataRequest, alias, locale }));
	};

	const isUpdate = roles.includes(ROLES.UPDATE_STACKS);

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading || !isUpdate } />

			<StackDescriptionField { ...descriptionField } isLoading={ isLoading } readOnly={ !isUpdate } />

			{ isUpdate && <FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice> }

			{ isUpdate &&
				<FormButtonWrap>
					<FormButton busy={ isLoading }>{ button }</FormButton>
				</FormButtonWrap>
			}
		</FormBox>
	);
}
