import { FormButtonLink } from 'src/components';
import { PATH_SIGN_IN } from 'src/routes';
import { useLocalization } from 'src/toolkit';

import { SIGN_UP_EMAIL_SUCCESS_LOCALIZATION } from './localization';

import styles from '../../../../../../components/stages/SignUpStages.module.scss';


type TProps = {
	email: string;
};

export function SignUpStageEmailSuccess(props: TProps) {
	const { email } = props;
	const {
		successTextLeftHalf,
		successTextRightHalf,
		buttonText,
	} = useLocalization(SIGN_UP_EMAIL_SUCCESS_LOCALIZATION);

	return (
		<div className={ styles.succesContainer }>
			<p className={ styles.paragraph }>
				{ successTextLeftHalf } «{ email }» { successTextRightHalf }
			</p>

			<FormButtonLink
				className={ styles.button }
				to={ PATH_SIGN_IN }
			>{ buttonText }</FormButtonLink>
		</div>
	);
}
