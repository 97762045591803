import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { NavMenu } from 'src/components';
import { Footer } from 'src/components';

import styles from './EmptyLayout.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function EmptyLayout(props: TProps) {
	const { children } = props;

	return (
		<div className={ styles.layout }>
			<NavMenu>
				<main>
					{ children }
				</main>

				<Footer />
			</NavMenu>
		</div>
	);
}
