import { TPublicCvLanguage } from 'src/store';

import styles from './PublicCvLanguagesItem.module.scss';

type TProps = {
	item: TPublicCvLanguage;
};

export function PublicCvLanguagesItem(props: TProps) {
	const { item } = props;
	const { language, level } = item;

	return (
		<div className={ styles.row }>
			<span className={ styles.box }>{ language } — { level }</span>
		</div>
	);
}
