import { TListResponse } from 'src/common/types';
import { TTableSort, TTableSortCols } from 'src/components/table/thead/col';
import { PATH_DEFAULT_STACK, PATH_STATIC_STACK_IMAGES } from 'src/routes';


export const StackSortCol = {
	UPDATED_AT: 'updated_at',
	CREATED_AT: 'created_at',
	NAME: 'name',
};

export type TStackSortCol = TTableSortCols<typeof StackSortCol>;

export type TStackQuery = {
	locale: string;
	limit: number;
	offset: number;
	search?: string;
	sort?: TTableSort;
	sortCol?: TStackSortCol;
};

export type TStackQueryRequest = {
	locale: string;
	limit?: number;
	offset?: number;
	search?: string;
	sort?: string;
	sort_col?: string;
};

export type TStack = TStackLocale & {
  name: string;
  alias: string;
  image: string;
	locales: string[];
};

export type TStackLocale = {
  locale: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type TStackLocaleRequest = Omit<TStackLocale, 'createdAt' | 'updatedAt'>;

export type TStackLocaleBodyRequest = TStackLocaleRequest;

export type TAccountStack = Omit<TStack, 'createdAt' | 'updatedAt' | 'locale' | 'locales'>;

export type TStackResponse = {
  name: string;
  alias: string;
  description?: string;
  image?: string;
	locale?: string;
	locales?: string[];
  created_at: string;
  updated_at: string;
};

export type TAccountStackResponse = Omit<TStackResponse, 'created_at' | 'updated_at' | 'locale' | 'locales'>;

export type TStackListResponse = TListResponse<TStackResponse>;

export type TStackList = TListResponse<TStack>;

export type TStackRequest = Omit<TStack, 'createdAt' | 'updatedAt' | 'image' | 'locale' | 'locales' | 'description'> & {
	image?: string | File;
};

export type TStackUpdate = {
	alias: string;
	data: TStackRequest;
};

export type TStackLocaleParam = {
	alias: string;
	locale: string;
};

export type TStackLocaleUpdate = TStackLocaleParam & {
	data: TStackLocaleBodyRequest;
};

export type TStackLocaleCreate = Omit<TStackLocaleUpdate, 'locale'>;

export function adaptStackLocaleToRequest(data: TStackLocaleBodyRequest): TStackLocaleRequest {
	const {
		locale,
		description,
	} = data;

	return {
		locale,
		description,
	};
}

export function adaptStackLocaleFromResponse(data: TStackResponse): TStackLocale {
	const {
		locale = '',
		description = '',
		created_at: createdAt,
		updated_at: updatedAt,
	} = data;

	return {
		locale,
		description,
		createdAt,
		updatedAt,
	};
}

export function adaptStackFromListResponse(data: TStackListResponse): TStackList {
	const {
		items: itemsRaw = [],
		...restFields
	} = data;

	const items = itemsRaw.map(item => adaptStackFromResponse(item));

	return {
		items,
		...restFields
	};
}

export function adaptStackFromResponse(data: TStackResponse): TStack {
	const {
		created_at: createdAt,
		updated_at: updatedAt,
		locale = '',
		locales = [],
		...restFields
	} = data;

	const accountStack = adaptAccountStackFromResponse(restFields);

	return {
		...accountStack,
		locale,
		locales,
		createdAt,
		updatedAt,
	};
}

export function adaptAccountStackFromResponse(data: TAccountStackResponse): TAccountStack {
	const {
		name,
		alias,
		description = '',
		image: imageRaw = '',
	} = data;

	const image = imageRaw ? `${PATH_STATIC_STACK_IMAGES}/${imageRaw}` : PATH_DEFAULT_STACK;

	return {
		name,
		alias,
		description,
		image,
	};
}
