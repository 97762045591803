import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';
import { TAccountEducation, adaptAccountEducationFromResponse } from 'src/store/types';

import { useAppSelector } from '../../hooks';
import { BACKEND_ACCOUNT_EDUCATIONS } from '../../backend-paths';


export const accountEducationDeleteApi = createAsyncThunk(
	`DELETE/${BACKEND_ACCOUNT_EDUCATIONS}/:pid`,
	(payload: string, thunkApi) => {
		const path = `${BACKEND_ACCOUNT_EDUCATIONS}/${payload}`;

		return HttpService.delete(path).then(result => {
			return adaptAccountEducationFromResponse(result.data);
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TInitialState = {
	item?: TAccountEducation,
	isLoading: boolean,
	error?: HttpError,
};

const initialState: TInitialState = {
	item: undefined,
	isLoading: false,
	error: undefined,
};

export const accountEducationDeleteSlice = createSlice({
	name: 'accountEducationDeleteSlice',
	initialState,
	reducers: {
		clearAccountEducationDelete(state) {
			return { ...initialState };
		},
	},

	extraReducers: {
		[accountEducationDeleteApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[accountEducationDeleteApi.fulfilled.type]: (state, action: PayloadAction<TAccountEducation>) => {
			state.isLoading = false;
			state.item = action.payload;
		},

		[accountEducationDeleteApi.rejected.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
		},
	},
});

export const useAccountEducationDeleteSelector = () => useAppSelector(state => state[accountEducationDeleteSlice.name]);

export const { clearAccountEducationDelete } = accountEducationDeleteSlice.actions;
