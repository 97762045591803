export const SIGN_UP_MAIN_FORM_RU_LOCALIZATION = {
	nicknameLegend: 'Никнейм',
	nicknamePlaceholder: 'example_nickname',
	nicknameTitle: 'Введите ваш Никнейм',

	registrationTypeLegend: 'Регистрация по',
	phoneNumberTypeText: 'Номеру телефона',
	emailTypeText: 'Электронной почте',

	phoneNumberLegend: 'Номер телефона',
	phoneNumberTitle: 'Введите ваш Номер телефона',

	emailLegend: 'Электронная почта',
	emailPlaceholder: 'example@mail.ru',
	emailTitle: 'Введите вашу Электронную почту',

	passwordLegend: 'Пароль',
	passwordTitle: 'Введите устанавливаемый пароль',

	passwordConfirmLegend: 'Повторите пароль',
	passwordConfirmTitle: 'Повторите устанавливаемый пароль',

	acceptPolicyText: 'Я принимаю условия пользовательского соглашения',

	buttonText: 'Зарегистрироваться',
};
