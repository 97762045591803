import { HeadlineLink, LocaleBox } from 'src/pages/components';
import { useLocalization } from 'src/toolkit';

import { PUBLIC_CV_VERSIONS_LOCALIZATION } from './localization';

import styles from './PublicCvVersions.module.scss';


type TProps = {
	className?: string;
	classNames?: string[];

	locales: string[];
	pathStart: string;
	pathEnd?: string;
};

export function PublicCvVersions(props: TProps) {
	const { headline } = useLocalization(PUBLIC_CV_VERSIONS_LOCALIZATION);
	const {
		className = '',
		classNames: classNamesOuter = [],

		locales = [],
		pathStart = '',
		pathEnd = '',

		...restProps
	} = props;

	const classNames = [styles.box, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ classNames.join(' ') } { ...restProps }>
			<HeadlineLink className={ styles.headline }>{ headline }</HeadlineLink>

			<LocaleBox className={ styles.versions } pathStart={ pathStart } pathEnd={ pathEnd } items={ locales }/>
		</div>
	);
}
