import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline, PageRow, Preloader } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { PATH_USERS } from 'src/routes';
import { isInvalidNickname, useLocalization } from 'src/toolkit';
import { setTitle } from 'src/common/helpers';
import { userEmailReadGetApi, clearUserEmailRead, clearUserEmailUpdate, useUserEmailReadSelector, useAppDispatch } from 'src/store';
import { ErrorWidget } from 'src/pages/components';

import { USERS_COMMON_LOCALIZATION } from '../../localization';

import { UserEmailDetails, UserEmailForm } from './components';
import { USERS_EMAIL_COMMON_LOCALIZATION } from './localization';


export function UserEmailPage() {
	const dispatch = useAppDispatch();
	const { nickname = '' } = useParams();
	const { item, isLoading, error } = useUserEmailReadSelector();
	const { breadCrumbList } = useLocalization(USERS_COMMON_LOCALIZATION);
	const {
		title,
		headline,
		breadCrumbItem,
	} = useLocalization(USERS_EMAIL_COMMON_LOCALIZATION);

	const isInvalidNick = isInvalidNickname(nickname);

	useEffect(() => {
		if (!isInvalidNick) {
			dispatch(userEmailReadGetApi(nickname));
		}

		return () => {
			dispatch(clearUserEmailRead());
			dispatch(clearUserEmailUpdate());
		};
	}, []);

	if (isLoading || !item) {
		return (
			<AccountLayout>
				<div>
					<Preloader />
				</div>
			</AccountLayout>
		);
	}

	if (error || isInvalidNick) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	setTitle(title);

	const items = [
		{ to: PATH_USERS, text: breadCrumbList },
		{ to: `${PATH_USERS}/${nickname}`, text: nickname },
		{ to: `${PATH_USERS}/${nickname}/email`, text: breadCrumbItem },
	];

	return (
		<AccountLayout>
			<BreadCrumbs items={ items } />

			<PageHeadline>{ headline }</PageHeadline>

			<PageRow>
				<PageCol>
					<UserEmailForm { ...item } nickname={ nickname } />
				</PageCol>

				{ item.createdAt &&
					<PageCol>
						<UserEmailDetails { ...item } nickname={ nickname } />
					</PageCol>
				}
			</PageRow>
		</AccountLayout>
	);
}
