export const RU_LOCALIZATION = {
	until: 'По настоящее время',

	januaryParental: 'Январь',
	februaryParental: 'Февраль',
	marchParental: 'Март',
	aprilParental: 'Апрель',
	mayParental: 'Май',
	juneParental: 'Июнь',
	julyParental: 'Июль',
	augustParental: 'Август',
	septemberParental: 'Сентябрь',
	octoberParental: 'Октябрь',
	novemberParental: 'Ноябрь',
	decemberParental: 'Декабрь',

	januaryNominative: 'Января',
	februaryNominative: 'Февраля',
	marchNominative: 'Марта',
	aprilNominative: 'Апреля',
	mayNominative: 'Мая',
	juneNominative: 'Июня',
	julyNominative: 'Июля',
	augustNominative: 'Августа',
	septemberNominative: 'Сентября',
	octoberNominative: 'Октября',
	novemberNominative: 'Ноября',
	decemberNominative: 'Декабря',
};
