import { CvHeadline } from 'src/components';
import { TPublicProjectFile } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { PublicFileItem } from './components';
import { PUBLIC_FILE_BOX_LOCALIZATION } from './localization';

import styles from './PublicFileBox.module.scss';


type TProps = {
	items: TPublicProjectFile[];
};

export function PublicFileBox(props: TProps) {
	const { headline } = useLocalization(PUBLIC_FILE_BOX_LOCALIZATION);
	const { items } = props;

	if (items.length === 0) {
		return (
			<></>
		);
	}

	return (
		<div className={ styles.box }>
			<CvHeadline>{ headline }</CvHeadline>

			<div className={ styles.items }>
				{
					items.map(item => {
						return (
							<PublicFileItem
								key={ item.path }
								item={ item }
							/>
						);
					})
				}
			</div>
		</div>
	);
}
