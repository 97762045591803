import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Preloader, BreadCrumbs, PageCol, PageHeadline, PageRow } from 'src/components';
import { clearAccountEducationUpdate, useAppDispatch, useAccountEducationReadSelector, accountEducationReadGetApi, clearAccountEducationRead, useAccountEducationsSelector, setAccountEducationRead, clearAccountEducationCreatedItem, useAccountSourceEducationTypesSelector, sourceEducationTypesGetApi, useCredentialsSelector } from 'src/store';
import { ErrorWidget } from 'src/pages/components';
import { isInvalidPid, libFormatDate, useLocalization } from 'src/toolkit';
import { AccountLayout } from 'src/layouts';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_EDUCATION, PATH_ACCOUNT_EDUCATION_CREATE_END } from 'src/routes';

import { AccountEducationLocaleCreateWidget } from '../../widgets';
import { ACCOUNT_EDUCATION_COMMON_LOCALIZATION } from '../../localization';

import { AcountEducationFormUpdate, AccountEducationDetails } from './components';
import { ACCOUNT_EDUCATION_UPDATE_LOCALIZATION } from './localization';


export function AccountEducationUpdatePage() {
	const dispatch = useAppDispatch();
	const { pathname, hash } = useLocation();
	const { lang } = useCredentialsSelector();
	const { list, isLoading: isLoadingItems } = useAccountSourceEducationTypesSelector();
	const { pid = '' } = useParams();
	const { item: created } = useAccountEducationsSelector();
	const { data: item, isLoading, error } = useAccountEducationReadSelector();
	const { breadCrumbList } = useLocalization(ACCOUNT_EDUCATION_COMMON_LOCALIZATION);
	const { breadCrumbItem } = useLocalization(ACCOUNT_EDUCATION_UPDATE_LOCALIZATION);

	const isInvalidId = isInvalidPid(pid);

	useEffect(() => {
		const isExisted = created && created.pid === pid;
		const isNotRequest = isInvalidId || isExisted;

		if (!list) {
			dispatch(sourceEducationTypesGetApi(lang));
		}

		if (isExisted) {
			dispatch(setAccountEducationRead(created));
		}

		if (!isNotRequest) {
			dispatch(accountEducationReadGetApi(pid));
		}

		return () => {
			dispatch(clearAccountEducationUpdate());
			dispatch(clearAccountEducationRead());
			dispatch(clearAccountEducationCreatedItem());
		};
	}, []);

	if (isInvalidId) {
		return (
			<ErrorWidget />
		);
	}

	if (isLoading || isLoadingItems || !item || !list) {
		return (
			<AccountLayout>
				<div>
					<Preloader/>
				</div>
			</AccountLayout>
		);
	}

	if (error) {
		return (
			<ErrorWidget { ...error } />
		);
	}

	if (hash === PATH_ACCOUNT_EDUCATION_CREATE_END) {
		return (
			<AccountEducationLocaleCreateWidget { ...item } />
		);
	}

	const { items } = list;
	const { dateEnd } = item;

	const year = libFormatDate(dateEnd, 'yyyy');

	setTitle(`${breadCrumbItem} ${year}`);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_EDUCATION, text: breadCrumbList },
		{ to: pathname, text: year },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ breadCrumbItem } { year }</PageHeadline>

				<PageRow>
					<PageCol>
						<AcountEducationFormUpdate { ...item } items={ items } />
					</PageCol>

					<PageCol>
						<AccountEducationDetails { ...item } />
					</PageCol>
				</PageRow>
			</div>
		</AccountLayout>
	);
}
