import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { useLocalization } from 'src/toolkit';

import { PUBLIC_CV_LINKEDIN_LOCALIZATION } from './localization';

import styles from './PublicCvLinkedIn.module.scss';
import { Link } from 'src/components';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	classNames?: string[];
	value?: string;
};

export function PublicCvLinkedIn(props: TProps) {
	const { link, text } = useLocalization(PUBLIC_CV_LINKEDIN_LOCALIZATION);

	const {
		value,
		className = '',
		classNames: classNamesOuter = [],
		...restProps
	} = props;

	const classNames = [styles.headline, ...classNamesOuter];
	if (className) {
		classNames.push(className);
	}

	return (
		<div { ...restProps } className={ classNames.join(' ') }>
			<span className={ styles.text }>{ text } </span><Link to={ value } target='_blank'>{ link }</Link>
		</div>
	);
}
