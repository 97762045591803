// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicFileBox_box__nXDhD {\n  margin: 1em 0 1em 1em;\n  position: relative;\n  z-index: 1;\n}\n\n.PublicFileBox_items__appMj {\n  display: flex;\n  margin-top: 0.5em;\n  -webkit-user-select: none;\n          user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/components/public-file-box/PublicFileBox.module.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;EACA,kBAAA;EACA,UAAA;AACD;;AAEA;EACC,aAAA;EACA,iBAAA;EACA,yBAAA;UAAA,iBAAA;AACD","sourcesContent":[".box {\n\tmargin: 1em 0 1em 1em;\n\tposition: relative;\n\tz-index: 1;\n}\n\n.items {\n\tdisplay: flex;\n\tmargin-top: 0.5em;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicFileBox_box__nXDhD",
	"items": "PublicFileBox_items__appMj"
};
export default ___CSS_LOADER_EXPORT___;
