// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountCvPublicLink_box__YjzGO {\n  margin: 1em 0;\n}\n\n.AccountCvPublicLink_headline__WgmxQ {\n  color: rgb(120, 120, 120);\n  font-size: 1.25em;\n  margin-top: 1.5em;\n}\n\n.AccountCvPublicLink_items__JBN9M {\n  margin-top: 0.5em;\n}", "",{"version":3,"sources":["webpack://./src/pages/account-cvs/views/locale-update/components/details/components/public-link/AccountCvPublicLink.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AAEA;EACC,yBAAA;EACA,iBAAA;EACA,iBAAA;AACD;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".box {\n\tmargin: 1em 0;\n}\n\n.headline {\n\tcolor: rgba(120, 120, 120, 1);\n\tfont-size: 1.25em;\n\tmargin-top: 1.5em;\n}\n\n.items {\n\tmargin-top: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "AccountCvPublicLink_box__YjzGO",
	"headline": "AccountCvPublicLink_headline__WgmxQ",
	"items": "AccountCvPublicLink_items__JBN9M"
};
export default ___CSS_LOADER_EXPORT___;
