import { InputHTMLAttributes, RefAttributes, forwardRef } from 'react';
import ReactInputMask from 'react-input-mask';

import styles from './FormInput.module.scss';

type TProps = InputHTMLAttributes<HTMLInputElement> & RefAttributes<ReactInputMask> & {
	mask?: string;
};

export const FormInputMask = forwardRef<ReactInputMask, TProps>((props, ref) => {
	const {
		mask = '',
		...restProps
	} = props;

	return (
		<ReactInputMask
			mask={ mask }
			ref={ ref }
			className={ styles.formInput }
			{ ...restProps }
		/>
	);
});
