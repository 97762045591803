import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './PageButtonLink.module.scss';


type TProps = {
	className?: string;
	href?: string;
	text?: string;
	style?: Record<string, string>;
	children?: ReactNode;
};

export function PageButtonLink(props: TProps) {
	const { className = '', href = '#', text = '', children } = props;
	const classNames = [];

	if (styles.className) {
		classNames.push(styles[className]);
	}

	return (
		<NavLink
			to={ href }
			className={ styles.pageButtonLink }
		>
			{ children }
			<div className={ styles.text }>{ text }</div>
		</NavLink>
	);
}

{/* <PageIcon
class="icon"
></PageIcon> */}
