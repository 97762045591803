import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH_ACCOUNT_CVS } from 'src/routes';
import { FormButtonWrap, FormButton, FormNotice, FormBox } from 'src/components';
import { TAccountCv, accountCvLocaleUpdateApi, setAccountCvLocaleRead, useAppDispatch, useAccountCvLocaleUpdateSelector, TCurrency, DefaultCurrency } from 'src/store';
import { formHandleServerError, formValidateText, useInputFormField, useLocalization } from 'src/toolkit';
import { LocaleField } from 'src/pages/components';

import { AccountCvCurrencyField, AccountCvCityField, AccountCvDescriptionField, AccountCvFirstNameField, AccountCvLastNameField, AccountCvPreferredSalaryField, AccountCvTitleField, AccountCvFieldsRow } from '../../../../components';
import { ACCOUNT_CVS_LOCALE_UPDATE_LOCALIZATION } from '../../localization';


type TProps = TAccountCv & {
	currenciesSource: TCurrency[];
};

export function AccountCvsLocaleFormUpdate(props: TProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { item, isLoading, error } = useAccountCvLocaleUpdateSelector();
	const [notice, setNotice] = useState('');
	const { button, noticeSuccess } = useLocalization(ACCOUNT_CVS_LOCALE_UPDATE_LOCALIZATION);

	const {
		locale = '',
		alias = '',
		firstName = '',
		lastName = '',
		city = '',
		preferredSalary = '',
		currency = DefaultCurrency,
		title = '',
		description = '',
		currenciesSource = [],
	} = props;

	const localeField = useInputFormField({
		name: 'locale',
		defaultValue: locale,
		validation: value => formValidateText(value),
	});

	const firstNameField = useInputFormField({
		name: 'first_name',
		defaultValue: firstName,
		validation: value => formValidateText(value),
	});

	const lastNameField = useInputFormField({
		name: 'last_name',
		defaultValue: lastName,
		validation: value => formValidateText(value),
	});

	const cityField = useInputFormField({
		name: 'city',
		defaultValue: city,
		validation: value => formValidateText(value),
	});

	const preferredSalaryField = useInputFormField({
		name: 'preferred_salary',
		defaultValue: preferredSalary,
		validation: value => formValidateText(value),
	});

	const currencyField = useInputFormField({
		name: 'currency',
		defaultValue: currency,
		validation: value => formValidateText(value),
	});

	const titleField = useInputFormField({
		name: 'title',
		defaultValue: title,
		validation: value => formValidateText(value),
	});

	const descriptionField = useInputFormField({
		name: 'description',
		defaultValue: description,
		validation: value => formValidateText(value, { max: 512 }),
	});

	useEffect(() => {
		if (error) {
			setNotice('');

			formHandleServerError(error, setNotice,
				localeField,
				lastNameField,
				firstNameField,
				cityField,
				preferredSalaryField,
				currencyField,
				titleField,
				descriptionField,
			);
		}
	}, [error]);

	useEffect(() => {
		if (item) {
			setNotice(noticeSuccess);
			dispatch(setAccountCvLocaleRead(item));

			const { alias: changedAlias, locale: changedLocale } = item;
			if (alias !== changedAlias || locale !== changedLocale) {
				navigate(`${PATH_ACCOUNT_CVS}/${changedAlias}/${changedLocale}`);
			}
		}
	}, [item]);

	const onSubmit = () => {
		const errors = [
			localeField.validate(),
			lastNameField.validate(),
			firstNameField.validate(),
			cityField.validate(),
			preferredSalaryField.validate(),
			currencyField.validate(),
			titleField.validate(),
			descriptionField.validate(),
		];

		const isInvalidForm = errors.includes(true);

		if (isInvalidForm) {
			return;
		}

		const dataRequest = {
			locale: localeField.value,
			lastName: lastNameField.value,
			firstName: firstNameField.value,
			city: cityField.value,
			preferredSalary: preferredSalaryField.value,
			currency: currencyField.value,
			title: titleField.value,
			description: descriptionField.value,
		};

		dispatch(accountCvLocaleUpdateApi({ data: dataRequest, alias, locale }));
	};

	return (
		<FormBox onSubmit={ onSubmit }>
			<LocaleField { ...localeField } isLoading={ isLoading } />

			<AccountCvFirstNameField { ...firstNameField } isLoading={ isLoading } />

			<AccountCvLastNameField { ...lastNameField } isLoading={ isLoading } />

			<AccountCvCityField { ...cityField } isLoading={ isLoading } />

			<AccountCvFieldsRow>
				<AccountCvPreferredSalaryField { ...preferredSalaryField } isLoading={ isLoading } />
				<AccountCvCurrencyField { ...currencyField } isLoading={ isLoading } items={ currenciesSource } />
			</AccountCvFieldsRow>

			<AccountCvTitleField { ...titleField } isLoading={ isLoading } />

			<AccountCvDescriptionField { ...descriptionField } isLoading={ isLoading } />

			<FormNotice success={ !!item } error={ !!error }>{ notice }</FormNotice>

			<FormButtonWrap>
				<FormButton busy={ isLoading }>{ button }</FormButton>
			</FormButtonWrap>
		</FormBox>
	);
}
