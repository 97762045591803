import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HttpError, HttpService } from 'src/toolkit';

import { useAppSelector } from '../hooks';

function toRequestEmail(data: TSignUpEmail): TSignUpEmailRequest {
	const { emailExpected, ...restFields } = data;

	return {
		email_expected: emailExpected,
		...restFields
	};
}

export type TSignUpEmail = {
  nickname: string;
  password: string;
  emailExpected: string;
};

export type TSignUpEmailRequest = {
  nickname: string;
  password: string;
  email_expected: string;
};

export type TSignUpResponse = {
	nickname: string;
	image?: string;
};

const enum BackendSignUp {
	EMAIL = '/auth/sign-up/email',
	EMAIL_CONFIRM = '/auth/sign-up/email/confirm',
	PHONE_NUMBER = '/auth/sign-up/phone-number',
	PHONE_NUMBER_CONFIRM = '/auth/sign-up/phone-number/confirm',
}

export const signUpEmailApi = createAsyncThunk(
	BackendSignUp.EMAIL,
	(payload: TSignUpEmail, thunkApi) => {
		const dataRequest = toRequestEmail(payload);

		return HttpService.post<TSignUpEmail>(BackendSignUp.EMAIL, dataRequest).then(result => {
			return payload;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TSignUpEmailResponse = {
	nickname: string;
	image?: string;
};

export const signUpEmailConfirmApi = createAsyncThunk(
	BackendSignUp.EMAIL_CONFIRM,
	(payload: string, thunkApi) => {
		const dataRequest = { hashcode: payload };

		return HttpService.post<TSignUpEmailResponse>(BackendSignUp.EMAIL_CONFIRM, dataRequest).then(result => {
			return result.data;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toRequestPhoneNumber(data: TSignUpPhoneNumber): TSignUpPhoneNumberRequest {
	const { phoneNumberExpected, ...restFields } = data;

	return {
		phone_number_expected: phoneNumberExpected,
		...restFields
	};
}

export type TSignUpPhoneNumber = {
  nickname: string;
  password: string;
  phoneNumberExpected: string;
};

export type TSignUpPhoneNumberRequest = {
  nickname: string;
  password: string;
  phone_number_expected: string;
};

export const signUpPhoneNumberApi = createAsyncThunk(
	BackendSignUp.PHONE_NUMBER,
	(payload: TSignUpPhoneNumber, thunkApi) => {
		const dataRequest = toRequestPhoneNumber(payload);

		return HttpService.post<TSignUpPhoneNumber>(BackendSignUp.PHONE_NUMBER, dataRequest).then(result => {
			return payload;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

function toRequestPhoneNumberConfirm(data: TSignUpPhoneNumberConfirm): TSignUpPhoneNumberConfirmRequest {
	const { phoneNumberExpected, ...restFields } = data;

	return {
		phone_number_expected: phoneNumberExpected,
		...restFields
	};
}

export type TSignUpPhoneNumberConfirm = {
  code: string;
  phoneNumberExpected: string;
};

export type TSignUpPhoneNumberConfirmRequest = {
  code: string;
  phone_number_expected: string;
};

export const signUpPhoneNumberConfirmApi = createAsyncThunk(
	BackendSignUp.PHONE_NUMBER_CONFIRM,
	(payload: TSignUpPhoneNumberConfirm, thunkApi) => {
		const dataRequest = toRequestPhoneNumberConfirm(payload);

		return HttpService.post(BackendSignUp.PHONE_NUMBER_CONFIRM, dataRequest).then(result => {
			return result.data;
		}).catch((error: HttpError) => {
			return thunkApi.rejectWithValue({ ...error });
		});
	},
);

type TSignUpPageStage = typeof SignUpPageStage[keyof typeof SignUpPageStage];

export const enum SignUpPageStage {
	MAIN = 0,
	PHONE_NUMBER_CONFIRM = 1,
	PHONE_NUMBER_SUCCESS = 2,
	EMAIL_SUCCESS = 3,
}

type TInitialState = {
	login: string,
	email: string,
	phoneNumber: string,
	stage: TSignUpPageStage,
	isLoading: boolean,
	error: HttpError | undefined,
};

const initialState: TInitialState = {
	login: '',
	email: '',
	phoneNumber: '',
	stage: SignUpPageStage.MAIN,
	isLoading: false,
	error: undefined,
};

export const signUpSlice = createSlice({
	name: 'signUpSlice',
	initialState,
	reducers: {
		setSignUpLoginAction(state, action: PayloadAction<string>) {
			state.login = action.payload;
		},

		clearSignUpAction(state) {
			const { login } = state;

			return { ...initialState, login };
		},

		clearSignUpLoginAction(state) {
			state.login = '';
		},
	},

	extraReducers: {
		[signUpEmailApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[signUpEmailApi.fulfilled.type]: (state, action: PayloadAction<TSignUpEmail>) => {
			state.isLoading = false;
			state.error = undefined;
			state.email = action.payload.emailExpected;
			state.stage = SignUpPageStage.EMAIL_SUCCESS;
		},

		[signUpEmailApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		[signUpEmailConfirmApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[signUpEmailConfirmApi.fulfilled.type]: (state, action: PayloadAction<TSignUpEmailResponse>) => {
			state.isLoading = false;
			state.error = undefined;
			state.login = action.payload.nickname;
		},

		[signUpEmailConfirmApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		[signUpPhoneNumberApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[signUpPhoneNumberApi.fulfilled.type]: (state, action: PayloadAction<TSignUpPhoneNumber>) => {
			state.isLoading = false;
			state.error = undefined;
			state.stage = SignUpPageStage.PHONE_NUMBER_CONFIRM;
			state.phoneNumber = action.payload.phoneNumberExpected;;
			state.login = action.payload.nickname;
		},

		[signUpPhoneNumberApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},

		[signUpPhoneNumberConfirmApi.pending.type]: (state) => {
			state.isLoading = true;
			state.error = undefined;
		},

		[signUpPhoneNumberConfirmApi.fulfilled.type]: (state) => {
			state.isLoading = false;
			state.error = undefined;
			state.stage = SignUpPageStage.PHONE_NUMBER_SUCCESS;
		},

		[signUpPhoneNumberConfirmApi.rejected.type]: (state, action: PayloadAction<HttpError>) => {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const useSignUpSelector = () => useAppSelector(state => state[signUpSlice.name]);

export const { setSignUpLoginAction, clearSignUpAction, clearSignUpLoginAction } = signUpSlice.actions;
