// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicCvEmail_box__g2Uqx {\n  margin-top: 0.5em;\n}\n\n.PublicCvEmail_icon__m5H5K {\n  color: rgb(130, 130, 130);\n  font-size: 1.2em !important;\n  width: 1.2em !important;\n  vertical-align: middle;\n  margin: 0 0.25em 0 0 !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/public-cvs/components/contacts/components/email/PublicCvEmail.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AAEA;EACC,yBAAA;EACA,2BAAA;EACA,uBAAA;EACA,sBAAA;EACA,+BAAA;AACD","sourcesContent":[".box {\n\tmargin-top: 0.5em;\n}\n\n.icon {\n\tcolor: rgba(130, 130, 130, 1);\n\tfont-size: 1.2em !important;\n\twidth: 1.2em !important;\n\tvertical-align: middle;\n\tmargin: 0 0.25em 0 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PublicCvEmail_box__g2Uqx",
	"icon": "PublicCvEmail_icon__m5H5K"
};
export default ___CSS_LOADER_EXPORT___;
