import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './LinkDelete.module.scss';


type TProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export function LinkDelete(props: TProps) {
	const {
		children = '',
		className = '',
		...restProps
	} = props;

	const classNames = [styles.link];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className={ styles.linkContainer }>
			<span
				className={ classNames.join(' ') }
				{ ...restProps }
			>
				{ children }
			</span>
		</div>
	);
}
