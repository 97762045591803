import { useState } from 'react';

import { FormButtonLink, FormField, FormIcon, FormInput, FormRow, Icon } from 'src/components';
import { TUser, useCredentialsSelector } from 'src/store';
import { libFormatDate, libNumberMask, useLocalization } from 'src/toolkit';
import { LinkDelete } from 'src/pages/components';

import { UserModalDelete } from './components';
import { USERS_DETAILS_LOCALIZATION } from './localization';

import styles from 'src/components/form/form-box/FormBox.module.scss';
import { PageButtonLink } from 'src/components/page/page-button-list';

import userDetailsStyles from './UserDetails.module.scss';
import { PATH_USERS, ROLES } from 'src/routes';


export function UserDetails(props: TUser) {
	const { roles } = useCredentialsSelector();

	const {
		nickname,
		phoneNumber: phoneNumberRaw,
		email = '',
		createdAt = '',
		updatedAt = '',
	} = props;

	const [modal, setModal] = useState(false);
	const {
		createdAtLegend,
		createdAtTitle,
		updatedAtLegend,
		updatedAtTitle,
		removeLinkText,
		passwordButton,
		rolesButton,

		phoneNumberLegend,
		phoneNumberPlaceholder,
		phoneNumberConfirmedTitle,
		phoneNumberNoneTitle,
		phoneNumberButtonTitle,

		emailLegend,
		emailPlaceholder,
		emailConfirmedTitle,
		emailNoneTitle,
		emailButtonTitle,

	} = useLocalization(USERS_DETAILS_LOCALIZATION);

	const openModal = () => {
		setModal(true);
	};

	const closeModal = () => {
		setModal(false);
	};

	const phoneNumber = phoneNumberRaw ? libNumberMask(phoneNumberRaw) : '';
	const phoneNumberTitle = phoneNumberRaw ? phoneNumberConfirmedTitle : phoneNumberNoneTitle;
	const emailTitle = email ? emailConfirmedTitle : emailNoneTitle;

	const phoneNumberPath = `${PATH_USERS}/${nickname}/phone-number`;
	const emailPath = `${PATH_USERS}/${nickname}/email`;
	const passwordPath = `${PATH_USERS}/${nickname}/password`;
	const rolesPath = `${PATH_USERS}/${nickname}/roles`;

	const isDelete = roles.includes(ROLES.DELETE_USERS);
	const isUpdate = roles.includes(ROLES.UPDATE_USERS);

	return (
		<div className={ styles.formBox }>
			<FormField
				legend={ phoneNumberLegend }
				confirmed={ !!phoneNumber }
			>
				<FormRow>
					<FormInput
						placeholder={ phoneNumberPlaceholder }
						title={ phoneNumberTitle }
						value={ phoneNumber }
						readOnly
					/>

					{ isUpdate &&
						<FormButtonLink
							mini
							to={ phoneNumberPath }
							tabIndex={ -1 }
							title={ phoneNumberButtonTitle }
						>
							<FormIcon icon="icon-gears" />
						</FormButtonLink>
					}
				</FormRow>
			</FormField>

			<FormField
				legend={ emailLegend }
				confirmed={ !!email }
			>
				<FormRow>
					<FormInput
						placeholder={ emailPlaceholder }
						title={ emailTitle }
						value={ email }
						readOnly
					/>

					{ isUpdate &&
						<FormButtonLink
							mini
							to={ emailPath }
							tabIndex={ -1 }
							title={ emailButtonTitle }
						>
							<FormIcon icon="icon-gears" />
						</FormButtonLink>
					}
				</FormRow>
			</FormField>

			{ isUpdate &&
				<div className={ userDetailsStyles.linkBox }>
					<PageButtonLink text={ rolesButton } href={ rolesPath }>
						<Icon icon="icon-keys" className={ userDetailsStyles.icon } />
					</PageButtonLink>

					<PageButtonLink text={ passwordButton } href={ passwordPath }>
						<Icon icon="icon-lock" className={ userDetailsStyles.icon } />
					</PageButtonLink>
				</div>
			}

			<FormField
				legend={ createdAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ createdAtTitle }
					value={ libFormatDate(createdAt) }
					readOnly
				/>
			</FormField>

			<FormField
				legend={ updatedAtLegend }
			>
				<FormInput
					placeholder="—"
					title={ updatedAtTitle }
					value={ libFormatDate(updatedAt) }
					readOnly
				/>
			</FormField>

			{ isDelete && <LinkDelete onClick={ openModal }>{ removeLinkText }</LinkDelete> }

			{ isDelete && modal && <UserModalDelete data={ props } onClose={ closeModal } /> }
		</div>
	);
}
