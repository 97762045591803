import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import styles from './TablePaginationItem.module.scss';


export type TTablePaginationItem = DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
	offset: number;
	label?: ReactNode,
	active?: boolean;
	disabled?: boolean;
	breakView?: boolean;
};

export function TablePaginationItem(props: TTablePaginationItem) {
	const {
		children,
		label,
		active = false,
		disabled = false,
		breakView = false,
		...restProps
	} = props;

	const content = children || label;

	const classNames = [styles.tablePaginationItem];

	if (active) {
		classNames.push(styles.active);
	}

	if (disabled) {
		classNames.push(styles.disabled);
	}

	if (breakView) {
		classNames.push(styles.breakView);
	}

	return (
		<a className={ classNames.join(' ') } { ...restProps } tabIndex={ 0 }>
			{ content }
		</a>
	);
}
