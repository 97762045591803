import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { AccountLayout } from 'src/layouts';
import { PATH_ACCOUNT, PATH_ACCOUNT_PASSWORD } from 'src/routes';
import { useLocalization } from 'src/toolkit';
import { setTitle } from 'src/common/helpers';

import { ACCOUNT_COMMON_LOCALIZATION } from '../../localization';

import { AccountPasswordForm } from './components';
import { ACCOUNT_PASSWORD_COMMON_LOCALIZATION } from './localization';


export function AccountPasswordPage() {
	const { breadCrumbAccount } = useLocalization(ACCOUNT_COMMON_LOCALIZATION);
	const {
		title,
		headline,
		breadCrumbPassword,
	} = useLocalization(ACCOUNT_PASSWORD_COMMON_LOCALIZATION);

	const items = [
		{ to: PATH_ACCOUNT, text: breadCrumbAccount },
		{ to: PATH_ACCOUNT_PASSWORD, text: breadCrumbPassword },
	];

	setTitle(title);

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ items } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountPasswordForm />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
