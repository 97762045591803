import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import { TTablePaginationItem, TablePaginationItem } from './item';

import styles from './TablePagination.module.scss';


export type TTablePagination = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	limit?: number;
	offset?: number;
	setOffset: (value: number) => void;
	count: number;
	itemsLength: number;
	breakLabel?: string;
	prevLabel?: ReactNode;
	nextLabel?: ReactNode;
	isLoading?: boolean;
	isArrowsControls?: boolean;
};

type TIsPrevDisabled = boolean;
type TIsNextDisabled = boolean;
type TGeneratePages = [TTablePaginationItem[], TIsPrevDisabled, TIsNextDisabled];

export function TablePagination(props: TTablePagination) {
	const {
		count,
		limit = 10,
		offset = 0,
		setOffset,
		prevLabel = '❮' || '❰' || '〈' || '<',
		nextLabel = '❯' || '❱' || '〉' || '>',
		isArrowsControls = true,
		isLoading,
	} = props;

	const [pages, isPrevDisabled, isNextDisabled] = generatePages(props);

	const onClickPrev = () => {
		const computedValue = offset - limit;

		if (computedValue < 0) {
			return setOffset(0);
		}

		setOffset(computedValue);
	};

	const onClickNext = () => {
		const computedValue = offset + limit;

		if (computedValue > count) {
			const value = count - (count % limit);

			return setOffset(value);
		}

		setOffset(computedValue);
	};

	return (
		<div className={ styles.tablePagination }>
			{ isArrowsControls && <TablePaginationItem key="prev" label={ prevLabel } offset={ limit } disabled={ isPrevDisabled } onClick={ onClickPrev } /> }

			{ pages.map(item => <TablePaginationItem key={ item.offset } disabled={ isLoading } onClick={ e => setOffset(item.offset) } { ...item } />) }

			{ isArrowsControls && <TablePaginationItem key="next" label={ nextLabel } offset={ limit } disabled={ isNextDisabled } onClick={ onClickNext } /> }
		</div>
	);
}

function generatePages(props: TTablePagination): TGeneratePages {
	const {
		limit = 10,
		offset = 0,
		count,
		breakLabel = '…',
		isLoading,
	} = props;

	const pageSelectRangeMargin = 1;
	const pageRangeMargin = 1;
	const pages = [];

	const pageCountDisplayed = (pageSelectRangeMargin + pageRangeMargin) * 2 + 1;
	const startOffsetLeft = 0;
	const pagesCount = Math.ceil(count / limit);

	if (pagesCount <= pageCountDisplayed) {
		let pageIndex = 1;
		for (let i = startOffsetLeft; i < count; pageIndex++) {
			pages.push({
				label: pageIndex,
				active: offset === i,
				offset: i,
			});

			i += limit;
		}
	} else {
		let pageIndexStart = 1;
		let endOffsetLeft = limit * pageRangeMargin;
		let isSetActive = false;

		const startOffsetSelected = offset - limit * pageSelectRangeMargin;
		const endOffsetSelected = offset + limit + limit * pageSelectRangeMargin;

		if (startOffsetSelected <= endOffsetLeft) {
			endOffsetLeft = Math.max(endOffsetLeft, endOffsetSelected);
		}

		for (let i = startOffsetLeft; i < endOffsetLeft; pageIndexStart++) {
			const isActive = offset === i;

			if (isActive) {
				const endOffsetActive = limit * pageSelectRangeMargin + limit;
				endOffsetLeft = Math.max(endOffsetLeft, endOffsetActive);
				isSetActive = true;
			}

			pages.push({
				label: pageIndexStart,
				active: isActive,
				offset: i,
			});

			i += limit;
		}

		const countMultipleOfLimit = count - (count % limit);
		const endOffsetRight = countMultipleOfLimit === count ? count - limit : countMultipleOfLimit;
		let startOffsetRight = endOffsetRight - limit * (pageRangeMargin - 1);

		if (isSetActive) {
			const computed = startOffsetRight - endOffsetLeft;

			if (computed <= limit) {
				const pageIndex = Math.ceil(endOffsetLeft / limit) + 1;

				pages.push({
					label: pageIndex,
					offset: endOffsetLeft,
				});
			} else {
				const noincludeOffset = endOffsetLeft - limit;
				const rawOffset = Math.ceil((startOffsetRight - noincludeOffset) / 2) + noincludeOffset;
				const breakOffset = rawOffset - limit + (limit - rawOffset % limit);

				pages.push({
					breakView: true,
					label: breakLabel,
					offset: breakOffset,
				});
			}
		} else {
			if (endOffsetSelected >= startOffsetRight) {
				startOffsetRight = Math.min(startOffsetSelected, startOffsetRight);

				const noincludeOffset = endOffsetLeft - limit;
				const computed = startOffsetRight - endOffsetLeft;

				if (computed <= limit) {
					const pageIndex = Math.ceil(endOffsetLeft / limit) + 1;

					pages.push({
						label: pageIndex,
						offset: endOffsetLeft,
					});
				} else {
					const rawOffset = Math.ceil((startOffsetRight - noincludeOffset) / 2) + noincludeOffset;
					const breakOffset = rawOffset - limit + (limit - rawOffset % limit);

					pages.push({
						breakView: true,
						label: breakLabel,
						offset: breakOffset,
					});
				}
			} else {
				const startOffsetCenter = offset - limit * pageSelectRangeMargin;
				const endOffsetCenter = offset + limit + limit * pageSelectRangeMargin;

				const noincludeOffset = startOffsetCenter - limit;

				const rawOffsetLeft = Math.floor((startOffsetCenter - noincludeOffset) / 2) + noincludeOffset;
				const breakOffsetLeft = rawOffsetLeft - limit + (limit - rawOffsetLeft % limit);

				if (breakOffsetLeft === limit) {
					const pageIndex = Math.ceil(endOffsetLeft / limit) + 1;

					pages.push({
						label: pageIndex,
						offset: endOffsetLeft,
					});
				} else {
					pages.push({
						breakView: true,
						label: breakLabel,
						offset: breakOffsetLeft,
					});
				}

				let pageIndexCenter = Math.ceil(startOffsetCenter / limit) + 1;

				for (let i = startOffsetCenter; i < endOffsetCenter; pageIndexCenter++) {
					pages.push({
						label: pageIndexCenter,
						active: offset === i,
						offset: i,
					});

					i += limit;
				}

				const computed = startOffsetRight - endOffsetCenter;
				const rawOffsetRight = endOffsetCenter - limit + (startOffsetRight - endOffsetCenter);
				const breakOffsetRight = rawOffsetRight - limit + (limit - rawOffsetRight % limit);

				if (computed === limit) {
					const pageIndex = Math.ceil(startOffsetRight / limit);

					pages.push({
						label: pageIndex,
						offset: breakOffsetRight,
					});
				} else {
					pages.push({
						breakView: true,
						label: breakLabel,
						offset: breakOffsetRight,
					});
				}
			}
		}

		let pageIndexEnd = Math.ceil(startOffsetRight / limit) + 1;

		for (let i = startOffsetRight; i <= endOffsetRight; pageIndexEnd++) {
			pages.push({
				label: pageIndexEnd,
				active: offset === i,
				offset: i,
			});

			i += limit;
		}
	}

	const isPrevDisabled = isLoading || offset <= startOffsetLeft;
	const isNextDisabled = isLoading || (offset + limit) >= count;

	return [pages, isPrevDisabled, isNextDisabled];
}
