import { TAccountStack } from 'src/store';
import { Icon } from 'src/components';

import styles from './StackItem.module.scss';


type TProps = {
	item: TAccountStack;
	onFocus: (value: TAccountStack) => void;
	onBlur: () => void;
	onClick: (value: TAccountStack) => void;
};

export function StackItem(props: TProps) {
	const { item, onFocus, onBlur, onClick } = props;
	const {
    image,
    name,
	} = item;

	const classNames = [styles.stackItem];

	return (
		<div className={ styles.row }>
			<div className={ classNames.join(' ') } onFocus={ e => onFocus(item) } onBlur={ onBlur } tabIndex={ -1 }>
				<img className={ styles.image } src={ image } />
				<span className={ styles.name }>{ name }</span>
			</div>

			<Icon className={ styles.remove } onClick={ e => onClick(item) } icon="icon-cancel" />
		</div>
	);
}
