import { FormField, FormInputMask } from 'src/components';
import { TInputFormField, useLocalization } from 'src/toolkit';

import { ACCOUNT_PHONE_NUMBER_CODE_FIELD_LOCALIZATION } from './localization';


type TProps = TInputFormField & {
	isLoading?: boolean;
	readOnly?: boolean;
};

export function AccountPhoneNumberCodeField(props: TProps) {
	const { legend, title } = useLocalization(ACCOUNT_PHONE_NUMBER_CODE_FIELD_LOCALIZATION);

	const {
		name,
		errors,
		value,
		setValue,
		isLoading,
	} = props;

	return (
		<FormField
			legend={ legend }
			errors={ errors }
			required
		>
			<FormInputMask
				placeholder="___ - ___"
				mask="999 - 999"
				title={ title }
				name={ name }
				value={ value }
				onChange={ e => setValue(e.target.value) }
				disabled={ isLoading }
			/>
		</FormField>
	);
}
