import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbs, PageCol, PageHeadline } from 'src/components';
import { setTitle } from 'src/common/helpers';
import { PATH_ACCOUNT_PROJECTS } from 'src/routes';
import { AccountLayout } from 'src/layouts';
import { TAccountProject, clearAccountProjectLocaleCreate, useAppDispatch, useCredentialsSelector } from 'src/store';
import { useLocalization } from 'src/toolkit';

import { ACCOUNT_PROJECTS_COMMON_LOCALIZATION } from '../../localization';

import { AccountProjectsLocalesFormCreate } from './components';
import { ACCOUNT_PROJECTS_LOCALES_CREATE_LOCALIZATION } from './localization';


export function AccountProjectsLocalesCreateWidget(props: TAccountProject) {
	const dispatch = useAppDispatch();
	const { lang } = useCredentialsSelector();
	const { pathname, hash } = useLocation();
	const { breadCrumbList } = useLocalization(ACCOUNT_PROJECTS_COMMON_LOCALIZATION);
	const { title: titleLocale, headline, breadCrumbNew } = useLocalization(ACCOUNT_PROJECTS_LOCALES_CREATE_LOCALIZATION);

	const { alias, title } = props;

	const initialItem = {
		locale: lang,
		title: '',
		description: '',
		createdAt: '',
		updatedAt: '',

		image: '',
		alias: '',
		uri: '',
		repositoryUri: '',
		files: [],
		stack: [],
		locales: [],
	};

	useEffect(() => {
		return () => {
			dispatch(clearAccountProjectLocaleCreate());
		};
	}, []);

	setTitle(titleLocale);

	const breadCrumbs = [
		{ to: PATH_ACCOUNT_PROJECTS, text: breadCrumbList },
		{ to: pathname, text: title },
		{ to: `${pathname}${hash}`, text: breadCrumbNew },
	];

	return (
		<AccountLayout>
			<div>
				<BreadCrumbs items={ breadCrumbs } />

				<PageHeadline>{ headline }</PageHeadline>

				<PageCol>
					<AccountProjectsLocalesFormCreate { ...initialItem } alias={ alias } />
				</PageCol>
			</div>
		</AccountLayout>
	);
}
