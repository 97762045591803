import { DetailedHTMLProps, TdHTMLAttributes } from 'react';

import { TableBodyCol, TableBodyRow } from '../tbody';

import styles from './TableRowEmpty.module.scss';


type TProps = DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;

export function TableRowEmpty(props: TProps) {
	const { children } = props;

	return (
		<TableBodyRow>
			<TableBodyCol className={ styles.tableRowEmpty } { ...props }>
				{ children }
			</TableBodyCol>
		</TableBodyRow>
	);
}
